import { Accordion } from 'react-bootstrap';
function HotelFaqSection({  faq }) {

    return (
        <>
            <section className="openings" id="current-openings" >
           <div className="container" data-aos="new-animation">
           <h2 className="bigHead text-center mb-5">Pricing FAQs</h2>
           <Accordion>
           { faq.map((faq, index) => {
         
            
              return (
                <Accordion.Item eventKey={index}  key={index}>
                <Accordion.Header>{faq.title}</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-12 ">
                      <p>{faq.desc}
                      </p>
                   </div>
                   </div>
  
  
                </Accordion.Body>
              </Accordion.Item>
             
              )
           })

          }
          
          
           
           </Accordion>
            </div>
            </section>
        </>
    );
}

export default HotelFaqSection;