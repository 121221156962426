import AllRoutes from "../../AllRoutes";
import viajeLogo from '../../assets/img/viaje-logo.png'

const TravelHeader = ({scrollToKeyFeatures, scrollToProfitCalculator, scrollToRouteReports}) => {

    return(
        <section style={{ padding: '0', borderBottom: '1px solid #fff', width: '100%', position: 'fixed', top: '43px', height: '4.5em', zIndex: 100 }} className='banner_retail main_banner bg-black'>
              <div className='container'>
              <div className="row w-100 banner_logo_div">
                        <div className="col-md-3">
                            <a href={AllRoutes.travel}>
                            <img src={viajeLogo} alt="Viaje Logo" id="banner-logo" className="travel-logo"/>
                            </a>
                        </div>
                        <div className="col-md-9 text-md-end d-none d-md-block">
                            <ul>
                                <li><a onClick={scrollToKeyFeatures}>KEY FEATURES</a></li>
                                <li><a onClick={scrollToRouteReports}>ROUTE REPORTS</a></li>
                                <li><a onClick={scrollToProfitCalculator}>PROFIT CALCULATOR</a></li>
                            </ul>
                        </div>
                    </div>
              </div>
            </section>
    )
};

export default TravelHeader;