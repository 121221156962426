
import entrepreneurindia from '../../assets/img/travel-award/entrepreneur-india.png'
import turbostart from '../../assets/img/travel-award/turbo-start.png'
import levelnext from '../../assets/img/travel-award/level-next.png'
import travelbiz from '../../assets/img/travel-award/travel-biz.png'
import mailaward from '../../assets/img/travel-award/mail.png'
import gartner from '../../assets/img/travel-award/gartner.png'

const Recoginition = ({ page_name, page_heading }) => {

  return (
    <section className={"black-bg " + page_name}>
      <div className={'recog_div recog_2 '} >
        <div className="container" data-aos="new-animation">
          <h2 className="bigHead text-center mb-5 ">{page_heading}</h2>
          <div className="row row-gap-3">
            <div className=" col-md-6 col-xl-3 px-4">
              <div className="recog_img_div">
                <img src={levelnext} alt="Level Next Logo" />
                <p className='award_desc'>India's top 10 most promising and innovative start-ups</p>
                <p className="award_cat">National Winner 2019</p>
              </div>
            </div>
            {/*  col-md-6 col-xl-3 end */}
            <div className=" col-md-6 col-xl-3 px-4">
              <div className="recog_img_div">
                <img src={turbostart} alt="Turbo Start Logo" />
                <p className='award_desc'>The most innovative start-ups in India</p>
                <p className="award_cat">Turbo Start Founders' Fellowship 2020</p>
              </div>
            </div>
            {/*  col-md-6 col-xl-3 end */}
            <div className=" col-md-6 col-xl-3 px-4">
              <div className="recog_img_div">
                <img src={entrepreneurindia} alt="Entrepreneur India Logo" />
                <p className='award_desc'>Travel Start-up of the year</p>
                <p className="award_cat">Entrepreneurship 2021</p>
              </div>
            </div>
            {/*  col-md-6 col-xl-3 end */}
            <div className=" col-md-6 col-xl-3 px-4">
              <div className="recog_img_div">

                <img src={mailaward} alt="Turbo Start Logo" />
                <p className='award_desc'>Maruti Suzuki MAIL</p>
                <p className="award_cat">Recognized by Maruti  Suzuki's Mobility and Automobile Innovation Lab, 2022</p>
              </div>
            </div>
            {/*  col-md-4 end */}
            <div className=" col-md-6 col-xl-3 px-4">
              <div className="recog_img_div">
                <img src={travelbiz} alt="Travel Biz Logo" />
                <p className='award_desc'>A new-age technology startup</p>
                <p className="award_cat">Recognized by Travel Biz Monitor, 2022</p>
              </div>
            </div>
            {/*  col-md-4 end */}
            <div className=" col-md-6 col-xl-3 px-4">
              <div className="recog_img_div">
                <img src={gartner} alt="Gartner Logo" />
                <p className='award_desc'>Best of badges to Sciative Solutions</p>
                <p className="award_cat">by Gartner for 2023</p>
              </div>
            </div>
            {/*  col-md-4 end */}

          </div>

        </div>

      </div>
    </section>
  );
}
Recoginition.defaultProps = {
  page_heading: "Awards & Recognition",
};


export default Recoginition;