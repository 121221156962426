import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import min_15 from '../../assets/img/hotels/15+MinutesPriceUpdates.png';
import accurate_rev from '../../assets/img/hotels/AccurateRevenueForecasting.png';
import ai_powered from '../../assets/img/hotels/AI-PoweredPricingAdjustments.png';
import comp_rev from '../../assets/img/hotels/ComprehensiveRevenueMaximisation.png';
import cust_data from '../../assets/img/hotels/CustomizableDataVisualizations.png';
import forwar_look from '../../assets/img/hotels/Forward-LookingAnalytics.png';
import indepth from '../../assets/img/hotels/In-DepthMarketAnalysis.png';
import ondemand from '../../assets/img/hotels/On-DemandComprehensiveReporting.png';
import realtime from '../../assets/img/hotels/Real-TimeRateManagement&Alerts.png';
import revpar from '../../assets/img/hotels/RevPAREnhancement.png';
import targetinsight from '../../assets/img/hotels/TargetedInsightDelivery.png';
// import seasoninsight from '../../assets/img/hotels/SeasonalityBasedPricing.png';
import season from '../../assets/img/hotels/Seasonality-BasedPricing.png';
import adv from '../../assets/img/hotels/AdvancedMultimodalDemandForecasting.png';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const HotelMainSlider = ({ end_point }) => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: "30px" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: "30px" }}
                onClick={onClick}
            />
        );
    }
    const slider_data = [
        {
            "head": "15+ Minutes Price Updates",
            "img": min_15,
            "alt": "15+ Minutes Price Updates",
            "sec_head": "15+ Minutes Price Updates",
            "para": "Get optimized price updates every 15+ minutes. Get tailored rates to your needs enhancing both profits and occupancy",
            "btn_name": "Get Price Updates"

        },
        {
            "head": "RevPAR Enhancement",
            "img": revpar,
            "alt": "RevPAR Enhancement",
            "sec_head": "RevPAR Enhancement",
            "para": "Achieve an impressive +10% average RevPAR increase with Zettaprice",
            "btn_name": "Boost Your RevPAR"

        },
        {
            "head": "Comprehensive Revenue Maximisation",
            "img": comp_rev,
            "alt": "Comprehensive Revenue Maximisation",
            "sec_head": "Comprehensive Revenue Maximisation",
            "para": "Optimize pricing for room types, customer segments, stay dates, and distribution channels to maximize revenue while maintaining booking flexibility.",
            "btn_name": "Maximize Revenue Today"

        },
        {
            "head": "AI-Powered Pricing Adjustments",
            "img": ai_powered,
            "alt": "AI-Powered Pricing Adjustments",
            "sec_head": "AI-Powered Pricing Adjustments",
            "para": "Optimize rates using intelligent, AI-driven recommendations to balance demand and capacity.",
            "btn_name": "Optimize Rates Now"

        },
        {
            "head": "Seasonality-Based Pricing",
            "img": season,
            "alt": "Seasonality-Based Pricing",
            "sec_head": "Seasonality-Based Pricing",
            "para": "Zettaprice employs a dynamic calendar to flexibly adjust rates according to seasonal demand fluctuations or events, ensuring competitive pricing and maximizing revenue during peak periods.",
            "btn_name": "Adjust for Seasons"

        },
        {
            "head": "Real-Time Rate Management& Alerts",
            "img": realtime,
            "alt": "Real-Time Rate Management& Alerts",
            "sec_head": "Real-Time Rate Management& Alerts",
            "para": "Utilize live analytics for immediate rate updates based on current market conditions recieve alerts as soon as market conditions change",
            "btn_name": "Receive Alerts Now"

        },
        {
            "head": "Advanced Multimodal Demand Forecasting",
            "img": adv,
            "alt": "Advanced Multimodal Demand Forecasting",
            "sec_head": "Advanced Multimodal Demand Forecasting",
            "para": "Accurately predict travel demand by analyzing trends across flights, trains, and buses, allowing you to optimize strategies proactively ",
            "btn_name": "Forecast Travel Demand"

        },
        {
            "head": "Forward-Looking Analytics",
            "img": forwar_look,
            "alt": "Forward-Looking Analytics",
            "sec_head": "Forward-Looking Analytics",
            "para": "Receive rate recommendations for up to 365 days into the future with our advanced analytical tools. ",
            "btn_name": "View Future Insights"

        },
        {
            "head": "In-Depth Market Analysis",
            "img": indepth,
            "alt": "In-Depth Market Analysis",
            "sec_head": "In-Depth Market Analysis",
            "para": "Gain valuable insights into local market trends and competitive factors.",
            "btn_name": "Explore Market Trends"

        },
        {
            "head": "Targeted Insight Delivery",
            "img": targetinsight,
            "alt": "Targeted Insight Delivery",
            "sec_head": "Targeted Insight Delivery",
            "para": "Easily switch between broad and detailed insights for precise property management.",
            "btn_name": "Get Targeted Insights"

        },
        {
            "head": "Accurate Revenue Forecasting",
            "img": accurate_rev,
            "alt": "Accurate Revenue Forecasting",
            "sec_head": "Accurate Revenue Forecasting",
            "para": "Quickly generate precise month-level and daily revenue forecasts.",
            "btn_name": "Get Forecasts"

        },
        {
            "head": "Customizable Data Visualizations",
            "img": cust_data,
            "alt": "Customizable Data Visualizations",
            "sec_head": "Customizable Data Visualizations",
            "para": "Access and interpret key business metrics with personalized visual dashboards.",
            "btn_name": "Customize it Today"

        },
        {
            "head": "On-Demand Comprehensive Reporting",
            "img": ondemand,
            "alt": "On-Demand Comprehensive Reporting",
            "sec_head": "On-Demand Comprehensive Reporting",
            "para": "Access detailed performance reports across multiple properties with personalized, on-demand insights. Tailored to your specific requirements, our reports support strategic planning and operational efficiency.",
            "btn_name": "Request Detailed Reports"

        },
    ]
    const slidersettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        asNavFor: nav2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        arrows: windowWidth < 1028 ? true : false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,

                }
            },
        ]
    };

    const slidersettings2 = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        centerMode: true,
        arrows: false,
        asNavFor: nav1,
        swipeToSlide: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,

                }
            },
        ]
    };

    const handleNextSlide = () => {
        sliderRef1.slickNext();
    };
    const handlePrevSlide = () => {
        sliderRef1.slickPrev();
    };

    return (
        <div className="hotel_main_slider " data-aos="new-animation">
            <div className="inner_ret_slider" >
                <div className="container">
                    <h2 className="bigHead text-center mb-3">Smart Revenue Management, Powered by AI & Expertise</h2>
                    <p className="text-center mb-5">Unlock your revenue potential with Zettaprice <br /> Designed by pricing experts with over <strong>25 years of experience</strong>, Zettaprice seamlessly blends advanced analytics, real-time data, <br />and expert insights.  Develop, implement, and oversee effective revenue strategies with ease.</p>
                    <div className="layer_content_2">
                        <Slider {...slidersettings2} asNavFor={nav1} ref={slider => (sliderRef2 = slider)}>
                            {slider_data.map((item, index) => (
                                <>
                                    <div className="">
                                        <div>
                                            <h3 className="slider_head2">{item.head}</h3>
                                        </div>
                                    </div>
                                </>

                            ))}
                        </Slider>
                    </div>
                    <div className="layer_content">
                        <Slider {...slidersettings} asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
                            {slider_data.map((item, index) => (
                                <>
                                    <div className="">

                                        <div className="row">
                                            <div className="col-lg-7 row_1 d-flex justify-content-between align-items-center">
                                                {windowWidth > 1028 ? (
                                                    <>
                                                        <div className="me-3">
                                                            <ArrowCircleLeftIcon sx={{ fontSize: '2.98em', cursor: 'pointer' }} onClick={handlePrevSlide} />
                                                        </div>
                                                    </>
                                                ) : null}
                                                <div key={index.alt} className="slider_card">
                                                    <img src={item.img} alt={item.alttext} />
                                                </div>

                                            </div>
                                            <div className="col-lg-5 row_2 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h3 className="slider_head">{item.sec_head}</h3>
                                                    <p className="slider_para">{item.para}</p>
                                                    <Link to={AllRoutes.hotelcontact}>
                                                        <button className="yellow-button">{item.btn_name}</button>
                                                    </Link>
                                                </div>
                                                {windowWidth > 1028 ? (
                                                    <>
                                                        <div className="ms-3">
                                                            <ArrowCircleRightIcon sx={{ fontSize: '3em', cursor: 'pointer' }} onClick={handleNextSlide} />
                                                        </div>
                                                    </>
                                                ) : null}

                                            </div>
                                        </div>
                                    </div>
                                </>

                            ))}
                        </Slider>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default HotelMainSlider;