import img10 from "../../assets/img/retail/automated-pricing/10.jpg";
import img11 from "../../assets/img/retail/automated-pricing/11.jpg";
import img12 from "../../assets/img/retail/automated-pricing/12.jpg";

import img1 from "../../assets/img/retail/customer-data/sentiment-analysis.png";
import img2 from "../../assets/img/retail/customer-data/customer-lifetime-value.png";
import img3 from "../../assets/img/retail/customer-data/segmentation-and-personalization.png";

import { Tab, Col, Nav, Row } from "react-bootstrap";
const CustomerDataTab = () => {
   
    return(
        <Tab.Container defaultActiveKey="mfirst">
        <Row>
        <Col xl={5}>
            <Nav variant="pills" className="flex-column">
            <Nav.Item>
                <Nav.Link eventKey="mfirst">Voice of Customer
                 <p>The sentiment analysis module analyses customer reviews to categorise their core sentiment in order to uncover valuable insights about your product. This enables you to understand consumer perceptions and identify areas for improvement or innovation.</p>
                 <div className="investor_edge_div res_img">
                    <img src={img1} alt="Voice of Customer Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="msecond">Customer Lifetime Value
                <p>BRIO streamlines the process of calculating customer lifetime value in providing accurate insights into the long-term value of customers. This approach helps businesses strategize more effectively, focusing on customer retention and maximising long-term profitability.</p>
                <div className="investor_edge_div res_img">
                    <img src={img2} alt="Customer Lifetime Value Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="mthird">Segmentation and Personalisation
                <p>BRIO is tailored for you. With the ability to segment and customise, customer satisfaction and loyalty is elevated with every click. This personalization fosters a deeper connection between your brand and your customers, driving engagement and loyalty.</p>
                <div className="investor_edge_div res_img">
                    <img src={img3} alt="Segmentation and Personalisation Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            </Nav>
        </Col>
        <Col xl={7} className="second_div">
            <Tab.Content>
            <Tab.Pane eventKey="mfirst">
                <div className="investor_edge_div">
                <img src={img1} alt="Voice of Customer" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="msecond">
            <div className="investor_edge_div">
                <img src={img2} alt="Customer Lifetime Value" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="mthird">
            <div className="investor_edge_div">
                <img src={img3} alt="Segmentation and Personalisation" />
                </div>
            </Tab.Pane>
            </Tab.Content>
        </Col>
        </Row>
</Tab.Container>
    )

}

export default CustomerDataTab