import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

const RecommendedBlogs = ({ pageId, categories }) => {

    const [recBlogs, setRecBlogs] = useState([]);

    const getRecommendedBlogs = async () => {
        try {
            const response = await axios.post(`/get_recommended_blogs`, { categories, pageId }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setRecBlogs(response.data.map(blog => (
                    {
                        thumbnailImg: blog['images']['img-main-img']['url'],
                        category: blog['categories'],
                        title: blog['title'],
                        published: blog['publishedDate'],
                        url: blog['url']
                    }
                )))
            }
        } catch (err) {
            console.log(err)
        }
    };

    function convertDateToWords(dateString) {
        const date = new Date(dateString);
        
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-IN', options);
      
        return formattedDate;
    };

    useEffect(() => {
        getRecommendedBlogs()
    }, [])

    return (
        <section className='w-100 container'>
            <div className='p-md-2 p-lg-4'>
                <div className='w-100 mb-3 d-flex justify-content-center'>
                    <h4 className='bold'>Recommended Articles</h4>
                </div>
                <div className='row'>
                    {
                        recBlogs.length ?
                            recBlogs.map(blog => (
                                <div className='mb-3 col-sm-12 col-md-6 col-lg-4'>
                                    <div className='w-100 h-100 rec-blog-card m-2 py-2 px-3 d-flex flex-column justify-content-evenly'>
                                        <div className='h-100 w-100'>
                                        <a href={blog['url']}><img alt={blog['title']} style={{ height: '10em' }} className='rec-blog-thumbnail w-100 object-fit-contain' src={blog['thumbnailImg']} /></a>
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                            {
                                                blog['category'].map(cat => <span className='mb-1 mx-1 rec-blog-category p-1'>{cat}</span>)
                                            }
                                        </div>
                                        <div className='mt-3'>
                                            <a href={blog['url']}><h5 className='text-black bold medium-2 rec-blog-title'>{blog['title'].length > 60 ? blog['title'].slice(0, 57) + '...' : blog['title']}</h5></a>
                                        </div>
                                        <div className='my-3 w-100 row d-flex align-items-center justify-content-between'>
                                            <div className='col-5'>
                                                <span className='small-2 text-muted'>{convertDateToWords(blog['published'])}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : null
                    }
                </div>
            </div>
        </section>
    )
}

export default RecommendedBlogs;