function BenefitSection({ id, heading, tabs }) {
    return (
        <>
            <section className="card_retail_product">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center">
              {heading}
            </h2>
            <div className="row about_why">
                { tabs.map((tab, index) => {
                        return (
                    <div className="col-md-6 col-lg-4 abt_why2" key={index}>
                        <div className="card">
                        <img src={tab.img} alt={tab.alt} />
                        <h3 className="a_w_head">
                            {tab.title}
                        </h3>
                        <p className="a_w_para">
                        {tab.desc}
                        </p>

                        </div>
                    </div>

                        ) })

                }
              {/* col-4 */}
          
            </div>
          </div>
        </section>
        </>
    );
}

export default BenefitSection;