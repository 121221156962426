import img1 from "../../assets/img/retail/discount-marketing/availability_status.png";
import img2 from "../../assets/img/retail/discount-marketing/availability_grid.png";
import img3 from "../../assets/img/retail/discount-marketing/discount_management.png";

import { Tab, Col, Nav, Row } from "react-bootstrap";
const DiscountManagementTab = () => {

    return (
        <Tab.Container defaultActiveKey="dfirst">
            <Row>
                <Col xl={5}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="dfirst">Strategic Price Competitive Positioning
                                <p>Classify your products' strategic price competitive positioning  to deep dive into market intelligence.</p>
                                <div className="investor_edge_div res_img">
                                    <img src={img1} alt="Strategic Price Competitive Positioning" className=" " />
                                </div>

                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="dsecond">Competitive Assortment
                                <p>Compare your competitive assortment to understand market distinction and disparity.</p>
                                <div className="investor_edge_div res_img">
                                    <img src={img2} alt="target" className=" " />
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="dthird">Market Intelligence
                                <p>Use historical data and predictive analysis for precise demand forecasting of your products</p>
                                <div className="investor_edge_div res_img">
                                    <img src={img3} className=" " />
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col xl={7} className="second_div">
                    <Tab.Content>
                        <Tab.Pane eventKey="dfirst">
                            <div className="investor_edge_div">
                                <img src={img1} alt="Strategic Price Competitive Positioning" />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="dsecond">
                            <div className="investor_edge_div">
                                <img src={img2} alt="target" />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="dthird">
                            <div className="investor_edge_div">
                                <img src={img3} />
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )

}

export default DiscountManagementTab