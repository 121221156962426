import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Alert from '@mui/material/Alert';
import Table from 'react-bootstrap/Table';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { fetchToken } from '../../../Auth';
import { toast } from 'react-toastify';
import axios from 'axios';
import { adminURL } from '../../../global';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({ 'label': '', 'value': '' })
    const [privileges, setPrivileges] = useState(null);

    const navigate = useNavigate();

    const getUsers = async () => {
        try {
            const response = await axios.get(`/get_auth_users`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setUsers(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate(`/niyantran-panel/${adminURL}/login`)
            }
        }
    }

    const getPrivileges = async () => {
        try {
            const response = await axios.get(`/get_privileges_for_user/${selectedUser['value']}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setPrivileges(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate(`/niyantran-panel/${adminURL}/login`)
            }
        }
    }

    const isSuperAdmin = async () => {
        try {
            const response = await axios.get(`/is_super_admin`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
              },
            });
            if (response.status == 200 && response.data !== undefined) {
              if(!response.data){
                navigate('/error')
              }
            }
          } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
              navigate(`/niyantran-panel/${adminURL}/login`)
            }
          }
    }

    useEffect(() => {
        isSuperAdmin();
        getUsers();
    }, [])

    useEffect(() => {
        if (selectedUser['value']) {
            getPrivileges();
        }
    }, [selectedUser]);

    const handleChange = (checked, content, action) => {
        setPrivileges(prevState => {
            // Create a copy of the previous state using spread syntax
            const temp = {...prevState};
            // Create a copy of the nested object to avoid mutating state directly
            temp[content] = {...temp[content]};
            // Update the specific value
            temp[content][action] = checked;
            return temp;
        });
    };

    const savePrivilege = async () => {
        if (!selectedUser['value']) {
            toast.error('Select a user!');
            return;
        }
        try {
            const response = await axios.post(`/edit_privileges_for_user`, { user_id: selectedUser['value'], privileges }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                toast.success(`Privileges set successfully for ${selectedUser['label']}!`);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate(`/niyantran-panel/${adminURL}/login`)
            }
            if (err.response.status === 500) {
                toast.error(`Something went wrong!`);
            }
        }
    }

    const removeAllAccess = () => {
        setPrivileges(prevState => {
            const temp = {...prevState};
            temp['blogs'] = {'create': false, 'makeLive': false, 'edit': false, 'delete': false};
            temp['caseStudies'] = {'create': false, 'makeLive': false, 'edit': false, 'delete': false};
            temp['roadAhead'] = {'create': false, 'makeLive': false, 'edit': false, 'delete': false};
            return temp;
        });
    }
    const grantAllAccess = () => {
        setPrivileges(prevState => {
            const temp = {...prevState};
            temp['blogs'] = {'create': true, 'makeLive': true, 'edit': true, 'delete': true};
            temp['caseStudies'] = {'create': true, 'makeLive': true, 'edit': true, 'delete': true};
            temp['roadAhead'] = {'create': true, 'makeLive': true, 'edit': true, 'delete': true};
            return temp;
        });
    }

    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'></div>
                    <div className='col-8'>
                        <h1 className='bold '>User Management</h1>
                        <p className='medium-3 text-muted'>Manage privileges for users.</p>
                    </div>
                    <div className='col-3 d-flex flex-column justify-content-center align-items-center'>
                    </div>
                </div>

                <div className='mt-3 admin-card corner row p-3 mb-5 shadow-2 w-100'>
                    <div className='col-12 row'>
                        <div className='col-6'>
                            <h6 className='bold'>Select a user</h6>
                            <Select
                                placeholder="Select User"
                                value={selectedUser}
                                closeMenuOnSelect={true}
                                onChange={(selectedOption) => setSelectedUser(selectedOption)}
                                options={users.map(user => ({ value: user.userId, label: user.username }))}
                            />
                        </div>
                        <div className='col-6 d-flex justify-content-evenly align-items-end'>
                            <Button disabled={selectedUser['value'] ? false : true} onClick={grantAllAccess} className='w-30' variant="outline" size='sm'><i className="mr-2 fa-regular fa-circle-check"></i> Grant All</Button>
                            <Button disabled={selectedUser['value'] ? false : true} onClick={removeAllAccess} className='w-30' variant="outline" size='sm'><i className="mr-2 fa-regular fa-circle-xmark"></i> Remove All</Button>
                            <Button disabled={selectedUser['value'] ? false : true} onClick={savePrivilege} className='w-30' variant="contained" size='sm'><i className="mr-2 fa-solid fa-user-lock"></i> Save</Button>
                        </div>
                    </div>

                    <div className='mt-4 col-12'>
                        {
                            privileges && selectedUser['value'] ?
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Content</th>
                                            <th>Create</th>
                                            <th>Make Live</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='bold'>Blog</td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'blogs', 'create')} checked={privileges['blogs']['create']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'blogs', 'makeLive')} checked={privileges['blogs']['makeLive']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'blogs', 'edit')} checked={privileges['blogs']['edit']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'blogs', 'delete')} checked={privileges['blogs']['delete']} /></td>
                                        </tr>
                                        <tr>
                                            <td className='bold'>Case Studies</td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'caseStudies', 'create')} checked={privileges['caseStudies']['create']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'caseStudies', 'makeLive')} checked={privileges['caseStudies']['makeLive']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'caseStudies', 'edit')} checked={privileges['caseStudies']['edit']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'caseStudies', 'delete')} checked={privileges['caseStudies']['delete']} /></td>
                                        </tr>
                                        <tr>
                                            <td className='bold'>Road Ahead Talks</td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'roadAhead', 'create')} checked={privileges['roadAhead']['create']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'roadAhead', 'makeLive')} checked={privileges['roadAhead']['makeLive']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'roadAhead', 'edit')} checked={privileges['roadAhead']['edit']} /></td>
                                            <td><Checkbox onChange={(evt) => handleChange(evt.target.checked, 'roadAhead', 'delete')} checked={privileges['roadAhead']['delete']} /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                :
                                <Alert className='mt-2 mb-5 w-100' severity="info">Select a user.</Alert>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default UserManagement;