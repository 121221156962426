const InnerCard = ({ pageheading, headImg, pagelink }) => {
return(<>
    <a href={pagelink}>
        <div className="card">
            <h3 className="smallHead">{pageheading}</h3>
            <img src={headImg} alt={pageheading} />
        </div>
    </a>
</>)
}

export default InnerCard;