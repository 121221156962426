import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import '../../assets/css/main.scss';

import rightyellow from '../../assets/img/right-arrow-yellow.png'
import rl from '../../assets/img/about/revenue-loss.png'
import cp from '../../assets/img/about/capacity.png'
import cr from '../../assets/img/about/customer-retention.png'
import pf from '../../assets/img/about/poor-forecasting.png'
import vl from '../../assets/img/about/valocity.png'
import abt from '../../assets/img/about/firm_ bg1.png'
import CapteraAchievements from "../travel/capterra-achievvement";
import leader1 from '../../assets/img/about/leader1.png'
import leader2 from '../../assets/img/about/leader2.png'
import gallery1 from '../../assets/img/about/gallery/1.jpg'
import gallery2 from '../../assets/img/about/gallery/2.png'
import gallery3 from '../../assets/img/about/gallery/3.jpg'
import gallery4 from '../../assets/img/about/gallery/4.jpg'
import gallery5 from '../../assets/img/about/gallery/5.jpg'
import gallery6 from '../../assets/img/about/gallery/6.jpg'
import gallery7 from '../../assets/img/about/gallery/7.jpg'
import gallery8 from '../../assets/img/about/gallery/8.jpg'
import gallery9 from '../../assets/img/about/gallery/9.jpg'
import gallery10 from '../../assets/img/about/gallery/10.png'
import gallery11 from '../../assets/img/about/gallery/11.png'
import gallery12 from '../../assets/img/about/gallery/12.png'
import gallery13 from '../../assets/img/about/gallery/13.jpg'
import gallery14 from '../../assets/img/about/gallery/14.jpg'
import gallery15 from '../../assets/img/about/gallery/15.jpg'
import impact1 from '../../assets/img/about/impact/1.png'
import impact2 from '../../assets/img/about/impact/2.png'
import impact3 from '../../assets/img/about/impact/3.png'
import impact4 from '../../assets/img/about/impact/4.png'
import impact5 from '../../assets/img/about/impact/5.png'
import { Button } from 'react-bootstrap';  
import Recoginition from "../retail/recognition";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import History from "../common/history";
import { Link } from 'react-router-dom';
import Leadership from "../retail/leadership";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ValueProposition from "../common/valueProposition";
import WhatMakesSciativeUnique from "../common/whatMakesSciativeUnique";
import mission from '../../assets/img/about/mission.jpg'
import vision from '../../assets/img/about/vision.jpg'
import Form from "../Forms/Form";
import { toast } from 'react-toastify';
import AllRoutes from "../../AllRoutes";
import { GlobalUrl, GlobalImg } from "../../global";
import AOS from "aos";
import "aos/dist/aos.css";
const About = ({  }) => {
    // const [leader, showLeader] = useState(true)
    // useEffect(() => {
    //     function showleader(leader_id) {
    //         console.log("leadr is os", leader_id)
    //         if(leader_id == 1){
    //             showLeader(true)
    //         }
    //         else{
    //             showLeader(false)
    //         }
    
    //     }
    //   }, []);
    useEffect(() => {
    
        AOS.init();
        AOS.refresh();
       
    }, [])
    const formProps = {
        template: 4,
        form_name: 'book-a-demo-page-form',
        fields: [
            { name: 'name', required: true },
            { name: 'email', required: true },
            { name: 'company', required: true },
            { name: 'phone', required: true },
            { name: 'message', required: true },
        ],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
            } else {
                toast.error(res.data)
            }
        }
    }
    const handleClick = (e) => {
        window.scrollTo(0, 0);
        // You can add any additional functionality here if needed
      };
      function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
          className={className}
          style={{ ...style, display: "block",borderRadius:"30px" }}
          onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block",borderRadius:"30px" }}
            onClick={onClick}
          />
        );
      }
    const whysection = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 105000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
               
              }
            },
            {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 2,
                 
                }
              },
        ]
     
      };
      const gallery_1 = {
        className: "center",
        centerMode: true,
        dots: false,
        arrow: false,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        // speed: 500,
        loop:true,
        speed: 5000,
        autoplay:true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        rtl:true,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
               
              }
            },
            {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 2,
                 
                }
              },
        ]
     
      };
      const gallery_2 = {
        className: "center",
        centerMode: true,
        dots: false,
        arrow: false,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        // speed: 500,
        loop:true,
        speed: 5000,
        autoplay:true,
        autoplaySpeed: 5000,
        cssEase: "linear",

        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        rtl: false,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
               
              }
            },
            {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 2,
                 
                }
              },
        ]
     
      };
    return (
        <>
        <HelmetProvider>
            <Helmet>
                <title>Know How We Lead Dynamic Pricing Across Industries | Sciative</title>
                <link rel="canonical" href={`${GlobalUrl}${AllRoutes.about}`} />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="csrf_token" content="" />
                <meta property="type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta name='description' content="Pricing is complex. We make it simple, scientific, scalable, AI-driven and successful. Know more about Sciative, our culture, values, and achievements." />
                {/* <meta name='keywords' content="Travel, Viaje.ai, Bus, Dynamic Pricing, automate pricing,automated pricing tool,brand protection software,competitor price tracking software,demand forecasting software,discounting software" /> */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content="Know How We Lead Dynamic Pricing Across Industries | Sciative" />
                <meta property="og:description" content="Pricing is complex. We make it simple, scientific, scalable, AI-driven and successful. Know more about Sciative, our culture, values, and achievements." />
         
                <meta property="og:hashtag" content="#ai" />
                <meta content="image/*" property="og:image:type" />
                <meta property="og:url" content={`${GlobalUrl}${AllRoutes.about}`} />
                <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                <meta property="og:image"  content={GlobalImg} />
                <meta property="og:type" content="website" />
            </Helmet>
        <section className=" about_banner abt_img">
            <div className="container" data-aos="new-animation">
                <h1>About Us</h1>
                <p className="about_para">We've been dedicatedly solving the problem of price cutting across industry verticals using our in-house built AI-driven dynamic pricing products.</p>
                {/* <p className="about_para_2">
                Explore our story: dive into a journey of purpose and passion.
                </p> */}

                <div>
                <Link to={AllRoutes.aboutcontact}>
                    <button className="transparent-button">Discover more! <img src={rightyellow} alt="right arrow"/></button>
                </Link>
                </div>
                
             

            </div>
        </section>
        <section className=" black-bg">
            <div className="about_why">
                <div className="container" data-aos="new-animation">
                <h2 className="bigHead  text-center mb-3">Challenges Addressed by Sciative</h2>
                    <Slider  {...whysection}>
                            <div>
                                <div className="card">
                                    <img src={rl} alt="Revenue Loss Image" />
                                    <h3 className="a_w_head">
                                    Revenue Loss <br /> (Underpricing/ Overpricing)
                                    </h3>
                                    <p className="a_w_para">
                                    Indian travel industry faces annual losses exceeding $25B due to pricing inaccuracies.
                                    </p>

                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <img src={pf} alt="Revenue Loss Image" />
                                    <h3 className="a_w_head">
                                    Poor Forecasting
                                    </h3>
                                    <p className="a_w_para">
                                    For OEMs and retailers, 10% - 40% of inventories made up of slow-moving items.
                                    </p>

                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <img src={cr} alt="Revenue Loss Image" />
                                    <h3 className="a_w_head">
                                    Low Customer Retention
                                    </h3>
                                    <p className="a_w_para">
                                    Over 83% of online shoppers do a price comparison before purchase decision.
                                    </p>

                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <img src={cp} alt="Revenue Loss Image" />
                                    <h3 className="a_w_head">
                                    Underutilised Capacity
                                    </h3>
                                    <p className="a_w_para">
                                    Indian cinema halls run at annual occupancy rate of 19-25% leaving 75% of the halls are empty!
                                    </p>

                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <img src={vl} alt="Revenue Loss Image" />
                                    <h3 className="a_w_head">
                                    Low Velocity
                                    </h3>
                                    <p className="a_w_para">
                                    Pricing decisions demand rapid response; delays can be costly! For instance, Amazon conducts around 50 million price changes daily.
                                    </p>

                                </div>
                            </div>
                    </Slider>
                    <h3 className="smallHead mt-5 text-center">Majority of these value leaks can be fixed to a large extent with Pricing RIGHT! </h3>
                </div>
            </div>
        </section>

        <WhatMakesSciativeUnique />
        <ValueProposition />
        
       <section className="about_firm">
            <div className="container" data-aos="new-animation">
                <h2 className="bigHead  text-center mb-3 text-white">Our Firm</h2>
                {/* <p className="text-center text-white firm_para">
                Pricing is complex, we make it simple, scientific, scalable & successful
                </p> */}
                <div className="row">
                    <div className="col-md-10 offset-md-1 first_div">
                        <div className="row">
                            <div className="col-md-6 order-md-2">
                                <img src={mission} alt="Mission Image" />
                            </div>
                            <div className="col-md-6">
                                <div className="card">
                                    <h3 className="firm_head_1">
                                    Our <br /> Mission
                                    </h3>
                                    <p>At Sciative, we aim to create the most powerful artificial intelligence powered automated pricing tools. We drive automation, use advanced AI and machine learning to analyze big data and gain pricing domain expertise.
                                    </p>
                                </div>
                            </div>
                           
                        </div>
                      
                    </div>
                    {/* col-10 end */}
                    <div className="col-md-10 offset-md-1 second_div">
                        <div className="row g-0">
                            <div className="col-md-6">
                                <img src={vision} alt="Vision Image" />
                            </div>
                            <div className="col-md-6 ">
                                <div className="card">
                                    <h3 className="firm_head_1">
                                    Our <br /> Vision
                                    </h3>
                                    <p>Our vision is simple - to enable all businesses to price right - every time! We have four pillars that hold us up, and hold us together:
                                    </p>
                                    <ul>
                                        <li>Growth & profitability</li>
                                        <li>Returns on capacities</li>
                                        <li>Favorable customer behavior and loyalty</li>
                                        <li>New customer segments</li>
                                      
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* col-10 end */}
                    <div className="col-md-10 offset-md-1">
                        <p className="text-center text-white mt-5">
                        We value the importance of a team and people, and are and have been on a quest to better our growth and reach out to more unexplored territory at every time. After all, we want every business to ace the issue of pricing with us!
                        </p>
                    </div>
                </div>
               
            </div>
       </section>
       <CapteraAchievements />
       <Leadership />
       {/* <section className="about_leadrrship">
            <div className="container">
                 <h2 className="bigHead text-center mb-3 text-white">Our leadership</h2>
                 <p className="text-white text-center">
                 At the helm of Sciative, our leadership embodies a compass guiding the ship of innovation, steering with a blend of expertise, vision, and a passion for pushing boundaries. With a commitment to excellence and a visionary approach, Sciative's leadership fosters a culture where every idea is a potential breakthrough, setting the course for a future where science and leadership converge
                 </p>
                   
         
            <div className="row leader_img_div">
                <div className="col-md-6 col-xl-3 offset-xl-2">
                    <div className="card" >
                        <div className="img_card">
                            <img src={leader1} alt="Dr. Anshu Jalora" />
                        </div>
                        <p className="leader_name">Dr. Anshu Jalora</p>
                        <p className="leader_desg">Founder & Managing Director |Chief Scientist | FT</p>
                    </div>
                </div>
               
                <div className="col-md-6 col-xl-3 offset-xl-1">
                    <div className="card"  >
                        <div className="img_card">
                            <img src={leader2} alt="Vijeta Soni" />
                        </div>
                        <p className="leader_name">Vijeta Soni</p>
                        <p className="leader_desg">Co-Founder & CEO | Customer Success | FT</p>
                    </div>
                </div>
               
            </div> 
           {leader?
           
           <div className="leader_1" id="leader_1">
           <p className="text-white">anshu sir</p>
       </div>: <div className="leader_1" id="leader_1">
                <p className="text-white">vijeta ma'am</p>
            </div>
       } 
          <div className="leader_1 leader_data">
                <p>Dr. Anshu is a recognised Pricing Strategic Expert with more than 17 years of hands-on experience managing globally recognized consumer and corporate brands. Before starting Sciative, Dr. Anshu was the Global Director of Pricing Strategy at Starbucks. He earned his Ph.D. from Texas A&M University, USA and has over 17 International Publications under his name.</p>
                <div className="row">
                    <div className="col-xl-4">
                        <div className="l_card">
                            <ul>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est voluptas possimus aspernatur dicta cumque iure mollitia id ipsam</li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est voluptas possimus aspernatur dicta cumque iure mollitia id ipsam</li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est voluptas possimus aspernatur dicta cumque iure mollitia id ipsam</li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est voluptas possimus aspernatur dicta cumque iure mollitia id ipsam</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-8">
                    <iframe src="https://www.youtube.com/embed/VgWF2N2E6Gg?si=oJZN7LGzc06XVyw1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
                    </div>
                </div>
           </div> 
           </div>
       </section> */}
       <section className="abt_people">
            <div className="container" data-aos="new-animation">
                <h2 className="bigHead text-center mb-3 ">Our People</h2>
                 <p className=" text-center mb-5">
                 Meet the brilliant minds that weave the narrative of Sciative - a diverse tapestry of innovators, dreamers, and problem-solvers, each contributing a unique chapter to the captivating story of "Humans of Sciative".
                 </p>
                 <div className="gal_1">

                 
                 <Slider {...gallery_1}>
                    <div className="img_people">

                    <img src={gallery1} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery2} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery3} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery4} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery5} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery6} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery13} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery14} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery15} alt="gallery Image" />
                    </div>
                 </Slider>
                 </div>
                 <Slider {...gallery_2}>
                    <div className="img_people">

                    <img src={gallery7} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery8} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery9} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery10} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery11} alt="gallery Image" />
                    </div>
                    <div className="img_people">
                        
                    <img src={gallery12} alt="gallery Image" />
                    </div>
                 </Slider>
                 <p className=" text-center mt-5">From the pricing tables to boardroom, "Humans of Sciative" encapsulate a spectrum of passions, talents, and quirks, making the journey of scientific exploration not just groundbreaking but uniquely human.</p>
                 <Link to="/careers/" className="yellow-button">Join Our Talent Community</Link>
            </div>
       </section>
       <Recoginition />
       <section className="abt_impact">
        <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-3 text-white">Our Impact & Commitment</h2>
            <p className=" text-center mb-5 text-white">
            At Sciative, our impact resonates beyond scientific breakthroughs; it's a commitment to forging a positive influence on the world. We channel our collective expertise and unwavering dedication to not just meet expectations but to exceed them, making a lasting mark on the forefront of innovation and progress.
            </p>
            <div className="row">
                <div className="col-lg-4 mb-4">
                    <div className="impact_card height_card">
                        <p className="card_para text-yellow">
                        100+ Employees
                        </p>
                        <img src={impact1} alt="Employees Cheering" />
                    </div>
                </div>
                {/* col-4 end */}
                <div className="col-lg-8 mb-4">
                   <div className="row">
                        <div className="col-lg-6">
                            <div className="impact_card second_card">
                                <p className="card_para  text-white">
                                Clients
                                </p>
                                <img src={impact2} alt="Clients handshake" />
                            </div>
                        </div>
                        {/* col-6 end */}
                        <div className="col-lg-6">
                            <div className="impact_card hor_card">
                                <div>
                                <p className="card_para  text-white">
                                2018
                                </p>
                                <p className="card_para2 text-yellow"> Founded by Dr. Anshu Jalora</p>
                               
                                </div>
                                <img src={impact3} alt="Office Building" className="small_img" />
                            </div>
                            <div className="impact_card text-lg-center">
                                <p className="card_para text-white">
                                Integrations
                                </p>
                                <img src={impact4} alt="Integration Image"  className="small_img2" />
                            </div>
                        </div>
                        {/* col-6 end */}
                        <div className="col-md-12">
                            <div className="impact_card hor_card mb-0">
                                <p className="card_para text-yellow">
                                5 Billion+ <br /> Optimization <br /> Daily
                                </p>
                                <p className="card_para2"></p>
                                <img src={impact5} alt="Optimization process Image" className="small_img" />
                            </div>
                            
                        </div>
                        {/* col-6 end */}
                   </div>
                </div>
            </div>
        </div>
       </section>
       <History />
        <section className="partner-form-section">
        <div className="container d-flex flex-column align-items-center" data-aos="new-animation">
          <div className="text-container">
            <h2 className="bigHead">Explore the minds shaping the future!</h2>
            <p>Explore our story: Dive into a journey of purpose and passion. See how we're making a difference and unmask the masterminds behind the magic!</p>
          </div>
          <div className="form-container m-0">
            <Form {...formProps}/>
          </div>
        </div>
      </section>
      </HelmetProvider>
       </>
    );
}


export default About;