import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";

import img1 from "../../assets/img/retail/automated-pricing/1.png";
import img2 from "../../assets/img/retail/automated-pricing/2.png";
import img3 from "../../assets/img/retail/automated-pricing/3.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";

import img1_ from "../../assets/img/retail/pricing-consulting/navigate_complex_web_of_pricing.png";
import img2_ from "../../assets/img/retail/pricing-consulting/minimise_churn_rate.png";
import img3_ from "../../assets/img/retail/pricing-consulting/benchmark_your_brand.png";
import img4_ from "../../assets/img/retail/pricing-consulting/shorter_gestation_period_and_lower_cost.png";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";

import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import b1 from "../../assets/img/icon/Benchmarking.png";
import b2 from "../../assets/img/icon/assessment.png";
import b3 from "../../assets/img/icon/Optimization.png";
import b4 from "../../assets/img/icon/uncertainty.png";
import b5 from "../../assets/img/icon/AItechnology.png";
import b6 from "../../assets/img/icon/Support.png";

const PricingConsulting = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "pricing-consulting";

  const qa_list = [
    "Do you want to empower your teams by implementing effective data management strategies?",
    "Do you have access to industry specialists' guidance to propel your pricing strategies to the top?",
    "Can you to identify your KVIs effectively, unlocking hidden opportunities for profit maximization?",
    "Are you confident your marketing efforts are strategically aligned, driving results in the right direction?"
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
    } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_section_props = {
    id: "pricing_consulting",
    heading: "How Can Sciative Experts Guide You in Pricing Right?",
    tabs: [
        {
          title: "Navigate Complex Web of Pricing",
          desc: "Pricing is a crucial lever for profitability—just a 1% price adjustment can enhance profits by 11%. Incorrect pricing equals a missed revenue opportunity. Our experts ensure accurate pricing decisions to help you avoid missed revenue opportunities.",
          img: img1_ ,
          alt: "Navigate Complex Web of Pricing" 
        },
        {
          title: "Pricing Evaluations",
          desc: "We consistently assess the effectiveness of pricing strategies and suggest tailored approaches for your key value items (KVIs).",
          img: img2_,
          alt: "Pricing Evaluations"
        },
        {
          title: "Training and Support",
          desc: "Receive ongoing training and support from pricing experts to ensure your team maximizes the tools and strategies provided.",
          img: img3_,
          alt: "Training and Support"
        },
        {
          title: "Strategy Development",
          desc: "We help you develop comprehensive pricing strategies that align with the company’s business objectives, such as increasing market share, maximizing profits, or entering new markets.",
          img: img4_,
          alt: "Strategy Development"
        }
    ]
  }
  const benefit_props = {
    id: "pricing_consulting",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Diagnostic and Benchmarking",
        desc: "Our pricing experts assess your current pricing structure and help your brand identify areas for revenue improvement.",
        img: b1 ,
        alt: "Diagnostic and Benchmarking Image" 
      },
      {
        title: "Simultaneous Assessment of Multiple Decision Criteria",
        desc: "Our pricing experts make sense of your data using the latest technologies to provide you with actionable insights to save you from major revenue leaks.",
        img: b2 ,
        alt: "Simultaneous Assessment of Multiple Decision Criteria" 
      },
      {
        title: "Pricing, Promotion and Markdown Optimization",
        desc: "Leverage our expertise to develop data-driven strategies that maximize ROI and protect margins amidst the complexities of global pricing.",
        img: b3 ,
        alt: "Pricing, Promotion and Markdown Optimization" 
      },
      {
        title: "Minimal Uncertainty in Success",
        desc: "Our expertise with proven years of domain knowledge in pricing across industries aims to provide immediate and measurable ROI.",
        img: b4 ,
        alt: "Minimal Uncertainty in Success" 
      },
      {
        title: "Application of Newest AI Technology in Pricing",
        desc: "Let our pricing experts guide you into the latest landscape of working technology so that your brand stays ahead of your competition.",
        img: b5 ,
        alt: "Application of Newest AI Technology in Pricing" 
      },
      {
        title: "Implementation and Ongoing Support",
        desc: "We guide you through the implementation of your pricing strategies with the help of our pricing engine to ensure your business success.",
        img: b6 ,
        alt: "Implementation and Ongoing Support" 
      },

    ]
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Pricing Consulting for Retail Business Growth | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.pricingconsultpage}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Unlock hidden profits & optimize your retail pricing strategy with Sciative's expert pricing consulting. Contact us to get detailed insights" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Pricing Consulting for Retail Business Growth | Sciative" />
          <meta property="og:description" content="Unlock hidden profits & optimize your retail pricing strategy with Sciative's expert pricing consulting. Contact us to get detailed insights" />
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.pricingconsultpage}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org/", 
                  "@type": "BreadcrumbList", 
                  "itemListElement": [{
                    "@type": "ListItem", 
                    "position": 1, 
                    "name": "Home",
                    "item": "https://sciative.com"  
                  },{
                    "@type": "ListItem", 
                    "position": 2, 
                    "name": "Retail",
                    "item": "https://sciative.com/retail"  
                  },{
                    "@type": "ListItem", 
                    "position": 3, 
                    "name": "Unified Pricing Engine",
                    "item": "https://sciative.com/retail/retail-pricing-consulting"  
                  }]
                }              
             `}
            </script>
        </Helmet>

        <InnerHeroRetail end_point={end_point} page_name="PricingConsulting" page_heading="Retail Pricing Consulting" page_desc1="Leverage Sciative's expertise for smarter" page_desc2="retail pricing strategies." button_name="Discover Our Pricing Solutions" />
        <RetailClient />

      <NavigationSection {...navigation_section_props}/>
        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
       <BenefitSection {...benefit_props}/>
        <MarketPlaceIntegration  end_point={end_point} />
        <RetailTestimonial dataHeading="We Deliver, Our Customers ‘ Speak" />
        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />

        <QAScore end_point={end_point} qa_list={qa_list} />

        <section className="qa_store">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Pricing Engine" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container"  data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>

    </>
  )
}
export default PricingConsulting;