import { React, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import AOS from "aos";
import Form from "../../../components/Forms/Form";
import { toast } from "react-toastify";

import "aos/dist/aos.css";

import ebook1 from "../../../assets/img/ebooks/e_book_1.png";
import ebook2 from "../../../assets/img/ebooks/e_book_2.png";
import ebook3 from "../../../assets/img/ebooks/e_book_3.png";
import ebook4 from "../../../assets/img/ebooks/e_book_4.png";

const EBook = ({ ebookProps }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [pdfRequestModal, setPdfRequestModal] = useState(false);
  const [requestedFileKey, setRequestedFileKey] = useState("");
  const [requestedFileName, setRequestedFileName] = useState("");

  const pdfRequestProps = {
    template: 1,
    form_name: "ebook-pdf-request",
    fields: [
      { name: "name", required: true },
      { name: "email", required: true },
      { name: "phone", required: true },
    ],
    title: "Register Now!",
    content_upper:
      "Discover the pulse of the industry and gain valuable insights from top minds as we navigate the 'Road Ahead Talks' in travel, retail and hospitality on this captivating webinar series.",
    content_lower: "Fill out the form to download the e-book.",
    recipients_group_id: 1,
    requestedFileKey: requestedFileKey,
    requestedFileName: requestedFileName,
    handleApiResponse: async (res) => {
      if (res && res.status && res.status === "success") {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = requestedFileName; 
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        // toast.success("Form submitted successfully!");
        setRequestedFileKey("")
        setRequestedFileName("")
        setPdfRequestModal(false);
      } else {
        toast.error(res.data);
      }
    },
  };

  const handlePdfRequest = (file_key, filename) => {
    setPdfRequestModal(true);
    setRequestedFileKey(file_key);
    setRequestedFileName(filename)
  };

  return (
    <>
      <div className="w-100 row ali">
        <div className="h-100 col-12 col-md-4">
          <div className="ebook-div d-flex flex-column">
            <div className="h-60 d-flex justify-content-center align-items-center p-2">
              <img
                onClick={() => handlePdfRequest("retail", "retail_ebook.pdf")}
                src={ebook1}
                className="e-book-img"
              />
            </div>
            <div
              className="h-40 d-flex flex-column justify-content-between bg-grey-6 p-3"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <h6 className="bold">Retail Industry Pricing Terms</h6>
              <p className="mb-0 small thin">
                A comprehensive guide to essential pricing concepts and
                strategies that drive success in the retail sector.
              </p>
              <p className="mt-3 text-muted small">10th September 2024</p>
            </div>
          </div>
        </div>
        <div className="h-100 col-12 col-md-4">
          <div className="ebook-div d-flex flex-column">
            <div className="h-60 d-flex justify-content-center align-items-center p-2">
              <img
                onClick={() => handlePdfRequest("hotels", "hospitality_ebook.pdf")}
                src={ebook2}
                className="e-book-img"
              />
            </div>
            <div
              className="h-40 d-flex flex-column justify-content-between bg-grey-6 p-3"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <h6 className="bold">Hospitality Industry Pricing Terms</h6>
              <p className="mb-0 small thin">
                An insightful resource outlining key pricing terms to optimize
                revenue in the hospitality industry.
              </p>
              <p className="mt-3 text-muted small">10th September 2024</p>
            </div>
          </div>
        </div>
        <div className="h-100 col-12 col-md-4">
          <div className="ebook-div d-flex flex-column">
            <div className="h-60 d-flex justify-content-center align-items-center p-2">
              <img
                onClick={() => handlePdfRequest("airlines", "airlines_ebook.pdf")}
                src={ebook3}
                className="e-book-img"
              />
            </div>
            <div
              className="h-40 d-flex flex-column justify-content-between bg-grey-6 p-3"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <h6 className="bold">Airline Industry Pricing Terms</h6>
              <p className="mb-0 small thin">
                An essential reference detailing the pricing mechanisms and
                strategies that influence the airline sector.
              </p>
              <p className="mt-3 text-muted small">10th September 2024</p>
            </div>
          </div>
        </div>
        {/* <div className="h-100 col-12 col-md-3">
          <div className="ebook-div d-flex flex-column">
            <div className="h-60 d-flex justify-content-center align-items-center p-2">
              <img
                onClick={() => handlePdfRequest("")}
                src={ebook4}
                className="e-book-img"
              />
            </div>
            <div
              className="h-40 d-flex flex-column justify-content-between bg-grey-6 p-3"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <h6 className="bold">Bus Industry Pricing Terms</h6>
              <p className="mb-0 small thin">
                A focused exploration of pricing terminology and practices
                specific to the bus transportation industry.
              </p>
              <p className="mt-3 text-muted small">10th September 2024</p>
            </div>
          </div>
        </div> */}
      </div>

      <Modal
        show={pdfRequestModal}
        onHide={() => setPdfRequestModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="remove-white-bg"
        backdrop="static"
        centered
      >
        <Modal.Body className="p-0">
          <CloseIcon
            onClick={() => setPdfRequestModal(false)}
            className="modal-close-icon"
          />
          <Form {...pdfRequestProps} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EBook;
