import { Tab, Col, Nav, Navbar, Container, NavDropdown, Button, Row } from "react-bootstrap";
import AllRoutes from "../../AllRoutes";
import brioLogo from '../../assets/img/brio.png'

const RetailHeaderMobile = () => {
    return(
        <Navbar style={{ zIndex: 200, position: 'fixed', top: '60px', width: '100%'}} collapseOnSelect expand="xl" className={`p-0 p-fixed mob-nav-logos`}>
                <Container>
                  <Navbar.Brand href={AllRoutes.retail}> <img src={brioLogo} alt="Brio Logo" id="brand-logo-2" /></Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                      <NavDropdown title="Products" id="collapsible-nav-dropdown">
                        <NavDropdown.Item href={AllRoutes.automatedpricingpage}>Automated Retail Price Management</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.unifiedpricingpage}>Unified Retail Pricing Software</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.competitionintpage}>Competition Intelligence in Retail</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.goalbasedpage}>Goal Driven Retail Pricing</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.pricingconsultpage}>Retail Pricing Consulting</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.otherdriver}>Other Drivers of Pricing</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Solutions" id="collapsible-nav-dropdown2">
                        <p className="mob_nav_head">By Need</p>
                        <NavDropdown.Item href={AllRoutes.automated_dp}>Time Saving with an Automated Pricing Tool</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.pricingautomation}>Automated Pricing Across Channels</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.comppricetrack}>Competitive Price Tracking</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.marketpricing}>Market Driven Pricing</NavDropdown.Item>
                        <p className="mob_nav_head">By Type</p>
                        <NavDropdown.Item href={AllRoutes.retailers}>For Retailers</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.marketintel}>For Marketplaces</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.brandseller}>For Brands & Sellers</NavDropdown.Item>
                        <p className="mob_nav_head">By Industry</p>
                        <NavDropdown.Item href={AllRoutes.electronics}>Electronics</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.hwb}>Health, Wellness & Beauty</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.food}>Food & Consumables</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.fashion}>Fashion & Apparel</NavDropdown.Item>
                        <p className="mob_nav_head">By Roles</p>
                        <NavDropdown.Item href={AllRoutes.cxodecision}>CXOs & Decision Makers</NavDropdown.Item>
                        <NavDropdown.Item href={AllRoutes.categorymanagers}>Category Managers</NavDropdown.Item>
                      </NavDropdown>

                      <Nav.Link href='/retail/why-us'>Why Us</Nav.Link>
                      <Nav.Link href='/retail/book-a-demo'>Book a Demo</Nav.Link>

                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
    )
}

export default RetailHeaderMobile;