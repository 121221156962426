import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/food-consumbales/demand-forecasting.png";
import img2 from "../../assets/img/retail/food-consumbales/competition-intelligence.png";
import img3 from "../../assets/img/retail/food-consumbales/3.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/retail/fashion-apparel/2.png";
import bgunified from "../../assets/img/retail/electronics/competition_intelligence.png";
import bgcomp from "../../assets/img/retail/health-wellness-beauty/digital_shelf.png";


import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";
import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import RetailInsideNumsix from "../retail/retailInsideNumSix";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import NavigationSection from "../retail/NavigationSection";
import { GlobalUrl, GlobalImg } from "../../global";


const FoodConsumable = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "food-consumable";

  const qa_list = [
    "Have you experienced challenges in adjusting prices quickly in response to competitor pricing changes?",
    "Do you find it difficult to track and monitor pricing data across multiple products and categories?",
    "Have you missed opportunities to optimize prices based on real-time market demand and trends?",
    "Is it challenging to ensure pricing consistency across different sales channels and locations?",
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_props = {
    id: "food_cons",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Demand and Price Forecasting",
        desc: "Estimates your future customer demand with 94% accuracy in combination your inventory status thereby increasing your operational efficiency by 30%.",
        img: img1 ,
        alt: "Demand and Price Forecasting Image" 
      },
      {
        title: "Competition Intelligence",
        desc: "With 99% product matching accuracy our dynamic solution stays on top of your competitors’ price movements leading to higher revenue sales.",
        img: img2 ,
        alt: "Competition Intelligence" 
      },
      {
        title: "Target Pricing",
        desc: "Chart out your growth goals - powered by BRIO’s AI algorithm. Apply real-time tracking and optimization to auto-tweak your pricing and promotion to achieve the same.",
        img: img3 ,
        alt: "Target Pricing" 
      },
     

    ]
  }
  const number_props = {
    id: "num_prop",
    heading: "We Let Numbers Do the Talking",
    tabs:[
      {
        num: "30%",
        desc: "Improvement in operational efficiency",
      },
      {
        num: "38%",
        desc: "Improvement in sales with scientific and timely decision",
      },
      {
        num: "21%",
        desc: "Improvement in profitability",
      },
      {
        num: "37%",
        desc: "Improvement in markdown realization",
      },
      {
        num: "94%",
        desc: "Accurate demand forecasting",
      },
      {
        num: "65%",
        desc: "Improvement in response to market changes",
      },
      
     

    ]
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
        <title>Best Pricing Solutions for Food & Consumables | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.food}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Our automated pricing tool system ensures optimal prices for food & consumables, tackling overstocking and maximizing profits." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Best Pricing Solutions for Food & Consumables | Sciative" />
          <meta property="og:description" content="Our automated pricing tool system ensures optimal prices for food & consumables, tackling overstocking and maximizing profits." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.food}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
               {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/food-consumable"  
                }]
              }
              
              `}
            </script>
        </Helmet>
        <InnerHeroRetail end_point={end_point} page_name="Food and Consumables" page_heading="Tailored Soutions for Food and Consumables Industry" page_desc1="Unlock Precise Pricing, Gain a Competitive Edge, and Boost Profitability in Food & Consumable Retail" button_name="See How Automation Works" />
       
       <NavigationSection {...navigation_props} />
        <RetailClient />



        <section className="client_det inside_det">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead mb-5">We Deliver, Our Customer's Speak </h2>
            <div className="card">

              <p className="client_test">Ever since integrating BRIO's automated pricing software and feature tagging, our food & consumable retail business has experienced a remarkable surge in revenue. The software's price optimization and highlighting key product features have resulted in a 30% increase in sales. We've seen a noticeable improvement in customer engagement and satisfaction, with the software enabling us to showcase our products more effectively.</p>
              <div className="flex_card">
                <div className="card_1">
                  {/* <img src={clientimg} alt="Rupesh Patil" /> */}
                  <div className="client_desc">
                    <p className="namec">Premium Food Brand</p>
                    <p className="named">North America</p>
                  </div>
                </div>

                {/* <img src={kkimg} alt="" className="kk_img" /> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <p className="bold">Our experts are ready to help you with your profit margins now!</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button class="yellow-button">Book your slot!</button>
              </Link>
            </div>
          </div>
        </section>
        <RetailInsideNumsix end_point={end_point} num_prop={number_props} />
        {/* <RetailBenefits /> */}
        <CapteraAchievements props={"brio_inside"} />

        <MarketPlaceIntegration   end_point={end_point}/>
        <section className="qa_store">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Electronics" pagelink={AllRoutes.electronics} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgcomp} pageheading="Health, Wellness & Beauty" pagelink={AllRoutes.hwb} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Fashion & Apparels" pagelink={AllRoutes.fashion} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>

      </HelmetProvider>


    </>
  )
}
export default FoodConsumable;