import { useState, useEffect, useMemo, useCallback } from "react";
import Form from "../Forms/Form";
import axios from "axios";
import { GlobalUrl, GlobalImg } from "../../global.js";
import AllRoutes from "../../AllRoutes";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import { toast } from "react-toastify";
import "../../assets/css/Glossary.css";
import { Skeleton } from "@mui/material";
import { Box, Button } from "@mui/material";
import { Divider } from "@mui/material";
import ScrollButtons from "../common/ScrollButtons";
import "../../assets/css/main.scss";

const AlphaSkeleton = ({ letters }) => {
  return (
    <div className="container">
      <ul className="my-2 inline-list d-flex flex-wrap">
        <li className="enabled selected-alpha">
          <Skeleton variant="text" width={30} />
        </li>
        {Array.from({ length: 17 }).map((_, index) => (
          <li key={index} className="enabled">
            <Skeleton variant="text" width={20} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const CategorySkeleton = (props) => {
  const skeletons = Array.from({ length: 6 }); // One extra for "All Topics"

  return (
    <Box className="mt-4">
      <Box className="mb-5 filter-section w-100 d-flex align-items-center justify-content-center flex-wrap">
        {skeletons.map((_, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            width={120}
            height={40}
            className="mx-1 my-2"
          />
        ))}
      </Box>
    </Box>
  );
};

const GlossarySkeleton = () => {
  return (
    <Box>
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className="glossary-ele">
          <Box className="my-5">
            <Skeleton variant="text" width="60%" height={50} />
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="70%" height={20} />
          </Box>
          <Divider sx={{ borderColor: "black", borderWidth: 1 }} />
        </div>
      ))}
    </Box>
  );
};

const SearchSkeleton = () => {
  return (
    <Box className="w-100 row gap-3">
      <Box className="col-sm-12 col-md-6">
        <Skeleton variant="rounded" width="100%" height={40} />
      </Box>
      <Box className="col-sm-12 col-md-2 rounded-pill">
        <Skeleton variant="rounded" width="100%" height={40} />
      </Box>
      <Box className="">
        <Skeleton variant="text" width="50%" height={20} />
      </Box>
    </Box>
  );
};

const AlphabetStickyComponent = ({
  letters,
  handleAlphaChange,
  selectedAlpha,
}) => {
  const [isLetterSticky, setIsLetterSticky] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [stickyAtPosition, setStickyAtPosition] = useState(0);

  useEffect(() => {
    // Handler to update the state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    const categoryTabs = document.querySelector("#categoryTabs");

    if (categoryTabs) {
      const categoryTabB = categoryTabs.getBoundingClientRect();
      const bottom = categoryTabB.bottom + window.scrollY - 130;
      setStickyAtPosition(bottom);
    }
    // Remove .fixed when window is resized
    const alphabet_list = document.querySelector("#alphabet_list");
    alphabet_list?.classList?.remove("fixed");
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const alphabet_list = document.querySelector("#alphabet_list");
    alphabet_list?.classList?.remove("fixed");
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (windowWidth < 768) {
        // Mobile screen check
        const offset = window.scrollY;
        setIsLetterSticky(offset > stickyAtPosition);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stickyAtPosition, windowWidth]);

  return (
    <>
      <div
        className={`container letter-sticky ${isLetterSticky ? "fixed" : ""}`}
        id="alphabet_list"
      >
        <ul className="inline-list my-2 py-2 d-flex flex-wrap">
          <li
            className={`enabled ${
              selectedAlpha === null ? "selected-alpha" : ""
            }`}
            onClick={() => handleAlphaChange(null)}
          >
            Show All
          </li>
          {letters.map(({ letter, disabled }) => (
            <li
              key={letter}
              onClick={() => handleAlphaChange(letter)}
              className={`${selectedAlpha === letter ? "selected-alpha" : ""} ${
                disabled ? "disabled" : "enabled"
              }`}
            >
              {letter}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const Glossary = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [glossaryList, setGlossaryList] = useState([]);
  const [catFilteredList, setCatFilteredList] = useState([]);
  const [letFilteredList, setLetFilteredList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAlpha, setSelectedAlpha] = useState(null);

  const [categories, setCategories] = useState([]);
  const [letters, setLetters] = useState(
    Array.from({ length: 26 }, (_, i) => {
      const letter = String.fromCharCode(65 + i);
      return {
        letter: letter,
        disabled: true,
      };
    })
  );

  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [catChangeLoading, setCatChangeLoading] = useState(true);
  const [isLetterSticky, setIsLetterSticky] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [stickyAtPostion, setStickyAtPostion] = useState();
  const [mobileSize, SetMobileSize] = useState(768);

  // Update windowWidth state on resize
  useEffect(() => {
    // Handler to update the state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const categoryTabs = document.querySelector("#categoryTabs");
    if (categoryTabs) {
      const categoryTabB = categoryTabs.getBoundingClientRect();
      const bottom = categoryTabB.bottom;
      setStickyAtPostion(bottom);
    }
    const alphabet_list = document.querySelector("#alphabet_list");
    // const mobile = 768;
    if(windowWidth > mobileSize){
      alphabet_list?.classList?.remove('fixed')
    }
  }, [windowWidth]);

  // Make the letters sticky on scroll
  useEffect(() => {
    const categoryTabs = document.querySelector("#categoryTabs");
    if (categoryTabs) {
      const categoryTabB = categoryTabs.getBoundingClientRect();
      const bottom = categoryTabB.bottom;

    }
    // const mobile = 768;

    const handleScroll = () => {
      const offset = window.scrollY;
      if (windowWidth < mobileSize) {
        setIsLetterSticky(offset > stickyAtPostion);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stickyAtPostion]);


  // Show skeleton when there's a category change
  useEffect(() => {
    if (glossaryList.length != 0) {
      setCatChangeLoading(true);
      const timeout = setTimeout(() => {
        setCatChangeLoading(false);
      }, 300);

      return () => clearTimeout(timeout);
    }
  }, [selectedCategory, glossaryList]);

  const updateLetters = () => {
    const updatedLetters = letters.map(({ letter }) => {
      const isLetterPresent = catFilteredList?.some(
        (item) => item.term?.charAt(0).toUpperCase() === letter
      );
      return {
        letter: letter,
        disabled: !isLetterPresent,
      };
    });
    setLetters(updatedLetters);
  };

  useEffect(() => {
    updateLetters();
  }, [glossaryList, catFilteredList]);

  // Get glossary data
  const getGlossary = async () => {
    try {
      const response = await axios("/fetch_all_glossary", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200 && response.data !== undefined) {
        const filteredData = response.data.filter(
          (item) => item.keywords === undefined
        );
        filteredData.sort((a, b) => a.term.localeCompare(b.term));
        setCatFilteredList(filteredData);
        setLetFilteredList(filteredData);
        setGlossaryList(filteredData);
        const timeout = setTimeout(() => {
          setLoading(false);
          setCatChangeLoading(false);
        }, 1000);
      } else {
        setLoading(true);
        setCatChangeLoading(true);
      }
    } catch (error) {
      setLoading(true);
      setCatChangeLoading(true);
      console.log(error);
    }
  };

  // Update filtered list based on search input
  const handleSearch = () => {
    if (selectedCategory != "all" || selectedAlpha != null) {
      if (!searchInput){
        toast.dismiss()
        toast.error('Invalid input! Please enter a valid input.');
        return
      }
      setSelectedCategory("all");
      setSelectedAlpha(null);
      return;
    }
    let result = glossaryList;
    if (searchInput) {
      if (searchInput != ""){
        result = glossaryList.filter(
          (item) =>
            item.term.toLowerCase().includes(searchInput) ||
            item.definition.toLowerCase().includes(searchInput)
        );
      }
    }
    else {
      toast.dismiss()
      toast.error('Invalid input! Please enter a valid input.');
    }
    setLetFilteredList(result);
  };

  const handleAlphaChange = (letter) => {
    const alphabet_list = document.querySelector("#alphabet_list");
    const categoryTabs_ = document.querySelector("#categoryTabs");
    if (alphabet_list.classList.contains("fixed")) {
      categoryTabs_.scrollIntoView({
        behavior: "smooth", 
        block: "start", 
        inline: "center",
      });
    }
    setSearchInput("");
    setSelectedAlpha(letter);
  };

  const handleCategoryChange = (e) => {
    setSearchInput("");
    setSelectedCategory(e.target.value);
  };

  // Update categories when glossaryList changes
  useEffect(() => {
    const categorySet = new Set(glossaryList.map((item) => item.category));
    setCategories([...categorySet]);
  }, [glossaryList]);

  // Update filtered list
  useEffect(() => {
    let result = glossaryList;

    if (selectedCategory !== "all") {
      result = result.filter((item) => item.category === selectedCategory);
    }

    setCatFilteredList(result);

    if (selectedAlpha) {
      result = result.filter((item) =>
        item.term.toUpperCase().startsWith(selectedAlpha)
      );
    }
    if (searchInput) {
      if(searchInput != ""){
        result = glossaryList.filter(
          (item) =>
            item.term.toLowerCase().includes(searchInput) ||
            item.definition.toLowerCase().includes(searchInput)
        );
      }
      
    } 
    setLetFilteredList(result);
  }, [selectedCategory, selectedAlpha, glossaryList]);

  useEffect(()=>{
    if(searchInput === "" && selectedAlpha === null){
      setLetFilteredList(glossaryList)
    }
  }, [searchInput, selectedAlpha])

  useEffect(() => {
    setSelectedAlpha(null);
  }, [selectedCategory]);
  useEffect(() => {
    getGlossary();
  }, []);

  const props = {
    template: 2,
    //glossary form subscription
    form_name: "glossary-page",
    fields: [
      {
        name: "email",
        required: true,
      },
    ],
    recipients_group_id: 1,
    country: "IN",
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success") {
        toast.success("Form Submitted successfully");
      } else {
        toast.error(res.data);
      }
    },
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Pricing Glossary: AI, Analytics & Dynamic Pricing | Sciative </title>
        <link rel="canonical" href={`${GlobalUrl}${AllRoutes.glossary}`} />
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />

        <meta property="type" content="website" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="_token" content="" />
        <meta name="robots" content="noodp" />

        <meta
          name="description"
          content="Explore clear definitions and expert insights on key pricing concepts like Dynamic Pricing, Fare Adjustment, and Market Penetration. Learn More!"
        />
        <meta name="keywords" content="Bus travel, Dynamic pricing, Bus ticketing, Fleet management, Route optimization, Passenger demand, Real-time pricing, Seat allocation, Revenue management, Bus schedules, Fare adjustment, Competitive pricing, Online ticket booking, Mobile ticketing, Bus operator, AI in transportation, Predictive analytics, Demand forecasting, Occupancy optimization, Price elasticity, Travel trends, Seasonal pricing, Off-peak pricing, Peak period pricing, Customer segmentation, Travel behavior, Travel analytics, Bus reservations, Pricing strategy, Market competition, Fare comparison, Pricing algorithms, Revenue optimization, Dynamic fare rules, Bus services, Automated pricing, Travel planning, Booking systems, Yield management, AI-powered pricing, Bus network, Transportation logistics, Passenger flow, Booking lead time, Route planning, Ticket pricing, Bus fleet, Passenger experience, Bus revenue, Pricing intelligence, Travel patterns, Price optimization, Transit data, Bus booking platform, Travel insights, Price adjustment, Route profitability, Travel demand, Fare strategy, Travel booking trends, Bus occupancy, Passenger analytics, Bus seat pricing, Travel booking system, Pricing trends, Travel demand forecasting, Automated fare collection"></meta>
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Pricing Glossary: AI, Analytics & Dynamic Pricing | Sciative " />
        <meta
          property="og:description"
          content="Explore clear definitions and expert insights on key pricing concepts like Dynamic Pricing, Fare Adjustment, and Market Penetration. Learn More!"
        />

        <meta property="og:hashtag" content="#ai" />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={`${GlobalUrl}${AllRoutes.glossary}`} />
        <meta
          property="og:site_name"
          content="Sciative Solutions - We Price Right"
        />

        <meta property="og:image" content={GlobalImg} />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="glossary-banner">
        <div className="container pb-5" data-aos="new-animation">
          <h1 className="about_para w-80 bold">
            Comprehensive Glossary of Key Pricing Terms
          </h1>
          <div className="mt-5 col-sm-12 col-md-6">
            <p className="bold about_para_2 mb-4">
              Subscribe to our research articles for industry driven insights
            </p>
            <Form {...props} />
          </div>
        </div>
      </section>
      {/* Category Tabs */}
      <div className="mt-4" id="categoryTabs">
        {loading ? (
          <CategorySkeleton />
        ) : (
          <div className="mb-5 filter-section w-100 d-flex align-items-center justify-content-center flex-wrap">
            <button
              key={"all"}
              onClick={handleCategoryChange}
              value="all"
              className={`mx-1 fs-6 px-4 my-2 ${
                selectedCategory.toLowerCase() === "all" ? "selected" : ""
              }`}
            >
              All Topics
            </button>
            {categories.map((item) => (
              <button
                key={item}
                onClick={handleCategoryChange}
                value={item}
                className={`mx-1 fs-6 px-4 my-2 ${
                  selectedCategory === item ? "selected" : ""
                }`}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
      {/* Alphabetical filter */}

      {catChangeLoading ? (
        <AlphaSkeleton letters={letters} />
      ) : (
        <AlphabetStickyComponent
          letters={letters}
          handleAlphaChange={handleAlphaChange}
          selectedAlpha={selectedAlpha}
        />
      )}
      <section className="">
        <div className="container">
          <div className=""></div>

          {/* Search Bar */}
          {catChangeLoading ? (
            <SearchSkeleton />
          ) : (
            <>
              <div
                class="w-100 row gap-3 m-auto"

                // data-aos="new-animation"
              >
                <input
                  class="col-sm-12 col-md-6 px-4 py-2 glos-input-box"
                  type="text"
                  placeholder="Search for Revenue Management"
                  style={{ color: "black" }}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
                />
                <button
                  className="col-sm-12 col-md-1 fs-5 rounded-pill glos-search-btn"
                  // class="col-sm-12 col-md-2 fs-5 rounded-pill search-btn"
                  onClick={handleSearch}
                >
                  Search
                </button>
                <p className="px-0 mb-4">
                  There are currently {letFilteredList?.length} glossary items
                  in this directory
                </p>
              </div>
            </>
          )}

          {/* List of Words */}
          {catChangeLoading ? (
            <GlossarySkeleton />
          ) : (
            <>
              <div id="glossary-div">
                {letFilteredList.map((item, index) => (
                  <div className="glossary-ele">
                    <div key={index} className="my-5">
                      <h3 className="fw-bolder">{item.term}</h3>
                      <p>{item.definition}</p>
                    </div>
                    <Divider sx={{ borderColor: "black", borderWidth: 1 }} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <ScrollButtons />
      </section>
    </HelmetProvider>
  );
};

export default Glossary;
