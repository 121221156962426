import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import '../../assets/css/main.scss';
import { useEffect, useState } from "react";
import { Tab, Col, Nav, Row } from "react-bootstrap";
import investors from '../../assets/img/investors/bg.jpg';
import missionimg from '../../assets/img/investors/mission_img.png';
import venkatraju from '../../assets/img/investors/venkat-raju.png'
import indianretailer from '../../assets/img/media/indian-retailer.png';
import travelworld from '../../assets/img/media/travel-world.png';
import yourstory from '../../assets/img/media/your-story.png';
import rightarrowyellow from '../../assets/img/right-arrow-yellow.png'

import { Helmet, HelmetProvider } from "react-helmet-async";
import { Button } from 'react-bootstrap';
import ClientBusSlider from "../travel/clientBusSlider";
import Leadership from "../retail/leadership";
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
import p_1 from "../../assets/img/partner/p_1.png";
import p_2 from "../../assets/img/partner/p_2.png";
import p_3 from "../../assets/img/partner/p_3.png";
import p_4 from "../../assets/img/partner/p_4.png";
import p_5 from "../../assets/img/partner/p_5.png";
import p_6 from "../../assets/img/partner/p_6.png";
import p_7 from "../../assets/img/partner/p_7.png";
import p_8 from "../../assets/img/partner/p_8.png";
import p_9 from "../../assets/img/partner/p_9.png";
import p_10 from "../../assets/img/partner/p_10.png";
import p_11 from "../../assets/img/partner/p_11.png";
import p_12 from "../../assets/img/partner/p_12.png";
import bitla from "../../assets/img/partner/integration/bitla.png";
import abhibus from "../../assets/img/partner/integration/abhibus.png";
import amazon from "../../assets/img/partner/integration/amazon.png";
import flipkart from "../../assets/img/partner/integration/flipkart.png";
import infinity from "../../assets/img/partner/integration/infinity.png";
import maventech from "../../assets/img/partner/integration/maventech.png";
import quickbus from "../../assets/img/partner/integration/qwikbus_logo.webp";
import shopify from "../../assets/img/partner/integration/shopify.png";
import yolobus from "../../assets/img/partner/integration/yolobus.png";
import value_1 from "../../assets/img/partner/value/1.png";
import value_2 from "../../assets/img/partner/value/2.png";
import value_3 from "../../assets/img/partner/value/3.png";
import RaTalkSingle from "../retail/ratalksingle";
import TestimonialSlider from "../retail/testimonialslider";
import TravelBusInsights from "../travel/travelBusinsights";


import TravelProductNumber from "../travel/travelproductnumber";
import TestimonialSliderTravel from "../travel/testimonialslidertravel";
import CapteraAchievements from "../travel/capterra-achievvement";
import AllClientSlider from "../common/allClientSlider";
import { Link } from "react-router-dom";
import Form from "../Forms/Form";
import { toast } from 'react-toastify';
import AllRoutes from "../../AllRoutes";
import AOS from "aos";
import "aos/dist/aos.css";

import { GlobalUrl, GlobalImg } from "../../global";
const Partner = ({ }) => {
  useEffect(() => {
    
    AOS.init();
    AOS.refresh();
   
}, [])
  const formProps = {
    template: 4,
    form_name: 'book-a-demo-page-form',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
          toast.error(res.data)
      }
    }
}

  return (
    <>
  <HelmetProvider>
            <Helmet>
                <title>Join Our AI-Dynamic Pricing Ecosystem to grow more | Sciative</title>
                <link rel="canonical" href={`${GlobalUrl}${AllRoutes.partner}`} />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="csrf_token" content="" />
                <meta property="type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta name='description' content="Partner with us to Harness AI dynamic Pricing Power and Achieve Unprecedented Growth. Explore Our Partner Program and Join the Journey to Success Today." />
                {/* <meta name='keywords' content="Travel, Viaje.ai, Bus, Dynamic Pricing, automate pricing,automated pricing tool,brand protection software,competitor price tracking software,demand forecasting software,discounting software" /> */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content="Join Our AI-Dynamic Pricing Ecosystem to grow more | Sciative" />
                <meta property="og:description" content="Partner with us to Harness AI dynamic Pricing Power and Achieve Unprecedented Growth. Explore Our Partner Program and Join the Journey to Success Today." />
                <meta property="og:hashtag" content="#ai" />
                <meta content="image/*" property="og:image:type" />
                <meta property="og:url" content={`${GlobalUrl}${AllRoutes.partner}`} />
                <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                <meta property="og:image"  content={GlobalImg} />
                
                <meta property="og:type" content="website" />
            </Helmet>
      <section className="smallBanner partner_bg">
        <div className="container" data-aos="new-animation">
          <div className="row">
            <div className="col-md-8">
              <p className="small_head">Partners</p>
              <h1 className="about_para">Drive New Growth Opportunities as Our Trusted Partner </h1>
              <p className="about_para_2">
              With Our Game-Changing, Automated Pricing Tool, You Can Unlock New Opportunities, Uncover Cutting-Edge Pricing Intelligence, and Deliver Tangible Value for Your Clients.
              </p>

              <div>
                {/* <Button className="transparent-button">Discover more! </Button> */}
                <Link to={AllRoutes.partnercontact}>
                  <button className="yellow-button">Let's Collaborate</button>
                </Link>
              </div>
            </div>
          </div>




        </div>
      </section>
      <CapteraAchievements props="partner_capterra" />
      <section className="why_section partner_synergy" id="key-features">

        <div className="container" data-aos="new-animation">
          <h2 className="bigHead  text-center mb-5">Partner Synergies, Powering Progress</h2>

          <div className="row">
            <div className="col-md-6">
              <div className="why_card">
                <div className="why_card_1">
                  <img src={p_1} alt="" />
                </div>
                <div className="why_card_2">
                  <h3 className="card_head">
                  Become a Business Partner
                  </h3>
                  <p className="why_card_3">Let Sciative’s innovative solutions be a part of your offerings, which will expand revenue streams and deliver better business value for clients, and yourself.</p>
                  <Link to={AllRoutes.partnercontact}>
                    <button className="yellow-button">Discover New Revenue Streams</button>
                  </Link>
                </div>
              </div>
            </div>
            {/* col-md-6 */}
            <div className="col-md-6">
              <div className="why_card">
                <div className="why_card_1">
                  <img src={p_2} alt="" />
                </div>
                <div className="why_card_2">
                  <h3 className="card_head">
                   Become a Technology Partner
                  </h3>
                  <p className="why_card_3">Augment your capability and feature compatibility by easily integrating our APIs into your platform. Deliver cutting-edge dynamic pricing tools to your users.</p>
                  <Link to={AllRoutes.partnercontact}>
                    <button className="yellow-button">Explore Advanced Tech Markets</button>
                  </Link>
                </div>
              </div>
            </div>
            {/* col-md-6 */}
            <div className="col-md-6">
              <div className="why_card">
                <div className="why_card_1">
                  <img src={p_3} alt="" />
                </div>
                <div className="why_card_2">
                  <h3 className="card_head">
                  Become a Research Partner
                  </h3>
                  <p className="why_card_3">By partnering with us, an AI pricing leader, gain access to vast datasets spanning diverse industries, enriching your services and publications with unparalleled insights and depth.</p>
                  <Link to={AllRoutes.partnercontact}>
                    <button className="yellow-button">Expand Data Insights’ Horizon</button>
                  </Link>
                </div>
              </div>
            </div>
            {/* col-md-6 */}
            <div className="col-md-6">
              <div className="why_card">
                <div className="why_card_1">
                  <img src={p_4} alt="" />
                </div>
                <div className="why_card_2">
                  <h3 className="card_head">
                  Become a Data Partner
                  </h3>
                  <p className="why_card_3">Join us as a data partner and harness the power of AI pricing technology. Contribute your extensive datasets from various industries to unlock new revenue streams for your team.</p>
                  <Link to={AllRoutes.partnercontact}>
                    <button className="yellow-button">Transform Data Into Revenue </button>
                  </Link>
                </div>
              </div>
            </div>
            {/* col-md-6 */}
          </div>
        </div>
      </section>
      <section className="partner_value">
        <div className="container"  data-aos="new-animation">
          <h2 className="bigHead text-center mb-5">Our Valued Partners</h2>
          <div className="row">
            <div className="col-md-4 col-lg-3 card_div">
              <div className="card">
                <img src={bitla} alt="Bitla Logo" />
                <p>Bitla is the world's largest technology and solutions provider to the bus industry.</p>
              </div>
            </div>
            {/* card_div end */}
            <div className="col-md-4 col-lg-3 card_div">
              <div className="card">
                <img src={amazon} alt="Amazon Logo" />
                <p>Amazon is a global e-commerce tech giant dealing in an extensive network of industries. </p>
              </div>
            </div>
            {/* card_div end */}
            <div className="col-md-4 col-lg-3 card_div">
              <div className="card">
                <img src={maventech} alt="Maventech Logo" />
                <p>MavenTech Labs builds smart & robust road transport network platform products. </p>
              </div>
            </div>
            {/* card_div end */}
            <div className="col-md-4 col-lg-3 card_div">
              <div className="card">
                <img src={flipkart} alt="Flipkart Logo" />
                <p>Flipkart is India’s trailblazing e-commerce platform with millions of product listings.</p>
              </div>
            </div>
            {/* card_div end */}
            <div className="col-md-4 col-lg-3 card_div">
              <div className="card">
                <img src={shopify} alt="Shopify Logo" />
                <p>Shopify is a new age e-commerce platform built for online retail stores and small businesses.</p>
              </div>
            </div>
            {/* card_div end */}
            <div className="col-md-4 col-lg-3 card_div">
              <div className="card">
                <img src={yolobus} alt="Yolobus Logo" />
                <p>Yolo Bus is a major bus operator and travel solutions provider covering more than 250 routes across India providing global distribution system support solutions.</p>
              </div>
            </div>
            {/* card_div end */}
            <div className="col-md-4 col-lg-3 card_div">
              <div className="card">
                <img src={infinity} alt="Infinity Logo" />
                <p>Infinity Travel Solutions is a budding travel solutions provider for major bus operators in India.  </p>
              </div>
            </div>
            {/* card_div end */}
            <div className="col-md-4 col-lg-3 card_div">
              <div className="card">
                <img src={quickbus} alt="Quikbus Logo" />
                <p>QwikBus enables bus companies to sell tickets online through multiple channels- Direct website, Online Travel Agents & Offline Agents.  </p>
              </div>
            </div>
            {/* card_div end */}
            <div className="col-md-4 col-lg-5 col-xl-4 card_div">
              <div className="card">
                <img src={abhibus} alt="Abhibus Logo" />
                <p>Abhibus is a leading and top-rated bus booking platform in India for various major bus services including both state transportation corporations and private operators. </p>
              </div>
            </div>
            {/* card_div end */}


          </div>
        </div>
      </section>
      <section className="investor_edge partner_edge">
        <div className="container"  data-aos="new-animation">
          <h2 className="bigHead text-center mb-5">We Drive Value for Partners and Their Clients</h2>
          <Tab.Container defaultActiveKey="first">
            <Row>
              <Col xl={5}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Expand Your Offerings

                      <p>Access to state-of-the-art AI tools for dynamic, automated pricing across channels, and deep competition intelligence.</p>
                      <div className="investor_edge_div res_img">
                        <img src={value_1} alt="Expand Your Offerings" />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Build New Expertise
                      <p>Benefit from our extensive knowledge and industry insights across travel, retail, hospitality.</p>
                      <div className="investor_edge_div res_img">
                        <img src={value_2} alt="Build New Expertise" />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Innovative Tools
                      <p>Deliver measurable value with Sciative's innovative tools. We have helped clients add up to 66% to their revenue within a few months.</p>
                      <div className="investor_edge_div res_img">
                        <img src={value_3} alt="Expand Your Offerings" />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xl={7}  className="second_div">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="investor_edge_div">
                      <img src={value_1} alt="Expand Your Offerings" />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="investor_edge_div">
                      <img src={value_2} alt="Build New Expertise" />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="investor_edge_div">
                      <img src={value_3} alt="Innovative Tools" />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </div>
      </section>

      <section className="why_partner">
        <div className="container"  data-aos="new-animation">
          <h2 className="bigHead text-center mb-5">Why Partner with Sciative?</h2>
          <div className="row">
            <div className="col-md-6 why_row">
              <div className="card_2 row">
                <div className="why_headings col-12  col-xl-7">

                  <p className="why_head">Increase NPS by 15%</p>
                  <p className="why_para">Strengthen customer relationships and satisfaction levels through collaborative initiatives.</p>
                </div>
                <div className="why_img col-6 offset-6  offset-xl-0 col-xl-5">
                  <div className="img_inside">
                    <img src={p_9} alt="AI icon" />
                  </div>
                </div>
              </div>
            </div>
            {/* col-md-6 end */}
            <div className="col-md-6 why_row">
              <div className="card_2 row">
                <div className="why_headings col-12  col-xl-7">

                  <p className="why_head">Boost Repeat Purchase Orders by 22%</p>
                  <p className="why_para">Foster long-term partnerships that encourage trust and loyalty among your clientele.</p>
                </div>
                <div className="why_img col-6 offset-6  offset-xl-0 col-xl-5">
                  <div className="img_inside">
                    <img src={p_10} alt="Orders icon" />
                  </div>
                </div>
              </div>
            </div>
            {/* col-md-6 end */}
            <div className="col-md-6 why_row">
              <div className="card_2 row">
                <div className="why_headings col-12  col-xl-7">

                  <p className="why_head">Reduce Customer Attrition</p>
                  <p className="why_para">Implement strategies together to retain customers and ensure sustainable growth for both parties.</p>
                </div>
                <div className="why_img col-6 offset-6  offset-xl-0 col-xl-5">
                  <div className="img_inside">
                    <img src={p_11} alt="Diamond icon" />
                  </div>
                </div>
              </div>
            </div>
            {/* col-md-6 end */}
            <div className="col-md-6 why_row">
              <div className="card_2 row">
                <div className="why_headings col-12  col-xl-7">

                  <p className="why_head">Unlock New Revenue Streams</p>
                  <p className="why_para">Explore mutually beneficial opportunities to expand revenue sources and drive shared success in a 1 trillion dollar industry.</p>
                </div>
                <div className="why_img col-6 offset-6  offset-xl-0 col-xl-5">
                  <div className="img_inside">
                    <img src={p_12} alt="Graph icon" />
                  </div>
                </div>
              </div>
            </div>
            {/* col-md-6 end */}
          </div>
        </div>
      </section>
      {/* <Leadership /> */}

      <section className="partner-form-section">
        <div className="container d-flex flex-column align-items-center"  data-aos="new-animation">
          <div className="text-container">
            <h2 className="bigHead">Let us Grow Together, Partner with Sciative</h2>
            <p>Book a meeting with our team to get an overview of our partnership program and how we can tailor it for you.</p>
          </div>
          <div className="form-container m-0">
            <Form {...formProps}/>
          </div>
        </div>
      </section>
</HelmetProvider>
    </>
  );
}


export default Partner;