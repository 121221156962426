import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import '../../assets/css/main.scss';
import rightarrow from '../../assets/img/right-arrow.png';
import retailvideo from '../../assets/videos/retail_video.mp4';

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import ClientSlider from "../retail/clientSlider";
import Leadership from "../retail/leadership";
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
import ProductNumber from "../retail/productnumber";
import RaTalkSingle from "../retail/ratalksingle";
import TestimonialSlider from "../retail/testimonialslider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";
import RetailNumbers from "../retail/retailNumbers";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailTestimonial from "../retail/retailTestimonial";
import RetailDatapoint from "../retail/retaiDatapoint";
import img10 from "../../assets/img/retail/automated-pricing/10.jpg";
import img11 from "../../assets/img/retail/automated-pricing/11.jpg";
import img12 from "../../assets/img/retail/automated-pricing/12.jpg";
import AutomatedPricingTab from "../retail/automatedPricingTab";
import MarketIntelligenceTab from "../retail/marketIntelligenceTab";
import SalesInventoryTab from "../retail/salesInventoryTab";
import DiscountManagementTab from "../retail/discountManagement";
import { Link } from "react-router-dom";
import CustomerDataTab from "../retail/customerDataTab";
import DigitalShelfTab from "../retail/digitalShelfTab";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
import InnerHeroRetail from "../retail/innerHeroRetail";
import BrandProtectionTab from "../retail/brandProtectionTab";


const BrandSellers = ({ }) => {
  const end_point = "brands-sellers";
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
        <title>Dynamic Pricing software for Brands & Sellers | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.brandseller}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />

          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta property="og:type" content="website" />
          <meta name='description' content="Smarter Retail Price Optimization: Sciative helps brands achieve effortless growth with AI-powered pricing. Drive results, maximize profits, and stay ahead." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Dynamic Pricing software for Brands & Sellers | Sciative" />
          <meta property="og:description" content="Smarter Retail Price Optimization: Sciative helps brands achieve effortless growth with AI-powered pricing. Drive results, maximize profits, and stay ahead." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.brandseller}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
        
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/brands-and-sellers"  
                }]
              }
              
              `}
            </script>
        </Helmet>
        <InnerHeroRetail end_point={end_point} page_name="retailers" page_heading="Tailored Solutions Brands and Sellers" page_desc1="BRIO's Big Data Analytics: Retail Insights" page_desc2="for Brand Success" button_name="Automate Your Business Today" />
      
        <RetailClient page_name="retail_page_client" page_heading="Brands who Leverage Sciative to Stay Ahead" />
        <section className="main_edge">
          <div className="container"  data-aos="new-animation">

            <div className="retail_main_tab">
              <Tab.Container defaultActiveKey="mainthird" >
                <Row id="r_main_one">
                <h2 className="bigHead text-center fw-normal">Elevate Your Brand Presence and Drive Growth with Our AI-Driven Tailored Solutions for Your Brand</h2>
               
                    <Tab.Content className="investor_edge">
                      <Tab.Pane eventKey="mainfirst">
                        {/* automated pricing start */}
                        <h4 className="bigHead text-center mb-5">Automated Pricing</h4>
                        <AutomatedPricingTab />
                        {/* automated pricing end */}
                        {/* marker inteligence start */}
                        <h4 className="bigHead text-center mb-5 mt-5">Market Intelligence</h4>
                        <MarketIntelligenceTab />
                        {/* market intelligence end */}
                        {/* sales and inventory start */}
                        <h4 className="bigHead text-center mb-5 mt-5">Sales and Inventory Analytics</h4>
                        <SalesInventoryTab />
                        {/* sales and inventory end */}
                        {/* first accordian  for retailers end */}
                      </Tab.Pane>
                      <Tab.Pane eventKey="mainsecond">
                           {/* discount management start */}
                           <h4 className="bigHead text-center mb-5 ">Discount Management</h4>
                        <DiscountManagementTab />
                        {/* discount management end */}
                        {/* automated pricing start */}
                        <h4 className="bigHead text-center mb-5 mt-5">Automated Pricing</h4>
                        <AutomatedPricingTab />
                        {/* automated pricing end */}
                        {/* marker inteligence start */}
                        <h4 className="bigHead text-center mb-5 mt-5">Market Intelligence</h4>
                        <MarketIntelligenceTab />
                        {/* market intelligence end */}
                     
                        {/* accordian  for marketplace end */}
                      </Tab.Pane>
                      <Tab.Pane eventKey="mainthird">
                         {/* Digital shelf  start */}
                         <h4 className="bigHead text-center mb-5 ">Digital Shelf Analytics </h4>
                        <DigitalShelfTab />
                        {/* Digital shelf  end */}
                        {/* automated pricing start */}
                        <h4 className="bigHead text-center mb-5 mt-5">Automated Pricing</h4>
                        <AutomatedPricingTab />
                        {/* automated pricing end */}
                        {/* marker inteligence start */}
                        <h4 className="bigHead text-center mb-5 mt-5">Market Intelligence</h4>
                        <MarketIntelligenceTab />
                        {/* market intelligence end */}
                        {/* Customer data  start */}
                        <h4 className="bigHead text-center mb-5 mt-5">Customer Data Analytics </h4>
                        <CustomerDataTab />
                        {/* Customer data  end */}
                        {/* brand protection  start */}
                        <h4 className="bigHead text-center mb-5 mt-5">Brand Protection </h4>
                       <BrandProtectionTab />
                        
                        {/* brand protection  end */}
                       
                        {/* first accordian  for brands end */}
                      </Tab.Pane>
                    </Tab.Content>
                
                </Row>
              </Tab.Container>
            </div>


          </div>
        </section>
        <CapteraAchievements props={"whyus_captera"} />
        <RetailDatapoint />
        <Recoginition page_name="home_recog" />


        <ProductNumber />
        <RetailTestimonial />
        {/* <TestimonialSlider /> */}
        <section className="click_button ">
          <div className="container text-center"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-3 text-white">See How We Can Make a Difference to Your Business</h2>
            <Button className="yellow-button">Explore Sciative</Button>
          </div>
        </section>

        <section className="form-section">
          <div className="container"  data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>
    </>
  );
}


export default BrandSellers;