import img10 from "../../assets/img/retail/automated-pricing/10.jpg";
import img11 from "../../assets/img/retail/automated-pricing/11.jpg";
import img12 from "../../assets/img/retail/automated-pricing/12.jpg";

import img1 from "../../assets/img/retail/customer-data/sentiment-analysis.png";
import img2 from "../../assets/img/retail/customer-data/customer-lifetime-value.png";
import img3 from "../../assets/img/retail/customer-data/segmentation-and-personalization.png";

import { Tab, Col, Nav, Row } from "react-bootstrap";
const BrandProtectionTab = () => {
   
    return(
        <Tab.Container defaultActiveKey="mfirst">
        <Row>
        <Col xl={5}>
            <Nav variant="pills" className="flex-column">
            <Nav.Item>
                <Nav.Link eventKey="mfirst">MAP Monitoring
                 <p>Protect your brand's pricing with our MAP monitoring tool, offering real-time alerts and verifiable proof of violations, enabling proactive pricing strategy maintenance.This ensures your brand remains competitive, preserving your market position and profitability.</p>
                 <div className="investor_edge_div res_img">
                    <img src={img1} alt="MAP Monitoring Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="msecond">Unauthorized Seller Tracking
                <p>Identify and address unauthorised sellers effectively with unauthorised seller monitoring, safeguarding your brand and pricing integrity while maintaining control over your product availability and online brand reputation.</p>
                <div className="investor_edge_div res_img">
                    <img src={img2} alt="Unauthorized Seller Tracking Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="mthird">Price and Availability Parity
                <p>BRIO ensures price and availability parity in e-commerce by synchronising prices and monitoring inventory across channels. Its automated tools help maintain pricing integrity, build customer trust, and navigate the market landscape for a strong presence.</p>
                <div className="investor_edge_div res_img">
                    <img src={img3} alt="Price and Availability Parity Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            </Nav>
        </Col>
        <Col xl={7} className="second_div">
            <Tab.Content>
            <Tab.Pane eventKey="mfirst">
                <div className="investor_edge_div">
                <img src={img1} alt="MAP Monitoring" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="msecond">
            <div className="investor_edge_div">
                <img src={img2} alt="Unauthorized Seller Tracking" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="mthird">
            <div className="investor_edge_div">
                <img src={img3} alt="Price and Availability Parity" />
                </div>
            </Tab.Pane>
            </Tab.Content>
        </Col>
        </Row>
</Tab.Container>
    )

}

export default BrandProtectionTab