import ReCAPTCHA from 'react-google-recaptcha';

const ReCAPTCHAComponent = ({setCaptcha}) => {

    const handleRecaptchaChange = async (token) => {
        if(
            token === undefined ||
            token === '' ||
            token === null
        ){
            console.log('CAPTCHA submit unsuccessfull.')
        }
        else{
            setCaptcha(token)
        }
    };

    const handleError = () => {
        alert('Something went wrong.')
    }

    return (
        <div className='mt-3 captcha-section'>
            <ReCAPTCHA
                size="normal"
                render="explicit"
                theme="dark"
                sitekey="6LfLXIApAAAAAD0NWDn1l6mESuIdnbvGGUpofY-d" //prod
                // sitekey="6Lf7z8cpAAAAAOeDC6h_wjKmYZyeqdQCChSYYUpR" //dev
                onChange={handleRecaptchaChange}
                onError={handleError}
            />
        </div>
    )
}

export default ReCAPTCHAComponent;