import { useEffect, useState } from 'react';
import { fetchToken } from '../../Auth';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const ShortStories = () => {
    const navigate = useNavigate()
    const [shorts, setShorts] = useState([])
    const [shortsCount, setShortCounts] = useState(4)
    const getShorts = async (url) => {

        try {
            const response = await axios.get(`ra_talks/get_latest_shorts`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setShorts(response.data.raYTShorts)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 400) {
                navigate('/error')
            }
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        getShorts()
    }, [])
    return (
        <div className='w-100 container d-flex flex-column align-items-center justify-content-center'>
            <h4 className='bold'>Short Industry Tales</h4>

            <div className='mt-4 w-100 row'>
                {shorts && Object.values(shorts) 
                    .slice(0, shortsCount)
                    .map((ele, index) => (
                        <div key={index} className='col-sm-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center'>
                            <iframe className='yt-shorts'
                                src={ele || "https://www.youtube.com/embed/B7gWZQIYQ7c"} 
                                title={"YouTube video player"}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen />
                        </div>
                    ))
                }

            </div>
        </div >
    )
}

export default ShortStories;