import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/automated-pricing/1.png";
import img2 from "../../assets/img/retail/automated-pricing/2.png";
import img3 from "../../assets/img/retail/automated-pricing/3.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";

import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";

import img1_ from "../../assets/img/retail/goalbased-pricing/accurately_predict_demand_forecasting.png";
import img2_ from "../../assets/img/retail/goalbased-pricing/brand_positioning.png";
import img3_ from "../../assets/img/retail/goalbased-pricing/position_your_brand_in_highly_competitive_market.png";
import img4_ from "../../assets/img/retail/goalbased-pricing/profit_maximization.png";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import { GlobalUrl, GlobalImg } from "../../global";
import b1 from "../../assets/img/icon/MaxmiseRevenue.png";
import b2 from "../../assets/img/icon/datadriven.png";
import b3 from "../../assets/img/icon/OptimizationandDecision.png";
import b4 from "../../assets/img/icon/CustomizableRules.png";
import b5 from "../../assets/img/icon/OperationalEfficiency.png";
import b6 from "../../assets/img/icon/Improvementinsales.png";

const GoalBasedPricing = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "goal-based-pricing";

  const qa_list = [
    "Do you get valuable insights into customer behaviour and purchasing trends across your product range?",
    "Does your pricing software align your pricing strategies with your sales targets?",
    "Can your tool optimize pricing strategies to meet demand and stay in sync with market trends across your product portfolio?",
    "Do you have automated demand prediction and price adjustments to free up your team and boost efficiency?"
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_section_props = {
    id: "goal_driven_pricing",
    heading: "Why Choose Goal-Driven Pricing in Retail?",
    tabs: [
        {
          title: "Accurately Predict Demand Forecasting",
          desc: "Estimate your future customer demand with 94% accuracy in combination with your inventory status, thereby increasing your operational efficiency by 30%.",
          img: img1_ ,
          alt: "Accurately Predict Demand Forecasting" 
        },
        {
          title: "Profit Maximization",
          desc: "Simultaneous assessment of multiple decision criteria like product margins, demand elasticity and competitor pricing, to ensure there are no revenue leaks thus achieving the highest possible profit margins.",
          img: img2_,
          alt: "Profit Maximization"
        },
        {
          title: "Brand Positioning",
          desc: "Secure and enhance your brand's standing in the global market. Select premium pricing strategies for your luxury brands to emphasize their exclusivity and quality, or adopt value-based pricing to attract budget-conscious consumers.",
          img: img3_,
          alt: "Brand Positioning"
        },
       
    ]
  }
  const benefit_props = {
    id: "goal_driven_pricing",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Revenue Maximization Potential",
        desc: "Transform your pricing strategies from manual to goal-based strategies, boosting bottom-line revenue by up to 16% and unlocking new heights of profitability.",
        img: b1 ,
        alt: "Revenue Maximization Potential Image" 
      },
      {
        title: "Data-Driven Insights Recommendations",
        desc: "Harness the power of advanced data analysis and machine learning to provide highly accurate forecasts, ultimately helping businesses achieve a 38% hike in sales.",
        img: b2 ,
        alt: "Data-Driven Insights Recommendations" 
      },
      {
        title: "Optimization and Data-Driven Decision Support",
        desc: "Strike the right balance between profitability and competitiveness through retail pricing recommendations based on automated pricing agility and seasonal price optimizations.",
        img: b3 ,
        alt: "Optimization and Data-Driven Decision Support" 
      },
      {
        title: "Customizable Rules and Automated Adjustments",
        desc: "We offer flexible retail pricing rules, integrating data-driven insights and demand forecasting to maximize profits through our automated pricing tool.",
        img: b4 ,
        alt: "Customizable Rules and Automated Adjustments" 
      },
      {
        title: "Operational Efficiency and Integration",
        desc: "Leverage leading marketplace integrations and advanced technology solutions to enhance operational efficiency by 30%.",
        img: b5 ,
        alt: "Operational Efficiency and Integration" 
      },
      {
        title: "Improvement in Sales and Timely Decisions",
        desc: "Our real-time market insights generate a 38% improvement in pricing optimizations with scientific and timely decisions.",
        img: b6 ,
        alt: "Improvement in Sales and Timely Decisions" 
      },

    ]
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Target Pricing Tool for Retail Industry | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.goalbasedpage}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Target Pricing empowers you to set clear profit or sales goals using AI-driven dynamic pricing to find the optimal price for every product, every day." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Target Pricing Tool for Retail Industry | Sciative" />
          <meta property="og:description" content="Target Pricing empowers you to set clear profit or sales goals using AI-driven dynamic pricing to find the optimal price for every product, every day." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.goalbasedpage}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org/", 
                  "@type": "BreadcrumbList", 
                  "itemListElement": [{
                    "@type": "ListItem", 
                    "position": 1, 
                    "name": "Home",
                    "item": "https://sciative.com"  
                  },{
                    "@type": "ListItem", 
                    "position": 2, 
                    "name": "Retail",
                    "item": "https://sciative.com/retail"  
                  },{
                    "@type": "ListItem", 
                    "position": 3, 
                    "name": "Unified Pricing Engine",
                    "item": "https://sciative.com/retail/goal-driven-retail-pricing"  
                  }]
                }
                
                
              `}
            </script>
        </Helmet>

        <InnerHeroRetail end_point={end_point} page_name="goalbasedpricing" page_heading="Goal Driven Retail Pricing" page_desc1="Achieve growth with automated real-time pricing" page_desc2="and promotions." button_name="Explore Target Pricing in Retail" />
        
        <RetailClient />

       <NavigationSection {...navigation_section_props}/>
      
        <RetailInsideNumber end_point="goal-based-pricing" />
        {/* <RetailBenefits /> */}
        <BenefitSection {...benefit_props}/>
        <MarketPlaceIntegration  end_point="goal-based-pricing" />
        <RetailTestimonial dataHeading="We Deliver, Our Customers ‘ Speak" />
        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />

        <QAScore end_point={{ end_point }} qa_list={qa_list} />

        <section className="qa_store">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Pricing Engine" pagelink={AllRoutes.automatedpricingpage} />
              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgcomp} pageheading="Competition Intelligence" pagelink={AllRoutes.competitionintpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />
              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container"  data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>

      </HelmetProvider >
    </>
  )
}
export default GoalBasedPricing;