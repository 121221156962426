import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";

const ScrollButtons = () => {
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {

      const topRange = window.innerHeight*1.8
      setIsAtTop(
        window.scrollY < topRange
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 110,
        right: 55,
        backgroundColor: "black",
        borderRadius: "100%",
        display: "flex",
        padding: "0.1em",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton
        onClick={!isAtTop ? scrollToTop : scrollToBottom}
        sx={{ color: "#FFC601", p: "0.01em" }}
      >
        {!isAtTop ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      </IconButton>
    </Box>
  );
};

export default ScrollButtons;
