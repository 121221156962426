import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";

import img1 from "../../assets/img/retail/automated-pricing/1.png";
import img2 from "../../assets/img/retail/automated-pricing/2.png";
import img3 from "../../assets/img/retail/automated-pricing/3.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";

import img1_ from "../../assets/img/retail/unified-pricing/maintain_consistency_across_channels.png";
import img2_ from "../../assets/img/retail/unified-pricing/grab_on_cross-selling_and_up-selling_opportunities.png";
import img3_ from "../../assets/img/retail/unified-pricing/efficiently_manage_promotion_discount.png";
import img4_ from "../../assets/img/retail/unified-pricing/enhance_your_inventory_management.png";


import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";

import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import BenefitSection from "../retail/BenefitSection";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import InnerCard from "../retail/innercard";
import AllRoutes from "../../AllRoutes";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import NavigationSection from "../retail/NavigationSection";
import b1 from "../../assets/img/icon/pricecontrol.png";
import b2 from "../../assets/img/icon/CustomizableRules.png";
import b3 from "../../assets/img/icon/integration.png";
import b4 from "../../assets/img/icon/multicurrency.png";
import b5 from "../../assets/img/icon/dataanalysis_1.png";
import b6 from "../../assets/img/icon/customalert.png";



const UnifiedPricing = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  const end_point = "unified-pricing-engine";

  const qa_list = [
    "Does your business have quality brand reputation by ensuring ironclad pricing consistency across all your sales channels?",
    "Do you have the convenience of updating prices seamlessly across all your sales channels with just one click?",
    "Can your pricing solution swiftly adapt to market shifts and competitor pricing strategies across diverse sales channels?",
    "Are you alerted to potential brand damage caused by inconsistent pricing across your sales channels?"
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
      }
    }
  }
  const navigation_section_props = {
    id: "unified_pricing",
    heading: "Why Unified Retail Pricing Engine Is a Game Changer?",
    tabs: [
        {
          title: "Maintains Consistency Across Channels",
          desc: "With our unified pricing engine, we ensure consistent pricing across various sales channels. You name the channel and it's unified with reduced time and effort in just a click of a button. ",
          img: img1_ ,
          alt: "Maintains Consistency Across Channels" 
        },
        {
          title: "Grab on Cross-Selling and Up-Selling Opportunities",
          desc: "Consistent pricing information allows brands to implement targeted promotions and recommendation strategies seamlessly across, thereby increasing your cart value by 15% and boosting customer satisfaction.",
          img: img2_,
          alt: "Grab on Cross-Selling and Up-Selling Opportunities"
        },
        {
          title: "Efficiently Manage Promotion/Discount",
          desc: "Centrally manage and execute promotions, discounts, and offers across all channels, minimizing human errors and ensuring a 30% improvement in operational efficiency.",
          img: img3_,
          alt: "Efficiently Manage Promotion/Discount"
        },
        {
          title: "Enhance Your Inventory Management",
          desc: "Understocking and overstocking is no more a problem with our pricing engine as its integration with inventory management systems tracks your inventory on a real-time basis across all channels, thereby giving an 80% reduction in Non Performing Asset (NPA) inventory.",
          img: img4_,
          alt: "Enhance Your Inventory Management"
        }
    ]
  }
  const benefit_props = {
    id: "unified_pricing",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Centralized Price Control and Real-Time Updates",
        desc: "A centralized control hub built with real-time price synchronization capabilities ensures pricing remains competitive and up-to-date across multiple platforms, thereby appealing to customers.",
        img: b1 ,
        alt: "Centralized Price Control and Real-Time Updates" 
      },
      {
        title: "Customizable Rules and Automated Adjustments",
        desc: "Maximize profits and streamline inventory management with our pricing software's customizable rules, powered by AI data intelligence and real-time price adjustments from our pricing engine.",
        img: b2 ,
        alt: "Customizable Rules and Automated Adjustments" 
      },
      {
        title: "Marketplace Integration",
        desc: "We offer marketplace integration with leading platforms such as Amazon, eBay, Shopify, and others. Our plug-and-play integration systems simplify the setup process to generate more revenue. ",
        img: b3 ,
        alt: "Marketplace Integration" 
      },
      {
        title: "Multi-Currency Support",
        desc: "Our global tech stack supports multi-currency pricing, simplifying cross-border transactions. You can convert and display prices in different currencies to reach a broader customer base.",
        img: b4 ,
        alt: "Multi-Currency Support" 
      },
      {
        title: "Centralized Data Analysis",
        desc: "Our system centralizes pricing data from multiple channels and allows you to gather crucial insights into customer behaviour, purchasing patterns, and market trends.",
        img: b5 ,
        alt: "Centralized Data Analysis" 
      },
      {
        title: "Custom Alerts",
        desc: "Never miss a revenue opportunity with customized alerts and notifications, enabling you to stay proactive and responsive to market dynamics.",
        img: b6 ,
        alt: "Custom Alerts" 
      },

    ]
  }
  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Unified Pricing Software For Brands and Sellers | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.unifiedpricingpage}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Unified Pricing Engine streamlines pricing decisions across platforms, ensuring consistent pricing and maximize profit for all stakeholders. Connect us now" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Unified Pricing Software For Brands and Sellers | Sciative" />
          <meta property="og:description" content="Unified Pricing Engine streamlines pricing decisions across platforms, ensuring consistent pricing and maximize profit for all stakeholders. Connect us now" />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content='https://sciative.com' />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          
          <meta property="og:image"  content={GlobalImg} />
         
          <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org/", 
                  "@type": "BreadcrumbList", 
                  "itemListElement": [{
                    "@type": "ListItem", 
                    "position": 1, 
                    "name": "Home",
                    "item": "https://sciative.com"  
                  },{
                    "@type": "ListItem", 
                    "position": 2, 
                    "name": "Retail",
                    "item": "https://sciative.com/retail"  
                  },{
                    "@type": "ListItem", 
                    "position": 3, 
                    "name": "Unified Pricing Engine",
                    "item": "https://sciative.com/retail/unified-retail-pricing-software"  
                  }]
                }
                
              `}
            </script>
        </Helmet>
        <div className='retail_pages'>
        <InnerHeroRetail button_name={'Explore Unified Pricing Tool'} end_point={end_point} page_name="automatedpricing" page_heading="Unified Retail Pricing Software" page_desc1="Manage prices across all selling channels to maximize profits with just one click." page_desc2="" />
        <RetailClient />

        <NavigationSection {...navigation_section_props} />
        
        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
        <BenefitSection {...benefit_props} />
       
        <MarketPlaceIntegration  end_point={end_point}/>
        <RetailTestimonial dataHeading="We Deliver, Our Customers ‘ Speak" />
        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />

        <QAScore end_point={end_point} qa_list={qa_list} />

        <section className="qa_store">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Pricing Engine" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgcomp} pageheading="Competition Intelligence" pagelink={AllRoutes.competitionintpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container"  data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
</div>
      </HelmetProvider>
    </>
  )
}
export default UnifiedPricing;