
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import aretza from "../../assets/img/retail-client/arteza.png";
import avis from "../../assets/img/retail-client/avis.png";
import clubmahindra from "../../assets/img/retail-client/club-mahindra.png";
import coupang from "../../assets/img/retail-client/coupang.png";
import croma from "../../assets/img/retail-client/croma.png";
import kisankonnect from "../../assets/img/retail-client/kisan-konnect.png";
import licious from "../../assets/img/retail-client/licious.png";
import logitech from "../../assets/img/retail-client/logitech.png";
import nykaaf from "../../assets/img/retail-client/nykaa-fashions.png";
import nykaa from "../../assets/img/retail-client/nykaa.png";
import shoppers from "../../assets/img/retail-client/shoppers-stop.png";
import star from "../../assets/img/retail-client/star.png";
import vijaysales from "../../assets/img/retail-client/vijay-sales.png";
import wonderchef from "../../assets/img/retail-client/wonderchef.png";

import essar from "../../assets/img/travel-client/essar.png";
import gotour from "../../assets/img/travel-client/gotour.png";
import jabbartravels from "../../assets/img/travel-client/jabbar-travels.png";
import kanker from "../../assets/img/travel-client/kanker.png";
import laxmiholidays from "../../assets/img/travel-client/laxmi-holidays.png";
import limoliner from "../../assets/img/travel-client/limoliner.png";
import neetabus from "../../assets/img/travel-client/neetabus.png";
import nuego from "../../assets/img/travel-client/nuego.png";
import rajeshtransport from "../../assets/img/travel-client/rajesh-transport.png";
import rajkalpana from "../../assets/img/travel-client/rajkalpana.png";
import shyamoli from "../../assets/img/travel-client/shyamoli.png";
import spsluxury from "../../assets/img/travel-client/sps-luxury.png";
import svkdt from "../../assets/img/travel-client/svkdt.png";
import tsrtc from "../../assets/img/travel-client/tsrtc.png";
import vigneshtravels from "../../assets/img/travel-client/vignesh-tat.png";
import yolobus from "../../assets/img/travel-client/yolobus.png";
import CapteraAchievements from '../travel/capterra-achievvement';
const AllClientSlider = ({props}) => {
    // Define a function to require all images in a directory
    const retail_client= [
      {
          "image": avis,
          "alttext": "Avis rent a Car"
      },
      {
          "image": aretza,
          "alttext": "Arteza"
      },
      {
          "image": coupang,
          "alttext": "Coupang"
      },
      {
          "image": logitech,
          "alttext": "Logitech"
      },
      {
          "image": clubmahindra,
          "alttext": "Club Mahindra"
      },
      {
          "image": star,
          "alttext": "Star"
      },
      {
          "image": nykaaf,
          "alttext": "Nykaa Fashions"
      },
      {
          "image": shoppers,
          "alttext": "Shoppers Stop"
      },
      {
          "image": licious,
          "alttext": "Licious"
      },
      {
          "image": croma,
          "alttext": "Croma"
      },
      {
          "image": vijaysales,
          "alttext": "Vijay Sales"
      },
      {
          "image": nykaa,
          "alttext": "Nykaa"
      },
      {
          "image": kisankonnect,
          "alttext": "Kisan Konnect"
      },
      {
          "image": wonderchef,
          "alttext": "WonderChef"
      },
    
  
    ];
    const travel_client= [
      {
          "image": nuego,
          "alttext": "Nuego"
      },
      {
          "image": yolobus,
          "alttext": "YoloBus"
      },
      {
          "image": tsrtc,
          "alttext": "TSRTC"
      },
      {
          "image": laxmiholidays,
          "alttext": "Laxmi Holidays"
      },
      {
          "image": jabbartravels,
          "alttext": "Jabbar Travels"
      },
      {
          "image": neetabus,
          "alttext": "Neeta Bus"
      },
      {
          "image": essar,
          "alttext": "Essar"
      },
      {
          "image": shyamoli,
          "alttext": "Shyamoli"
      },
      {
          "image": spsluxury,
          "alttext": "SPS Luxury Travels"
      },
      {
          "image": kanker,
          "alttext": "Kanker Roadways"
      },
      {
          "image": rajeshtransport,
          "alttext": "Rajesh Transport"
      },
      {
          "image": rajkalpana,
          "alttext": "Raj Kalpana"
      },
      {
          "image": gotour,
          "alttext": "GoTour"
      },
      {
          "image": svkdt,
          "alttext": "SVKDT"
      },
      {
          "image": vigneshtravels,
          "alttext": "Vignesh TAT"
      },
      {
          "image": limoliner,
          "alttext": "Limoliner"
      },
    
  
    ];
    const all_client = [...retail_client , ...travel_client]
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  // Import all images from the specified directory
  const images = importAll(require.context('../../assets/img/all-client/', false, /\.(png|jpe?g|svg)$/));
  
  // Create an array of image paths
  const imagePaths = Object.values(images);
  
//   // Usage example:
//   console.log(imagePaths);
const allclientsettings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
           
          }
        },
        {
            breakpoint: 1023,
            settings: {
              slidesToShow: 5,
             
            }
          },
    ]
  };
      return (
            <div className={"retail_slider all_client "+props}>
                <div className="inner_ret_slider" >
                    <h2 className="bigHead text-center mb-3">Our Clients from Across the Globe</h2>
                    <Slider {...allclientsettings}>
                        {all_client.map((item, index) => (
                            <div key={index.alttext} className="slider_card">
                            
                            <img src={item.image} alt={item.alttext}  />
                            </div>
                        ))}
                        </Slider>
                </div>
            </div>
        );
    }
    
    
    export default AllClientSlider;