import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";
const HotelBgcta = ({props}) => {
    const [url, setUrl] = useState('');


return(
    <>  <section className={"hotel_bg_cta "+props}>
    <div className="container">
        <div className="card ap_card">
            <p className="card_text">Explore the difference with Zettaprice</p>
            <a href={AllRoutes.hotelcontact}>
                <button className="yellow-button">Book a Demo </button>
            </a>
        </div>
    </div>
</section>

    </>
)

}

export default HotelBgcta;