import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import aretza from "../../assets/img/retail-client/arteza.png";
import avis from "../../assets/img/retail-client/avis.png";
import clubmahindra from "../../assets/img/retail-client/club-mahindra.png";
import coupang from "../../assets/img/retail-client/coupang.png";
import croma from "../../assets/img/retail-client/croma.png";
import kisankonnect from "../../assets/img/retail-client/kisan-konnect.png";
import licious from "../../assets/img/retail-client/licious.png";
import logitech from "../../assets/img/retail-client/logitech.png";
import nykaaf from "../../assets/img/retail-client/nykaa-fashions.png";
import nykaa from "../../assets/img/retail-client/nykaa.png";
import shoppers from "../../assets/img/retail-client/shoppers-stop.png";
import star from "../../assets/img/retail-client/star.png";
import vijaysales from "../../assets/img/retail-client/vijay-sales.png";
import wonderchef from "../../assets/img/retail-client/wonderchef.png";

const RetailClient = ({page_name, page_heading}) => {
    const retail_client= [
        {
            "image": avis,
            "alttext": "Avis rent a Car"
        },
        {
            "image": aretza,
            "alttext": "Arteza"
        },
        {
            "image": coupang,
            "alttext": "Coupang"
        },
        {
            "image": logitech,
            "alttext": "Logitech"
        },
        {
            "image": clubmahindra,
            "alttext": "Club Mahindra"
        },
        {
            "image": star,
            "alttext": "Star"
        },
        {
            "image": nykaaf,
            "alttext": "Nykaa Fashions"
        },
        {
            "image": shoppers,
            "alttext": "Shoppers Stop"
        },
        {
            "image": licious,
            "alttext": "Licious"
        },
        {
            "image": croma,
            "alttext": "Croma"
        },
        {
            "image": vijaysales,
            "alttext": "Vijay Sales"
        },
        {
            "image": nykaa,
            "alttext": "Nykaa"
        },
        {
            "image": kisankonnect,
            "alttext": "Kisan Konnect"
        },
        {
            "image": wonderchef,
            "alttext": "WonderChef"
        },
      
    
      ]; 
      const allclientsettings = {
        dots: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1970,
        cssEase: "linear",
        pauseOnHover: false,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
               
              }
            },
            {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 6,
                 
                }
              },
        ]
      };
    return( <section className={"all_client_home client_why_us "+page_name}>
    <div className="container-fluid">
        <h2 className="bigHead text-center">
            {page_heading}
       
        </h2>
    <div className="row home_img_slider">
    <Slider {...allclientsettings}>
          {retail_client.map(obj => (
              <div key={obj.alttext} className="col-4 col-sm-2 ">
               <img src={obj.image} alt={obj.alttext} />
              </div>
            ))}
</Slider>
          </div>
    </div>
</section>)

}
RetailClient.defaultProps = {
    page_heading: " Trusted by Companies Around the Globe",
  };
export default RetailClient