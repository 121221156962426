import img10 from "../../assets/img/retail/automated-pricing/10.jpg";
import img11 from "../../assets/img/retail/automated-pricing/11.jpg";
import img12 from "../../assets/img/retail/automated-pricing/12.jpg";

import img1 from "../../assets/img/retail/digital-shlef/image_based_tagging.png";
import img2 from "../../assets/img/retail/digital-shlef/product_listing_quality.png";
import img3 from "../../assets/img/retail/digital-shlef/share_of_search.png";

import { Tab, Col, Nav, Row } from "react-bootstrap";
const DigitalShelfTab = () => {
   
    return(
        <Tab.Container defaultActiveKey="mfirst">
        <Row>
        <Col xl={5}>
            <Nav variant="pills" className="flex-column">
            <Nav.Item>
                <Nav.Link eventKey="mthird">Share of Search and Sales
                <p>Boost your online visibility by navigating the digital landscape with precision, dominate search results, and elevate your brand's presence for unparalleled online success by significantly increasing your market share in the digital domain.</p>
                <div className="investor_edge_div res_img">
                    <img src={img3} alt="Share of Search and Sales Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="mfirst">Image-Based Feature Tagging
                 <p>In mere seconds, our AI-powered auto-tagging system swiftly identifies intricate product features such as necklines and embroideries, which are abundant in the Indian fashion market. This capability significantly enhances precision, elevating our overall performance.</p>
                 <div className="investor_edge_div res_img">
                    <img src={img1} alt="Image-Based Feature Tagging Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="msecond">Content Quality Enhancement
                <p>Effortlessly enhance your content with our audit tool. We comprehensively analyse every aspect, from titles and descriptions to images, including subtle elements like keywords, each of which profoundly impact your overall catalogue and content performance.</p>
                <div className="investor_edge_div res_img">
                    <img src={img2} alt="Content Quality Enhancement Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
          
            </Nav>
        </Col>
        <Col xl={7} className="second_div">
            <Tab.Content>
            <Tab.Pane eventKey="mfirst">
                <div className="investor_edge_div">
                <img src={img1} alt="Image-Based Feature Tagging Image" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="msecond">
            <div className="investor_edge_div">
                <img src={img2} alt="Content Quality Enhancement Image" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="mthird">
            <div className="investor_edge_div">
                <img src={img3} alt="Share of Search and Sales Image" />
                </div>
            </Tab.Pane>
            </Tab.Content>
        </Col>
        </Row>
</Tab.Container>
    )

}

export default DigitalShelfTab