import Form from 'react-bootstrap/Form';
import "../../../assets/css/road_ahead_talks/create_webinar.css";
import { Button } from '@mui/material';
import DraftsIcon from '@mui/icons-material/Drafts';
import PublishIcon from '@mui/icons-material/Publish';
import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import isUrl from 'is-url';
import { useNavigate } from "react-router-dom";
import { adminURL } from '../../../global';

function CreateRecordedVideo() {

    const navigate = useNavigate();

    const [webinarData, setWebinarData] = useState({
        title: "",
        pageURL: "",
        publish_date: "",
        publish_time: "",
        reg_link: "",
        category: "",
        desc: "",
        featured_experts: [],
        talks_recommendations: ["", "", ""],
        youtube_link: "",
        spotify_link: "",
        page_title: "",
        tags: [],
        meta_keywords: [],
        meta_desc: ""
    });

    const [images, setImages] = useState({
        pre_img: null,
        post_img: null
    });

    const [featuredExpert, setFeaturedExpert] = useState({
        id: "",
        type: ""
    })

    const [featuredExperts, setFeaturedExperts] = useState([]);
    const [talksRecommendations, setTalksRecommendations] = useState([]);
    const get_featured_experts = async () => {
        try {
            const response = await axios.get(`/ra_talks/get_featured_experts`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                setFeaturedExperts(response.data)
            }
        } catch (err) {
            console.error("Error : ", err);
        }
    }
    const get_talks_recommendations = async () => {
        try {
            const response = await axios.get(`/ra_talks/get_talks_recommendations`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                setTalksRecommendations(response.data)
            }
        } catch (err) {
            console.error("Error : ", err);
        }
    }

    const [selectedTalks, setSelectedTalks] = useState({
        0: "",
        1: "",
        2: "",
    });

    useEffect(() => {
        get_featured_experts();
        get_talks_recommendations();
    }, [])


    const handleOnChange = (e) => {
        const { name, value } = e.target;

        if (name === "title") {
            setWebinarData({ ...webinarData, title: value, pageURL: value.replace(/ /g, '-'), page_title: value })
        }
        else {
            setWebinarData({ ...webinarData, [name]: value });
        }
    }

    const handleImageChange = (e) => {
        const name = e.target.name;
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImages({ ...images, [name]: reader.result });
            };
            reader.readAsDataURL(file);
        }
    }

    const handleAddFeaturedExpert = () => {
        if (featuredExpert.id === "") {
            return alert("Select an expert");
        }
        else if (featuredExpert.type === "") {
            return alert("Select type of expert");
        }

        // validation for already added expert
        const isAlreadyAdded = webinarData.featured_experts.some(e => e.id === featuredExpert.id);
        if (isAlreadyAdded) {
            return alert("Selected expert is already added");
        }

        // push new featured expert item into array
        const newWebinarData = { ...webinarData };
        const new_featured_experts = [...newWebinarData.featured_experts];
        new_featured_experts.push(featuredExpert);
        newWebinarData.featured_experts = new_featured_experts
        setWebinarData(newWebinarData);

        // make select empty
        setFeaturedExpert({
            id: "",
            type: ""
        })
    }

    const handleRemoveExpert = (id) => {
        const newWebinarData = { ...webinarData };
        const new_featured_experts = newWebinarData.featured_experts.filter(e => e.id !== id);
        newWebinarData.featured_experts = new_featured_experts;
        setWebinarData(newWebinarData);
    }

    const handleCategorySelect = (fieldNumber, value) => {
        setSelectedTalks(prevState => ({
            ...prevState,
            [fieldNumber]: value,
        }));
    };

    const handleOnTalksRecommendationChange = (index, value) => {
        console.log(value);
        const newWebinarData = { ...webinarData };
        const new_talks_recommendations = [...newWebinarData.talks_recommendations];
        new_talks_recommendations[index] = value
        newWebinarData.talks_recommendations = new_talks_recommendations
        console.log(newWebinarData.talks_recommendations);
        setWebinarData(newWebinarData);
    }

    const handlePublish = async () => {

        // client side validations
        const title = webinarData.title.trim();
        if (title === "") {
            return alert("Webinar title is required");
        }
        if (title.length < 4) {
            return alert("Minimum 4 characters are required in webinar title");
        }

        const pageURL = webinarData.pageURL.trim();
        if (pageURL === "") {
            return alert("Page URL is required");
        }
        console.log(pageURL);
        if ((/\s/).test(pageURL)) {
            return alert("Page URL should not contain any spaces");
        }

        const publish_date = webinarData.publish_date;
        if (publish_date === "") {
            return alert("Webinar publish date is required");
        }

        const publish_time = webinarData.publish_time;
        if (publish_time === "") {
            return alert("Webinar publish time is required");
        }

        const category = webinarData.category;
        if (category === "") {
            return alert("Webinar category is required");
        }

        const featured_experts = webinarData.featured_experts;
        if (featured_experts.length === 0) {
            return alert("At least one featured expert is required");
        }

        const talks_recommendations = webinarData.talks_recommendations;
        const nonEmptyArray = talks_recommendations.filter(item => item.trim() !== '');
        const hasDuplicates = new Set(nonEmptyArray).size !== nonEmptyArray.length;
        if (hasDuplicates) {
            return alert("Duplicate entry for recommended road ahead talks");
        }

        const youtube_link = webinarData.youtube_link.trim();
        if (youtube_link !== "" && !isUrl(youtube_link)) {
            return alert("Invalid YouTube video link")
        }

        const spotify_link = webinarData.spotify_link.trim();
        if (spotify_link !== "" && !isUrl(spotify_link)) {
            return alert("Invalid Spotify link")
        }

        const page_title = webinarData.page_title.trim();
        if (page_title === "") {
            return alert("Webinar page title is required");
        }

        if (webinarData.tags.trim() === "") {
            return alert("Webinar tags are required");
        }
        const tags = webinarData.tags.split(',').map(tag => tag.trim());

        if (webinarData.meta_keywords.trim() === "") {
            return alert("Webinar meta keywords are required");
        }
        const meta_keywords = webinarData.meta_keywords.split(',').map(keyword => keyword.trim());

        const meta_desc = webinarData.meta_desc.trim();
        if (meta_desc === "") {
            return alert("Webinar meta desription is requried");
        }

        // if past talk => post webinar display fields are required
        const dateTimeString = `${publish_date}T${publish_time}:00`;
        const WebinarDateTime = new Date(dateTimeString);
        const currentDate = new Date();
        if (WebinarDateTime < currentDate && (youtube_link == "" || spotify_link == "")) {
            return alert("Post webinar display fields are required for past webinar");
        }

        // prepare data
        const webinarPostData = {
            type: "recorded-video",
            state: "publish",
            isActive: true,
            title: title,
            pageURL: pageURL,
            publish_date: publish_date,
            publish_time: publish_time,
            category: category,
            desc: "hard coded",
            featured_experts: featured_experts,
            talks_recommendations: talks_recommendations,
            video_links: {
                youtube_link: youtube_link,
                spotify_link: spotify_link
            },
            seo: {
                page_title: page_title,
                tags: tags,
                meta_keywords: meta_keywords,
                meta_desc: meta_desc
            }
        }
        console.log(webinarPostData);
        try {
            const response = await axios.post(`/ra_talks/create_recorded_video`, webinarPostData, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                alert('Webinar Created Successfully');
                navigate(`/niyantran-panel/${adminURL}/road-ahead-talks-management`);
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    return (
        <>
            <div className="container white-bg text-center shadow p-2 rounded mt-2">
                <h1 className='fw-bolder'>Create Recorded Video</h1>
            </div>
            <div className="row mt-4">
                <div className="col-8 d-flex flex-column gap-3">
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label className="med-font ml-1">Title</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                type="text"
                                name="title"
                                value={webinarData.title}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="page_URL">
                            <Form.Label className="med-font ml-1">Page URL</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                type="text"
                                name="pageURL"
                                value={webinarData.pageURL}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="date">
                            <Form.Label className="med-font ml-1">Publish Date</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                type="date"
                                name="publish_date"
                                value={webinarData.date}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="time">
                            <Form.Label className="med-font ml-1">Publish Time</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                    type="time"
                                    name="publish_time"
                                    value={webinarData.publish_time}
                                    onChange={handleOnChange}
                                />
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="category">
                            <Form.Label className="med-font ml-1">Category</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Select
                                aria-label="select_category"
                                name="category"
                                value={webinarData.category}
                                onChange={handleOnChange}
                            >
                                <option value="">Select Category</option>
                                <option value="air-travel">Air - Travel</option>
                                <option value="bus-travel">Bus - Travel</option>
                                <option value="retail">Retail</option>
                                <option value="customer-success">Customer Success</option>
                                <option value="pricing">Pricing</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="page_URL">
                            <Form.Label className="med-font ml-1">Description</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            {/* <RichTextEditor
                                editorState={editorState} onChange={setEditorState}
                            /> */}
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="page_URL">
                            <Form.Label className="med-font ml-1">Add Featured Experts</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <div className="row">
                                <div className="col">
                                    <Form.Select
                                        aria-label="select_featured_expert"
                                        name="featured_expert_id"
                                        value={featuredExpert.id}
                                        onChange={(e) => setFeaturedExpert({ ...featuredExpert, id: e.target.value })}
                                    >
                                        <option value="">Select Expert</option>
                                        {
                                            featuredExperts.map(featuredExpert => (
                                                <option key={featuredExpert._id} value={featuredExpert._id}>
                                                    {featuredExpert.name}
                                                </option>
                                            ))
                                        }
                                    </Form.Select>
                                </div>
                                <div className="col">
                                    <Form.Select
                                        aria-label="select_featured_expert"
                                        name="featured_expert_type"
                                        value={featuredExpert.type}
                                        onChange={(e) => setFeaturedExpert({ ...featuredExpert, type: e.target.value })}
                                    >
                                        <option value="">Select Type</option>
                                        <option value="speaker">Speaker</option>
                                        <option value="panelist">Panelist</option>
                                    </Form.Select>
                                </div>
                                <div className="col-2">
                                    <Button
                                        variant="contained"
                                        onClick={handleAddFeaturedExpert}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>
                            {
                                webinarData.featured_experts.length > 0
                                &&
                                <Table bordered hover className='mt-2 text-center'>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Expert</th>
                                            <th>Type</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            webinarData.featured_experts.map((featured_expert, row) => (
                                                <tr key={featured_expert.id}>
                                                    <td>{row + 1}</td>
                                                    <td>{featuredExperts.find(f => f._id === featured_expert.id).name}</td>
                                                    <td>{featured_expert.type}</td>
                                                    <td><DeleteIcon onClick={() => handleRemoveExpert(featured_expert.id)} sx={{ color: '#ff3333', cursor: 'pointer' }} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            }
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3">
                            <Form.Label className="med-font ml-1">Display Recommended Road Ahead Talks</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <div className="d-flex flex-column gap-2">
                                <div className="row">
                                    <div className="col-1 d-flex justify-content-center align-items-center">
                                        <span>#1</span>
                                    </div>
                                    <div className="col">
                                        <Form.Select
                                            aria-label="select_category"
                                            name="recommended_talks_category_1"
                                            value={selectedTalks[0]}
                                            onChange={(e) => handleCategorySelect(0, e.target.value)}
                                        >
                                            <option value="">Select Category</option>
                                            <option value="travel-bus">Travel - Bus</option>
                                            <option value="travel-air">Travel - Air</option>
                                            <option value="retail">Retail</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col">
                                        <Form.Select
                                            aria-label="select_title"
                                            name="recommended_talks_title_1"
                                            value={webinarData.talks_recommendations[0]}
                                            onChange={(e) => handleOnTalksRecommendationChange(0, e.target.value)}
                                        >
                                            <option>Select Talk</option>
                                            {talksRecommendations
                                                .filter(talk => talk.category === selectedTalks[0])
                                                .map(filteredTalk => (
                                                    <option key={filteredTalk._id} value={filteredTalk._id}>
                                                        {filteredTalk.title}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1 d-flex justify-content-center align-items-center">
                                        <span>#2</span>
                                    </div>
                                    <div className="col">
                                        <Form.Select
                                            aria-label="select_category"
                                            name="recommended_talks_category_2"
                                            value={selectedTalks[1]}
                                            onChange={(e) => handleCategorySelect(1, e.target.value)}
                                        >
                                            <option value="">Select Category</option>
                                            <option value="travel-bus">Travel - Bus</option>
                                            <option value="travel-air">Travel - Air</option>
                                            <option value="retail">Retail</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col">
                                        <Form.Select
                                            aria-label="select_title"
                                            name="recommended_talks_title_2"
                                            value={webinarData.talks_recommendations[1]}
                                            onChange={(e) => handleOnTalksRecommendationChange(1, e.target.value)}
                                        >
                                            <option>Select Talk</option>
                                            {talksRecommendations
                                                .filter(talk => talk.category === selectedTalks[1])
                                                .map(filteredTalk => (
                                                    <option key={filteredTalk._id} value={filteredTalk._id}>
                                                        {filteredTalk.title}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1 d-flex justify-content-center align-items-center">
                                        <span>#3</span>
                                    </div>
                                    <div className="col">
                                        <Form.Select
                                            aria-label="select_category"
                                            name="recommended_talks_category_3"
                                            value={selectedTalks[2]}
                                            onChange={(e) => handleCategorySelect(2, e.target.value)}
                                        >
                                            <option value="">Select Category</option>
                                            <option value="travel-bus">Travel - Bus</option>
                                            <option value="travel-air">Travel - Air</option>
                                            <option value="retail">Retail</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col">
                                        <Form.Select
                                            aria-label="select_title"
                                            name="recommended_talks_title_3"
                                            value={webinarData.talks_recommendations[2]}
                                            onChange={(e) => handleOnTalksRecommendationChange(2, e.target.value)}
                                        >
                                            <option>Select Talk</option>
                                            {talksRecommendations
                                                .filter(talk => talk.category === selectedTalks[2])
                                                .map(filteredTalk => (
                                                    <option key={filteredTalk._id} value={filteredTalk._id}>
                                                        {filteredTalk.title}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="pre_img">
                            <Form.Label className="med-font ml-1">Image for Pre-Webinar Display</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                type="file"
                                name="pre_img"
                                accept="image/png, image/jpeg"
                                onChange={handleImageChange}
                            />
                        </Form.Group>
                        {
                            images.pre_img &&
                            <div>
                                <img src={images.pre_img} alt="" width={500} />
                            </div>
                        }
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="post_img">
                            <div className='d-flex justify-content-between mb-1'>
                                <div>
                                    <Form.Label className="med-font ml-1 mb-0">Image for Post-Webinar Display</Form.Label>
                                    <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                </div>
                                <span className='tip py-1 px-2'>Post-Webinar Display</span>
                            </div>
                            <Form.Control
                                type="file"
                                name="post_img"
                                accept="image/png, image/jpeg"
                                onChange={handleImageChange}
                            />
                        </Form.Group>
                        {
                            images.post_img &&
                            <div>
                                <img src={images.post_img} alt="" width={500} />
                            </div>
                        }
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="youtube_link">
                            <div className='d-flex justify-content-between mb-1'>
                                <div>
                                    <Form.Label className="med-font ml-1 mb-0">YouTube Link</Form.Label>
                                    <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                </div>
                                <span className='tip py-1 px-2'>Post-Webinar Display</span>
                            </div>
                            <Form.Control
                                type="text"
                                name="youtube_link"
                                value={webinarData.youtube_link}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="spotify_link">
                            <div className='d-flex justify-content-between mb-1'>
                                <div>
                                    <Form.Label className="med-font ml-1 mb-0">Spotify Link</Form.Label>
                                    <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                </div>
                                <span className='tip py-1 px-2'>Post-Webinar Display</span>
                            </div>
                            <Form.Control
                                type="text"
                                name="spotify_link"
                                value={webinarData.spotify_link}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="col-4 d-flex flex-column gap-3">
                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="page_title">
                            <Form.Label className="med-font ml-1">Page Title</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                type="text"
                                name="page_title"
                                value={webinarData.page_title}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="tags">
                            <Form.Label className="med-font ml-1">Tags</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                type="text"
                                name="tags"
                                value={webinarData.tags}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="meta_keywords">
                            <Form.Label className="med-font ml-1">Meta Keywords</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                type="text"
                                name="meta_keywords"
                                value={webinarData.meta_keywords}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                        <Form.Group className="mb-3" controlId="meta_desc">
                            <Form.Label className="med-font ml-1">Meta Description</Form.Label>
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="meta_desc"
                                value={webinarData.meta_desc}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="grey-blue-bg rounded d-flex justify-content-center gap-4 p-4 shadow-sm my-3">
                <Button variant="contained" startIcon={<DraftsIcon />}>
                    Save Draft
                </Button>
                <Button
                    variant="contained"
                    startIcon={<PublishIcon />}
                    onClick={handlePublish}
                >
                    Publish
                </Button>
            </div>
        </>
    );
}

export default CreateRecordedVideo;