import { useEffect, useState, useRef } from 'react';

export function useHeadsObserver() {
    const observer = useRef()
    const [activeId, setActiveId] = useState('');
    const [elements, setElements] = useState([])

    useEffect(() => {
        setElements(Array.from(document.querySelector('#additional-content').querySelectorAll("h3, h4, h5"))
        .filter((elem) => elem.innerText.trim() !== ''))
    }, [])

    useEffect(() => {
        if(elements.length){
            const handleObsever = (entries) => {
                entries.forEach((entry) => {
                    if (entry?.isIntersecting) {
                        setActiveId(entry.target.id)
                    }
                }) 
            }
    
            observer.current = new IntersectionObserver(handleObsever, {
                rootMargin: "-20% 0% -35% 0px"
            }
            )
    
            elements.forEach((elem) => observer.current.observe(elem))
            return () => observer.current?.disconnect()
        }
    }, [elements])

    return { activeId }
}