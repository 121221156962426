import {useEffect} from 'react';
import { Button } from 'react-bootstrap';
import lost from '../assets/img/lost.svg';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {

    const navigate = useNavigate();
    useEffect(() => {
        if(window.location.href.includes('/blog/')){
            const blogURL = (window.location.href.split('/blog/'))[1]
            window.location.href = `/research-articles/${blogURL}`;
        }else{
            window.location.href = "/";
        }
    }, []);
    
    return(
        <div className='container'>
            {/* <section className=' row err_page'>
                <div className='col-md-6 order-md-2'>
                    <img className='h-80' src={lost}/>
                </div>
                <div className='col-md-6'>
                    <div className=''>
                        <h1 className='bold w-80' style={{fontSize: '4em'}}>Oops!</h1>
                        <p className='mt-3 text-muted' style={{fontSize: '1.2em', lineHeight: '1.5em'}}>The page you requested for was not found. This might be because this 
                        link is outdated, under maintanance or does not exist.</p>
                        <Button onClick={() => navigate('/')} className="yellow-button">Go to Home</Button>
                    </div>
                </div>
               
            </section> */}
        </div>
    )
};

export default NotFound;