import { useEffect, useState } from "react";
import styles from "../../assets/css/road_ahead_talks/webinar.module.css";
import moment from 'moment';

function Countdown({ date, time }) {

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const talkDateTime = new Date(`${date}T${time}:00`);
            const currentDateTime = new Date();
            const timeDifference = talkDateTime - currentDateTime;

            if (timeDifference > 0) {
                const remainingSeconds = Math.floor(timeDifference / 1000);
                const remainingDays = Math.floor(remainingSeconds / (3600 * 24));
                const remainingHours = Math.floor((remainingSeconds % (3600 * 24)) / 3600);
                const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
                const remainingSecondsValue = remainingSeconds % 60;

                setDays(remainingDays);
                setHours(remainingHours);
                setMinutes(remainingMinutes);
                setSeconds(remainingSecondsValue);
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [date, time]);


    return (
        <div className={styles.timerSection}>
            <div className={styles.countdown}>
                <span className={styles.dateText}>
                    {moment(date).format("Do MMMM YYYY")}, {moment(time, "HH:mm").format("h:mm A")}
                </span>
                <ul>
                    <li><span id="days">{days}</span>Days</li>
                    <li><span id="hours">{hours}</span>Hours</li>
                    <li><span id="minutes">{minutes}</span>Minutes</li>
                    <li><span id="seconds">{seconds}</span>Seconds</li>
                </ul>
            </div>
        </div>
    );
}

export default Countdown;