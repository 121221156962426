import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/health-wellness-beauty/competition_intelligence.png";
import img2 from "../../assets/img/retail/health-wellness-beauty/automated_pricing.png";
import img3 from "../../assets/img/retail/health-wellness-beauty/digital_shelf.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/retail/fashion-apparel/2.png";
import bgunified from "../../assets/img/retail/electronics/competition_intelligence.png";
import bgcomp from "../../assets/img/retail/food-consumbales/competition-intelligence.png";


import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";

import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import RetailInsideNumsix from "../retail/retailInsideNumSix";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import NavigationSection from "../retail/NavigationSection";
import { GlobalUrl, GlobalImg } from "../../global";


const HWB = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "health-wellness-beauty";

  const qa_list = [
    "Have you experienced challenges in adjusting prices quickly in response to competitor pricing changes?",
    "Do you find it difficult to track and monitor pricing data across multiple products and categories?",
    "Have you missed opportunities to optimize prices based on real-time market demand and trends?",
    "Is it challenging to ensure pricing consistency across different sales channels and locations?",
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_props = {
    id: "health_welness",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Assortment and Competition Intelligence",
        desc: "With 99% product matching accuracy and near real-time refresh rates, our technology monitors competitors' prices, to boost sales and revenue.",
        img: img1 ,
        alt: "Assortment and Competition Intelligence Image" 
      },
      {
        title: "Automated Pricing",
        desc: "Move away from manual pricing methods and explore the benefits of target pricing. This approach boosts your bottom-line revenue by 21%.",
        img: img2 ,
        alt: "Automated Pricing" 
      },
      {
        title: "Digital Shelf Analytics",
        desc: "BRIO empowers businesses to actively manage their presence in the competitive digital marketplace. It enhances brand visibility and accelerates sales growth.",
        img: img3 ,
        alt: "Digital Shelf Analytics" 
      },
     

    ]
  }
  const number_props = {
    id: "num_prop",
    heading: "We Let Numbers Do the Talking",
    tabs:[
      {
        num: "30%",
        desc: "Improvement in operational efficiency",
      },
      {
        num: "38%",
        desc: "Improvement in sales with scientific and timely decision",
      },
      {
        num: "21%",
        desc: "Improvement in profitability",
      },
      {
        num: "37%",
        desc: "Improvement in markdown realization",
      },
      {
        num: "94%",
        desc: "Accurate demand forecasting",
      },
      {
        num: "65%",
        desc: "Improvement in response to market changes",
      },
      
     

    ]
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
        <title>Automated Pricing Tool for Wellness Health & Beauty | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.hwb}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Our automated pricing tool empowers brands with retail pricing insights, optimizing sales and profitability in the beauty industry." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Automated Pricing Tool for Wellness Health & Beauty | Sciative" />
          <meta property="og:description" content="Our automated pricing tool empowers brands with retail pricing insights, optimizing sales and profitability in the beauty industry." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.hwb}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/health-wellness-beauty"  
                }]
              }
              
              `}
            </script>
        </Helmet>
        <InnerHeroRetail end_point={end_point} page_name="hwb" page_heading="Tailored Solutions for Health, Wellness and Beauty Industry" page_desc1="Intelligently Automated Pricing Across Zones, Safeguard Margins and Outmaneuver Competition for Maximized Revenue Growth." button_name="See How Automation Works" />
      
        <NavigationSection {...navigation_props} />
        <RetailClient />



        <section className="client_det inside_det">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead mb-5">We Deliver, Our Customer's Speak </h2>
            <div className="card">

              <p className="client_test">Using BRIO's competition price tracking feature has been a game-changer for our beauty and wellness brand. We've seen significant improvements across key areas, such as a 25% increase in sales due to strategic pricing adjustments based on competitor insights. Additionally, our product visibility has soared by 40% as we've been able to position ourselves competitively in the market. BRIO's solution has truly empowered us to make informed decisions, stay ahead of the competition, and drive remarkable growth in our industry.</p>
              <div className="flex_card">
                <div className="card_1">
                  {/* <img src={clientimg} alt="Rupesh Patil" /> */}
                  <div className="client_desc">
                    <p className="namec">Private Label Beauty Brand</p>
                    <p className="named">Australia</p>
                  </div>
                </div>

                {/* <img src={kkimg} alt="" className="kk_img" /> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <p className="bold">Our experts are ready to help you with your profit margins now!</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button class="yellow-button">Book your slot!</button>
              </Link>
            </div>
          </div>
        </section>
        <RetailInsideNumsix end_point={end_point} num_prop={number_props} />
        {/* <RetailBenefits /> */}
        <CapteraAchievements props={"brio_inside"} />

        <MarketPlaceIntegration  end_point={end_point} />
        <section className="qa_store">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Electronics" pagelink={AllRoutes.electronics} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgcomp} pageheading="Food & Consumables" pagelink={AllRoutes.food} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Fashion & Apparels" pagelink={AllRoutes.fashion} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>



    </>
  )
}
export default HWB;