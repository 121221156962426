import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server';
import { DataGrid } from '@mui/x-data-grid';
import Select from 'react-select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CaseStudiesPreview from './CaseStudiesPreview';
import ImageLayout1 from '../../../components/ImageLayout/ImageLayout1';
import iFrame from '../../Blogs/components/iFrame';
import { fetchToken } from '../../../Auth';
import { arrayMoveImmutable } from 'array-move';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
    Editor,
    EditorState,
    RichUtils,
    CompositeDecorator,
    convertToRaw,
    convertFromRaw,
} from "draft-js";
import MyEditor from '../../../components/TextEditor/Editor';
import { stateToHTML, stateFromHTML } from 'draft-js-export-html';

import placeholderImg from '../../../assets/img/placeholder_img.svg';

// Validators
import isValidURL from '../../../services/validators/urlValidator';

import templatesData from '../../../data/templates';
import categories from '../../../data/blogCategories';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { adminURL } from '../../../global';

import { SketchPicker } from 'react-color';
import { ReactComponent as PatternThree } from '../../../assets/img/PatternThree.svg';
import { ReactComponent as PatternTwo } from '../../../assets/img/PatternTwo.svg';
import { ReactComponent as PatternOne } from '../../../assets/img/PatternOne.svg';
import colorPickerCard from '../../../assets/img/black-color-picker.png';
import cardPatternPicker from '../../../assets/img/card-pattern.png';

import caseStudyLogo from '../../../assets/img/case-study.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { ReactComponent as CaseWhite } from '../../../assets/img/case-white.svg';
import { ReactComponent as CaseBlack } from '../../../assets/img/case-black.svg';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    //   padding: theme.spacing(1),
    padding: '5px',
    textAlign: 'center',
    fontSize: '0.9em',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const CreateCaseStudies = () => {

    const [templates, setTemplates] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [inputs, setInputs] = useState([]);
    const [templateType, setTemplateType] = useState('');
    const [inputValues, setInputValues] = useState({});
    const [inputFile, setInputFile] = useState(null);
    const [pdfTemplateImage, setPdfTemplateImage] = useState(null);
    const [additionalContent, setAdditionalContent] = useState([]);
    const contentRef = useRef([]);
    const [images, setImages] = useState(0);
    const [selectedImages, setSelectedImages] = useState([]); // To store image data
    const [imgValid, setImgValid] = useState(false);
    const [linkNames, setLinkNames] = useState({});
    const [publishedDate, setPublishedDate] = useState('');
    const [caseStudyTitle, setCaseStudyTitle] = useState('');
    const [caseStudyDesc, setCaseStudyDesc] = useState('');
    const [caseStudyURL, setCaseStudyURL] = useState('');
    const [keywords, setKeywords] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showCustomCategories, setShowCustomCategories] = useState(false);
    const [customCategories, setCustomCategories] = useState('');

    const [open, setOpen] = useState(false);
    const [imgModal1, setImgModal1] = useState(false);
    const [imgModal2, setImgModal2] = useState(false);
    const [imgModal3, setImgModal3] = useState(false);
    const [imgModalHorizontal, setImgModalHorizontal] = useState(false);

    const [masterKeywords, setMasterKeywords] = useState([]);
    const [frequency, setFrequency] = useState({});
    const [frequencyData, setFrequencyData] = useState([]);
    const [analyzeSEOModal, setAnalyzeSEOModal] = useState(false);

    const [contentID, setContentID] = useState(0);

    const [linkTutorial, setLinkTutorial] = useState(false);

    const navigate = useNavigate();

    const [cardColor, setCardColor] = useState({ 'hex': 'black' })

    const [uploadedIconImage, setUploadedIconImage] = useState(null);
    const [uploadedIcon, setUploadedIcon] = useState(null);
    const [cardTextColor, setCardTextColor] = useState()

    const [openCardPattern, setOpenCardPattern] = useState(false)

    const handlCardPatternClose = () => {
        setOpenCardPattern(false)
    }


    const hexToHsl = (hex) => {
        let r = parseInt(hex.slice(1, 3), 16) / 255;
        let g = parseInt(hex.slice(3, 5), 16) / 255;
        let b = parseInt(hex.slice(5, 7), 16) / 255;

        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
                default: break;
            }
            h /= 6;
        }

        return [h * 360, s * 100, l * 100];
    };

    // Utility function to convert HSL to HEX
    const hslToHex = (h, s, l) => {
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    };

    // Adjust title box color based on card color
    const adjustTitleBoxColor = (hex, saturationAdjustment, lightnessAdjustment) => {
        let [h, s, l] = hexToHsl(hex);

        // Adjust saturation and lightness based on the given percentage
        s = Math.min(100, s + saturationAdjustment);
        l = Math.min(100, l + lightnessAdjustment);

        return hslToHex(h, s, l);
    };

    const [cardTextBackgroundColor, setCardTextBackgroundColor] = useState(adjustTitleBoxColor('#000000', -10, 15))
    const handleCardColor = (color) => {
        const textColor = isDarkColor(color.hex) ? "#FFFFFF" : "#000000";
        setCardTextColor(textColor)
        setCardTextBackgroundColor(adjustTitleBoxColor(color.hex, -10, 15))
        setCardColor(color)
    }
    const [cardPickerVisible, setCardPickerVisible] = useState(false)



    useEffect(() => {
        const handleClickOutside = (event) => {
            // Get the color picker element
            const colorPickerElement = document.getElementById(`color-picker`);
            if (cardPickerVisible && colorPickerElement && !colorPickerElement.contains(event.target)) {
                setCardPickerVisible(false);
            }
        };

        // Attach the event listener for clicks outside
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [cardPickerVisible]);



    const getAuthors = async () => {
        try {
            const response = await axios.get(`/get_authors`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setAuthors(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getTemplates = async () => {
        try {
            const response = await axios.get(`/get_case_studies_templates`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setTemplates(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }


    const handleRemoveFile = (id) => {
        setInputFile(null);
        document.getElementById(id).value = "";
    }

    const handleIconRemove = (id) => {
        setInputFile(null);
        setUploadedIcon(null)
        setUploadedIconImage(null)
        document.getElementById(id).value = "";
    }

    const toggleColorPicker = () => {
        setCardPickerVisible(!cardPickerVisible)
    }

    useEffect(() => {
        getTemplates();
        getAuthors();
    }, [])


    const isDarkColor = (color) => {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luminance < 140;
    };


    const setValue = (key, val) => {
        if (key === 'caseStudyMainImg') {
            var fileInput = document.getElementById(key);
            if (fileInput.files && fileInput.files[0]) {
                var selectedFile = fileInput.files[0];
                const maxSize = 500000
                if (selectedFile.size > maxSize) {
                    alert('File size should be less than 500KB.')
                    document.getElementById(key).value = "";
                } else {
                    setInputValues(prevVal => {
                        let temp = prevVal;
                        temp[key] = selectedFile;
                        return temp;
                    })
                }
            }
        }
        else if (key === 'pdf-template-icon-img') {
            var fileInput = document.getElementById(key);
            if (fileInput.files && fileInput.files[0]) {
                var selectedFile = fileInput.files[0];
                const maxSize = 500000
                if (selectedFile.size > maxSize) {
                    alert('File size should be less than 500KB.')
                    document.getElementById(key).value = "";
                } else {
                    const imageUrl = URL.createObjectURL(selectedFile);

                    // Update the uploaded image URL in state
                    setUploadedIcon(imageUrl);
                    setUploadedIconImage(selectedFile)
                    setInputValues(prevVal => {
                        let temp = prevVal;
                        temp[key] = selectedFile;
                        return temp;
                    })
                }
            }
        }

        else if (key === 'file') {
            var fileInput = document.getElementById(key);
            if (fileInput.files && fileInput.files[0]) {
                var selectedFile = fileInput.files[0];
                const maxSize = 10 * 1024 * 1024;
                if (selectedFile.size > maxSize) {
                    alert('File size should be less than 10MB.');                
                    document.getElementById(key).value = "";
                } else {
                    // console.log('setting file');
                    setInputFile(selectedFile);
                }
            }
        }
        else if (key === 'published') {
            setPublishedDate(new Date(val).toISOString().split('T')[0])
            let date = new Date(val);
            let formattedDate = date.toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
            const day = date.getDate();
            const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
                (day % 10 === 2 && day !== 12) ? 'nd' :
                    (day % 10 === 3 && day !== 13) ? 'rd' : 'th';
            const formattedDateWithSuffix = day + suffix + ' ' + formattedDate.substring(formattedDate.indexOf(' ') + 1);
            setInputValues(prevVal => {
                let temp = prevVal;
                temp[key] = formattedDateWithSuffix;
                return temp;
            })
        }
        else {
            setInputValues(prevVal => {
                let temp = prevVal;
                temp[key] = val
                return temp;
            })
        }


        if (key === 'caseStudyTitle' && !caseStudyURL) {
            setCaseStudyTitle(val);
            setCaseStudyURL(val.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''))
        }
    }



    const [selectedPattern, setSelectedPattern] = useState('PatternOne');

    // Function to handle click and update selection
    const handlePatternClick = (pattern) => {
        setSelectedPattern(pattern);
    };

    const populateForEditing = () => {
        let temp = []
        let inp = (templates.find(temp => temp['templateId'] == selectedTemplate))['inputs']
        const type = (templates.find(temp => temp['templateId'] == selectedTemplate))['type']
        setTemplateType(type);

        for (let key in inp) {
            if (inp[key]['type'] === 'text') {
                temp.push(
                    <div className='admin-card p-3 my-3 d-flex flex-column'>
                        <div className='w-100 row'>
                            <div className='col-6 d-flex align-items-end'>
                                <h6 className='bold'>{inp[key]['label']}</h6>
                                <Tooltip placement="right" size='small' title={inp[key]['desc']}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <input className='form-control medium-2' type='text' id={key} onChange={(evt) => setValue(key, evt.target.value)} />
                    </div>
                )
            }
            else if (inp[key]['type'] === 'img') {
                temp.push(
                    <div className='admin-card p-3 my-3 d-flex flex-column'>
                        <div className='w-100 row'>
                            <div className='col-6 img-layout-heading d-flex align-items-end'>
                                <h6 className='bold'>{inp[key]['label']}</h6>
                                <Tooltip placement="right" size='small' title={inp[key]['desc']}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <input className='form-control medium-2' type='file' accept="image/png, image/jpeg, image/jpg, image/gif" id={key} onChange={(evt) => setValue(key, '')} />
                        {/* <div className="mt-2">
                            <span role='button' onClick={() => handleRemoveFile(key)} className="pointer-underline">Remove Image</span>
                        </div> */}
                    </div>
                )
            }
            else if (inp[key]['type'] === 'file') {
                temp.push(
                    <div className='admin-card p-3 my-3 d-flex flex-column'>
                        <div className='w-100 row'>
                            <div className='col-6 img-layout-heading d-flex align-items-end'>
                                <h6 className='bold'>{inp[key]['label']}</h6>
                                <Tooltip placement="right" size='small' title={inp[key]['desc']}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <input className='form-control medium-2' type='file' accept="application/pdf" id={key} onChange={(evt) => setValue(key, '')} />
                        {
                            // console.log("input file", inputFile) &&
                            // inputFile != null &&
                            // <div className="mt-2">
                            //     <span role='button' onClick={() => handleRemoveFile(key)} className="pointer-underline">Remove File</span>
                            // </div>
                        }
                    </div>
                )
            }
            else if (inp[key]['type'] === 'date') {
                temp.push(
                    <div className='admin-card p-3 my-3 d-flex flex-column'>
                        <div className='w-100 row'>
                            <div className='col-6 d-flex align-items-end'>
                                <h6 className='bold'>{inp[key]['label']}</h6>
                                <Tooltip placement="right" size='small' title={inp[key]['desc']}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <input className='form-control medium-2' type='date' id={key} onChange={(evt) => setValue(key, evt.target.value)} />
                    </div>
                )
            }
            else if (inp[key]['type'] === 'icon') {
                temp.push(
                    <div className='admin-card p-3 my-3 d-flex flex-column'>
                        <div className='w-100 row'>
                            <div className='col-6 img-layout-heading d-flex align-items-end'>
                                <h6 className='bold'>{inp[key]['label']}</h6>
                                <Tooltip placement="right" size='small' title={inp[key]['desc']}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <input className='form-control medium-2' type='file' accept="image/png, image/jpeg, image/jpg, image/gif" id={key} onChange={(evt) => setValue(key, '')} />
                        {/* <div className="mt-2">
                            <span role='button' onClick={() => handleIconRemove(key)} className="pointer-underline">Remove Icon</span>
                        </div> */}
                    </div>
                )
            }
        }
        setInputs(temp);
    }

    useEffect(() => {
        if (selectedTemplate) {
            populateForEditing()
        }
    }, [selectedTemplate]);

    const addEditor = () => {
        setAdditionalContent(prevContent => [...prevContent, { id: contentID, mode: 'editor', element: EditorState.createEmpty() }]);
        setContentID(contentID + 1)
    };



    const addImageAndText = async () => {
        setAdditionalContent(prevContent => {
            return [...prevContent,
            {
                'id': contentID,
                'img_id': images,
                'mode': 'img-text',
                'element': EditorState.createEmpty()
            }
            ]
        })
        setImages(images + 1)
        setContentID(contentID + 1)
    }

    const addImageLayoutHorizontal = async () => {
        var fileInput1 = document.getElementById('img-choose-horizontal');
        let temp = [];
        let blobArray = [];
        const readFile = (file, id, contentid) => {
            return new Promise((resolve, reject) => {
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        temp.push(<img id={`img-${id}`} className='w-100 corner blog-img-horizontal' src={e.target.result} />);
                        blobArray.push({ contentid, id, file, type: file.type })
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(); // Resolve immediately if no file
                }
            });
        };
        await Promise.all([readFile(fileInput1.files[0], images + 1, contentID)]);
        setSelectedImages(prevImages => [...prevImages, ...blobArray]);
        setImages(images + 2);
        let div = <div className='my-3 admin-card p-3'>
            Content #{contentID}
            <div className='w-100 row'>
                <div className='col-6 d-flex align-items-end'>
                    <h6 className='bold'>Horizontal Image Layout</h6>
                    <Tooltip placement="right" size='small' title="Layout used for displaying a single image">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteContentBlock(contentID, 'img')} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div id='actual-img-content' className='w-100 d-flex justify-content-center align-items-center'>
                {temp}
            </div>
        </div>;
        setAdditionalContent(prevContent => [...prevContent, { id: contentID, element: div, mode: 'img', layout: "horizontal" }]);
        setContentID(contentID + 1)
        setImgModalHorizontal(false);
    }


    const addImageLayout1 = async () => {
        var fileInput1 = document.getElementById('img-choose-1-1');

        let temp = [];
        let blobArray = [];

        const readFile = (file, id, contentid) => {
            return new Promise((resolve, reject) => {
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        temp.push(<img id={`img-${id}`} className='blog-img-1' src={e.target.result} />);
                        blobArray.push({ contentid, id, file, type: file.type, layout: '1' })
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(); // Resolve immediately if no file
                }
            });
        };
        await Promise.all([readFile(fileInput1.files[0], images + 1, contentID)]);
        setSelectedImages(prevImages => [...prevImages, ...blobArray]);
        setImages(images + 2)

        let div = <div className='my-3 admin-card p-3'>
            Content #{contentID}
            <div className='w-100 row'>
                <div className='col-6 d-flex align-items-end'>
                    <h6 className='bold'>1 Image Layout</h6>
                    <Tooltip placement="right" size='small' title="Layout used for displaying a single image">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteContentBlock(contentID, 'img')} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div id='actual-img-content' className='w-100 d-flex justify-content-evenly align-items-center'>
                {temp}
            </div>
        </div>;

        setAdditionalContent(prevContent => [...prevContent, { id: contentID, element: div, mode: 'img', layout: '1' }]);
        setContentID(contentID + 1)
        setImgModal1(false);
    };

    const addImageLayout2 = async () => {
        var fileInput1 = document.getElementById('img-choose-1');
        var fileInput2 = document.getElementById('img-choose-2');

        let temp = [];
        let blobArray = [];

        const readFile = (file, id, contentid) => {
            return new Promise((resolve, reject) => {
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        temp.push(<img id={`img-${id}`} className='blog-img' src={e.target.result} />);
                        blobArray.push({ contentid, id, file, type: file.type, layout: '2' })
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(); // Resolve immediately if no file
                }
            });
        };
        await Promise.all([readFile(fileInput1.files[0], images + 1, contentID), readFile(fileInput2.files[0], images + 2, contentID)]);
        setSelectedImages(prevImages => [...prevImages, ...blobArray]);
        setImages(images + 3)

        let div = <div className='my-3 admin-card p-3'>
            Content #{contentID}
            <div className='w-100 row'>
                <div className='col-6 d-flex align-items-end'>
                    <h6 className='bold'>2 Image Layout</h6>
                    <Tooltip placement="right" size='small' title="Layout used for displaying a single image">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteContentBlock(contentID, 'img')} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div id='actual-img-content' className='w-100 d-flex justify-content-evenly align-items-center'>
                {temp}
            </div>
        </div>;

        setAdditionalContent(prevContent => [...prevContent, { id: contentID, element: div, mode: 'img', layout: '2' }]);
        setContentID(contentID + 1)
        setImgModal2(false);
    };


    const addImageLayout3 = async () => {
        var fileInput1 = document.getElementById('img-choose-3-1');
        var fileInput2 = document.getElementById('img-choose-3-2');
        var fileInput3 = document.getElementById('img-choose-3-3');

        let temp = [];
        let blobArray = [];

        const readFile = (file, id, contentid) => {
            return new Promise((resolve, reject) => {
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        temp.push(<img id={`img-${id}`} className='blog-img-3' src={e.target.result} />);
                        blobArray.push({ contentid, id, file, type: file.type, layout: '3' })
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(); // Resolve immediately if no file
                }
            });
        };
        await Promise.all([readFile(fileInput1.files[0], images + 1, contentID), readFile(fileInput2.files[0], images + 2, contentID), readFile(fileInput3.files[0], images + 3, contentID)]);
        setSelectedImages(prevImages => [...prevImages, ...blobArray]);
        setImages(images + 4)

        let div = <div className='my-3 admin-card p-3'>
            Content #{contentID}
            <div className='w-100 row'>
                <div className='col-6 d-flex align-items-end'>
                    <h6 className='bold'>3 Image Layout</h6>
                    <Tooltip placement="right" size='small' title="Layout used for displaying 3 images">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteContentBlock(contentID, 'img')} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div id='actual-img-content' className='w-100 d-flex justify-content-evenly align-items-center'>
                {temp}
            </div>
        </div>;

        setAdditionalContent(prevContent => [...prevContent, { id: contentID, element: div, mode: 'img', layout: '3' }]);
        setContentID(contentID + 1)
        setImgModal3(false);
    };

    const saveCaseStudy = async () => {
        if (!caseStudyTitle) {
            toast.error('Please provide title for this case study!');
            return;
        }
        if (!selectedAuthor) {
            toast.error('Select an Author for this case study!');
            return;

        }
        // if (!caseStudyURL) {
        //     toast.error('Please provide a URL for your case study!', {
        //         position: "bottom-right",
        //         autoClose: 2500,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: false,
        //         draggable: false,
        //         theme: "colored",
        //     });
        //     return;
        // }
        // if (!caseStudyDesc && templateType === 'page') {
        //     toast.error('Please set a description for the Case study!', {
        //         position: "bottom-right",
        //         autoClose: 2500,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: false,
        //         draggable: false,
        //         theme: "colored",
        //     });
        //     return;
        // }
        // if (!keywords && templateType === 'page') {
        //     toast.error('Please set keywords for this Case study!', {
        //         position: "bottom-right",
        //         autoClose: 2500,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: false,
        //         draggable: false,
        //         theme: "colored",
        //     });
        //     return;
        // }
        if (!publishedDate) {
            toast.error('Please enter the publishing date!');
            return;
        }
        if (!selectedCategories.length) {
            toast.error('Please select at least one category for this Case study!');
            return;
        }
        if (showCustomCategories && !customCategories.length) {
            toast.error('You have not entered any custom categories!');
            return;
        }
        if (showCustomCategories && customCategories.length) {
            let temp = customCategories.split(',').map(item => item.toLowerCase().trim())
            for (let cat of categories) {
                if (temp.includes(cat.toLowerCase().trim())) {
                    toast.error(`${cat} already exists in the dropdown!`);
                    return;
                }
            }

        }

        let images = {}

        //Save Case study Main Image
        if (templateType !== 'pdf') {
            let mainImg = inputValues['caseStudyMainImg']
            if (!mainImg) {
                toast.error(`Choose a feature image for this case study!`);
                return;
            }
            images['img-main-img'] = {
                type: mainImg['type']
            }
            try {
                const formData = new FormData();
                formData.append('url', caseStudyURL);
                formData.append('id', `main-img.${mainImg['type'] === 'image/jpeg' ? 'jpeg' : mainImg['type'] === 'image/jpg' ? 'jpg' : mainImg['type'] === 'image/png' ? 'png' : 'jpg'}`);
                formData.append('file', mainImg);
                const response = await axios.post('/upload_case_study_images', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                // console.log('File uploaded successfully:', response.data);
            } catch (error) {
                // alert('Error while uploading Main image...')
                console.error('Error uploading file:', error);
            }

            for (let img of selectedImages) {
                try {
                    const formData = new FormData();
                    formData.append('url', caseStudyURL);
                    formData.append('id', `${img['id']}.${img['type'] === 'image/jpeg' ? 'jpeg' : img['type'] === 'image/jpg' ? 'jpg' : img['type'] === 'image/png' ? 'png' : 'jpg'}`);
                    formData.append('file', img['file']);
                    const response = await axios.post('/upload_case_study_images', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    // console.log('File uploaded successfully:', response.data);
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            }

            for (let img of selectedImages) {
                images[`img-${img['id']}`] = {
                    type: img['type']
                }
            }
        }

        let file_key = "";
        let image_key = "";
        if (templateType === "pdf") {
            //Save Case study PDF File

            if (!inputFile) {
                toast.error(`Choose a PDF file for this case study!`);
                return;
            }
        }
        if (!uploadedIconImage) {
            toast.error(`Choose an icon image for this case study!`);
            return;
        }
        if (templateType === 'pdf') {
            try {
                const formData = new FormData();
                formData.append('title', caseStudyTitle);
                formData.append('file', inputFile);
                formData.append('image', uploadedIconImage);
                formData.append('template', templateType)
                const response = await axios.post('/upload_case_study_pdf', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.status === 200 && response.data !== undefined) {

                    file_key = response.data.file_key;
                    image_key = response.data.image_key;
                }
            } catch (error) {
                // alert('Error while uploading Main image...')
                console.error('Error uploading file:', error);
            }
        }
        if (templateType !== 'pdf') {
            try {
                const formData = new FormData();
                formData.append('title', caseStudyTitle);
                formData.append('image', uploadedIconImage);
                formData.append('template', templateType)
                const response = await axios.post('/upload_case_study_page', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.status === 200 && response.data !== undefined) {
                    image_key = response.data.image_key;
                }
            } catch (error) {
                // alert('Error while uploading Main image...')
                console.error('Error uploading file:', error);
            }
        }


        let newCaseStudy = {};
        templateType === 'pdf'
            ?
            newCaseStudy = {
                'templateId': parseInt(selectedTemplate),
                'authorId': selectedAuthor['value'],
                'inputs': inputValues,
                'publishedDate': publishedDate,
                'categories': selectedCategories.map(cat => cat['value']),
                'customCategories': customCategories.split(',').map(item => item.trim()).map(str => str.charAt(0).toUpperCase() + str.slice(1)).filter(str => str !== ''),
                'title': caseStudyTitle,
                'active': true,
                'image_key': image_key,
                'file_key': file_key,
                'templateType': templateType,
                'keywords': [],
                'cardPattern': selectedPattern,
                'cardColor': cardColor,
                'cardTextColor': cardTextColor,
                'cardTextBackgroundColor': cardTextBackgroundColor
            }
            :
            newCaseStudy = {
                'templateId': parseInt(selectedTemplate),
                'authorId': selectedAuthor['value'],
                'url': caseStudyURL,
                'inputs': inputValues,
                'publishedDate': publishedDate,
                'additionalContent': (additionalContent.map(element => processElement(element, 'save'))).join(' '),
                'desc': caseStudyDesc,
                'keywords': keywords.split(',').map(item => item.trim()).filter(item => item !== ''),
                'categories': selectedCategories.map(cat => cat['value']),
                'customCategories': customCategories.split(',').map(item => item.trim()).map(str => str.charAt(0).toUpperCase() + str.slice(1)).filter(str => str !== ''),
                'title': caseStudyTitle,
                'templateType': templateType,
                'images': images,
                'image_key': image_key,
                'active': true,
                'cardPattern': selectedPattern,
                'cardColor': cardColor,
                'cardTextColor': cardTextColor,
                'cardTextBackgroundColor': cardTextBackgroundColor
            };
        // console.log(newCaseStudy);
        try {
            const response = await axios.post(`/create_new_case_study`, newCaseStudy, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                alert('Case study created!')
                navigate(`/niyantran-panel/${adminURL}/case-studies-admin`)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 400) {
                toast.error('This Case study URL already exists! Please change the Case study title or URL');
                return
            }
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    };

    const readImg = (file, contentid, id) => {
        if (file) {
            let blobArray = []
            const reader = new FileReader();
            reader.onload = function (e) {
                const imagePreview = document.getElementById(`img-text-img-${id}`)
                imagePreview.src = e.target.result;
                imagePreview.classList.add('blog-img-text');
                blobArray.push({ contentid, id, file, type: file.type })
                setSelectedImages(prevImages => [...prevImages, ...blobArray]);
                setImages(images + 2)
                setContentID(contentID + 1)
            };
            reader.readAsDataURL(file);
        }
    }

    const processElement = (element, mode) => {
        if (element['element']) {
            if (element['mode'] === 'editor') {
                let html = stateToHTML(element['element'].getCurrentContent())
                if (mode !== 'save') {
                    let arr = html.split('##')
                    for (let i = 0; i < arr.length; i += 1) {
                        if (arr[i].includes('__')) {
                            arr[i] = `<a target='_blank' href='//${(arr[i].split('__'))[1]}'>${(arr[i].split('__'))[0]}</a>`
                        }
                    }
                    html = arr.join('')
                }
                return `<div data-content-id="${element['id']}" data-content-type='text' class='my-2'>` + html + "</div>"
            }
            else if (element['mode'] === 'img-text') {
                let html = stateToHTML(element['element'].getCurrentContent()).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
                let src = ''
                if (mode !== 'save') {
                    // src = document.getElementById(`img-text-img-${element['id']}`).getAttribute('src')
                    src = document.querySelector(`[data-content-id="img-text-img-contentid-${element['id']}"]`).getAttribute('src')
                }
                let doc = selectedImages.find(item => item['contentid'] == element['id'])
                return `<div data-content-id="${element['id']}" data-content-type="img-text" class='w-100 row'><div class='col-6 p-1 d-flex flex-column justify-content-center align-items-center'><img id='${`img-${doc["id"]}`}' src='${src}' class='blog-img-text'/></div><div class='col-6 p-1'>${html}</div></div>`
            }
            else if (element['mode'] === 'img') {
                if (element['layout'] === 'horizontal' || element['layout'] == '1' || element['layout'] == '2' || element['layout'] == '3') {
                    let item = ReactDOMServer.renderToString(element['element']).replace(/admin-card/g, '')
                    item = new DOMParser().parseFromString(item, "text/html")
                    item = item.querySelector('#actual-img-content')
                    if (mode == 'save') {
                        Array.from(item.querySelectorAll('img')).forEach(img => img.setAttribute('src', ''))
                    }
                    return `<div data-content-id="${element['id']}" data-content-type='img' data-layout=${element['layout']} class='my-3'>` + item.outerHTML + "</div>";
                }
            }
            else if (element['mode'] === 'link') {
                if (document.getElementById(`link-input-name-${element['id']}`)) {
                    return ReactDOMServer.renderToString(
                        <div data-content-id={String(element['id'])} data-content-type="link" className='my-2 d-flex align-items-center'>
                            <i className="mr-2 fa-solid fa-link text-muted"></i>
                            <a target="_blank" className='blog-link' href={`//${document.getElementById(`link-input-url-${element['id']}`).value}`}>{document.getElementById(`link-input-name-${element['id']}`).value}</a>
                        </div>
                    )
                }
            }
            else if (element['mode'] === 'iframe') {
                if (document.getElementById(`iframe-name-${element['id']}`) && document.getElementById(`iframe-src-${element['id']}`)) {
                    let name = document.getElementById(`iframe-name-${element['id']}`).value
                    let src = document.getElementById(`iframe-src-${element['id']}`).value

                    return ReactDOMServer.renderToString(
                        <div data-content-id={String(element['id'])} data-content-type="iframe" className='my-2 w-100 d-flex justify-content-center align-items-center'>
                            <iFrame src={src} name={name} />
                        </div>
                    )
                }
            }
            else if (element['mode'] === 'form-button') {
                let btnName = ''
                btnName = document.getElementById(`form-button-name-${element['id']}`).value
                let form = 'contact-us'
                form = document.getElementById(`form-button-form-${element['id']}`).value
                return ReactDOMServer.renderToString(
                    <div data-content-id={String(element['id'])} data-content-type="form-button" className='my-2 d-flex align-items-center'>
                        <button data-attached-form={form} className='clickable-form-button p-2 btn-yellow text-center text-black'>{btnName}</button>
                    </div>
                )
            }
        } else {
            return ""
        }
    }

    const checkCaseStudyContent = () => {
        //Check links
        // for (let key in links) {
        //     if (!isValidURL(links[key]['url'])) {
        //         alert('One of your URL links is invalid, please check again!')
        //         return false;
        //     }
        // }

        return true
    }

    const openPreview = () => {
        if (!caseStudyTitle) {
            alert('Please set Case study Title');
            return;
        }
        if (!selectedAuthor['value']) {
            alert('Please select Case study Author');
            return;
        }
        if (checkCaseStudyContent()) {
            setOpen(true)
        }
    }

    const deleteContentBlock = (id, mode) => {
        if (mode === 'link') {
            document.getElementById(`link-card-${id}`).outerHTML = "";
        }
        else if (mode === 'editor') {
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
        }
        else if (mode === 'iframe') {
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
        }
        else if (mode === 'img') {
            setSelectedImages(prevContent => {
                let temp = prevContent.filter(item => item['contentid'] !== id)
                return temp;
            })
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
        }
        else if (mode === 'form-button') {
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
        }
    }

    const addiFrame = () => {
        setAdditionalContent(prevState => {
            return [...prevState,
            {
                'id': contentID,
                'mode': 'iframe',
                'element': <div id={`iframe-${contentID}`} className='my-3 link-card admin-card p-3'>
                    ID: #{contentID}
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>Video Name</h6>
                            <Tooltip placement="right" size='small' title="Video Name">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='col-6 d-flex justify-content-end'>
                            <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                                <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteContentBlock(contentID, 'iframe')} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                    <input placeholder='Ex. Sciative Road Ahead Talks' className='form-control' type='text' id={`iframe-name-${contentID}`} />
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>Video URL</h6>
                            <Tooltip placement="right" size='small' title="Video URL">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input placeholder='Paste URL here' className='form-control' type='text' id={`iframe-src-${contentID}`} />
                </div>
            }
            ]
        })
        setContentID(contentID + 1)
    }

    const handleLinkNameChange = (id, val) => {
        setLinkNames(prevState => {
            let temp = prevState;
            temp[id] = val;
            return temp;
        })
    }

    const addFormButton = () => {
        setAdditionalContent(prevState => {
            return [...prevState, {
                'id': contentID,
                'mode': 'form-button',
                'element': <div id={`form-button-section-${contentID}`} className='my-3 link-card admin-card p-3'>
                    ID: #{contentID}
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>Button Name</h6>
                            <Tooltip placement="right" size='small' title="Text on the button">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='col-6 d-flex justify-content-end'>
                            <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                                <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteContentBlock(contentID, 'form-button')} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <input placeholder='Ex. Request a Demo' className='form-control' type='text' id={`form-button-name-${contentID}`} />
                        <div className='w-100 row'>
                            <div className='col-6 d-flex align-items-end'>
                                <h6 className='bold'>Choose Form</h6>
                                <Tooltip placement="right" size='small' title="Form to be displayed in the popup modal">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <select className='form-select' id={`form-button-form-${contentID}`}>
                            <option selected value="contact-us">Contact Us</option>
                            <option value="request-demo">Request a Demo</option>
                        </select>
                    </div>
                </div>
            }]
        })
        setContentID(contentID + 1)
    }

    const addLink = () => {

        setAdditionalContent(prevState => {
            let cid = contentID
            return [...prevState, {
                'id': contentID,
                'mode': "link",
                'element': <div id={`link-card-${contentID}`} className='my-3 link-card admin-card p-3'>
                    ID: #{contentID}
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>Link Name</h6>
                            <Tooltip placement="right" size='small' title="Clickable text to display on the blog.">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='col-6 d-flex justify-content-end'>
                            <IconButton onClick={() => shiftUp(cid)} aria-label="shift-up">
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton onClick={() => shiftDown(cid)} aria-label="shift-down">
                                <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteContentBlock(contentID, 'link')} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                    <input onChange={(evt) => handleLinkNameChange(contentID, evt.target.value)} placeholder='Ex. Go to Google' className='form-control' type='text' id={`link-input-name-${contentID}`} />
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>URL</h6>
                            <Tooltip placement="right" size='small' title="Link to the website where you want to redirect users">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input placeholder='Ex. www.google.com' className='form-control' type='text' id={`link-input-url-${contentID}`} />
                </div>
            }]
        })
        setContentID(contentID + 1)
    }

    const handleChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 500000; // in bytes

        if (file && file.size > maxSize) {
            setImgValid(false);
        } else {
            setImgValid(true);
        }
    };


    const analyzeSEO = async () => {
        if (!selectedCategories.length) {
            toast.error('You have not selected any categories!');
            return;
        }
        try {
            const response = await axios.post(`/get_keywords`, { categories: selectedCategories.map(item => item.value) }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setMasterKeywords(response.data)
                let freq = {}
                for (let keyword of response.data) {
                    freq[keyword] = 0
                }
                let contents = []
                for (let content of additionalContent) {
                    if (content['mode'] === 'editor') {
                        contents = [...contents, ...content['element'].getCurrentContent().getPlainText('\n').toLowerCase().split(' ')]
                    }
                }
                for (let word of contents) {
                    if (word in freq) {
                        freq[word] += 1
                    }
                }
                setFrequency(freq);
                let tableData = [];
                for (let keyword in freq) {
                    tableData.push({ id: keyword, count: freq[keyword] })
                }
                setFrequencyData(tableData.sort((a, b) => {
                    return b.count - a.count;
                }));
                setAnalyzeSEOModal(true);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        contentRef.current = additionalContent
    }, [additionalContent])

    const shiftUp = (id) => {
        let originalPos
        for (let i = 0; i < contentRef.current.length; i += 1) {
            // console.log(id, i, contentRef.current[i]['id'])
            if (contentRef.current[i]['id'] == id) {
                originalPos = i
                break
            }
        }
        if (originalPos !== null && originalPos !== undefined && originalPos != 0) {
            let newArr = arrayMoveImmutable(contentRef.current, originalPos, originalPos - 1)
            setAdditionalContent(newArr);
        }
    }

    const shiftDown = (id) => {
        let originalPos
        for (let i = 0; i < contentRef.current.length; i += 1) {
            // console.log(id, i, contentRef.current[i]['id'])
            if (contentRef.current[i]['id'] == id) {
                originalPos = i
                break
            }
        }
        if (originalPos !== null && originalPos !== undefined && originalPos < contentRef.current.length) {
            let newArr = arrayMoveImmutable(contentRef.current, originalPos, originalPos + 1)
            setAdditionalContent(newArr);
        }
    }


    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'>
                        <IconButton onClick={() => navigate(`/niyantran-panel/${adminURL}/case-studies-admin`)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div className='col-8'>
                        <h1 className='bold '>Create new Case Study</h1>
                        <p className='medium-3 text-muted'>Manage Case Study content by choosing a template.</p>
                    </div>
                    <div className='col-3 py-2 d-flex flex-column'>
                        <h6 className='bold medium-2 text-muted'>Choose Template</h6>
                        <select onChange={(evt) => setSelectedTemplate(parseInt(evt.target.value))} class="form-select" aria-label="Select template">
                            <option hidden selected>Select a template</option>
                            {
                                templates.map(item => <option value={item['templateId']}>{item['templateName']}</option>)
                            }
                        </select>
                    </div>
                </div>

                {
                    selectedTemplate ?
                        <>
                            <div className='mt-4 col-8'>
                                {inputs}

                                <div className='admin-card p-3 my-3 d-flex'>
                                <div className='col-6 row d-flex flex-column'>
                                    <h6 className='bold'>Case study section</h6>
                                        <div className='col-12 img-layout-heading d-flex'>



                                            <div className='d-flex flex-column '>
                                                <div className='d-flex r'>
                                                    <h6 >Choose Case studies Card's cover color</h6>
                                                </div>
                                                <div className='col-6'>
                                                    <div onClick={() => toggleColorPicker()} className='w-10'>
                                                        <div title="Choose card color" >
                                                            <img src={colorPickerCard} alt="color picker" className='color-picker-img' />
                                                        </div>
                                                        <div className='picker-button'

                                                            style={{
                                                                // width: '3em',
                                                                backgroundColor: cardColor?.hex || '#000000',
                                                            }}>
                                                        </div>
                                                    </div>
                                                    {cardPickerVisible && (
                                                        <div
                                                            id={`color-picker`} className='case-color-picker' >
                                                            <SketchPicker
                                                                color={cardColor?.hex || 'red'}
                                                                onChange={(newColor, event) => {
                                                                    event.preventDefault()
                                                                    handleCardColor(newColor)

                                                                }}
                                                                style={{ position: 'absolute', zIndex: 100, left: '0%' }}
                                                            />

                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        </div>


                                        <div className='col-12 img-layout-heading d-flex mt-3'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex'>
                                                    <h6>Choose Case studies Card's cover pattern</h6>
                                                </div>
                                                <div className='col-6'>
                                                    <div onClick={() => setOpenCardPattern(true)} className='w-10 me-5'>
                                                        <div title="Choose card pattern" >
                                                            <img src={cardPatternPicker} alt="color picker" className='color-picker-img' />
                                                        </div>
                                                        <div className='picker-button'
                                                            style={{
                                                                // width: '3em',
                                                                visibility: 'hidden'
                                                            }}>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div style={{ position: 'relative' }}>
                                        <div className='mb-2 bold fs-6'>Card Preview : </div>
                                        <div>
                                            <div style={{ position: 'relative', display: 'inline-block', backgroundColor: cardColor?.hex, height: '25em', width: '20em' }}>
                                                <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: '20%' }}>

                                                    {selectedCategories.length != 0 &&
                                                        <Stack direction="row" spacing={1} className='col-10 ms-1 card-stack'  >
                                                            <Item
                                                                title={selectedCategories.length > 0 ? selectedCategories.map(item => item.value).join(', ') : ''}
                                                                style={{ maxWidth: '80%', backgroundColor: cardTextBackgroundColor || 'white' }}>
                                                                <div
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                        color: cardTextColor || 'white',
                                                                    }}
                                                                >
                                                                    {selectedCategories.length > 0 &&
                                                                        selectedCategories.slice(0, 2).map(item => item.value).join(' & ')
                                                                    }
                                                                </div>
                                                            </Item>

                                                            {selectedCategories.length > 2 && (
                                                                <Item
                                                                    style={{
                                                                        color: cardTextColor || 'white',
                                                                        backgroundColor: cardTextBackgroundColor || 'white'
                                                                    }}
                                                                >
                                                                    + {selectedCategories.length - 2}
                                                                </Item>
                                                            )}

                                                        </Stack>
                                                    }
                                                </div>
                                                <div className='col-12 d-flex justify-content-center ' style={{ height: '60%' }}>
                                                    <div className='col-10 case-study-description-container'>
                                                        <div className='case-study-description' style={{
                                                            color: cardTextColor || 'white',
                                                            overflow: 'hidden',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: '6',
                                                            WebkitBoxOrient: 'vertical',
                                                            textOverflow: 'ellipsis',
                                                            fontWeight: 'bold'
                                                        }}>

                                                            {caseStudyTitle}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='col-12 d-flex justify-content-evenly' style={{ height: '4em' }}>
                                                    <div className='col-5 align-self-end'>
                                                    {(cardTextColor === '#FFFFFF' || !cardTextColor) ? (
                                                            <>
                                                                <CaseWhite />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CaseBlack />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className='col-3'>

                                                        {uploadedIcon && (
                                                            <img
                                                                src={uploadedIcon}
                                                                alt="icon"
                                                                className='case-study-custom-icon'
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                {selectedPattern === 'PatternOne' && <PatternOne className='previewPattern'  />}
                                                {selectedPattern === 'PatternTwo' && <PatternTwo  className='previewPattern'  />}
                                                {selectedPattern === 'PatternThree' && <PatternThree className='previewPattern'   />}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {
                                    additionalContent.map((item, idx) => (
                                        item['mode'] === 'editor' ?
                                            <div id={`editor-card-${item['id']}`} className='admin-card p-3 my-3' key={idx}>
                                                Content #{item['id']}
                                                <div className='w-100 row'>
                                                    <div className='col-6 d-flex align-items-end'>
                                                        <h6 className='bold'>Add Content</h6>
                                                        <Tooltip placement="right" size='small' title="Insert your blog content">
                                                            <IconButton>
                                                                <InfoIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className='col-6 d-flex justify-content-end'>
                                                        <IconButton onClick={() => setLinkTutorial(true)} aria-label="shift-up">
                                                            <LinkIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => shiftUp(item['id'])} aria-label="shift-up">
                                                            <ArrowUpwardIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => shiftDown(item['id'])} aria-label="shift-down">
                                                            <ArrowDownwardIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => deleteContentBlock(item['id'], 'editor')} aria-label="delete">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                                <MyEditor editorState={item['element']} setEditorState={updatedEditorState => {
                                                    const updatedContent = [...additionalContent];
                                                    // updatedContent[idx] = updatedEditorState;
                                                    (updatedContent.find(con => con['id'] === item['id']))['element'] = updatedEditorState
                                                    setAdditionalContent(updatedContent);
                                                }} />
                                            </div>
                                            :
                                            item['mode'] === 'img-text' ?
                                                <div id={`img-text-section-${item['id']}`} className='admin-card p-3 my-3' key={idx}>
                                                    Content #{item['id']}
                                                    <div className='w-100 row'>
                                                        <div className='col-6 d-flex align-items-end'>
                                                            <h6 className='bold'>Add Image & Text</h6>
                                                            <Tooltip placement="right" size='small' title="Insert your blog content">
                                                                <IconButton>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        <div className='col-6 d-flex justify-content-end'>
                                                            <IconButton onClick={() => shiftUp(item['id'])} aria-label="shift-up">
                                                                <ArrowUpwardIcon />
                                                            </IconButton>
                                                            <IconButton onClick={() => shiftDown(item['id'])} aria-label="shift-down">
                                                                <ArrowDownwardIcon />
                                                            </IconButton>
                                                            <IconButton onClick={() => deleteContentBlock(item['id'], 'editor')} aria-label="delete">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 row'>
                                                        <div className='col-6 p-1'>
                                                            <input onChange={(evt) => readImg(evt.target.files[0], item['id'], item['img_id'])} className='form-control' id={`img-text-${item['img_id']}`} type='file' accept="image/png, image/jpeg, image/jpg, image/gif" />
                                                            <img src="" data-content-id={`img-text-img-contentid-${item['id']}`} id={`img-text-img-${item['img_id']}`} />
                                                        </div>
                                                        <div className='col-6 p-1'>
                                                            <MyEditor editorState={item['element']} setEditorState={updatedEditorState => {
                                                                const updatedContent = [...additionalContent];
                                                                // updatedContent[idx] = updatedEditorState;
                                                                (updatedContent.find(con => con['id'] === item['id']))['element'] = updatedEditorState
                                                                setAdditionalContent(updatedContent);
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                item['element']
                                    ))
                                }
                                {
                                    templateType !== 'pdf' &&
                                    <div className='admin-card p-3 mt-3 d-flex flex-column'>
                                        <div>
                                            <h6 className='text-grey'>Content</h6>
                                            <div className='mt-2 d-flex align-items-center flex-wrap'>
                                                <div onClick={addEditor} className='mx-2 bordered content-button-2 corner p-3'>
                                                    <div className='w-60 heading-placeholder mb-3'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 mt-3 d-flex justify-content-center'>
                                                        <h6 className='bold'>Text</h6>
                                                    </div>
                                                </div>
                                                <div onClick={addLink} className='mx-2 bordered content-button-2 corner p-3'>
                                                    <div className='w-60 heading-placeholder mb-3'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <button className='blog-button-2 bg-yellow m-0 p-0'><i className="fa-solid fa-link"></i></button>
                                                    <div className='w-100 mt-3 d-flex justify-content-center'>
                                                        <h6 className='bold'>Link</h6>
                                                    </div>
                                                </div>
                                                <div onClick={addFormButton} className='mx-2 bordered content-button-2 corner p-3'>
                                                    <div className='w-60 heading-placeholder mb-3'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <button className='blog-button-2 bg-yellow m-0 p-0'><i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                                    <div className='w-100 mt-3 d-flex justify-content-center'>
                                                        <h6 className='bold'>Form Button</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-3'>
                                            <h6 className='text-grey'>Images</h6>
                                            <div className='mt-2 d-flex align-items-center flex-wrap'>
                                                <div className='mt-2 d-flex align-items-center flex-wrap'>
                                                    <div onClick={() => setImgModalHorizontal(true)} className='mx-2 bordered content-button corner p-3'>
                                                        <div className='w-60 heading-placeholder mb-3'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='corner bg-light-blue mt-2 p-3 w-100 d-flex justify-content-center align-items-center'>
                                                            <i className="fa-solid fa-image"></i>
                                                        </div>
                                                        <div className='w-100 mt-3 d-flex justify-content-center'>
                                                            <h6 className='bold'>Horizontal Layout</h6>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => setImgModal1(true)} className='mx-2 bordered content-button corner p-3'>
                                                        <div className='w-60 heading-placeholder mb-3'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                                            <div className='corner bg-light-blue mt-2 p-3 w-50 d-flex justify-content-center align-items-center'>
                                                                <i className="fa-solid fa-image"></i>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 mt-3 d-flex justify-content-center'>
                                                            <h6 className='bold'>Layout 1</h6>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => setImgModal2(true)} className='mx-2 bordered content-button corner p-3'>
                                                        <div className='w-60 heading-placeholder mb-3'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                                            <div className='corner bg-light-blue mt-2 p-3 w-40 d-flex justify-content-center align-items-center'>
                                                                <i className="fa-solid fa-image"></i>
                                                            </div>
                                                            <div className='corner bg-light-blue mt-2 p-3 w-40 d-flex justify-content-center align-items-center'>
                                                                <i className="fa-solid fa-image"></i>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 mt-3 d-flex justify-content-center'>
                                                            <h6 className='bold'>Layout 2</h6>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => setImgModal3(true)} className='mx-2 my-2 bordered content-button corner p-3'>
                                                        <div className='w-60 heading-placeholder mb-3'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                                            <div className='corner bg-light-blue mt-2 p-3 w-20 d-flex justify-content-center align-items-center'>
                                                                <i className="fa-solid fa-image"></i>
                                                            </div>
                                                            <div className='corner bg-light-blue mt-2 p-3 w-20 d-flex justify-content-center align-items-center'>
                                                                <i className="fa-solid fa-image"></i>
                                                            </div>
                                                            <div className='corner bg-light-blue mt-2 p-3 w-20 d-flex justify-content-center align-items-center'>
                                                                <i className="fa-solid fa-image"></i>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 mt-3 d-flex justify-content-center'>
                                                            <h6 className='bold'>Layout 3</h6>
                                                        </div>
                                                    </div>
                                                    <div onClick={addImageAndText} className='mx-2 my-2 bordered content-button corner p-3'>
                                                        <div className='w-60 heading-placeholder mb-3'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 para-placeholder mb-2'></div>
                                                        <div className='w-100 row'>
                                                            <div className='col-6 d-flex justify-content-center align-items-center'>
                                                                <div className='corner bg-light-blue mt-2 p-3 w-20 d-flex justify-content-center align-items-center'>
                                                                    <i className="fa-solid fa-image"></i>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 flex-column d-flex justify-content-center align-items-center'>
                                                                <div className='w-100 para-placeholder mb-2'></div>
                                                                <div className='w-100 para-placeholder mb-2'></div>
                                                                <div className='w-100 para-placeholder mb-2'></div>

                                                            </div>

                                                        </div>
                                                        <div className='w-100 mt-2 d-flex justify-content-center'>
                                                            <h6 className='bold'>Image & Text</h6>
                                                        </div>
                                                    </div>
                                                    {/* <Button variant='outlined' className='mb-2 mx-1' onClick={addiFrame}>+ iFrame <i className="mx-2 fa-brands fa-youtube"></i></Button> */}
                                                </div>
                                                {/* <Button className='mb-2 mx-1' variant="outlined">+ Horizontal Image <i className="mx-2 fa-solid fa-image"></i></Button> */}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='mt-4 col-4'>


                                <div className='d-flex flex-column admin-card p-3'>
                                    {
                                        templateType !== 'pdf' &&
                                        <>
                                            <Button className='w-100 my-2' variant='outlined' onClick={() => analyzeSEO()}>Analyze SEO</Button>
                                            <Button className='w-100 my-2' variant='outlined' onClick={() => openPreview()}>Preview Case Study</Button>
                                        </>
                                    }
                                    <Button className='w-100 my-2' variant='contained' onClick={() => saveCaseStudy()}>Save Case Study</Button>
                                </div>


                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='w-100 row'>
                                        <div className='col-6 d-flex align-items-end'>
                                            <h6 className='bold text-grey text-nowrap'>Case Study Author</h6>
                                            <Tooltip placement="right" size='small' title="Select the author of this case study">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <Select
                                            placeholder="Select author"
                                            value={selectedAuthor}
                                            onChange={(selectedOption) => setSelectedAuthor(selectedOption)}
                                            options={authors.map(author => ({ value: author.authorId, label: author.authorName }))}
                                        />
                                    </div>

                                </div>


                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='d-flex flex-column'>
                                        <div className='w-100 row'>
                                            <div className='col-6 d-flex align-items-end'>
                                                <h6 className='bold text-grey'>Categories</h6>
                                                <Tooltip placement="right" size='small' title="Select relevant categories for this case study to recommend other suggested case studies to users based on these categories.">
                                                    <IconButton>
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>

                                        <Select placeholder="Select categories" value={selectedCategories} onChange={opt => {
                                            setSelectedCategories(opt)
                                        }} isMulti closeMenuOnSelect={false} options={categories.map(item => ({ 'label': item, 'value': item }))} className="w-100" />

                                        <div className='w-100 mt-3'>
                                            {
                                                showCustomCategories ?
                                                    <input value={customCategories} onChange={(evt) => setCustomCategories(evt.target.value)} className='my-2 form-control w-100' placeholder='Enter comma separated values' />
                                                    : null
                                            }
                                            <Button onClick={() => setShowCustomCategories(!showCustomCategories)} className='w-100' variant='outlined'>
                                                <i className="mr-2 fa-solid fa-wand-magic-sparkles"></i> {showCustomCategories ? 'Remove custom categories' : 'Add custom categories'}
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                                {
                                    templateType !== 'pdf' &&
                                    <>
                                        <div className='admin-card bg-grey-5 p-3 my-3'>
                                            <div className='w-100 row'>
                                                <div className='col-6 d-flex align-items-end'>
                                                    <h6 className='bold text-grey'>SEO Title</h6>
                                                    <Tooltip placement="right" size='small' title="Title to be displayed in search engine results">
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <input value={caseStudyTitle} onChange={(evt) => setCaseStudyTitle(evt.target.value)} className='form-control' type='text' />
                                        </div>
                                        <div className='admin-card bg-grey-5 p-3 my-3'>
                                            <div className='w-100 row'>
                                                <div className='col-6 d-flex align-items-end'>
                                                    <h6 className='bold text-grey'>Case Study URL</h6>
                                                    <Tooltip placement="right" size='small' title="URL is generated from the Blog Title, however it can be changed. Ensure the URL lowercased and hyphen separated with no spaces">
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <input value={caseStudyURL} onChange={(evt) => setCaseStudyURL(evt.target.value)} className='form-control' type='text' />
                                        </div>
                                        <div className='admin-card bg-grey-5 p-3 my-3'>
                                            <div className='w-100 row'>
                                                <div className='col-8 d-flex align-items-end'>
                                                    <h6 className='bold text-grey'>SEO Description</h6>
                                                    <Tooltip placement="top" size='small' title="Description to be displayed in search engine results">
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <textarea className='form-control' value={caseStudyDesc} onChange={(evt) => setCaseStudyDesc(evt.target.value)} />
                                        </div>
                                        <div className='admin-card bg-grey-5 p-3 my-3'>
                                            <div className='w-100 row'>
                                                <div className='col-6 d-flex align-items-end'>
                                                    <h6 className='bold text-grey'>SEO Keywords</h6>
                                                    <Tooltip placement="right" size='small' title="Words or phrases that users type into search engines to find relevant results. Add as many keywords, for web crawlers to efficiently crawl your blog">
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <input value={keywords} onChange={(evt) => setKeywords(evt.target.value)} className='form-control' type='text' />
                                        </div>

                                    </>
                                }

                            </div>
                        </>
                        :
                        <div className='mt-4 w-100 admin-card p-3'>
                            <Alert className='w-100' severity='info'>Choose a template to create a Case Study.</Alert>
                        </div>
                }

            </section>


            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='lg'
            >
                <section className='w-100 bg-light-blue'>

                    <div className='p-2 d-flex justify-content-center w-100'>
                        {
                            selectedTemplate && open ?
                                <CaseStudiesPreview
                                    author={authors.find(author => author.authorId == selectedAuthor.value)}
                                    inputs={inputValues}
                                    additionalContent={(additionalContent.map(element => processElement(element, 'preview'))).join(' ')}
                                    categories={selectedCategories}
                                    structure={(templates.find(temp => temp['templateId'] == selectedTemplate))['structure']}
                                />
                                :
                                null
                        }
                    </div>
                </section>
                <DialogActions>
                    <Button variant='contained' onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={imgModal1}
                onClose={() => setImgModal1(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <h3 className='bold'>Add 1 Image Layout</h3>
                        <p className='text-muted mb-0'>Add a single image</p>
                    </div>
                    <div className='col-6 p-3'>
                        <div className='w-100 small-2 border corner'>
                            <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                            </div>
                            <div className='p-3'>
                                <div className='w-30 heading-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 d-flex justify-content-center align-items-center'>
                                    <div className='w-40 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-1 d-flex flex-column justify-content-center'>
                        <h6 className='bold'>Choose Image File</h6>
                        <input id='img-choose-1-1' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <Alert className='mt-2 w-100' severity="info">File should be smaller than 500KB. Ensure only jpg, jpeg and png files</Alert>
                    </div>

                    <div className='mt-3 col-12 d-flex justify-content-center align-items-center'>
                        <Button size='large' variant='contained' onClick={() => addImageLayout1()}>Submit</Button>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={imgModal2}
                onClose={() => setImgModal2(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <h3 className='bold'>Add 2 Image Layout</h3>
                        <p className='text-muted mb-0'>Add two images, both appearing in a single row</p>
                    </div>
                    <div className='col-6 p-3'>
                        <div className='w-100 small-2 border corner'>
                            <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                            </div>
                            <div className='p-3'>
                                <div className='w-30 heading-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                    <div className='w-40 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                    <div className='w-40 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-1 d-flex flex-column justify-content-center'>
                        <h6 className='bold'>Choose Image Files</h6>
                        <input id='img-choose-1' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input id='img-choose-2' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <Alert className='mt-2 w-100' severity="info">Files should be smaller than 500KB. Ensure only jpg, jpeg and png files</Alert>
                    </div>

                    <div className='mt-3 col-12 d-flex justify-content-center align-items-center'>
                        <Button size='large' variant='contained' onClick={() => addImageLayout2()}>Submit</Button>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={imgModal3}
                onClose={() => setImgModal3(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <h3 className='bold'>Add 3 Image Layout</h3>
                        <p className='text-muted mb-0'>Add three images, all appearing in a single row</p>
                    </div>
                    <div className='col-6 p-3'>
                        <div className='w-100 small-2 border corner'>
                            <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                            </div>
                            <div className='p-3'>
                                <div className='w-30 heading-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                    <div className='w-20 mx-4 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                    <div className='w-20 mx-4 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                    <div className='w-20 mx-4 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-1 d-flex flex-column justify-content-center'>
                        <h6 className='bold'>Choose Image Files</h6>
                        <input id='img-choose-3-1' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input id='img-choose-3-2' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input id='img-choose-3-3' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <Alert className='mt-2 w-100' severity="info">Files should be smaller than 500KB. Ensure only jpg, jpeg and png files</Alert>
                    </div>

                    <div className='mt-3 col-12 d-flex justify-content-center align-items-center'>
                        <Button size='large' variant='contained' onClick={() => addImageLayout3()}>Submit</Button>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={imgModalHorizontal}
                onClose={() => setImgModalHorizontal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <div className='w-100 row'>
                            <div className='col-11'>
                                <h3 className='bold'>Add Horizontal Image Layout</h3>
                                <p className='text-muted mb-0'>Add an image which spans across the entire width of the page</p>
                            </div>
                            <div className='col-1 d-flex justify-content-end'>
                                <CloseIcon onClick={() => setImgModalHorizontal(false)} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-3'>
                        <div className='w-100 small-2 border corner'>
                            <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                            </div>
                            <div className='p-3'>
                                <div className='w-30 heading-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                    <img className='placeholder-img' src={placeholderImg} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-1 d-flex flex-column justify-content-center'>
                        <h6 className='bold'>Choose Image File</h6>
                        <input onChange={handleChange} id='img-choose-horizontal' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        {!imgValid && document.getElementById('img-choose-horizontal') && document.getElementById('img-choose-horizontal').value ? <small className='text-danger'>File size is more than 500KB!</small> : null}
                        <Alert className='mt-2 w-100' severity="info">Files should be smaller than 500KB. Ensure only jpg, jpeg and png files</Alert>
                    </div>
                    <div className='mt-3 col-12 d-flex justify-content-center align-items-center'>
                        <Button disabled={!imgValid} size='large' variant='contained' onClick={addImageLayoutHorizontal}>Add Image</Button>
                    </div>
                </section>
            </Dialog>



            <Dialog
                open={analyzeSEOModal}
                onClose={() => setAnalyzeSEOModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <div className='w-100 row'>
                            <div className='col-11'>
                                <h3 className='bold'>SEO Analysis of selected Categories</h3>
                                <p className='text-muted mb-0'>Analyze the count of occurence of the following keywords based on selected categories.</p>
                            </div>
                            <div className='col-1 d-flex justify-content-end'>
                                <CloseIcon onClick={() => setAnalyzeSEOModal(false)} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 w-100 d-flex justify-content-center'>
                        <div className='w-80'>
                            {
                                masterKeywords.length ?
                                    <DataGrid
                                        sx={{ borderCollapse: 'collapse', border: 'none' }}
                                        rows={frequencyData}
                                        columns={[
                                            { field: 'id', headerName: 'Keyword', flex: 1, headerClassName: 'bg-grey-3 bold' },
                                            { field: 'count', headerName: 'Count', flex: 1, headerClassName: 'bg-grey-3 bold' },
                                        ]}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 10 },
                                            },
                                        }}
                                        pageSizeOptions={[10, 20, 30, 40]}
                                        checkboxSelection={false}
                                        headerClassName="table-header"
                                    />
                                    :
                                    <Alert severity="warning">No keywords set for selected categories.</Alert>
                            }
                        </div>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={linkTutorial}
                onClose={() => setLinkTutorial(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='sm'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <div className='w-100 row'>
                            <div className='col-11'>
                                <h3 className='bold'>How to add Hyperlinks in the content</h3>
                            </div>
                            <div className='col-1 d-flex justify-content-end'>
                                <CloseIcon onClick={() => setLinkTutorial(false)} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='mb-4 w-100 d-flex justify-content-center'>
                        <div className='w-80'>
                            <p style={{ lineHeight: '2' }}>Wrap the text between placeholders <span className='a-placeholder bold'>##</span>.
                                Enter the text to be displayed and the URL separated by <span className='a-placeholder bold'>__</span>
                            </p>
                            <p>For example,<br />
                                <span className='a-placeholder'>Go to Google__www.google.com</span>
                            </p>
                        </div>
                    </div>
                </section>
            </Dialog>

            <Dialog open={openCardPattern} onClose={handlCardPatternClose} maxWidth="lg" fullWidth>
                <DialogTitle>
                    Select a Card Pattern
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handlCardPatternClose}
                        aria-label="close"
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    <div className="d-flex justify-content-between">
                        <div
                            className='card-custom-v2'
                            style={{
                                backgroundColor: cardColor?.hex || 'black',
                                border: selectedPattern === 'PatternOne' ? '2px solid blue' : 'none',
                            }}
                            onClick={() => handlePatternClick('PatternOne')}
                        >
                            <PatternOne style={{ position: 'absolute', top: 0, cursor: 'pointer' }} />
                            {selectedPattern === 'PatternOne' && (
                                <CheckCircleIcon
                                    className='check-circle'
                                />
                            )}
                        </div>
                        <div
                            className='card-custom-v2'
                            style={{
                                backgroundColor: cardColor?.hex || 'black',
                                border: selectedPattern === 'PatternTwo' ? '2px solid blue' : 'none',
                            }}
                            onClick={() => handlePatternClick('PatternTwo')}
                        >
                            <PatternTwo style={{ position: 'absolute', top: 0, cursor: 'pointer' }} />
                            {selectedPattern === 'PatternTwo' && (
                                <CheckCircleIcon
                                    className='check-circle'
                                />
                            )}
                        </div>
                        <div
                            className='card-custom-v2'
                            style={{
                                backgroundColor: cardColor?.hex || 'black',
                                border: selectedPattern === 'PatternThree' ? '2px solid blue' : 'none',
                            }}
                            onClick={() => handlePatternClick('PatternThree')}
                        >
                            <PatternThree style={{ position: 'absolute', top: 0, cursor: 'pointer' }} />
                            {selectedPattern === 'PatternThree' && (
                                <CheckCircleIcon
                                    className='check-circle'
                                />
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateCaseStudies;