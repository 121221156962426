import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/footer.scss';
import sciativeLogo from '../../assets/img/sciative-logo.png'
import { Nav, Navbar, Container, NavDropdown, Button } from 'react-bootstrap';
import handles from '../../data/socialMediaHandles';
import AllRoutes from '../../AllRoutes';
import XIcon from '@mui/icons-material/X';
import contentPolicy from '../../assets/files/content_policy.pdf';

const Footer = () => {

    const handleClick = () => {
        window.open(contentPolicy, '_blank');
    }

    return (
        <>
            <footer style={{fontSize: '0.9em'}} className='footer-div'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-4 logo_div'>
                        <h3 className='footer-heads'>Sciative HQ</h3>
                            <ul className='address-div'>
                                <li className='end'><i className="fa-regular fa-envelope"></i><a href="mailto:info@sciative.com">info@sciative.com</a></li>
                                {/* <li className='end'><i className="fa fa-phone"></i><a href="tel:+918369327884">+91 8369327884</a></li> */}
                                <li className='start'><i class="fa-solid fa-location-dot"></i><p>1201, 12th Floor, Rupa Sapphire, Plot No. 12, Sector 18, Vashi, Navi Mumbai – 400703, MH, India.</p></li>


                            </ul>

                        </div>
                        {/* col-6 end */}
                        <div className='col-md-12 col-lg-8 row nav_2'>
                            <div className='col-md-3 col-lg-5'>
                                <h3 className='footer-heads'>Want to Explore Our Products?</h3>
                                <ul>
                                    <li>Fill out the <a className='bold' href='/contact/'>contact form</a> or call us at - (+91) 8369327884</li>
                                </ul>
                                <ul style={{fontSize: '1.2em'}} className='social_div'>
                                    <li><a href={`//${handles['facebook']}`} target="_blank" title="Follow us on Facebook"><i className="fab fa-facebook"></i></a></li>
                                    <li><a href={`//${handles['instagram']}`} target="_blank" title="Follow us on Instagram"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href={`//${handles['linkedin']}`} target="_blank" title="Follow us on LinkedIn"><i className="fab fa-linkedin"></i></a></li>
                                    <li><a href={`//${handles['youtube']}`} target="_blank" title="Follow us on Youtube"><i className="fab fa-youtube"></i></a></li>
                                    <li><a href={`//${handles['x']}`} target="_blank" title="Follow us on Twitter"><XIcon sx={{fontSize:'1em'}}/></a></li>
                                    <li><a href={`//${handles['spotify']}`} target="_blank" title="Follow us on Spotify"><i className="fab fa-spotify"></i></a></li>
                                </ul>
                            </div>

                            <div className='col-md-3 col-lg-2'>
                                <h3 className='footer-heads'>Products</h3>
                                <ul>
                                    <li><a href={AllRoutes.retail}>Brio</a></li>
                                    <li><a href={AllRoutes.travel}>Viaje</a></li>
                                    <li><a href={AllRoutes.hotel}>Zettaprice</a></li>
                                </ul>

                            </div>

                            <div className='col-md-3 col-lg-2'>
                                <h3 className='footer-heads'>Explore</h3>
                                <ul>
                                    <li><a className='text-white' href={AllRoutes.about}>About Us</a></li>
                                    <li><a className='text-white' href={AllRoutes.careers}>Careers</a></li>
                                    <li><a className='text-white' href={AllRoutes.contact}>Contact Us</a></li>
                                    <li><a className='text-white' href={AllRoutes.roadaheadtalks}>Road Ahead Talks</a></li>
                                    <li><a className='text-white' href={AllRoutes.researcharticles}>Research Articles</a></li>
                                    <li><a className='text-white' href={AllRoutes.casestudies}>Case Studies</a></li>
                                </ul>
                            </div>

                            <div className='col-md-3 col-lg-3'>
                                <h3 className='footer-heads'>Legal</h3>
                                <ul>
                                    <li><a className='text-white' href={AllRoutes.terms}>Terms & Condition</a></li>
                                    <li><a className='text-white' href={AllRoutes.privacy}>Privacy Policy</a></li>
                                    <li><a className='text-white' href={AllRoutes.cookie}>Cookie Policy</a></li>
                                    <li><a className='text-white' onClick={handleClick}>Content Policy</a></li>

                                </ul>
                            </div>
                        </div>
                        {/* col-6 end */}
                        <div className='col-md-6 social_div_1 d-none d-md-block'>
                            {/* <p>Take charge of your pricing and stay ahead with advanced data analytics, pricing optimization, and real-time market insights.</p> */}
                            {/* <ul className='social_div'>
                                <li className='medium-2'><a target="_blank" className='text-white' href={`//${handles['facebook']}`} title="Follow on Facebook"><i className="fab fa-facebook"></i></a></li>
                                <li className='medium-2'><a target="_blank" className='text-white' href={`//${handles['instagram']}`} title="Follow on Intsagram"><i className="fab fa-instagram"></i></a></li>
                                <li className='medium-2'><a target="_blank" className='text-white' href={`//${handles['linkedin']}`} title="Follow on LinkedIn"><i className="fab fa-linkedin"></i></a></li>
                                <li className='medium-2'><a target="_blank" className='text-white' href={`//${handles['youtube']}`} title="Follow on Youtube"><i className="fab fa-youtube"></i></a></li>
                                <li className='medium-2'><a target="_blank" className='text-white' href={`//${handles['x']}`} title="Follow on Twitter"><i className="fab fa-twitter"></i></a></li>
                                <li className='medium-2'><a target="_blank" className='text-white' href={`//${handles['spotify']}`} title="Follow on Spotify"><i className="fab fa-spotify"></i></a></li>
                            </ul> */}
                        </div>
                        {/* col-6 end */}
                        {/* <div className='col-md-6 col-xl-4 offset-xl-2 form_container'>
                    <h3 className='footer-heads'>Subscribe to our Articles</h3>
                        <div className=' row'>
                            <div className='col-7  col-lg-8'>
                                <input type="email" id="email" placeholder='Enter your email'/>
                                
                            </div>
                            <div className='col-5  col-lg-4 p-0'><Button className="yellow-button">Subscribe</Button></div>
                        </div>
                  </div> */}
                        {/* col-4 end */}
                        <div className='col-md-12 copyright_div'>
                            <div className='row'>
                                <div className='col-md-12 justify-content-center align-items-center d-flex'>
                                <a className='mx-3' href="/">
                                <img style={{height: '2em', width: 'auto'}} src={sciativeLogo} alt="footer-logo"/>
                            </a>
                                    <p className='mb-0 text-center bold'>|</p>
                                    <p className='mx-3 mb-0 text-center'>
                                        Copyright &copy; {new Date().getFullYear()} <a href={AllRoutes.home}>Sciative Solutions</a> . All rights reserved.
                                    </p>
                                </div>
                                {/* <div className='terms_div col-md-6'>
                                <ul>
                                    <li><a>Terms & Condition</a></li>
                                    <li><a>Privacy Policy</a></li>
                                </ul>
                            </div> */}
                            </div>
                        </div>
                        {/* col-12 end */}
                    </div>
                </div>
            </footer>
        </>
    );
}


export default Footer;