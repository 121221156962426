import img10 from "../../assets/img/retail/automated-pricing/10.jpg";
import img11 from "../../assets/img/retail/automated-pricing/11.jpg";
import img12 from "../../assets/img/retail/automated-pricing/12.jpg";
import { Tab, Col, Nav, Row } from "react-bootstrap";
const SalesInventoryTab = () => {
   
    return(
        <Tab.Container defaultActiveKey="sfirst">
        <Row>
        <Col xl={5}>
            <Nav variant="pills" className="flex-column">
            <Nav.Item>
                <Nav.Link eventKey="sfirst">Marketplace Inventory Management
                 <p>BRIO streamlines marketplace inventory management with centralized control, real-time syncing, advanced forecasting, and seamless order integration. With our robust reporting & insights, BRIO optimizes inventory levels and order fulfillment for enhanced efficiency and growth.</p>
                 <div className="investor_edge_div res_img">
                    <img src={img10} alt="Marketplace Inventory Management Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="ssecond">Cross Channel Sales Analytics
                <p>By aggregating data from various sales platforms, BRIO provides real-time insights to optimize marketing strategies and maximize revenue. With customizable dashboards and predictive analytics, BRIO propels businesses to drive growth effectively.</p>
                <div className="investor_edge_div res_img">
                    <img src={img10} alt="Cross Channel Sales Analytics Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="sthird">Inventory Forecasting 
                <p>BRIO uses advanced algorithms to predict future inventory needs based on historical sales data, seasonal trends, and market dynamics, ensuring optimal stock levels and efficient demand fulfillment.</p>
                <div className="investor_edge_div res_img">
                    <img src={img10} alt="Inventory Forecasting Image" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            </Nav>
        </Col>
        <Col xl={7} className="second_div">
            <Tab.Content>
            <Tab.Pane eventKey="sfirst">
                <div className="investor_edge_div">
                <img src={img10} alt="Marketplace Inventory Management" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="ssecond">
            <div className="investor_edge_div">
                <img src={img11} alt="Cross Channel Sales Analytics" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="sthird">
            <div className="investor_edge_div">
                <img src={img12} alt="Inventory Forecasting" />
                </div>
            </Tab.Pane>
            </Tab.Content>
        </Col>
        </Row>
</Tab.Container>
    )

}

export default SalesInventoryTab