import ContactTemplate from "../common/contact";
import '../../assets/css/main.scss';
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
import { Helmet, HelmetProvider } from "react-helmet-async";
const ContactPage = ({ }) => {

    return (
        <>
           <HelmetProvider>
            <Helmet>
                <title>Contact Us to Know About AI Pricing Solutions | Sciative</title>
                <link rel="canonical" href={`${GlobalUrl}${AllRoutes.contact}`} />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="csrf_token" content="" />
                <meta property="type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta name='description' content="Thank you for expressing your interest in Sciative Solutions. Have Questions?, Get in touch with us ! Contact us below, VISIT US in office." />
                {/* <meta name='keywords' content="Travel, Viaje.ai, Bus, Dynamic Pricing, automate pricing,automated pricing tool,brand protection software,competitor price tracking software,demand forecasting software,discounting software" /> */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content="Contact Us to Know About AI Pricing Solutions | Sciative" />
                <meta property="og:description" content="Thank you for expressing your interest in Sciative Solutions. Have Questions?, Get in touch with us ! Contact us below, VISIT US in office." />
                <meta property="og:hashtag" content="#ai" />
                <meta content="image/*" property="og:image:type" />
              
                <meta property="og:url" content={`${GlobalUrl}${AllRoutes.contact}`} />
                <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                <meta property="og:image"  content={GlobalImg} />
                <meta property="og:type" content="website" />
            </Helmet>
            <ContactTemplate 
            title_upper = "Contact Us"
            title_lower = "No more pricing guesswork: Get expert help from Sciative."
            text = "Ready to talk AI pricing? Get a free consultation & see real client results!"
            client="all"
            />
            </HelmetProvider>
        </>
    );
}


export default ContactPage;