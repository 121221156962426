import { useEffect, useState } from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import img_1_1 from '../../assets/img/careers/smile-1-1-min.jpg';
import img_1_2 from '../../assets/img/careers/smile-1-2-min.jpg';
import img_1_3 from '../../assets/img/careers/smile-1-3-min.jpg';
import img_1_4 from '../../assets/img/careers/smile-1-4-min.jpg';
import img_1_5 from '../../assets/img/careers/smile-1-5-min.jpg';

import img_2_1 from '../../assets/img/careers/smile-2-1-min.jpg';
import img_2_2 from '../../assets/img/careers/smile-2-2-min.jpg';
import img_2_3 from '../../assets/img/careers/smile-2-3-min.jpg';
import img_2_4 from '../../assets/img/careers/smile-2-4-min.jpg';
import img_2_5 from '../../assets/img/careers/smile-2-5-min.jpg';

import img_3_1 from '../../assets/img/careers/smile-3-1-min.jpg';
import img_3_2 from '../../assets/img/careers/smile-3-2-min.jpg';
import img_3_3 from '../../assets/img/careers/smile-3-3-min.jpg';
import img_3_4 from '../../assets/img/careers/smile-3-4-min.jpg';
import img_3_5 from '../../assets/img/careers/smile-3-5-min.jpg';

import img_4_1 from '../../assets/img/careers/smile-4-1-min.jpg';
import img_4_2 from '../../assets/img/careers/smile-4-2-min.jpg';
import img_4_3 from '../../assets/img/careers/smile-4-3-min.jpg';
import img_4_4 from '../../assets/img/careers/smile-4-4-min.jpg';
import img_4_5 from '../../assets/img/careers/smile-4-5-min.jpg';
import smilevideo from '../../assets/videos/smile.mp4';

const SmilesAtSciative = () => {

    const card1Images = [img_1_1, img_1_2, img_1_3, img_1_4, img_1_5];
    const card2Images = [img_2_1, img_2_2, img_2_3, img_2_4, img_2_5];
    const card3Images = [img_3_1, img_3_2, img_3_3, img_3_4, img_3_5];
    const card4Images = [img_4_1, img_4_2, img_4_3, img_4_4, img_4_5];
    
    return (
        <>
            <section className="sciative_media" id="smiles-at-sciative" >
                <div className="container"  data-aos="new-animation">
                    <h2 className="bigHead text-center mb-5">Smiles @ Sciative</h2>
                    <div className='mt-4 row'>
                        <div className='col-sm-12 col-md-8 col-xl-6 offset-xl-1'>
                            <div className='grid-container row'>
                                <div className="col-4 h-100 p-1 slide-container">
                                    <Fade arrows={false} autoplay duration={4000} pauseOnHover={false}>
                                        {card1Images.map((img, index) => (
                                            <div style={{backgroundImage: `url(${img})`}} className='grid-item item1 w-100' key={index}>
                                                
                                            </div>
                                        ))}
                                    </Fade>
                                </div>
                                <div className="col-8 h-100 p-1 slide-container">
                                    <Fade arrows={false} autoplay duration={4000} pauseOnHover={false}>
                                        {card2Images.map((img, index) => (
                                            <div style={{backgroundImage: `url(${img})`}} className='grid-item item2 w-100' key={index}>
                                                
                                            </div>
                                        ))}
                                    </Fade>
                                </div>
                            </div>

                            <div className='grid-container-2 row'>
                                <div className="col-8 h-100 p-1 slide-container">
                                    <Fade arrows={false} autoplay duration={4000} pauseOnHover={false}>
                                        {card3Images.map((img, index) => (
                                            <div style={{backgroundImage: `url(${img})`}} className='grid-item item3 w-100' key={index}>
                                                
                                            </div>
                                        ))}
                                    </Fade>
                                </div>
                                <div className="col-4 h-100 p-1 slide-container">
                                    <Fade arrows={false} autoplay duration={4000} pauseOnHover={false}>
                                        {card4Images.map((img, index) => (
                                            <div style={{backgroundImage: `url(${img})`}} className='grid-item item4 w-100' key={index}>
                                                
                                            </div>
                                        ))}
                                    </Fade>
                                </div>

                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 '>
                        <video autoPlay loop muted playsInline>
                    <source src={smilevideo} type="video/mp4" />
                    {/* <source src="movie.ogg" type="video/ogg"> */}
                    Your browser does not support the video tag.
                </video>
                          </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SmilesAtSciative;