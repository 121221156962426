import clientimg1 from '../assets/img/clients/rupesh-patil.png';
import kkimg from '../assets/img/retail-client/kisan-konnect.png';
import yolo from '../assets/img/travel-client/yolobus.png';
import nuego from '../assets/img/travel-client/nuego.png';
import kanker from '../assets/img/travelbus-client/kankerroadways.jpg';
import execlride from '../assets/img/travelbus-client/execlride.png';
import laxmiHolidays from '../assets/img/travel-client/laxmi-holidays.png';
import samanvitravels from '../assets/img/travelbus-client/samanvitravels.png';
import jabbarTravels from '../assets/img/travel-client/jabbar-travels.png';
import tsrtc from '../assets/img/travel-client/tsrtc.png';
import essar from '../assets/img/travel-client/essar.png';
import demoimg from '../assets/img/travelbus-client/demo.png';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientTestimonial = () => {
    const data = [
        {
            "test_name": "Nidhi Nirmal",
            "test_desg": "Co Founder and Business Head",
            "test_det": "What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.",
            "test_company_img": kkimg,
            "test_company_name": "Kisan Konnect",
        },
        {
            "test_name": "Sanjay J",
            "test_desg": "CEO, Transportation/Trucking/Railroad",
            "test_det": "I liked the support of the team and its user interface (UI) the most. The model prediction is what I appreciate the most.",
            "test_company_img": yolo,
            "test_company_name": "Yolo Bus ",
        },
        {
            "test_name": "Arpit A.",
            "test_desg": "Head of Strategy and PMO, Transportation/Trucking/Railroad",
            "test_det": "Domain expertise - Ease of Use - Competition Analysis - Commitment of team",
            "test_company_img": nuego,
            "test_company_name": "Nuego ",
        },
        {
            "test_name": "Navsharan G.",
            "test_desg": "Director, Transportation/Trucking/Railroad",
            "test_det": "This takes away the need for multiple staff keeping track of data everyday. Also eliminates the possibility of Human Error.",
            "test_company_img": kanker,
            "test_company_name": " Kanker",
        },
        {
            "test_name": "Satish kumar D.",
            "test_desg": "Managing Director, Transportation/Trucking/Railroad",
            "test_det": "Dedicated team, good 👍 n taking customer requirement, best in result.",
            "test_company_img": execlride,
            "test_company_name": "Exel Rides ",
        },
        {
            "test_name": "Bharat A.",
            "test_desg": "On time service, Leisure, Travel & Tourism",
            "test_det": "They are very serious about their work and working hard to get best revenue for us.",
            "test_company_img": laxmiHolidays,
            "test_company_name": "Laxmi Holidays ",
        },
        {
            "test_name": "Subba Rama Reddy P.",
            "test_desg": "Passeneger Transport Services, Transportation/Trucking/Railroad",
            "test_det": "We have reduced the manual efforts in pricing the seats using the Dynamic pricing provided by viaje.ai. During the high demand days, We are unable to predict the occupancy and used to have low fares. But this issue is solved by the pricing algorithms using by sciative.",
            "test_company_img": samanvitravels,
            "test_company_name": "Smanavi Travels ",
        },
        {
            "test_name": "Jameel J.",
            "test_desg": "Managing Director & CEO, Leisure, Travel & Tourism",
            "test_det": "In the highly competitive market,pricing plays a vital role in getting customers at the right time.Sciative had played this role exceptionally well",
            "test_company_img": jabbarTravels,
            "test_company_name": " JAbbar Travels",
        },
        {
            "test_name": "Venka Reddy B.",
            "test_desg": "Sr Manager, Transportation/Trucking/Railroad",
            "test_det": "Their pricing algorithm is giving the best prices for our buses.",
            "test_company_img": tsrtc,
            "test_company_name": " TSRTC",
        },
        {
            "test_name": "Muthiah R.",
            "test_desg": "Pricing, Transportation/Trucking/Railroad",
            "test_det": "Ease of use . And the pricing is most accurate",
            "test_company_img": essar,
            "test_company_name": "Essar ",
        }
    ];
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: "30px" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: "30px" }}
                onClick={onClick}
            />
        );
    }
    const testimonialsettings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />

    };
    return (
        <>
            <section className="client_det">
                <div className="container" data-aos="new-animation">
                    <h2 className="bigHead mb-5">See What Our Clients Speak About Our Product</h2>

                    <Slider {...testimonialsettings}>
                        {
                            data.map((item) => (
                                <div className='card-wrapper'>
                                    <div className="card">
                                        <div className="flex_card">
                                            <div className="card_1">
                                                {/* <img src={demoimg} alt={item.test_name} /> */}
                                                <div className="client_desc">
                                                    <p className="namec">
                                                        {item.test_name}
                                                    </p>
                                                    <p className="named">
                                                        {item.test_desg}
                                                    </p>
                                                </div>
                                            </div>
                                            <img src={item.test_company_img} alt={item.test_company_name} className="kk_img" />
                                        </div>
                                        <p className="client_test">
                                            {item.test_det}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>

                </div>
            </section>
        </>
    );
}


export default ClientTestimonial;