import React, { useEffect, useState, useRef } from "react";
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { fetchToken } from "../../../Auth";
import axios from "axios";

import "../../../assets/css/road_ahead_talks/create_webinar.css"
import { adminURL } from "../../../global";

function RATManagement() {

    const [fetch, setFetch] = useState(false);
    //Privileges
    const [superAdmin, setSuperAdmin] = useState(false);
    const [createPrivilege, setCreatePrivilege] = useState(false);
    const [livePrivilege, setLivePrivilege] = useState(false);
    const [editPrivilege, setEditPrivilege] = useState(false);
    const [deletePrivilege, setDeletePrivilege] = useState(false);

    const navigate = useNavigate();

    const [ShowCreateNewModal, setShowCreateNewModal] = useState(false);

    const handleCreateNewModalShow = () => {
        setShowCreateNewModal(!ShowCreateNewModal);
        navigate(`/niyantran-panel/${adminURL}/create-webinar`)
    }

    const [rows, setRows] = React.useState([]);

    const handleEdit = (id) => {
        navigate(`/niyantran-panel/${adminURL}/edit-webinar/${id}`);
    }

    const handleSwitchChange = async (id, isActiveState, unique_id) => {
        const stateData = {
            unique_id: unique_id,
            isActiveState: !isActiveState
        }
        try {
            const response = await axios.put(`/ra_talks/update_isActiveState/${unique_id}`, stateData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                window.location.reload()
                // const updatedRows = rows.map(row =>
                //     row.id == unique_id ? { ...row, isActive: !isActiveState } : row
                // );
                // setRows(updatedRows);
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    const columns = useRef([])

    const getPrivileges = async () => {
        try {
            const response = await axios.get(`/get_privileges`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                let privileges = response.data['roadAhead']
                if (privileges['create']) { setCreatePrivilege(true) };
                if (privileges['makeLive']) { setLivePrivilege(true) };
                if (privileges['edit']) { setEditPrivilege(true) };
                if (privileges['delete']) { setDeletePrivilege(true) };
                if (response.data['superAdmin']) { setSuperAdmin(true) };
                setFetch(true);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate(`/niyantran-panel/${adminURL}/login`)
            }
        }
    }

    function getRowId(row) {
        return row.pageURL;
    }

    const getTalks = async () => {
        try {
            const response = await axios.get(`/ra_talks/get_all_talks`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                let cols = [
                    { field: 'id', headerName: 'Sr. no.', type: 'number', align: 'center', flex: 1, headerClassName: 'bg-grey-3 bold', },
                    { field: 'title', headerName: 'Title', type: 'string', flex: 1, headerClassName: 'bg-grey-3 bold', },
                    {
                        field: 'state',
                        headerName: 'State',
                        type: 'string',
                        flex: 1, headerClassName: 'bg-grey-3 bold',
                        valueGetter: (data) => {
                            return data.value.charAt(0).toUpperCase() + data.value.slice(1)
                        },
                    },
                    {
                        field: 'publish_date',
                        headerName: 'Publish Date',
                        type: 'string',
                        flex: 1, headerClassName: 'bg-grey-3 bold',
                        valueGetter: (data) => {
                            if (data.value === '') return '';
                            let parts = data.value.split('-');
                            let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                            return formattedDate;
                        },
                        width: 120
                    },
                    { field: 'pageURL', headerName: 'Page URL', type: 'string', width: 180, flex: 1, headerClassName: 'bg-grey-3 bold', },
                    {
                        field: 'category',
                        headerName: 'Category',
                        type: 'singleSelect',
                        flex: 1, headerClassName: 'bg-grey-3 bold',
                        valueOptions: [
                            'retail',
                            'travel-bus',
                            'travel-air'
                        ]
                    }
                ]

                if (livePrivilege) {
                    cols.push({
                        field: 'isActive',
                        headerName: 'Active',
                        filterable: false,
                        flex: 1, headerClassName: 'bg-grey-3 bold',
                        renderCell: (params) => (
                            <Switch
                                checked={params.row.isActive}
                                onChange={() => handleSwitchChange(params.id, params.row.isActive, params.row.id)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        ),
                    })
                }
                if (editPrivilege) {
                    cols.push(
                        {
                            field: 'edit',
                            type: 'actions',
                            headerName: 'Edit',
                            flex: 1, headerClassName: 'bg-grey-3 bold',
                            getActions: (params) => [
                                <GridActionsCellItem
                                    icon={<EditIcon />}
                                    label="Edit"
                                    onClick={() => handleEdit(params.row.pageURL)}
                                />
                            ],
                        }
                    )
                }
                cols.push({
                    field: 'view',
                    type: 'actions',
                    headerName: 'URL',
                    flex: 1, headerClassName: 'bg-grey-3 bold',
                    getActions: (params) => {
                        if (params.row.isActive && params.row.state === 'publish') {
                            return [
                                <GridActionsCellItem
                                    icon={<OpenInNewIcon />}
                                    label="View"
                                    onClick={() => window.open(`/road-ahead-talks/${params.row.pageURL}`, '_blank')}
                                />
                            ]
                        }
                        else {
                            return []
                        }
                    }
                })
                columns.current = cols
                setRows(response.data);
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    useEffect(() => {
        getPrivileges();
    }, [])

    useEffect(() => {
        if (fetch) getTalks();
    }, [fetch])

    const constructSentence = () => {
        let sentence = 'You have';
        if (createPrivilege) {
            sentence += ' create, ';
        }
        if (livePrivilege) {
            sentence += ' make live, '
        }
        if (editPrivilege) {
            sentence += ' edit ';
        }
        // if (deletePrivilege) {
        //   sentence += ' delete ';
        // }
        sentence += ' access for Road Ahead Talks';
        // let lastCommaIndex = sentence.lastIndexOf(",");
        // if (lastCommaIndex !== -1) {
        //   sentence = sentence.slice(0, lastCommaIndex) + " &" + sentence.slice(lastCommaIndex + 1);
        // }
        return sentence;
    };

    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'></div>
                    <div className='col-8'>
                        <h1 className='bold '>Road Ahead Talks Admin</h1>
                        <p className='medium-3 text-muted'>Create and Manage Webinars</p>
                    </div>
                    <div className='col-3 d-flex flex-column justify-content-center align-items-center'>
                        {
                            createPrivilege ?
                                <Button variant="contained" size='large' onClick={handleCreateNewModalShow}>Create New Webinar</Button>
                                :
                                <div class="alert alert-warning" role="alert">
                                    You do not have create access.
                                </div>
                        }
                    </div>
                </div>

                <div className="admin-card corner shadow-2 row p-2 mt-4">
                    <div className='col-sm-12 col-md-6 d-flex justify-content-center align-items-center'></div>
                    <div className='col-sm-12 col-md-6 d-flex justify-content-center align-items-center'>
                        {
                            !createPrivilege && !livePrivilege && !editPrivilege && !deletePrivilege ?
                                <div className="w-100 alert alert-danger" role="alert">
                                    <p className='mb-0 bold'>Access Rights</p>
                                    <p className='mb-0 thin'>You do not have any privileges for managing Road Ahead Talks.</p>
                                </div>
                                :
                                <div className="w-100 alert alert-info" role="alert">
                                    <p className='mb-0 bold'>Access Rights</p>
                                    <p className='mb-0 thin'>{constructSentence()}</p>
                                </div>
                        }

                    </div>
                    {
                        columns.current.length && rows.length ?
                            <div>
                                <DataGrid
                                    className="w-100"
                                    sx={{ borderCollapse: 'collapse', border: 'none' }}
                                    rows={rows.map((item, index) => ({ id: index + 1, ...item }))}
                                    columns={columns.current}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 10 },
                                        },
                                    }}
                                    pageSizeOptions={[10, 20, 30, 40]}
                                    checkboxSelection={false}
                                    disableRowSelectionOnClick={true}
                                    headerClassName="table-header"
                                />
                            </div>
                            :
                            <Alert severity="info">No Webinars created.</Alert>
                    }
                </div>
            </section>
        </>
    );
}

export default RATManagement;