import { Button, Tooltip } from '@mui/material';
import Form from 'react-bootstrap/Form';
import PublishIcon from '@mui/icons-material/Publish';
import { useEffect, useState } from 'react';
import isUrl from 'is-url';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { fetchToken } from '../../../Auth';
import { adminURL } from '../../../global';

function EditExpert() {
    const navigate = useNavigate();

    const { id } = useParams();
    const [expertData, setExpertData] = useState({
        id: null,
        name: "",
        email: "",
        bio: "",
        linkedin: "",
        twitter: "",
        instagram: "",
        facebook: "",
        designation: "",
        affiliation: "",
        areas: "",
        contact: "",
        img_alt_text: ""
    });
    const [image, setImage] = useState({
        imageReader: null,
        imageFile: null
    });
    const [isImageChanged, setIsImageChanged] = useState(false);

    const getExpert = async (id) => {
        try {
            const response = await axios.get(`/ra_talks/get_expert/${id}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                const expert_data = response.data;
                setExpertData({
                    id: expert_data.id,
                    name: expert_data.name,
                    email: expert_data.email,
                    bio: expert_data.bio,
                    linkedin: expert_data.social_profiles.linkedIn,
                    twitter: expert_data.social_profiles.twitter,
                    instagram: expert_data.social_profiles.instagram,
                    facebook: expert_data.social_profiles.facebook,
                    designation: expert_data.designation,
                    affiliation: expert_data.affiliation,
                    areas: expert_data.expertise_areas.join(', '),
                    contact: expert_data.contact_info,
                    img_alt_text: expert_data.image.alt_text
                });
                setImage({ imageReader: expert_data.image.key })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.detail);
            }
            else {
                alert("Unrecognised error occurred. Please try again.");
            }
        }
    }

    useEffect(() => {
        getExpert(id);
    }, [])

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setExpertData({ ...expertData, [name]: value });
    }

    const numericalValidator = (str) => {
        const numericalRegex = /^[0-9]+$/;
        return numericalRegex.test(str);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage({ imageReader: reader.result, imageFile: file });
            };
            reader.readAsDataURL(file);
        }
        setIsImageChanged(true);
    }

    const handleCancelImage = () => {
        setImage({ imageReader: null, imageFile: null });
        document.getElementById('expert-image').value = '';
    }

    const handleSave = async () => {

        // client side validations
        const name = expertData.name.trim();
        if (name === "") {
            return alert("Expert name is required");
        }
        if (numericalValidator(name)) {
            return alert("Expert name cannot be numerical");
        }

        const email = expertData.email.trim();
        if (email === "") {
            return alert("Expert email is required");
        }
        const email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (!email_regex.test(email)) {
            return alert("Invalid expert email");
        }

        const bio = expertData.bio.trim();
        if (bio === "") {
            return alert("Expert bio is required");
        }
        if (numericalValidator(bio)) {
            return alert("Expert bio cannot be numerical");
        }

        const image_file = image.imageFile;
        if (isImageChanged && !image_file) {
            return alert("Expert image is required");
        }

        const img_alt_text = expertData.img_alt_text.trim();
        if (img_alt_text === "") {
            return alert("Alt text for image is required");
        }
        if (numericalValidator(img_alt_text)) {
            return alert("Alt text for image cannot be numerical");
        }

        const linkedin = expertData.linkedin.trim();
        if (linkedin && !isUrl(linkedin)) {
            return alert("Invalid LinkedIn profile link");
        }
        const twitter = expertData.twitter.trim();
        if (twitter && !isUrl(twitter)) {
            return alert("Invalid Twitter profile link");
        }
        const instagram = expertData.instagram.trim();
        if (instagram && !isUrl(instagram)) {
            return alert("Invalid Instagram profile link");
        }
        const facebook = expertData.facebook.trim();
        if (facebook && !isUrl(facebook)) {
            return alert("Invalid Facebook profile link");
        }

        const designation = expertData.designation.trim();
        if (designation === "") {
            return alert("Expert Designation is required");
        }
        if (numericalValidator(designation)) {
            return alert("Expert Designation cannot be numerical");
        }

        const affiliation = expertData.affiliation.trim();
        if (affiliation === "") {
            return alert("Expert Affiliation is required");
        }
        if (numericalValidator(affiliation)) {
            return alert("Expert Affiliation cannot be numerical");
        }

        const areas_list = expertData.areas.trim() !== ""
            ? expertData.areas.split(',').map(area => area.trim())
            : [];
        if (areas_list.length === 0) {
            return alert("Expertise areas are required");
        }
        const hasNumericalValue = areas_list.some(area => numericalValidator(area));

        if (hasNumericalValue) {
            return alert("Expertise areas cannot be numerical");
        }

        const contact = expertData.contact.trim();

        const postData = {
            id: expertData.id,
            name: name,
            email: email,
            bio: bio,
            img_alt_text: img_alt_text,
            linkedIn: linkedin,
            twitter: twitter,
            instagram: instagram,
            facebook: facebook,
            designation: designation,
            affiliation: affiliation,
            expertise_areas: areas_list,
            contact_info: contact
        }

        const formData = new FormData();
        isImageChanged && formData.append('expert_image', image_file);
        formData.append('expert_data', JSON.stringify(postData));

        try {
            const response = await axios.put(`/ra_talks/edit_expert/${id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                alert('Expert Updated Successfully');
                navigate(`/niyantran-panel/${adminURL}/expert-management`);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.detail);
            }
            else {
                alert("Unrecognised error occurred. Please try again.");
            }
        }
    }


    return (
        <>
            <div className="container white-bg text-center shadow p-2 rounded mt-2">
                <h1 className='fw-bolder'>Edit Expert</h1>
            </div>
            <div className="container mt-4 d-flex flex-column align-items-center gap-3">
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label className="med-font ml-1">Expert Name</Form.Label>
                        <Tooltip title="Enter the name of the expert" arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="name"
                            value={expertData.name}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                </div>
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label className="med-font ml-1">Expert Email</Form.Label>
                        <Tooltip title="Enter the email of the expert" arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="email"
                            value={expertData.email}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                </div>
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="bio">
                        <Form.Label className="med-font ml-1">Expert Bio</Form.Label>
                        <Tooltip title="Provide a brief description of the expert's background or qualifications." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="bio"
                            value={expertData.bio}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                </div>
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="expert-image">
                        <Form.Label className="med-font ml-1">Expert Image</Form.Label>
                        <Tooltip title="Upload an image of the expert" arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="file"
                            name="img"
                            accept="image/png, image/jpeg"
                            onChange={handleImageChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="img_alt_text">
                        <Form.Label className="med-font ml-1">Alt text for image</Form.Label>
                        <Tooltip title="Enter alternative text (alt text) for the image." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="img_alt_text"
                            value={expertData.img_alt_text}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                    {
                        image.imageReader &&
                        <div className='d-flex'>
                            <img src={image.imageReader} alt="" width={500} />
                            <Tooltip title="Remove image" arrow placement="right">
                                <CancelRoundedIcon role="button" onClick={handleCancelImage} />
                            </Tooltip>
                        </div>
                    }
                </div>
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="linkedin">
                        <Form.Label className="med-font ml-1">Expert LinkedIn Profile</Form.Label>
                        <Tooltip title="Enter the LinkedIn profile URL of the expert." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="linkedin"
                            value={expertData.linkedin}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="twitter">
                        <Form.Label className="med-font ml-1">Expert Twitter Profile</Form.Label>
                        <Tooltip title="Enter the Twitter profile URL of the expert." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="twitter"
                            value={expertData.twitter}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="instagram">
                        <Form.Label className="med-font ml-1">Expert Instagram Profile</Form.Label>
                        <Tooltip title="Enter the Instagram profile URL of the expert." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="instagram"
                            value={expertData.instagram}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="facebook">
                        <Form.Label className="med-font ml-1">Expert Facebook Profile</Form.Label>
                        <Tooltip title="Enter the Facebook profile URL of the expert." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="facebook"
                            value={expertData.facebook}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                </div>
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="designation">
                        <Form.Label className="med-font ml-1">Expert Designation</Form.Label>
                        <Tooltip title="Enter the official title or position of the expert." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="designation"
                            value={expertData.designation}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                </div>
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="affiliation">
                        <Form.Label className="med-font ml-1">Expert Affiliation</Form.Label>
                        <Tooltip title="Specify the organization or institution the expert is associated with." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="affiliation"
                            value={expertData.affiliation}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                </div>
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="areas">
                        <Form.Label className="med-font ml-1">Expertise Areas</Form.Label>
                        <Tooltip title="Enter the specific areas of expertise separated by commas." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="areas"
                            value={expertData.areas}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                </div>
                <div className="white-bg rounded py-2 px-3 shadow-sm w-80">
                    <Form.Group className="mb-3" controlId="contact">
                        <Form.Label className="med-font ml-1">Contact Info</Form.Label>
                        <Tooltip title="Provide the contact details of the expert." arrow placement="right">
                            <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                        </Tooltip>
                        <Form.Control
                            type="text"
                            name="contact"
                            value={expertData.contact}
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                </div>
                <div className="grey-blue-bg rounded p-4 shadow-sm w-80 text-center">
                    <Button
                        variant="contained"
                        startIcon={<PublishIcon />}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>
    );
}

export default EditExpert;