import { useEffect, useState } from 'react';
import ContactTemplate from "../common/contact";
import '../../assets/css/main.scss';

import { HelmetProvider, Helmet } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
import {toast, ToastContainer } from 'react-toastify';
import toast_config from "../../toast_config";
import Form from '../Forms/Form';

import sciativeLogo from '../../assets/img/sciative.png';
import greenicon from '../../assets/img/green-icon.png';
import redicon from '../../assets/img/red-icon.png';
import uparrow from '../../assets/img/up-arrow-green.png';
import Accordion from 'react-bootstrap/Accordion';
const AustraliaLandingPage = ({ }) => {
  
  const formProps = {
    template: 6,
    form_name: 'book-a-demo-page-form',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'companySize', required: false },
      { name: 'companyUrl', required: false },
      { name: 'message', required: false },
    ],
    recipients_group_id: 6,
    country: "AU",
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
      }
    }
  }
  var features = [
    {
      title: "Product URLs",
      desc: "Unique product links (of client and competition) for which data is extracted.",
      
      plan: 3,
      plan_value: "500 URLs"
    },
    {
      title: "Number of sites/competitors",
      desc: "The total number of competitor websites or marketplaces being monitored.",
      
      plan: 3,
      plan_value: "5"
    },
    {
      title: "Historical price charts",
      desc: "Dashboards visualizing and tracking price changes over time.",
      
      plan: 3,
      plan_value: "3 months"
    },
    {
      title: "Product Variant Price Tracking",
      desc: "Monitoring prices of different product variations (e.g., sizes, colours).",
      plan: 2,
      plan_value: ""
    },
    {
      title: "Capturing product discounts and list prices",
      desc: "Tracks both discounted prices and original list prices.",
      plan: 2,
      plan_value: ""
    },
    {
      title: "Rating Trends",
      desc: "Monitors changes in product ratings over time.",
      plan: 2,
      plan_value: ""
    },
    {
      title: "Stock Availability Monitoring",
      desc: "Tracks whether competition products are in stock or out of stock.",
      plan: 2,
      plan_value: ""
    },

    {
      title: "Ability to capture additional data",
      desc: "Collect extra data beyond standard metrics like descriptions, titles, and reviews.",
      plan: 2,
      plan_value: ""
    },
    {
      title: "Price change alerts (instant or daily)",
      desc: "Notifications delivered to stakeholders when competitors change prices.",
      plan: 2,
      plan_value: ""
    },
    {
      title: "Powerful reporting mechanism",
      desc: "Customizable, interactive dashboards and insightful reports.",
      plan: 2,
      plan_value: ""
    },
    // {
    //   title: "Historical price charts",
    //   desc: "Dashboards visualizing and tracking price changes over time.",
    //   plan: 3,
    //   plan_value: "3 months"
    // },
    {
      title: "Price check frequency (max checks/day)",
      desc: "The number of times pricing data is collected daily.",
      plan: 2,
      plan_value: ""
    },
    {
      title: "Customizable automated reports",
      desc: "Reports tailored to user needs, delivered via email.",
      plan: 2,
      plan_value: ""
    },
    {
      title: "User accounts",
      desc: "Platform access for authorized users.",
      plan: 2,
      plan_value: ""
    },
    {
      title: "Auto-match",
      desc: "Automated matching engine for SKUs across channels.",
       plan: 2,
      plan_value: ""
    },
    {
      title: "Manual Check on Auto-match",
      desc: "One-time manual verification of automated matches for accuracy.",
       plan: 2,
      plan_value: ""
    },
    {
      title: "BRIO Dashboard for Competition Intelligence",
      desc: "Interactive dashboards delivering insights on pricing, competition, and market positioning.",
       plan: 2,
      plan_value: ""
    },
    {
      title: "Sentiment Analysis",
      desc: "Analyzes customer reviews to categorize sentiments (e.g., delivery issues, pricing concerns).",
       plan: 2,
      plan_value: ""
    },
    {
      title: "Unauthorized Sellers Tracking",
      desc: "Identifies unauthorized sellers on marketplaces and reports them.",
       plan: 2,
      plan_value: ""
    },
    {
      title: "Keyword Search Ranking on Marketplaces",
      desc: "Provides insights on keyword performance and rankings across top marketplace pages.",
       plan: 2,
      plan_value: ""
    },
    {
      title: "Automated Discount Optimization Engine",
      desc: "AI-driven tool for optimizing discount strategies.",
       plan: 1,
      plan_value: ""
    },
    {
      title: "Content Quality Score",
      desc: "Evaluates listings against parameters to improve visibility and health.",
       plan: 1,
      plan_value: ""
    },
    {
      title: "API integration and access",
      desc: "Real-time data integration through API for seamless updates between systems.",
      plan: 1,
      plan_value: ""
    },
  ]
 
    return (
        <HelmetProvider>
        <Helmet> 
      <title>Invest in Future of AI Pricing the Scientific Way | Sciative</title>
      <link rel="canonical" href={`${GlobalUrl}${AllRoutes.aus}`} />
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />

      <meta property="type" content="website" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
    
      <meta name='description' content="Invest in AI the leading edge of dynamic pricing. Drive innovation and exceptional returns. Connect us now to know more! Call/Mail  Us " />
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content="Invest in Future of AI Pricing the Scientific Way | Sciative" />
      <meta property="og:description" content="Invest in AI the leading edge of dynamic pricing. Drive innovation and exceptional returns. Connect us now to know more! Call/Mail  Us " />
     
      <meta property="og:hashtag" content="#ai" />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={`${GlobalUrl}${AllRoutes.aus}`} />
      <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
    
      <meta property="og:image"  content={GlobalImg} />
      <meta property="og:type" content="website" />
    </Helmet>
    <section className="contact_page contact_page_lp">
      <ToastContainer
        position={toast_config.position}
        autoClose={toast_config.autoClose}
        hideProgressBar={toast_config.hideProgressBar}
        closeOnClick={toast_config.closeOnClick}
        pauseOnHover={toast_config.pauseOnHover}
        draggable={toast_config.draggable}
        theme={toast_config.theme} 
      />
      <div className="container" >
        <div className="contact_section_1">
          <div className="logo_img_div">
              <a href={AllRoutes.retailStaticPage} target="_blank">
              <img src={sciativeLogo} alt="Sciative Logo" id="brand-logo" />
              </a>

          </div>
          <div className="row row-container" >
            <div className="col-md-5 text-container">
              <h1>Transform Your Retail Pricing with AI</h1>
               <h2>Start your 3-month <b>FREE TRIAL</b> <br/><br/><span>(No payment information required!)</span></h2>
               <p className='mb-0 mt-5 text-paragraph'>Monitor, compare, and optimize prices with precision—tailored for the retail industry</p>
               <div className="text-center row_img mt-7 p-3 p-xl-0">
                <img src={uparrow}  />
               </div>
              
            </div>
            <div className="form-container col-md-7 ps-lg-5">
              <Form {...formProps} />
            </div>
          </div>
        </div>
 
        <section className="lp_table">
          <div className="container" >

            
            <div className="d-none d-lg-block">
            <div className="info_h2 text-center">
              <h2 className="bigHead mb-5">
                  Freemium Features
              </h2>
            </div>
              <table>
                <thead>
                  <th>Features</th>
                  <th>Details</th>
                  <th>Freemium <span>/(3 Months)</span></th>
                </thead>
                <tbody>
                {
                  features.map((tab, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {tab.title}
                        </td>
                        <td>
                          {tab.desc}
                        </td>
                        <td className="text-center">
                          {tab.plan === 3 ? (
                            tab.plan_value
                          ) : tab.plan === 2 ? (
                            <img src={greenicon} width="20" alt="Green Icon" />
                          ) : (
                            <img src={redicon} width="20" alt="Green Icon" />
                          )}
                        </td>
                      </tr>
                    );
                  })
                  
                }
                </tbody>
              
              </table>
            </div>
           
            {/* <div className="d-none d-lg-block">
            <div className="info_h2 text-center mt-5 info_2 pt-5">
              <h2 className="bigHead mb-5">
              Additional Sciative Modules (Optional)
              </h2>
            </div>
              <table>
                <thead>
                  <th>Features</th>
                  <th>Details</th>
                  <th>Freemium <span>/(3 Months)</span></th>
                </thead>
                <tbody>
                {
                  addnfeatures.map((tab, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {tab.title}
                        </td>
                        <td>
                          {tab.desc}
                        </td>
                        <td className="text-center">
                          {tab.plan === 3 ? (
                            tab.plan_value
                          ) : tab.plan === 2 ? (
                            <img src={greenicon} width="20" alt="Green Icon" />
                          ) : (
                            <img src={redicon} width="20" alt="Green Icon" />
                          )}
                        </td>
                      </tr>
                    );
                  })
                  
                }
                </tbody>
              
              </table>
            </div> */}
            <div className=" d-lg-none">
            <h2 className="bigHead mb-3 mob-lp-head2 text-center">
              Freemium Features
            </h2>
            <p className="mob-lp-head">FREEMIUM <span>/(3 Months)</span></p>
            <Accordion >
           { features.map((tab, index) => {
                    return (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header><div className="head1">{tab.title} </div><div className="head2">   
                  {tab.plan === 3 ? (
                    tab.plan_value
                  ) : tab.plan === 2 ? (
                    <img src={greenicon} width="20" alt="Green Icon" />
                  ) : (
                    <img src={redicon} width="20" alt="Green Icon" />
                  )}</div></Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-9 col-xl-10">
                      <p>{tab.desc}

                      </p>
                    
                    
                    </div>
                
                  </div>


                </Accordion.Body>
              </Accordion.Item>
          )
          })}
            </Accordion>
            {/* <h2 className="bigHead mt-5 mb-3 mob-lp-head2 text-center">
            Additional Sciative Modules
            </h2>
            <p className="mob-lp-head">FREEMIUM <span>/(3 Months)</span></p>
            <Accordion >
           { addnfeatures.map((tab, index) => {
                    return (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header><div className="head1">{tab.title} </div><div className="head2">   
                  {tab.plan === 3 ? (
                    tab.plan_value
                  ) : tab.plan === 2 ? (
                    <img src={greenicon} width="20" alt="Green Icon" />
                  ) : (
                    <img src={redicon} width="20" alt="Green Icon" />
                  )}</div></Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-9 col-xl-10">
                      <p>{tab.desc}

                      </p>
                    
                    
                    </div>
                
                  </div>


                </Accordion.Body>
              </Accordion.Item>
          )
          })}
            </Accordion> */}
            </div>
          </div>
        </section>
      </div>
    </section>
       </HelmetProvider>
    );
}


export default AustraliaLandingPage;