import Slider from 'react-slick';
import history1 from '../../assets/img/history/history_2018.jpg'
import history2 from '../../assets/img/history/history_2019.jpg'
import history3 from '../../assets/img/history/history_2020.jpg'
import history4 from '../../assets/img/history/history_2023.jpg'
import history5 from '../../assets/img/history/history_2022.jpg'
import history6 from '../../assets/img/history/history_2021.jpg'
import history7 from '../../assets/img/history/history_2024.jpg'
const History = () => {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: "30px" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: "30px" }}
                onClick={onClick}
            />
        );
    }
    const historySetting = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
               
              }
            },
        ]
     
      };
    return(<>
    <section className="history_div">
        <div className="container" data-aos="new-animation">
             <h2 className="bigHead text-center mb-2 text-white">
                Our History
            </h2>
            <p className="text-white text-center head_main_his">Embark on a journey through Sciative's timeline, tracing the footsteps of innovation from its inception to the present day. <br />
            Each milestone represents a chapter in our history, a testament to the continuous pursuit of excellence and a commitment to shaping the future of scientific pricing.</p>
          
        </div>
             <Slider {...historySetting}>
            <div className="history_main">
                <div className="history_div_1">
                    <div className="col-md-12 slide_div_1">
                        <h3 className="history_head bigHead">
                             Viaje.ai Development for Intercity Coaches (Bus) Industry 
                        </h3>
                        
                      
                    </div>
                    {/* col-6 end */}
                </div>
                <div className="col-md-12 slide_div">
                    <div className="his_num">
                        2018
                    </div>
                    <div className="history_circle"></div>
                    <div className="his_img">
                        <img src={history1} alt="2018 Image" />
                    </div>

                </div>
            </div>
            <div className="history_main">
                <div className="history_div_1">
                    <div className="col-md-12 slide_div_1">
                        <h3 className="history_head bigHead">
                        Viaje.ai launch in the Indian Bus Market <br /> <br /> Sciative winning a National Startup Award
                        </h3>
                        
                    </div>
                    {/* col-6 end */}
                </div>
                <div className="col-md-12 slide_div">
                    <div className="his_num">
                        2019
                    </div>
                    <div className="history_circle"></div>
                    <div className="his_img">
                        <img src={history2} alt="2019 Image" />
                    </div>

                </div>
            </div>
            <div className="history_main">
                <div className="history_div_1">
                    <div className="col-md-12 slide_div_1">
                        <h3 className="history_head bigHead">
                        Onset of COVID: Development of BRIO.ai for the Retail Industry <br /><br />
                        Seed Fund Raised
                        </h3>
                       
                    </div>
                    {/* col-6 end */}
                </div>
                <div className="col-md-12 slide_div">
                    <div className="his_num">
                        2020
                    </div>
                    <div className="history_circle"></div>
                    <div className="his_img">
                        <img src={history3} alt="2020 Image" />
                    </div>

                </div>
            </div>
            {/* div end */}
            <div className="history_main">
                <div className="history_div_1">
                    <div className="col-md-12 slide_div_1">
                        <h3 className="history_head bigHead">
                        Sciative wins Entrepreneur of the Year Award  <br /><br />
                        BRIO. ai Launch in the Indian Retail Market  <br /><br />
                        (Major Clients acquired: Nykaa, Kisankonnect, Arteza)
                        </h3>
                       
                    </div>
                    {/* col-6 end */}
                </div>
                <div className="col-md-12 slide_div">
                    <div className="his_num">
                        2021
                    </div>
                    <div className="history_circle"></div>
                    <div className="his_img">
                        <img src={history6} alt="2021 Image" />
                    </div>

                </div>
            </div>
            {/* div end */}
            <div className="history_main">
                <div className="history_div_1">
                    <div className="col-md-12 slide_div_1">
                        <h3 className="history_head bigHead">
                        Airviaje.ai Development for the Airlines Industry  <br /><br />
                        Brio.ai International Expansion to USA & South Korea
                        </h3>
                       
                    </div>
                    {/* col-6 end */}
                </div>
                <div className="col-md-12 slide_div">
                    <div className="his_num">
                        2022
                    </div>
                    <div className="history_circle"></div>
                    <div className="his_img">
                        <img src={history5} alt="2022 Image" />
                    </div>

                </div>
            </div>
            {/* div end */}
            <div className="history_main">
                <div className="history_div_1">
                    <div className="col-md-12 slide_div_1">
                        <h3 className="history_head bigHead">
                        Viaje.ai (For intercity coaches) Expansion in Public Sector (TSRTC) <br /> <br />
                        AirViaje.ai (Airlines) Launch in the Indian Airlines Market
                        </h3>
                       
                    </div>
                    {/* col-6 end */}
                </div>
                <div className="col-md-12 slide_div">
                    <div className="his_num">
                        2023
                    </div>
                    <div className="history_circle"></div>
                    <div className="his_img">
                        <img src={history4} alt="2023 Image" />
                    </div>

                </div>
            </div>
            {/* div end */}
            <div className="history_main">
                <div className="history_div_1">
                    <div className="col-md-12 slide_div_1">
                        <h3 className="history_head bigHead">
                        Zetta.ai Development for Hospitality Industry <br /><br />
                        Viaje.ai (Intercity coaches) acquired 40% of Indian Premium Bus Market <br /><br />
                        AirViaje.ai International Expansion
                        </h3>
                       
                    </div>
                    {/* col-6 end */}
                </div>
                <div className="col-md-12 slide_div">
                    <div className="his_num">
                        2024
                    </div>
                    <div className="history_circle"></div>
                    <div className="his_img">
                        <img src={history7} alt="2024 Image" />
                    </div>

                </div>
            </div>
            {/* div end */}
            </Slider>
      
    </section>
    </>)

}

export default History;