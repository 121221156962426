import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../../assets/css/base.css';
import { adminURL } from "../../global";

function ViewForm() {

    const { form_name } = useParams();

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const navigate = useNavigate();

    const getFormsData = async (form_name) => {
        try {
            const response = await axios.get(`/get_individual_form_data/${form_name}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                const uniqueFields = [...new Set(response.data.flatMap(doc => Object.keys(doc)))];
                const columns = [
                    { field: 'id', headerName: 'Sr. no.', width: 150, align: 'center', headerAlign: 'center' },
                    ...uniqueFields.map(field => ({
                        field,
                        headerName: field === 'jee_percentile' || field === 'cet_percentile'
                        ? field.toUpperCase().replace("_PERCENTILE", " percentile")
                        : field.charAt(0).toUpperCase() + field.replaceAll("_", " ").slice(1),
                        width: 150,
                        align: 'center',
                        headerAlign: 'center',
                        renderCell: (params) => {
                            if (params.field === 'resume') {
                                return <a href={params.value} download>Download</a>;
                            }
                            return params.value;
                        }
                    })),
                ];
                setColumns(columns);
                const rows = response.data.map((doc, index) => {
                    const rowData = { id: index + 1 };
                    uniqueFields.forEach(field => {
                        rowData[field] = doc.hasOwnProperty(field) ? doc[field] : "NA";
                    });
                    return rowData;
                });
                setRows(rows);
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    useEffect(() => {
        getFormsData(form_name);
    }, [])

    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'>
                        <IconButton onClick={() => navigate(`/niyantran-panel/${adminURL}/forms-management`)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div className='col-10'>
                        <h1 className='bold '>View Form Data <span className="small-2">({form_name})</span></h1>
                    </div>
                </div>
                <div className="table-container white-bg shadow rounded mt-2">
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                    />
                </div>
            </section>
        </>
    );
}

export default ViewForm;