import { useState, useEffect } from 'react';
import { ProgressBar } from '@nadfri/react-scroll-progress-bar';

import '../../../assets/css/blogs.css';

const BlogsPreview = ({ author, inputs, additionalContent, categories, structure, links, editing, pageId, blogURL, mainImg, mainImgChanged }) => {
    const [topContent, setTopContent] = useState(null);

    function JSONtoHTML(json, parentElement) {
        const element = document.createElement(json.tagName);
        if ('className' in json) {
            let classes = json.className.split(' ')
            classes.forEach(cl => element.classList.add(cl))
        }

        if (json.children) {
            json.children.forEach(childJson => JSONtoHTML(childJson, element));
        } else {
            if (json.userInput) {
                if (json.key in inputs) {
                    if (json.key === 'blogTitle') {
                        element.textContent = inputs[json.key]
                        element.classList.add('blog-title-main')
                    }
                    else {
                        element.textContent = inputs[json.key]
                    }

                }
                else {
                    element.textContent = "";
                }
            }
            else if (json.takeFromState) {
                if (json.key === 'categories') {
                    let cats = categories.map(cat => cat.value).sort().map(cat => `<div class='mx-1 category-tile p-1'>${cat}</div>`)
                    element.innerHTML = cats.join('')
                }
                else if (json.key === 'authorName') {
                    element.textContent = author['authorName']
                }
                // else if(json.key === 'authorImg'){
                //     if(author['profileImg']){
                //         element.setAttribute('src', author['profileImg'])
                //     }else{
                //         element.setAttribute('src', 'https://jeffjbutler.com//wp-content/uploads/2018/01/default-user.png')
                //     }
                // }
            }
            else {
                element.textContent = json.textContent;
            }

        }
        parentElement.appendChild(element);
    }

    const populateMainImg = async (img) => {
        if (!editing) {
            var reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = function (e) {
                const styleTag = document.createElement('style');
                const cssRules = `
                .blog-title-section::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url(${e.target.result});
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    mix-blend-mode: multiply;
                    /* to blend the overlay with the image */
                }
            `;
                styleTag.innerHTML = cssRules;
                document.head.appendChild(styleTag);
            }
        } else {
            if(!mainImgChanged){
                try {
                    let type = ''
                    if(mainImg['type'] === 'image/png'){
                        type = 'png'
                    }else if(mainImg['type'] === 'image/jpeg'){
                        type = 'jpeg'
                    }else if(mainImg['type'] === 'image/png'){
                        type = 'png'
                    }
                    const response = await fetch(`/get_blog_image/blog_${pageId + '_img-main-img'}.${type}`)
                    const data = await response.json();
                    //Render featured image
                    const styleTag = document.createElement('style');
                    const cssRules = `
                    .blog-title-section::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url(${data});
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        mix-blend-mode: multiply;
                    }
                    `;
                    styleTag.innerHTML = cssRules;
                    document.head.appendChild(styleTag);
                } catch (err) {
                    console.error("Error", err);
                }
            }else{
                var reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = function (e) {
                    const styleTag = document.createElement('style');
                    const cssRules = `
                    .blog-title-section::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url(${e.target.result});
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        mix-blend-mode: multiply;
                    }
                `;
                    styleTag.innerHTML = cssRules;
                    document.head.appendChild(styleTag);
                }
            }
            
        }

    }

    useEffect(() => {
        if (author && inputs && structure) {
            document.getElementById('blog-preview').innerHTML = ''
            JSONtoHTML(structure, document.getElementById('blog-preview'))
            populateMainImg(inputs['blogMainImg'])
        }
    }, [inputs, structure, author])


    return (
        <section className='w-70 p-3'>
            <div className='admin-card'>
                <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                    <i class="fa-solid fa-circle text-white mx-1"></i>
                    <i class="fa-solid fa-circle text-white mx-1"></i>
                    <i class="fa-solid fa-circle text-white mx-1"></i>
                </div>
                <div className='p-5'>
                    <div id='blog-preview'></div>
                    <div className='w-100 row'>
                        {/* <div className='w-100 sticky-section progress-section d-flex flex-column justify-content-end'>
                            <h5 className='mx-3 bold'>Current Heading</h5>
                            <ProgressBar
                                color1="#eee"
                                color2="#00acee"
                                height="4px"
                                position="relative"
                            />
                        </div> */}
                        <div className='col-4 p-2'></div>
                        <div className='col-8 p-2'>
                            <div className='my-3 blog-content' dangerouslySetInnerHTML={{ __html: additionalContent }}></div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default BlogsPreview;