import img1 from "../../assets/img/retail/automated-pricing/pricing_strategy.png";
import img2 from "../../assets/img/retail/automated-pricing/unified_price.png";
import img3 from "../../assets/img/retail/automated-pricing/demand_forceasting.png";

import { Tab, Col, Nav, Row } from "react-bootstrap";
const AutomatedPricingTab = () => {
   
    return(
        <Tab.Container defaultActiveKey="third">
        <Row>
        <Col xl={5}>
            <Nav variant="pills" className="flex-column">
            <Nav.Item>
                <Nav.Link eventKey="third">Demand and  Price Forecasting
                <p>Utilise our data to generate accurate demand forecasts, empowering you to implement optimal retail pricing strategies with confidence. Customise pricing effortlessly with BRIO’s pricing engine which ensures precise decisions to maximise profits at unmatched speeds.</p>
                <div className="investor_edge_div res_img">
                    <img src={img3} alt="Demand and  Price Forecasting" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="second">Unified Price Management
                <p>Easily manage prices across multiple marketplaces with just one click on BRIO's pricing engine. This simplifies your pricing strategy, ensures consistency, and enhances competitiveness across the board.</p>
                <div className="investor_edge_div res_img">
                    <img src={img2} alt="Unified Price Management" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
        
            <Nav.Item>
                <Nav.Link eventKey="first">Target Driven Pricing
                    <p>Chart out your growth goals -  by BRIO’s AI algorithm: apply real time tracking and optimization to auto tweak your pricing and promotion.This method dynamically refines your strategy to market trends, boosting competitiveness and ensuring continuous growth.</p>
                    <div className="investor_edge_div res_img">
                    <img src={img1} alt="Target Driven Pricing" className=" "/>
                    </div>
                    
                </Nav.Link>
            </Nav.Item>
            </Nav>
        </Col>
        <Col xl={7}  className="second_div">
            <Tab.Content>
            <Tab.Pane eventKey="first">
                <div className="investor_edge_div">
                <img src={img1} alt="Pricing Strategy that work for you" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <div className="investor_edge_div">
                <img src={img2} alt="Unified Price Management" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
            <div className="investor_edge_div">
                <img src={img3} alt="Demand and forecasting" />
                </div>
            </Tab.Pane>
            </Tab.Content>
        </Col>
        </Row>
</Tab.Container>
    )

}

export default AutomatedPricingTab