
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientBusSlider = () => {
    // Define a function to require all images in a directory
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  // Import all images from the specified directory
  const images = importAll(require.context('../../assets/img/travel-client/', false, /\.(png|jpe?g|svg)$/));
  
  // Create an array of image paths
  const imagePaths = Object.values(images);
  
//   // Usage example:
//   console.log(imagePaths);
const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1970,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
           
          }
        },
        {
            breakpoint: 1023,
            settings: {
              slidesToShow: 5,
             
            }
          },
    ]
  };
      return (
            <div className="retail_slider">
                <div className="inner_ret_slider">
                    <h2 className="bigHead text-center mb-3">Brands who Leverage Sciative to Stay Ahead</h2>
                    <Slider {...settings}>
                        {imagePaths.map((item, index) => (
                            <div key={index} className="slider_card">
                            
                            <img src={item}  alt="client-logo"/>
                            </div>
                        ))}
                        </Slider>
                </div>
            </div>
        );
    }
    
    
    export default ClientBusSlider;