// import React from 'react'
// import { Tab, Col, Nav, Row } from "react-bootstrap";
// import { Helmet, HelmetProvider } from "react-helmet-async";
// import { GlobalUrl, GlobalImg } from "../../global";
// import '../../assets/css/main.scss';

// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import { useState,useEffect } from 'react';



// import Box from "@mui/material/Box";
// import hotelImg1 from '../../assets/img/hotel-customer/hotel-1.png';
// import hotelImg2 from '../../assets/img/hotel-customer/hotel-2.png';
// import hotelImg3 from '../../assets/img/hotel-customer/hotel-3.png';
// import hotelImg4 from '../../assets/img/hotel-customer/hotel-4.png';
// import hotelImg5 from '../../assets/img/hotel-customer/hotel-5.png';
// import hotelImg6 from '../../assets/img/hotel-customer/hotel-6.png';
// import hotelImg7 from '../../assets/img/hotel-customer/hotel-7.png';


// export const hotelCustomCard = ({details}) => {
//     return (

//         <>
//             <Box className="gallery" sx={{
//                 display: "flex",
//                 position: "relative",
//                 zIndex: 10,
//             }}>
//                 <Box className="h-customer-left-container" sx={{
//                     width: "40%",
//                     marginTop: '10em',
//                     marginLeft: "auto",
//                     "& .h-customer-left": {
//                         height: "30vh",
//                         display: "flex",
//                         flexDirection: "column",
//                         justifyContent: "center",
//                         width: "100%",
//                         marginLeft: "auto",
//                         color: "#000",
//                         fontSize: "1rem",
//                         fontWeight: 900,
//                     }
//                 }}>
//                     {details && (
//                         <>
//                             {details.map((ele, index) => (
//                                 <Box
//                                     key={index}
//                                     className={`h-customer-left ${0 === index ? 'showcase-slider-item-active' : ''}`}
//                                 >
//                                     <div className="showcase-slider">
//                                         <h4 className="header-2">{ele.title}</h4>
//                                         <p className="u-h5">
//                                             {ele.description}
//                                         </p>
//                                     </div>
//                                 </Box>
//                             ))}
//                         </>
//                     )}
//                 </Box>
//                 <Box className="rightblock" sx={{
//                     width: "60%",
//                     height: "70vh",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: 'center'
//                 }}>
//                     <Box sx={{
//                         height: '80%',
//                         width: '80%',
//                         position: "relative",
//                         display: 'flex',
//                         justifyContent: 'center',
//                         "& .h-customer-right": {
//                             position: "absolute",
//                             width: "100%",
//                             height: "100%",
//                             "& img": {
//                                 // height: "100%",

//                                 height: "auto",
//                                 width: "100%",
//                                 objectFit: "auto",
//                             }
//                         }
//                     }}>
//                         {details &&
//                             <>
//                                 {details.map((ele, index) => (
//                                     <Box key={index} className='h-customer-right'>
//                                         <img
//                                             alt={ele.title}
//                                             src={ele['img-id']}
//                                         />
//                                     </Box>
//                                 ))}

//                             </>}
//                     </Box>
//                 </Box>
//             </Box>
//         </>
//     )
// }

// export default hotelCustomCard


// import React from 'react';
// import { Box } from "@mui/material";
// import '../../assets/css/main.scss';
// import hotelImg1 from '../../assets/img/hotel-customer/hotel-1.png';
// import hotelImg2 from '../../assets/img/hotel-customer/hotel-2.png';
// import hotelImg3 from '../../assets/img/hotel-customer/hotel-3.png';
// import hotelImg4 from '../../assets/img/hotel-customer/hotel-4.png';
// import hotelImg5 from '../../assets/img/hotel-customer/hotel-5.png';
// import hotelImg6 from '../../assets/img/hotel-customer/hotel-6.png';
// import hotelImg7 from '../../assets/img/hotel-customer/hotel-7.png';

// export const HotelCustomCard = ({ details }) => {
//     return (
//         <Box className="gallery" sx={{
//             display: "flex",
//             flexDirection: { xs: "column", md: "row" }, // Column for mobile, row for larger screens
//             position: "relative",
//             zIndex: 10,
//         }}>
//             <Box className="rightblock" sx={{
//                 width: { xs: "100%", md: "60%" }, // Full width on mobile, 60% on larger screens
//                 height: { xs: "auto", md: "70vh" }, // Auto height on mobile, 70vh on larger screens
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 alignItems: 'center',
//                 order: { xs: 1, md: 2 }, // Order 1 on mobile (top), 2 on larger screens (right)
//             }}>
//                 <Box sx={{
//                     height: { xs: 'auto', md: '80%' }, // Auto height on mobile
//                     width: '80%',
//                     position: "relative",
//                     display: 'flex',
//                     justifyContent: 'center',
//                     "& .h-customer-right": {
//                         position: "absolute",
//                         width: "100%",
//                         height: "100%",
//                         "& img": {
//                             height: "auto",
//                             width: "100%",
//                             objectFit: "cover",
//                         }
//                     }
//                 }}>
//                     {details &&
//                         <>
//                             {details.map((ele, index) => (
//                                 <Box key={index} className='h-customer-right'>
//                                     <img alt={ele.title} src={ele['img-id']} />
//                                 </Box>
//                             ))}
//                         </>
//                     }
//                 </Box>
//             </Box>

//             <Box className="h-customer-left-container" sx={{
//                 width: { xs: "100%", md: "40%" }, 
//                 marginTop: { xs: '15em', md: '10em' }, 
//                 marginLeft: { xs: "0", md: "auto" }, 
//                 order: { xs: 2, md: 1 }, 
//                 "& .h-customer-left": {
//                     height: { xs: "auto", md: "30vh" }, 
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     width: "100%",
//                     color: "#000",
//                     fontSize: "1rem",
//                     fontWeight: 900,
//                     overflow:'hidden'
//                 }
//             }}>
//                 {details && (
//                     <>
//                         {details.map((ele, index) => (
//                             <Box key={index} className={`h-customer-left ${0 === index ? 'showcase-slider-item-active' : ''}`}>
//                                 <div className="showcase-slider">
//                                     <h4 className="header-2">{ele.title}</h4>
//                                     <p className="u-h5">
//                                         {ele.description}
//                                     </p>
//                                 </div>
//                             </Box>
//                         ))}
//                     </>
//                 )}
//             </Box>
//         </Box>
//     )
// }

// export default HotelCustomCard;

import React from 'react';
import { Box } from "@mui/material";
import '../../assets/css/main.scss';
export const HotelCustomCard = ({ details, isMobileScreen }) => {
    console.log(isMobileScreen, 'this is mobile screen')
    return (
        <>
            {isMobileScreen && (
                // Mobile View
                <>
                    {details && details.map((ele, index) => (
                        <Box key={index} className='mobile-card-customer' sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "1.5em", 
                            width: "100%",
                        }}>
                            <Box className='h-customer-right' sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                mb: 2,
                                "& img": {
                                    height: "auto",
                                    width: "100%",
                                    objectFit: "cover",
                                }
                            }}>
                                <img alt={ele.title} src={ele['img-id']} />
                            </Box>
                            <Box className={`h-customer-left showcase-slider-item-active`} sx={{
                                width: '100%',
                                textAlign: "center",
                                "& .showcase-slider": {
                                    fontSize: "1rem",
                                    fontWeight: 900,
                                    color: "#000"
                                }
                            }}>
                                <div className="showcase-slider">
                                    <h4 className="header-2">{ele.title}</h4>
                                    <p className="u-h5">{ele.description}</p>
                                </div>
                            </Box>
                        </Box>
                    ))}
                    </>
)}
             
            {!isMobileScreen &&
                (
                    <>
                        <Box className="gallery" sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" }, // Column for mobile, row for larger screens
                            position: "relative",
                            zIndex: 10,
                        }}>
                            <Box className="rightblock" sx={{
                                width: { xs: "100%", md: "60%" }, // Full width on mobile, 60% on larger screens
                                height: { xs: "auto", md: "70vh" }, // Auto height on mobile, 70vh on larger screens
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: 'center',
                                order: { xs: 1, md: 2 }, // Order 1 on mobile (top), 2 on larger screens (right)
                            }}>
                                <Box sx={{
                                    height: { xs: 'auto', md: '80%' }, // Auto height on mobile
                                    width: '80%',
                                    position: "relative",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    "& .h-customer-right": {
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        "& img": {
                                            height: "auto",
                                            width: "100%",
                                            objectFit: "cover",
                                        }
                                    }
                                }}>
                                    {details &&
                                        <>
                                            {details.map((ele, index) => (
                                                <Box key={index} className='h-customer-right'>
                                                    <img alt={ele.title} src={ele['img-id']} />
                                                </Box>
                                            ))}
                                        </>
                                    }
                                </Box>
                            </Box>

                            <Box className="h-customer-left-container" sx={{
                                width: { xs: "100%", md: "40%" },
                                marginTop: { xs: '15em', md: '10em' },
                                marginLeft: { xs: "0", md: "auto" },
                                order: { xs: 2, md: 1 },
                                "& .h-customer-left": {
                                    height: { xs: "auto", md: "30vh" },
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                    color: "#000",
                                    fontSize: "1rem",
                                    fontWeight: 900,
                                    overflow: 'hidden'
                                }
                            }}>
                                {details && (
                                    <>
                                        {details.map((ele, index) => (
                                            <Box key={index} className={`h-customer-left ${0 === index ? 'showcase-slider-item-active' : ''}`}>
                                                <div className="showcase-slider">
                                                    <h4 className="header-2">{ele.title}</h4>
                                                    <p className="u-h5">
                                                        {ele.description}
                                                    </p>
                                                </div>
                                            </Box>
                                        ))}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </>
                )
            }
        </>
    );
}
export default HotelCustomCard;