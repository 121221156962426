
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from 'react';
import sciativeLogo from '../../assets/img/sciative.png';
import { Button } from 'react-bootstrap';
import aretza from "../../assets/img/retail-client/arteza.png";
import avis from "../../assets/img/retail-client/avis.png";
import clubmahindra from "../../assets/img/retail-client/club-mahindra.png";
import coupang from "../../assets/img/retail-client/coupang.png";
import croma from "../../assets/img/retail-client/croma.png";
import kisankonnect from "../../assets/img/retail-client/kisan-konnect.png";
import licious from "../../assets/img/retail-client/licious.png";
import logitech from "../../assets/img/retail-client/logitech.png";
import nykaaf from "../../assets/img/retail-client/nykaa-fashions.png";
import nykaa from "../../assets/img/retail-client/nykaa.png";
import shoppers from "../../assets/img/retail-client/shoppers-stop.png";
import star from "../../assets/img/retail-client/star.png";
import vijaysales from "../../assets/img/retail-client/vijay-sales.png";
import wonderchef from "../../assets/img/retail-client/wonderchef.png";

import essar from "../../assets/img/travel-client/essar.png";
import gotour from "../../assets/img/travel-client/gotour.png";
import jabbartravels from "../../assets/img/travel-client/jabbar-travels.png";
import kanker from "../../assets/img/travel-client/kanker.png";
import laxmiholidays from "../../assets/img/travel-client/laxmi-holidays.png";
import limoliner from "../../assets/img/travel-client/limoliner.png";
import neetabus from "../../assets/img/travel-client/neetabus.png";
import nuego from "../../assets/img/travel-client/nuego.png";
import rajeshtransport from "../../assets/img/travel-client/rajesh-transport.png";
import rajkalpana from "../../assets/img/travel-client/rajkalpana.png";
import shyamoli from "../../assets/img/travel-client/shyamoli.png";
import spsluxury from "../../assets/img/travel-client/sps-luxury.png";
import svkdt from "../../assets/img/travel-client/svkdt.png";
import tsrtc from "../../assets/img/travel-client/tsrtc.png";
import vigneshtravels from "../../assets/img/travel-client/vignesh-tat.png";
import yolobus from "../../assets/img/travel-client/yolobus.png";
import CapteraAchievements from '../travel/capterra-achievvement';
import Form from '../Forms/Form';
import {toast, ToastContainer } from 'react-toastify';
import toast_config from "../../toast_config";
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const ContactTemplate = ({ title_upper, title_lower, text, client, country }) => {
  const [slidername, setSlidername] = useState([])
  const retail_client = [
    {
      "image": avis,
      "alttext": "Avis rent a Car"
    },
    {
      "image": aretza,
      "alttext": "Arteza"
    },
    {
      "image": coupang,
      "alttext": "Coupang"
    },
    {
      "image": logitech,
      "alttext": "Logitech"
    },
    {
      "image": clubmahindra,
      "alttext": "Club Mahindra"
    },
    {
      "image": star,
      "alttext": "Star"
    },
    {
      "image": nykaaf,
      "alttext": "Nykaa Fashions"
    },
    {
      "image": shoppers,
      "alttext": "Shoppers Stop"
    },
    {
      "image": licious,
      "alttext": "Licious"
    },
    {
      "image": croma,
      "alttext": "Croma"
    },
    {
      "image": vijaysales,
      "alttext": "Vijay Sales"
    },
    {
      "image": nykaa,
      "alttext": "Nykaa"
    },
    {
      "image": kisankonnect,
      "alttext": "Kisan Konnect"
    },
    {
      "image": wonderchef,
      "alttext": "WonderChef"
    },


  ];
  const travel_client = [
    {
      "image": nuego,
      "alttext": "Nuego"
    },
    {
      "image": yolobus,
      "alttext": "YoloBus"
    },
    {
      "image": tsrtc,
      "alttext": "TSRTC"
    },
    {
      "image": laxmiholidays,
      "alttext": "Laxmi Holidays"
    },
    {
      "image": jabbartravels,
      "alttext": "Jabbar Travels"
    },
    {
      "image": neetabus,
      "alttext": "Neeta Bus"
    },
    {
      "image": essar,
      "alttext": "Essar"
    },
    {
      "image": shyamoli,
      "alttext": "Shyamoli"
    },
    {
      "image": spsluxury,
      "alttext": "SPS Luxury Travels"
    },
    {
      "image": kanker,
      "alttext": "Kanker Roadways"
    },
    {
      "image": rajeshtransport,
      "alttext": "Rajesh Transport"
    },
    {
      "image": rajkalpana,
      "alttext": "Raj Kalpana"
    },
    {
      "image": gotour,
      "alttext": "GoTour"
    },
    {
      "image": svkdt,
      "alttext": "SVKDT"
    },
    {
      "image": vigneshtravels,
      "alttext": "Vignesh TAT"
    },
    {
      "image": limoliner,
      "alttext": "Limoliner"
    },


  ];

  useEffect(() => {

    if (client == "retail_client") {
      setSlidername([...retail_client]);
    }
    else if (client == "travel_client") {
      setSlidername([...travel_client])
    }
    else if (client == "all") {
      setSlidername([...retail_client, ...travel_client])
    }
    else if (client == "hotel") {
      setSlidername("")
    }
    // console.log("propsdlider name  is", slidername)
  }, []);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const formProps = {
    template: 4,
    form_name: 'book-a-demo-page-form',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: client == "travel_client" ? 2 : client == "hotel" ? 5 : 1 ,
    country: country ? country : "US",
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
      }
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1990,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
           
          }
        },
        {
            breakpoint: 1023,
            settings: {
              slidesToShow: 4,
             
            }
          },
    ]
  };

  return (
    <section className="contact_page">
      <ToastContainer
        position={toast_config.position}
        autoClose={toast_config.autoClose}
        hideProgressBar={toast_config.hideProgressBar}
        closeOnClick={toast_config.closeOnClick}
        pauseOnHover={toast_config.pauseOnHover}
        draggable={toast_config.draggable}
        theme={toast_config.theme} 
      />
      <div className="container" >
        <div className="contact_section_1">
          <div className="logo_img_div">
           
              <img src={sciativeLogo} alt="Sciative Logo" id="brand-logo" />
         

          </div>
          <div className="row row-container"  data-aos="new-animation">
            <div className="col-md-5 text-container">
              <h1>{title_upper}</h1>
              {title_lower &&  <h2>{title_lower}</h2>}
               {text &&  <p className='mb-0 mt-4 text-paragraph'>{text}</p>}
               {
                  slidername &&
              <div className="row contact_img_slider">
              
              
              <Slider {...settings}>
                {slidername.map(obj => (
                  <div key={obj.alttext} className="col-3">
                    <img src={obj.image} alt={obj.alttext} />
                  </div>
                ))}
                </Slider>

              </div>
                }
            </div>
            <div className="form-container col-md-7 ps-5">
              <Form {...formProps} />
            </div>
          </div>
        </div>
        <section className='mt-5'>
        <CapteraAchievements />
        </section>
        <section className="info_contact">
          <div className="container"  data-aos="new-animation">

            <div className="info_h2 text-center">
              <h2 className="bigHead">
                Customer Support
              </h2>
              <p className=''>Want to ask a question, solve a problem, schedule an interview, or <br className='d-none d-md-block' /> suggest an idea? Just send a message to one of our teams.</p>
            </div>
            <div className="row justify-content-center row-media-container mt-5">
              <div className="col-md-3">
                <div className="card">
                  <p className="c_info">For general queries</p>
                  <p className="c_det fw-bold">info@sciative.com</p>
                </div>
              </div>
              {/* col-3 end */}
              <div className="col-md-3">
                <div className="card">
                  <p className="c_info">Careers</p>
                  <p className="c_det fw-bold">careers@sciative.com</p>
                </div>
              </div>
              {/* col-3 end */}
            </div>
          </div>
        </section>
      </div>
    </section>
  )


}

export default ContactTemplate;