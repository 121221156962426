import ContactTemplate from "../common/contact";
import '../../assets/css/main.scss';
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
import { Helmet, HelmetProvider } from "react-helmet-async";
const RetailDemo = ({ }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [propsData, setPropsData] = useState({
        title_upper: "",
        text: "",
    })

    const data = {
        "main": {
            "title_upper": "Unlock the Power of AI Pricing: Let Sciative Guide You",
            "text": "From pricing to customer data, empower your retail business to price right with our powerful automated pricing tools."
        },
        "automated-pricing": {
            "title_upper": "Automate Pricing, Maximize Profits!",
            "text": "Optimize your pricing strategy with intelligent pricing automation and outmaneuver your competition."
        },
        "unified-pricing-engine": {
            "title_upper": "One Source: Perfect Prices With Omnichannel Pricing",
            "text": "Deliver a seamless customer experience with unified pricing across all platforms. Save time and resources with just one-click."
        },
        "goal-based-pricing": {
            "title_upper": "Meet Your Goals With Automated Pricing!",
            "text": "Tailor pricing to your goals. Customize your pricing strategy for maximum impact."
        },
        "competition-intelligence": {
            "title_upper": "Never Be Outsmarted: Gain the Competitive Edge with Our Pricing Intelligence",
            "text": "Dominate Pricing and Profits with Competitive Intelligence Unlock Actionable Insights."
        },
        "pricing-consulting": {
            "title_upper": "Optimize Pricing, Get a Free Consulting!",
            "text": "Craft your perfect pricing strategy and maximize profit with our expertise & technology."
        },
        "other-pricing-drivers": {
            "title_upper": "Growth & Profit Beyond Price: Discover the Hidden Factors for Success",
            "text": "Price is just one piece. Go beyond to solve the complete puzzle to unlock profit & growth."
        },
        "automated-dynamic-pricing": {
            "title_upper": "Intelligent Pricing, Effortless Growth",
            "text": "Free up your time to focus on growth: Automate pricing with our intelligent solutions."
        },
        "pricing-automation": {
            "title_upper": "Automate Pricing, Maximize Profits!",
            "text": "Optimize your pricing strategy with intelligent pricing automation and outmaneuver your competition."
        },
        "competitive-price-tracking": {
            "title_upper": "Never Be Outsmarted: Gain the Competitive Edge With Our Pricing Intelligence",
            "text": "Dominate pricing and profits with competitive tracking & intelligence and unlock actionable insights."
        },
        "market-based-pricing": {
            "title_upper": "Never Be Outsmarted: Gain the Competitive Edge With Our Pricing Intelligence",
            "text": "Dominate pricing and profits with competitive tracking & intelligence and unlock actionable insights."
        },
        "electronics": {
            "title_upper": "Unlock Revenue Growth with AI Pricing!",
            "text": "Gain a competitive edge: Reclaim valuable time with automated pricing solutions for the electronics industry."
        },
        "health-wellness-beauty": {
            "title_upper": "Succeed with AI Pricing and Boost Revenue Now!",
            "text": "Harness AI pricing for your health & beauty business: Automate pricing & boost revenue with our secure technology."
        },
        "food-consumable": {
            "title_upper": "Maximize Profits with Dynamic Pricing!",
            "text":"Effortlessly navigate market shifts and maximize profits with our AI solutions tailored for food & consumable retail."
        },
        "fashion-apparel": {
            "title_upper": "Use AI Solutions and Elevate Your Revenue!",
            "text": "Preserve brand value and safeguard margins: Utilize AI for pricing strategies in the fashion and apparel industry."
        },
        "cxo-decision-makers": {
            "title_upper": "Upgrade Your Retail Game: Start Automated Pricing Today",
            "text": "Empower your executive vision with cutting-edge automated pricing tailored for CXOs."
        },
        "category-managers": {
            "title_upper": "Succeed With AI Solutions for Your Business!",
            "text": "Effortlessly navigate your business and boost revenue using AI for pricing."
        },
        "why-us": {
            "title_upper": "Unlock Growth with All-in-One Pricing in Retail!",
            "text": "Connect with seasoned experts specializing in pricing and revenue management."
        },
        "retailers": {
            "title_upper": "Optimize Your Retail Business with AI Pricing!",
            "text": "Unlock growth in the retail industry: Make smarter decisions with our automated pricing tool, market intelligence, and advanced analytics."
        },
        "marketplace": {
            "title_upper": "Grow Your Business with Powerful Marketplace Solutions!",
            "text": "With our automated price optimization, leverage market intelligence, manage discounts strategically, and track competitor assortments effortlessly."
        },
        "brands": {
            "title_upper": "Attain Business Success by Undertaking Data-Driven Decisions!",
            "text": "Take command of your brand narrative: Automated pricing, unified pricing, advanced analytics, and brand protection tools at your fingertips."
        },
    }

    useEffect(() => {
        const navigation = searchParams.get("navigation");
        if (!data.hasOwnProperty(navigation)) {
            setSearchParams(new URLSearchParams("navigation=main")); // Set default navigation param
            setPropsData(data["main"]);
            return;
        }
        setPropsData(data[navigation]);
    }, [searchParams]); // Add searchParams as a dependency


return (
    <HelmetProvider>
         <Helmet> 
        <title>Dynamic Pricing the Future of Retail | Sciative</title>
        <link rel="canonical" href={`${GlobalUrl}${AllRoutes.retailcontact}`} />
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />

        <meta property="type" content="website" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="_token" content="" />
        <meta name="robots" content="noodp" />

        <meta name='description' content="Are you tired of outdated pricing strategies that leave profits behind? Schedule a Demo and See the Power of Next-Gen Pricing." />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Dynamic Pricing the Future of Retail | Sciative" />
        <meta property="og:description" content="Are you tired of outdated pricing strategies that leave profits behind? Schedule a Demo and See the Power of Next-Gen Pricing." />
        
        <meta property="og:hashtag" content="#ai" />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={`${GlobalUrl}${AllRoutes.retailcontact}`} />
        <meta property="og:site_name" content="Sciative Solutions - We Price Right" />

        <meta property="og:image"  content={GlobalImg} />
        <meta property="og:type" content="website" />
        </Helmet>
        <ContactTemplate
            title_upper={propsData.title_upper}
            // title_lower="Let's Price Right!"
            text={propsData.text}
            client="retail_client"
            country="US"
        />
    </HelmetProvider>
);
}

export default RetailDemo;