import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import graph from '../../assets/img/about/graph.png';
import logo from '../../assets/img/about/logo.png';
import graph2 from '../../assets/img/graph.png';

const WhatMakesSciativeUnique = () => {

    return (
        <>
            <section className="sciative_media why_partner">
                <div className="container" data-aos="new-animation">
                    <h2 className="bigHead text-center mb-5">What Makes Sciative Unique?</h2>

                    <div className='d-none d-md-flex flex-column w-100 align-items-center justify-content-center'>
                        <div className='graph-container'>
                            <img className='h-100 w-auto' src={graph} alt="Graph"/>
                            <Tooltip title="Build Internal Data Science Teams"><div className='box-1 text-white d-flex justify-content-center align-items-center p-3'><span className='bold'>I</span></div></Tooltip>
                            <Tooltip title="100% Manual Pricing with non-specialised Members"><div className='box-2 text-white d-flex justify-content-center align-items-center p-3'><span className='bold'>M</span></div></Tooltip>
                            <Tooltip title="Price Rule Engine Vendors"><div className='box-3 text-white d-flex justify-content-center align-items-center p-3'><span className='bold'>R</span></div></Tooltip>
                            <Tooltip title="Internal Data Science Teams using Rule Engines"><div className='box-4 text-white d-flex justify-content-center align-items-center p-3'><span className='bold'>I+R</span></div></Tooltip>
                            <Tooltip title="Current Pricing Solution Vendors"><div className='box-5 text-white d-flex justify-content-center align-items-center p-3'><span className='bold'>C</span></div></Tooltip>
                            <Tooltip title="Formula Based (rule+competition)"><div className='box-6 text-white d-flex justify-content-center align-items-center p-3'><span className='bold'>F</span></div></Tooltip>
                            <Tooltip title="Sciative 100% AI & Speed"><div className='box-7 text-white d-flex justify-content-center align-items-center p-3'><img src={logo} className='h-100 w-auto' alt="Sciative icon"/></div></Tooltip>
                        </div>

                        <span className='mt-5 text-muted small text-center'>*Box size denotes COST to the Company</span>
                    </div>

                    <div className='d-md-none'>
                        <img className='w-100' src={graph2} />
                    </div>
                    
                </div>
            </section>
        </>
    )
};

export default WhatMakesSciativeUnique;