import { Tab, Col, Nav, Navbar, Container, NavDropdown, Button, Row } from "react-bootstrap";
import AllRoutes from "../../AllRoutes";
import brioLogo from '../../assets/img/brio.png'
import zettaLogo from '../../assets/img/hotels/zettaprice.png'

const HotelHeaderMobile = () => {
    return(
        <Navbar style={{ zIndex: 200, position: 'fixed', top: '60px', width: '100%'}} collapseOnSelect expand="xl" className={`p-0 p-fixed mob-nav-logos zetta-logos`}>
                <Container>
                  <Navbar.Brand href={AllRoutes.hotel}> <img src={zettaLogo} alt="Zettaprice Logo" id="brand-logo-2" /></Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    <Nav.Link href={AllRoutes.hotelcustomer} >CUSTOMERS</Nav.Link>

                      <Nav.Link href={AllRoutes.hotelfaq}>FAQs</Nav.Link>
                      <Nav.Link href={AllRoutes.hotelcontact}>Book a Demo</Nav.Link>

                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
    )
}

export default HotelHeaderMobile;