import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect } from "react";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';

import { adminURL } from "../../global";

function FormsManagement() {
    const navigate = useNavigate();

    const [rows, setRows] = React.useState([]);

    const handleView = (form_name) => {
        navigate(`/niyantran-panel/${adminURL}/forms-management/${form_name}`);
    }

    const columns = React.useMemo(
        () => [
            { flex: 1, field: 'id', headerName: 'Sr. no.', type: 'number', align: 'center', headerAlign: 'center' },
            { flex: 1, field: 'form_name', headerName: 'Form Name', type: 'string', width: 200, align: 'center', headerAlign: 'center' },
            { flex: 1, field: 'no_of_entries', headerName: 'No. of entries', type: 'number', align: 'center', width: 150, headerAlign: 'center' },
            { flex: 1, field: 'latest_submission_at', headerName: 'Latest Submission Timestamp', type: 'string', width: 230, align: 'center', headerAlign: 'center' },
            {
                flex: 1,
                field: 'view',
                type: 'actions',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<RemoveRedEyeIcon />}
                        label="Edit"
                        onClick={() => handleView(params.id)}
                    />
                ],
            },
        ],
        []
    );

    function getRowId(row) {
        return row.form_name;
    }

    const getFormsData = async () => {
        try {
            const response = await axios.get(`/get_form_data`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setRows(response.data);
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    useEffect(() => {
        getFormsData();
    }, [])

    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'></div>
                    <div className='col-8'>
                        <h1 className='bold '>Forms Management</h1>
                    </div>
                </div>

                <div className="table-container white-bg shadow rounded mt-2">
                    <DataGrid
                        columns={columns}
                        rows={rows.map((item, index) => ({ id: index + 1, ...item }))}
                        getRowId={getRowId}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                    />
                </div>
            </section>
        </>
    );
}

export default FormsManagement;