import img1 from '../../assets/img/retail/comprehensive.png'
import img2 from '../../assets/img/retail/data-accuracy.png'
import img3 from '../../assets/img/retail/global-market-reach.png'
import img4 from '../../assets/img/retail/unified-price.png'
import img5 from '../../assets/img/retail/unmatched-speed.png'

const RetailDatapoint = () => {
   
    return(
        <section className="r_data">
            <div className="container"  data-aos="new-animation">
                <h2 className="bigHead mb-5 text-center">
                What Makes BRIO Stand Out?
                </h2>
                <div className="row">
                    <div className="col-md-6 col-lg-4 row_data">
                        <div className="card">
                            <div className="first_div">
                                <div className="img_div">
                                    <img src={img2} alt="  Data Accuracy at Scale icon" />
                                </div>
                                <h3 className="div_content">
                                    Data Accuracy at Scale
                                </h3>
                            </div>
                            <p>With a database of over <span>15 BILLION</span> products, our system achieves a remarkable 99% matching accuracy in pricing.
                            </p>
                        </div>
                    </div>
                    {/* col-4 end */}
                    <div className="col-md-6 col-lg-4 row_data">
                        <div className="card">
                            <div className="first_div">
                                <div className="img_div">
                                    <img src={img5} alt="  Unmatched speed icon" />
                                </div>
                                <h3 className="div_content">
                                Unmatched Speed
                                </h3>
                            </div>
                            <p>Benefit from our lightning-fast response time, enabling instant adjustments to pricing strategies and real-time insights into market dynamics.</p>
                        </div>
                    </div>
                    {/* col-4 end */}
                    <div className="col-md-6 col-lg-4 row_data">
                        <div className="card">
                            <div className="first_div">
                                <div className="img_div">
                                    <img src={img3} alt="Global Market Reach icon" />
                                </div>
                                <h3 className="div_content">
                                Global Market Reach
                                </h3>
                            </div>
                            <p>Our versatile, language-independent technology platform efficiently processes data in more than <span>30+ LANGUAGES</span> .

                            </p>
                        </div>
                    </div>
                    {/* col-4 end */}
                    <div className="col-md-6  row_data">
                        <div className="card">
                            <div className="first_div">
                                <div className="img_div">
                                    <img src={img1} alt="Comprehensive 360° Platform icon" />
                                </div>
                                <h3 className="div_content">
                                Comprehensive 360° Platform
                                </h3>
                            </div>
                            <p>The singular solution equipped to address every conceivable retail challenge through advanced machine learning algorithms.</p>
                        </div>
                    </div>
                    {/* col-4 end */}
                    <div className="col-md-6  row_data">
                        <div className="card">
                            <div className="first_div">
                                <div className="img_div">
                                    <img src={img4} alt="1 Click Unified Price Management icon" />
                                </div>
                                <h3 className="div_content">
                                1 Click Unified Price Management
                                </h3>
                            </div>
                            <p>Effortlessly synchronize prices across integrated marketplaces with the click of a button using BRIO's advanced pricing engine.</p>
                        </div>
                    </div>
                    {/* col-4 end */}
                </div>
            </div>
        </section>
    )

}

export default RetailDatapoint