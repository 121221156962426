
import leader1img from '../../assets/img/leader1.png'
import leader2img from '../../assets/img/leader2.png'
const Leadership = () => {

      return (
            <div className="leadership_section">
              <div className="container" data-aos="new-animation">

            
              <div className="headWrap">
                 <h2 className="bigHead text-center mb-3 whiteColor">Our Leadership</h2>
              </div>
              <p className="whiteColor text-center mb-5">At the helm of Sciative, our leadership embodies a compass guiding the ship of innovation, steering with a blend of expertise, vision, and a passion for pushing boundaries. With a commitment to excellence and a visionary approach, Sciative's leadership fosters a culture where every idea is a potential breakthrough, setting the course for a future where science and leadership converge.</p>
              <div className="card">
                <div className="row">
                <div className="col-lg-5 order-lg-2 leader_img_div">
                      <img src={leader1img} alt="Dr. Anshu Jalora"/>
                      </div>
                  <div className="col-lg-7 order-lg-1 leader_content">
                    {/* <span className='d-none d-xl-block text-nowrap'>@Dr. Anshu Jalora</span> */}
                    <div>
                      <p className="leader_name">Dr. Anshu Jalora</p>
                      <p className="leader_desc">Founder & Managing Director | Chief Scientist | FT</p>
                      <p className="leader_about">Dr. Anshu Jalora, Founder & Managing Director of Sciative Solutions is a recognized Pricing thought leader with over 23 years of rich experience in driving pricing transformation across globally recognized consumer and corporate brands. Before starting Sciative, Dr. Anshu was the Global Director of Pricing Strategy at Starbucks (USA), Director of Pricing at Overstoc.com (USA), Sr. Pricing Scientist (PROS Revenue Management). He is a topper from IIT Delhi (BTech) and a Ph.D. from Texas A&M University, USA. Dr. Anshu owns a patent in pricing software and has over 17 International Publications under his name.
                      </p>
                      <a href="//www.linkedin.com/in/anshujalora/" target='_blank' title="LinkedIn Profile"><i class="fab fa-linkedin" /></a>
                      <a href="////www.instagram.com/anshujalora/" target='_blank' title="Instagram profile"><i class="fab fa-instagram" /></a> 
                      <a href="//www.twitter.com/anshujalora" target='_blank' title="Twitter Profile"><i class="fa-brands fa-x-twitter"></i></a>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="card">
                <div className="row">
                <div className="col-lg-5  leader_img_div">
                      <img src={leader2img} alt="Dr. Anshu Jalora"/>
                      </div>
                  <div className="col-lg-7  leader_content">
                    {/* <span className='d-none d-xl-block t_top'>@Vijeta Soni</span> */}
                    <div>
                      <p className="leader_name">Vijeta Soni</p>
                      <p className="leader_desc">Co-Founder & CEO | Customer Success | FT</p>
                      <p className="leader_about">Ms. Vijeta Soni, Co-Founder & CEO of Sciative Solutions, is a dynamic leader with over 17 years of experience in driving digital transformation across industries. An alumna of IIM Bangalore and the University of Houston, Texas, USA, she holds two master's degrees. Vijeta has led large-scale projects for companies like Bed Bath & Beyond, Reliance Industries, and Jio Institute. She was honored by Goldman Sachs as one of the top 10,000 Women Entrepreneurs in their program's history. In 2015, she co-founded Sciative to empower businesses with AI-powered dynamic pricing. Her leadership has optimized operations and fostered a high-performance culture at Sciative.</p>
                      <a href="//www.linkedin.com/in/vijetasoni/" target='_blank' title='LinkedIn Profile'><i class="fab fa-linkedin" /></a> 
                      <a href="//www.instagram.com/vijeta2680/" target='_blank' title="Instagram profile"><i class="fab fa-instagram" /></a> 
                      <a href="//twitter.com/VijetaSoni"  target='_blank' title="Twitter Profile"><i class="fa-brands fa-x-twitter"></i></a>
                    </div>
                  </div>
                  
                </div>
              </div>


              </div>
            </div>
        );
    }
    
    
    export default Leadership;