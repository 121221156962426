import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/automated-pricing/1.png";
import img2 from "../../assets/img/retail/automated-pricing/2.png";
import img3 from "../../assets/img/retail/automated-pricing/3.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import contentquality from "../../assets/img/retail/other-drivers/content-quality.png";
import imagefeature from "../../assets/img/retail/other-drivers/image-feature-tagging.png";
import voc from "../../assets/img/retail/other-drivers/voc.png";
import unauth from "../../assets/img/retail/other-drivers/unauthorised.png";
import crosschannel from "../../assets/img/retail/other-drivers/cross-channel.png";
import insightalert from "../../assets/img/retail/other-drivers/insight-alerts.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";


import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";

import img1_ from "../../assets/img/retail/other-drivers/increase_your_shelf_health.png";
import img2_ from "../../assets/img/retail/other-drivers/know_your_customers.png";
import img3_ from "../../assets/img/retail/other-drivers/safeguard_your_brand.png";
import img4_ from "../../assets/img/retail/other-drivers/keep_a_tab_on_sales_revenue.png";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import { GlobalUrl, GlobalImg } from "../../global";
import b1 from "../../assets/img/icon/Contentquality.png";
import b2 from "../../assets/img/icon/Imagefeaturetagging.png";
import b3 from "../../assets/img/icon/VOC.png";
import b4 from "../../assets/img/icon/Unauthorised.png";
import b5 from "../../assets/img/icon/Crosschannel.png";
import b6 from "../../assets/img/icon/Insightalerts.png";


const OtherDrivers = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "other-pricing-drivers";

  const qa_list = [
    "Are you confident your product listings across various platforms are fully optimized for maximum visibility and customer engagement?",
    "Have you recently conducted a comprehensive brand health check to identify areas for potential improvement?",
    "Do you have access to insights that could help you improve your share of search results for relevant keywords?",
    "Are you currently leveraging any tools or strategies to ensure consistent pricing across all your sales channels?"
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
    } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_section_props = {
    id: "other_pricing",
    heading: "Why Brio Is One Stop Solution for All Your Retail Pricing Problems?",
    tabs: [
        {
          title: "Extend Your Market Presence",
          desc: "Leverage our solution to monitor your share of search and sales on digital shelves, helping you pinpoint and address revenue leaks effectively.",
          img: img1_ ,
          alt: "Extend Your Market Presence" 
        },
        {
          title: " Deepen Customer Insights",
          desc: "Go beyond traditional metrics with our  suite. Our Customer Lifetime Value (CLV) feature enhances retail intelligence by measuring past customer value and forecasting future trends to optimize engagement across new customer segments.",
          img: img2_,
          alt: " Deepen Customer Insights"
        },
        {
          title: "Protect Your Brand Integrity",
          desc: "Maintain your brand's pricing integrity with our AI-powered solution. It enforces your Minimum Advertised Price (MAP) policy by providing time-stamped proof of violations on each retailer's platform, coupled with instant alerts to prevent revenue leaks.",
          img: img3_,
          alt: "Protect Your Brand Integrity"
        },
        {
          title: "Optimize Sales and Revenue Management",
          desc: "Our alogirthms utilise predictive analytics models to forecast future sales and revenue trends accurately, enabling proactive data-driven decision-making.",
          img: img4_,
          alt: "Optimize Sales and Revenue Management"
        }
    ]
  }
  const benefit_props = {
    id: "other_pricing",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Content Quality Comparison",
        desc: "Our solution examines essential hygiene factors like product titles and descriptions across online channels, to elevate your content quality relative to your competitors.",
        img: b1 ,
        alt: "Content Quality Comparison Image" 
      },
      {
        title: "Image-Based Feature Tagging",
        desc: "Harness the power of AI and ML for superior image feature extraction, significantly diminishing manual effort.",
        img: b2 ,
        alt: "Image-Based Feature Tagging" 
      },
      {
        title: "Voice of Customer",
        desc: "Access sentiment analysis scorecards that systematically evaluate customer feedback, enabling informed adjustments that enhance your overall business performance.",
        img: b3 ,
        alt: "Voice of Customer" 
      },
      {
        title: "Unauthorized Marketplace Sellers Tracking",
        desc: "Effectively police unauthorized sellers to protect your brand and pricing integrity, maintaining control over your product distribution and online brand reputation.",
        img: b4 ,
        alt: "Unauthorized Marketplace Sellers Tracking" 
      },
      {
        title: "Cross-Channel Sales Analytics",
        desc: "With 360° Solutions get a consolidated view of sales data from various channels to strategically expand your business.",
        img: b5 ,
        alt: "Cross-Channel Sales Analytics" 
      },
      {
        title: "Actionable Insights and Alerts",
        desc: "Receive tailored insights on market pricing with actionable data to improve your seller relationships and sales performance.",
        img: b6 ,
        alt: "Actionable Insights and Alerts" 
      },

    ]
  }
  return (
    <>

      <HelmetProvider>

        <Helmet>
          <title>Comprehensive Retail Price Management Tool | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.otherdriver}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Automate retail pricing to optimize margins, unlock data-driven insights, and improve retail price management to accelerate growth and boost business success." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Comprehensive Retail Price Management Tool | Sciative" />
          <meta property="og:description" content="Automate retail pricing to optimize margins, unlock data-driven insights, and improve retail price management to accelerate growth and boost business success." />
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.otherdriver}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org/", 
                  "@type": "BreadcrumbList", 
                  "itemListElement": [{
                    "@type": "ListItem", 
                    "position": 1, 
                    "name": "Home",
                    "item": "https://sciative.com"  
                  },{
                    "@type": "ListItem", 
                    "position": 2, 
                    "name": "Retail",
                    "item": "https://sciative.com/retail"  
                  },{
                    "@type": "ListItem", 
                    "position": 3, 
                    "name": "Unified Pricing Engine",
                    "item": "https://sciative.com/retail/other-pricing-drivers"  
                  }]
                }
                
                
              `}
            </script>
        </Helmet>

        <InnerHeroRetail end_point={end_point} page_name="otherdriverpricing" page_heading="Other Drivers of Pricing" page_desc1="Comprehensive 360° solutions for all retail " page_desc2="challenges to propel growth." button_name="Explore Other Retail Pricing Solutions" />
        <RetailClient />

      <NavigationSection {...navigation_section_props}/>
        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
       <BenefitSection {...benefit_props}/>
        <MarketPlaceIntegration  end_point={end_point} />
        <RetailTestimonial dataHeading="We Deliver, Our Customers ‘ Speak" />
        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />

        <QAScore end_point={end_point} qa_list={qa_list} />

        <section className="qa_store">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Pricing Engine" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container"  data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>

    </>
  )
}
export default OtherDrivers;