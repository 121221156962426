import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import correct from "../../assets/img/picon/correct.png";


function ProblemSection({ id, heading, tabs }) {
    return (
        <>
            <section className="problems_div">
                <div className="container"  data-aos="new-animation">
                    <h2 className="bigHead text-center mb-5">{heading}</h2>
                    {/* <Tab.Container defaultActiveKey={`${id}_tab_0`}>
                        <Row>
                            <Col xl={5}>
                                <Nav variant="pills" className="flex-column">
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <Nav.Item key={index}>
                                                    <Nav.Link eventKey={`${id}_tab_${index}`}>
                                                        {tab.title}
                                                        <p>
                                                            {tab.desc}
                                                        </p>
                                                        <div className="investor_edge_div res_img">
                                                            <img src={tab.img} alt={tab.alt} />
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            );
                                        })
                                    }
                                </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                                <Tab.Content>
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <Tab.Pane key={index} eventKey={`${id}_tab_${index}`}>
                                                    <div className="investor_edge_div">
                                                        <img src={tab.img} alt={tab.alt} />
                                                    </div>
                                                </Tab.Pane>
                                            );
                                        })
                                    }
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container> */}
                    <div className="row d-none d-xl-flex">
                    {
                        tabs.map((tab, index) => {
                            return (
                                <div className="col-md-6 col-xl-4" key={index}>
                                    <div className="card card1">
                                        <div className="img_red d-none d-md-block">
                                            <img src={tab.img} alt="icon 1" className='red_img'/>
                                            <img src={correct} alt="correct icon" className="correct" />
                                        </div>
                                        <p className='wr_aans'>{tab.title}</p>
                                        <p className='cr_aans'>{tab.correcttitle}</p>
                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className="row  d-xl-none">
                    {
                        tabs.map((tab, index) => {
                            return (
                                <div className="col-md-6 col-xl-4" key={index}>
                                    <div className="card card2">
                                        <div className="img_red">
                                            <img src={tab.img} alt="icon 1" className=''/>
                                          
                                        </div>
                                        <p className='wr_aans'>{tab.title}</p>
                                        <div className="img_red green">
                                            <img src={correct} alt="correct icon" className="" />
                                        </div>
                                        <p className='cr_aans'>{tab.correcttitle}</p>
                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProblemSection;