import { useState, useEffect } from 'react';
import RetailHeader from "./retailHeader";
import RetailHeaderMobile from "./retailHeaderMobile";
import { Link } from "react-router-dom";
import AllRoutes from '../../AllRoutes';
const InnerHeroRetail = ({ page_name, page_heading, page_desc1, page_desc2, button_name, end_point }) => {
  const [showHeader1, setShowHeader1] = useState(false);
  const [showHeader2, setShowHeader2] = useState(false);

  function scrollFunction() {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
   
    if (winScroll >= 80 && window.innerWidth >= 600) {
      setShowHeader2(false);
      setShowHeader1(true);
    }
    else if (winScroll >= 80 && window.innerWidth < 600) {
      setShowHeader1(false)
      setShowHeader2(true)
    }
    else {
      setShowHeader1(false)
      setShowHeader2(false)
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', function () { scrollFunction() });
    document.addEventListener('scroll', function () { scrollFunction() });
  }, [])

  return (<>
    {
      showHeader1 ?
        <section style={{ padding: '0', borderBottom: '1px solid #fff', width: '100%', position: 'fixed', top: '43px', height: '4.5em', zIndex: 100 }} className='banner_retail main_banner bg-black'>
          <div className='container'>
            <RetailHeader end_point={end_point} />
          </div>
        </section>
        :
        showHeader2 ?
          <RetailHeaderMobile />
          :
          null
    }
    <section className="banner_retail main_banner">
      <div className="container" >



        <RetailHeader end_point={end_point} />

        <p className="small_head">{page_heading}</p>
        <h1>{page_desc1} <br className="d-none d-md-block" /> {page_desc2}</h1>
        {/* <p>Cultivate Competitive Advantage with Precision Pricing Solutions Designed for the Modern Bus Industry</p> */}
        <div>
          {/* <Button className="yellow-button">Book a demo </Button> */}
          <Link to={AllRoutes.retailcontact + `?navigation=${end_point}`}>
            <button className="transparent-button"> {button_name} <span className="material-symbols-outlined">trending_flat</span></button>
          </Link>
        </div>


      </div>
    </section>
  </>)

}
InnerHeroRetail.defaultProps = {
  button_name: "Get a Demo",
};

export default InnerHeroRetail;