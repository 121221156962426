import indianretailer from '../../assets/img/indian-retailer.png';
import travelworld from '../../assets/img/travel-world.png';
import yourstory from '../../assets/img/your-story.png';
import rightarrowyellow from '../../assets/img/right-arrow-yellow.png'

import { Button } from 'react-bootstrap';  
import AllRoutes from '../../AllRoutes';

const SciativeMedia = ({  }) => {
  
  return(
    <section className="sciative_media">
          <div className="container">
          <h2 className="bigHead text-center mb-5">Sciative in Media</h2>
          <div className="row media_row g-0">
            <div className="col-md-7">
              <p className="media_head"><span>Article</span> 29 March, 2024</p>
              <h5 className="article_head"><a href="https://yourstory.com/smbstory/smb-wrap-archies-covid-19-sciative-pinklay" target="_blank" rel="noopener noreferrer">Decoding iconic brand Archies’ survival strategies to survive COVID-19 and other top stories of the week</a></h5>
              <p className="article_author">Bhavya Kaushal</p>
            </div>
            <div className="col-md-3 offset-md-2">
              <a href="https://yourstory.com/smbstory/smb-wrap-archies-covid-19-sciative-pinklay" target="_blank" rel="noopener noreferrer">

                <img src={yourstory} alt="Your Story" />
              </a>
            </div>
          </div>
          {/* media row end */}
          <div className="row media_row g-0">
            <div className="col-md-7">
              <p className="media_head"><span>Article</span> 12 August 2021</p>
              <h5 className="article_head"><a  href="https://travel.economictimes.indiatimes.com/speakeasy/dynamic-pricing/5047" target="_blank" rel="noopener noreferrer">AI-powered dynamic pricing: Ensuring sustainability in the travel industry</a></h5>
              <p className="article_author">Vijeta Soni</p>
            </div>
            <div className="col-md-3 offset-md-2">
              <a href="https://travel.economictimes.indiatimes.com/speakeasy/dynamic-pricing/5047" target="_blank" rel="noopener noreferrer">

                <img src={travelworld} alt="Travel World" />
              </a>
            </div>
          </div>
          {/* media row end */}
          <div className="row media_row g-0">
            <div className="col-md-7">
              <p className="media_head"><span>Article</span> 05 August 2021</p>
              <h5 className="article_head"><a  href="https://www.indianretailer.com/article/technology/digital-trends/tweet-buy-twitter-s-shop-module-pilot-launched-in-us-to-enable-shopping-on-platform.a7268" target="_blank" rel="noopener noreferrer">Tweet & Buy: What will Twitter's Shop Module Bring for India's Retail Industry</a></h5>
              <p className="article_author">Tanya Krishna</p>
            </div>
            <div className="col-md-3 offset-md-2 ">
                <a  href="https://www.indianretailer.com/article/technology/digital-trends/tweet-buy-twitter-s-shop-module-pilot-launched-in-us-to-enable-shopping-on-platform.a7268" target="_blank" rel="noopener noreferrer"><img src={indianretailer} alt="Indian Retailer" /></a>
            </div>
          </div>
          {/* media row end */}
          </div>
          <div className="text-center">
          <a className='text-black mt-5 text-center' href={AllRoutes.news}>
          Load More <i className="mx-2 fa-solid fa-arrow-right"></i>
          </a>
          </div>
        </section>
)

}

export default SciativeMedia;