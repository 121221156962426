import Form from "../../components/Forms/Form";
import CareersForm from "../../components/Forms/CareersForm";


function FormDevelopment() {

    // const handleApiResponse = (response) => {
    //     console.log(response);
    // }

    // possible fields name: name, email, phone, company, topic

    // const props = {
    //     template: 1,
    //     form_name: "local-storage-test",
    //     fields: [
    //       {
    //         name: "name",
    //         required: true
    //       },
    //       {
    //         name: "email",
    //         required: true
    //       },
    //       {
    //         name: "company",
    //         required: true
    //       }
    //     ],
    //     title: "Get in Touch",
    //     content_upper: "upper content",
    //     content_lower: "lower content",
    //     recipients_group_id: 1,
    //     handleApiResponse: handleApiResponse
    // }
    
    // const props = {
    //     template: 2,
    //     form_name: "",
    //     fields: [{
    //       name: "email",
    //       required: true
    //     }],
    //     recipients_group_id: 1,
    //     text_color: "text-black",
    //     handleApiResponse: handleApiResponse
    // }

    // const props = {
    //     template: 3,
    //     form_name: "",
    //     content_upper: "Sign up for the freshly brewed content on the Road Ahead Talks Series",
    //     fields: [{
    //       name: "email",
    //       required: true
    //     }],
    //     recipients_group_id: 1,
    //     handleApiResponse: handleApiResponse
    // }

    // const careers_props = {
    //     role: "Data Analyst",
    //     recipients_group_id: 1,
    //     handleApiResponse: handleApiResponse
    // }
    

  return (
    <div>
      {/* <Form {...props}/> */}
      {/* <CareersForm {...careers_props}/> */}
    </div>
  );
}

export default FormDevelopment;