const MenuItem = (props) => {
    return (
      <li>
          <a className={props.active ? 'active' : ''} href={props.link}>
              <i className={props.active ?  props.icon + " blue" : props.icon}></i>
              <span className="links_name">{props.menuTitle}</span>
          </a>
          <span className="tooltip">{props.menuTitle}</span>
      </li>
    );
  };
  
  export default MenuItem;