import React, { useState, useEffect } from 'react';

import '../../../assets/css/blogs.css';
import BlogCard from './BlogCard';
import { Link, useNavigate } from 'react-router-dom';
import BlueRectangleImage from '../../../assets/img/blue_rectangle.png';
import Book from '../../../assets/img/book.png';
import moment from 'moment';

const BlogsSection = ({ setTopBlogs, blogs, showReadMore }) => {

    const [otherBlogs, setOtherBlogs] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (blogs.length) {
            setTopBlogs([blogs[0], blogs[1], blogs[2]])
            let temp = []
            for (let i = 3; i < blogs.length; i += 1) {
                temp.push(blogs[i])
            }
            setOtherBlogs(temp)
        }
    }, [blogs])

    return (
        <>
            <section className='w-100 row'>
                {/* <span className='d-block d-md-none bold medium-3 text-center mb-3'>Select category</span> */}
                
                <div className='col-sm-12 col-md-6 p-2'>
                    {
                        typeof blogs[0] !== 'undefined' ?
                            <div className='hero-card corner d-flex flex-column justify-content-end main-blog' style={{ backgroundImage: `url(${blogs[0]['images']['img-main-img']['url']})` }}>
                                <div className='w-100 corner main-blog-gradient px-4 d-flex flex-column justify-content-between'>
                                    <span className='text-white'>{moment(blogs[0]['publishedDate']).format('Do MMMM, YYYY')}</span>
                                    <div>
                                        <Link className='d-inline-block' to={`/research-articles/${blogs[0]['url']}`}>
                                            <h3 className='bold text-white'>{blogs[0]['title'].length > 70 ? blogs[0]['title'].slice(0, 67) + '...' : blogs[0]['title']}</h3>
                                        </Link>
                                    </div>
                                    <div className='small-2 mb-3 text-white'>
                                    <span className='mr-2 '><i className="fa-solid fa-book mr-1"></i> {blogs[0]?.['blogReadTime'] || '10'} min read <span className=''>|</span></span>
                                        {blogs[0]['categories'].map((cat, index) => (
                                            <React.Fragment key={index}>
                                                <a className='text-white mr-1' href={`/research-articles/category/${cat}`}>{cat}</a>
                                                {index !== blogs[0]['categories'].length - 1 && ', '}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <div className='col-sm-12 col-md-3 p-2'>
                    <BlogCard blog={blogs[1]} />
                </div>
                <div className='col-sm-12 col-md-3 p-2'>
                    <BlogCard blog={blogs[2]} />
                </div>
            </section>
            <section className='w-100 row my-2'>
                {
                    showReadMore && otherBlogs.length > 7 ?
                        otherBlogs.slice(0, 7).map(blog => (
                            <div className='col-sm-12 col-md-3 p-1'>
                                <BlogCard blog={blog} />
                            </div>
                        ))
                        :
                        otherBlogs.map(blog => (
                            <div className='col-sm-12 col-md-3 p-1'>
                                <BlogCard blog={blog} />
                            </div>
                        ))
                }
                {
                    showReadMore && otherBlogs.length > 7 &&
                    <div className='col-sm-12 col-md-3 p-1'>
                        <div className='p-1'>
                            <Link to={`/research-articles/category/all`}>
                                <div className='corner read-more-card d-flex flex-column justify-content-center align-items-center gap-3'>
                                    <img className='icon-img' src={Book} alt="book" />
                                    <span className='text-white'>Read all blog articles</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

export default BlogsSection;