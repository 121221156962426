import { Button, IconButton, Tooltip, selectClasses } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "../../../assets/css/forms/forms_admin.css";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import AddIcon from '@mui/icons-material/Add';
import { set, useForm } from "react-hook-form"
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddFieldsModal from "./AddFieldsModal";

function CreateForm() {

    const [formTemplates, setFormTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showFieldsModal, setShowFieldsModal] = useState(false);
    const [fields, setFields] = useState([]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const get_form_templates = async () => {
        try {
            const response = await axios.get(`/form/get_all_templates`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                setFormTemplates(response.data);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.detail);
            }
            else {
                alert("Unrecognised error occurred. Please try again.");
            }
        }
    }

    useEffect(() => {
        get_form_templates();
    }, [])

    const handleCloseFieldsModal = () => {
        setShowFieldsModal(false);
    }

    const handleTemplateSelect = (selectedId) => {
        const selected_template = formTemplates.find(template => template.id === selectedId);
        setSelectedTemplate(selected_template);
    }

    const handleAddField = (data) => {
        setFields([...fields, data]);
        setShowFieldsModal(false);
        toast.success('Field added');
    }

    const handleSave = (data) => {
        fields.length === 0 &&
            toast.error('Please add at least one field');
        console.log(data);
        console.log(fields);
    }

    return (
        <>
            <div className='admin-card corner row p-3 shadow-2 mt-2'>
                <div className='col-1'>
                    <IconButton onClick={() => Navigate('')}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div className='col-11'>
                    <h1 className='bold '>Create new Form</h1>
                </div>
            </div>

            <div className="admin-card p-3 my-3">
                <div className="d-flex mb-3">
                    <h6 className="bold m-0">Choose template</h6>
                    <Tooltip title="template desc" arrow placement="right">
                        <i className="fa-solid fa-circle-info info-icon d-flex justify-content-center align-items-center ml-2 color-grey"></i>
                    </Tooltip>
                </div>
                <div className="row">
                    {
                        formTemplates.map((template, _) => {
                            switch (template.id) {
                                case 1:
                                    return (
                                        <div className="col-md-2">
                                            <div role="button" onClick={() => handleTemplateSelect(template.id)} className={`template-box px-3 pt-3 pb-2 ${selectedTemplate && template.id === selectedTemplate.id && 'active'}`}>
                                                {/* <div role="button" onClick={() => console.log(template.id)} className={`template-box px-3 pt-3 pb-2 ${selectedTemplate && template.id === selectedTemplate.id && 'active'}`}> */}
                                                <div className="row">
                                                    <div className="col-6 pe-0">
                                                        <div className="header-bar w-80 my-1 rounded" />
                                                        <div className="sub-header-bar w-50 my-1 rounded" />
                                                        <div className="sub-header-bar w-50 my-1 rounded" />
                                                    </div>
                                                    <div className="col-6 ps-0">
                                                        <div className="form-bar rounded d-flex flex-column justify-content-end">
                                                            <div className="btn-bar rounded mb-1 mx-auto w-40" />
                                                        </div>
                                                    </div>
                                                    <h6 className="template-name mt-2 text-center">{template.template_name}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        })
                    }
                </div>
            </div>

            {
                selectedTemplate &&
                <form onSubmit={handleSubmit(handleSave)}>

                    <div className="admin-card p-3 my-3 w-80 mx-auto">
                        <Form.Group className="mb-3" controlId="form_name">
                            <Form.Label className="required">
                                Form Name
                            </Form.Label>
                            <Tooltip title="button desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Control
                                type="text"
                                {...register("form_name", { required: true, minLength: 2, maxLength: 25 })}
                                isInvalid={errors.form_name}
                            />
                        </Form.Group>
                    </div>

                    <div className="admin-card p-3 my-3 w-80 mx-auto">
                        <Form.Group className="mb-3" controlId="form_desc">
                            <Form.Label className="required">
                                Form Description
                            </Form.Label>
                            <Tooltip title="button desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Control
                                type="text"
                                {...register("form_desc", { required: true, minLength: 2, maxLength: 100 })}
                                isInvalid={errors.form_desc}
                            />
                        </Form.Group>
                    </div>

                    <div className="admin-card p-3 my-3 w-80 mx-auto">
                        <Form.Group className="mb-3" controlId="btn_text">
                            <Form.Label className="required">
                                Button text
                            </Form.Label>
                            <Tooltip title="button desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Control
                                type="text"
                                {...register("btn_text", { required: true, minLength: 2, maxLength: 25 })}
                                isInvalid={errors.btn_text}
                            />
                        </Form.Group>
                    </div>

                    {
                        selectedTemplate.inputs.map((input, index) => {
                            return (
                                <>
                                    <div key={index} className="admin-card p-3 my-3 w-80 mx-auto">
                                        <Form.Group className="mb-3" controlId={`${index}`}>
                                            <Form.Label className={`${input.isRequired ? 'required' : ''}`}>
                                                {input.label}
                                            </Form.Label>
                                            <Tooltip title={input.description} arrow placement="right">
                                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type={input.type === "text" ? "text" : ""}
                                                {
                                                ...input.type === "text" ?
                                                    register(input.label, { required: input.isRequired, minLength: input.min, maxLength: input.max, ...(input.regex && { pattern: new RegExp(input.regex) }) })
                                                    :
                                                    register(input.label, { required: input.isRequired, min: input.min, max: input.max, ...(input.regex && { pattern: new RegExp(input.regex) }) })
                                                }
                                                isInvalid={errors[input.label]}
                                            />
                                        </Form.Group>
                                    </div>
                                </>
                            );
                        })
                    }

                    <div className="admin-card p-3 my-3 w-80 mx-auto">
                        <div className="text-center">
                            <Button onClick={() => setShowFieldsModal(true)} variant="contained" endIcon={<AddIcon />}>
                                Add field
                            </Button>
                            <Tooltip title="button desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                        </div>

                        {
                            fields.length === 0 ?
                                <div className="text-center mt-3">
                                    <span className="helper-text">Added fields will appear here</span>
                                </div>
                                :
                                <Table className="fields-table mt-3 text-center" striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Sr. no.</th>
                                            <th>Label</th>
                                            <th>Placeholder</th>
                                            <th>Is required</th>
                                            <th>Type</th>
                                            <th>Minimum length</th>
                                            <th>Maximum length</th>
                                            <th>Regex</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.map((field, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{field.label}</td>
                                                        <td>{field.placeholder}</td>
                                                        <td>{field.is_required ? 'Yes' : 'No'}</td>
                                                        <td>{field.type}</td>
                                                        <td>{field.min}</td>
                                                        <td>{field.max}</td>
                                                        <td>{field.regex}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                        }
                    </div>
                    <div className="admin-card p-3 my-3 w-80 mx-auto text-center">
                        <Button type="submit" variant="contained" color="success" endIcon={<SaveAltIcon />}>
                            Save
                        </Button>
                    </div>
                </form>
            }


            <AddFieldsModal showFieldsModal={showFieldsModal} handleCloseFieldsModal={handleCloseFieldsModal} handleAddField={handleAddField} />
        </>
    )
}

export default CreateForm;