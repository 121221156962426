import ContactTemplate from "../common/contact";
import '../../assets/css/main.scss';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
const AboutContactPage = ({ }) => {

    return (
        <HelmetProvider>
            <Helmet> 
          <title>Contact Us to Know About Our AI Solutions & Values | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.aboutcontact}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />

          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta property="og:type" content="website" />
          <meta name='description' content="Thank you for expressing your interest in Sciative Solutions. Have Questions?, Get in touch with us ! Contact us below, VISIT US in office." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Contact Us to Know About Our AI Solutions & Values | Sciative" />
          <meta property="og:description" content="Thank you for expressing your interest in Sciative Solutions. Have Questions?, Get in touch with us ! Contact us below, VISIT US in office." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.aboutcontact}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
        
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
        </Helmet>
            <ContactTemplate 
            title_upper = "Explore the minds shaping the future!"
            text = "Explore our story: dive into a journey of purpose and passion. See how we're making a difference and unmask the masterminds behind the magic!"
            client="all"
            />
        </HelmetProvider>
    );
}


export default AboutContactPage;