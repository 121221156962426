import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";

const HotelInsideNumber = ({end_point}) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if(end_point){
      setUrl(end_point)
    }
  }, [end_point])
return(<>
 <section className="investor-result  why_result" data-aos="new-animation">
          <div className="container" >
            <h2 className="bigHead text-center mb-5">See How We Can Boost Your Performance</h2>
            <div className="row">
              <div className="col-6 col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  10%
                  </p>
                  {/* <p className="num_desc">
                  Repeat Purchase Growth

                  </p> */}
                </div>
                <p className="num_desc_2">
                Increase in <br/> RevPAR
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-6 col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  27%
                  </p>
                  {/* <p className="num_desc">
                  Forecast Accuracy

                  </p> */}
                </div>
                <p className="num_desc_2">
                Increase in <br/>
                Occupancy
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-6 col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  15%
                  </p>
                  {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                </div>
                <p className="num_desc_2">
                Increase in<br/> Repeat Bookings
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-6 col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  43%
                  </p>
                  {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                </div>
                <p className="num_desc_2">
                Reduction in <br/>Work Hours
                </p>
              </div>
              {/* col-4 end */}
              </div>
             
          </div>
        </section>
        {/* <section className="hotel_bg_cta">
            <div className="container">
                <div className="card ap_card">
                  <div className="row align-items-center">
                    <div className="col-md-10">
                      <p className="card_text m-0 bigHead">Explore the difference with Zetta</p>
                    </div>
                    <div className="col-md-2">
                      <Link to={`/retail/book-a-demo?navigation=${url}`}>
                          <button className="yellow-button">Book a Demo </button>
                      </Link>
                    </div>
                  </div>
                   
                 
                </div>
            </div>
        </section> */}
</>)

}


export default HotelInsideNumber;