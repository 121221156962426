import { ThemeProvider } from '@mui/material/styles';
import theme from '../Themes/SciativeTheme';
import { Container } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import toast_config from '../../toast_config';
import Sidebar from "../common/AdminSidebar";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/admin.css';


const AdminLayout = ({ children }) => {

  return (
    <HelmetProvider>
      <Helmet>
        <title>Sciative Admin</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ThemeProvider theme={theme}>
      <main>
        <Sidebar />
        <Container className="" style={{ marginLeft: '8em' }}>
          {children}

          <ToastContainer 
          position={toast_config.position}
          autoClose={toast_config.autoClose}
          hideProgressBar={toast_config.hideProgressBar}
          closeOnClick={toast_config.closeOnClick}
          pauseOnHover={toast_config.pauseOnHover}
          draggable={toast_config.draggable}
          theme={toast_config.theme}   
          />
        </Container>
      </main>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default AdminLayout;