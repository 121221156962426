import ContactTemplate from "../common/contact";
import '../../assets/css/main.scss';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
const InvestorsContactPage = ({ }) => {

    return (
        <HelmetProvider>
        <Helmet> 
      <title>Invest in Future of AI Pricing the Scientific Way | Sciative</title>
      <link rel="canonical" href={`${GlobalUrl}${AllRoutes.investorscontact}`} />
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />

      <meta property="type" content="website" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
    
      <meta name='description' content="Invest in AI the leading edge of dynamic pricing. Drive innovation and exceptional returns. Connect us now to know more! Call/Mail  Us " />
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content="Invest in Future of AI Pricing the Scientific Way | Sciative" />
      <meta property="og:description" content="Invest in AI the leading edge of dynamic pricing. Drive innovation and exceptional returns. Connect us now to know more! Call/Mail  Us " />
     
      <meta property="og:hashtag" content="#ai" />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={`${GlobalUrl}${AllRoutes.investorscontact}`} />
      <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
    
      <meta property="og:image"  content={GlobalImg} />
      <meta property="og:type" content="website" />
    </Helmet>
            <ContactTemplate 
            title_upper = "Invest in Innovation with Us!"
            text = "Our innovative approach unlocks hidden value and creates a win-win situation for all. Invest in the future of pricing and unlock next-gen value."
            client="all"
            />
       </HelmetProvider>
    );
}


export default InvestorsContactPage;