import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from '../common/footer';
import sciativeLogo from '../../assets/img/sciative.png';
import lmIcon from '../../assets/img/lm-icon.png';

import data from '../../data/learningMaterials';

const LearningMaterials = ({role}) => {
    
    return (
        <>
            <section style={{height: 'auto'}} className="contact_page">
                <div className='container'>
                    <div className='h-50 row'>
                        <div className="col-6 col-lg-2 contact_section_1">
                            <div className="logo_img_div">
                                <Link to="/">
                                    <img src={sciativeLogo} alt="Sciative Logo" id="brand-logo" />
                                </Link>

                            </div>
                        </div>
                        <div className='col-6 col-lg-8 d-flex align-items-center justify-content-start'>
                            <div className='h-50 vertical-line mx-3'></div>
                            <img className='lm-icon' src={lmIcon} />
                            <h3 className='mx-3 bold study_mat_text'>Study Material</h3>
                        </div>
                        <div className='col-12 col-lg-2 d-flex align-items-center text-lg-end'>
                            <a href='/careers'><i className="fa-solid fa-angle-left mr-2"></i> Back To Careers Page</a>
                        </div>
                    </div>

                    {
                        data.filter(item => item['key'] == role).map((item, index) => 
                            <div className='mt-4 d-flex flex-column align-items-center'>
                                <h3 className='bold text-center'>{item['jobRole']}</h3>
                                <h4 className='thin mt-3'>Skills</h4>

                                <div className='table-container mt-3'>
                                    <table className="w-100">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col">SR NO.</th> */}
                                                <th className='p-3' scope="col">SKILL LEVEL</th>
                                                <th scope="col">TOPIC NAME</th>
                                                {/* <th scope="col">DESCRIPTION</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item['skills'].map((skill, idx) => 
                                                    <>
                                                    <tr style={{borderBottom: 'none'}} id={`${index}-${idx}`}>
                                                        {/* <th scope="row">{idx+1}</th> */}
                                                        <td className='skill-level p-3 bold'>{skill.skillLevel}</td>
                                                        <td style={{fontSize: '1.2em'}} className='p-3 bold' title={skill.skillName}>
                                                            <p id={`${index}-${idx}-skillName`} className='bold w-80'>{skill.skillName}</p>
                                                            <p className='d-none d-md-block mb-0 thin small text-muted'>{skill.desc}</p>
                                                            <p className='d-none d-md-block thin small text-muted' style={{fontStyle: 'italic'}}>Learning time: {skill.time}</p>
                                                        </td>
                                                        {/* <td title={skill.desc}><p id={`${index}-${idx}-desc`} className='p-3 w-90'>{skill.desc}</p></td> */}
                                                    </tr>
                                                    <tr style={{borderBottom: 'none'}} className='d-md-none'>
                                                        <td colspan='2'>
                                                                <div className='w-100 px-4'>
                                                                <p className='mb-0 thin small text-muted'>{skill.desc}</p>
                                                                <p className='thin small text-muted' style={{fontStyle: 'italic'}}>Learning time: {skill.time}</p>
                                                                </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan='2'>
                                                            <p className='mx-4 bold'>Sub Topics Covered</p>
                                                            <div className='px-4 mb-2 w-100 d-flex flex-wrap'>
                                                                {
                                                                    skill['subTopics'].map(topic => 
                                                                        <a href={topic['link']} target="_blank" rel="noreferrer" title='Click to start learning' className='topic-tile p-1 p-lg-2 text-center m-1'>{topic.topicName}</a>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>

            
        </>
    )
}

export default LearningMaterials;