import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";

const RetailInsideNumber = ({end_point}) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if(end_point){
      setUrl(end_point)
    }
  }, [end_point])
return(<>
 <section className="investor-result  why_result">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">We Let Numbers Do the Talking</h2>
            <div className="row">
              <div className="col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  +30%
                  </p>
                  {/* <p className="num_desc">
                  Repeat Purchase Growth

                  </p> */}
                </div>
                <p className="num_desc_2">
                Improvement in operational efficiency.
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  94%
                  </p>
                  {/* <p className="num_desc">
                  Forecast Accuracy

                  </p> */}
                </div>
                <p className="num_desc_2">
                Accurate demand forecasting
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  21%
                  </p>
                  {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                </div>
                <p className="num_desc_2">
                Improvement in response to market changes
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  65%
                  </p>
                  {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                </div>
                <p className="num_desc_2">
                Improvement in response to market changes
                </p>
              </div>
              {/* col-4 end */}
              </div>
             
          </div>
        </section>
        <section className="retail_bg_cta">
            <div className="container">
                <div className="card ap_card">
                    <p className="card_text">Let Your Numbers Speak For Your Brand Success</p>
                    <Link to={`/retail/book-a-demo?navigation=${url}`}>
                        <button className="yellow-button">Book a Demo Now</button>
                    </Link>
                </div>
            </div>
        </section>
</>)

}


export default RetailInsideNumber;