import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Form from '../../components/Forms/Form';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';

const RecommendedCaseStudies = ({categories}) => {

    const [pdfRequestModal, setPdfRequestModal] = useState(false);
    const [requestedFileKey, setRequestedFileKey] = useState('');

    const [recBlogs, setRecBlogs] = useState([])
    const getRecommendedBlogs = async () => {
        try {
            const response = await axios.post(`/get_recommended_case_studies`, { categories }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setRecBlogs(response.data.map(blog => (
                    {
                        thumbnailImg: blog['images']['img-main-img']['url'],
                        category: blog['categories'],
                        title: blog['title'],
                        published: blog['publishedDate'],
                        url: blog['url'],
                        templateType: blog['templateType'],
                        file_key: blog['templateType'] === 'pdf' ? blog['file_key'] : ''
                    }
                )))
            }
        } catch (err) {
            console.log(err)
        }
    }

    const pdfRequestProps = {
        template: 1,
        form_name: "case-studies-pdf-request",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true }
        ],
        title: "See Sciative in Action & Drive Results",
        content_upper: "Make data-driven decisions with confidence. Dive into case studies and see how Sciative tackled challenges and delivered exceptional results. Get access to case studies and unlock exceptional insights that inspire you.",
        content_lower: "",
        recipients_group_id: 1,
        requestedFileKey: requestedFileKey,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                window.open(res.data.signed_url);
                toast.success('Form submitted successfully!');
                setPdfRequestModal(false);
            }
            else {
                toast.error(res.data);
            }
        }
    }

    const handlePdfRequest = (file_key) => {
        setPdfRequestModal(true);
        setRequestedFileKey(file_key);
    }

    useEffect(() => {
        getRecommendedBlogs()
    }, [])
    return (
        <section className='w-100 container'>
            <div className='p-md-2 p-lg-4'>
                <div className='w-100 mb-3 d-flex justify-content-center'>
                    <h4 className='bold'>Recommended Case Studies</h4>
                </div>
                <div className='row'>
                    {
                        recBlogs.length ?
                            recBlogs.map(blog => (
                                <div className='mb-3 col-sm-12 col-md-6 col-lg-4'>
                                    <div className='w-100 h-100 rec-blog-card m-2 py-2 px-3 d-flex flex-column justify-content-evenly'>
                                        <div className='h-100 w-100'>
                                            {
                                                blog['templateType'] === 'pdf' ?
                                                <a style={{cursor: 'pointer'}} onClick={() => handlePdfRequest(blog['file_key'])}>
                                                    <img style={{ height: '10em' }} className='rec-blog-thumbnail w-100' src={blog['thumbnailImg']} />
                                                </a>
                                                :
                                                <a href={blog['url']}>
                                                    <img style={{ height: '10em' }} className='rec-blog-thumbnail w-100' src={blog['thumbnailImg']} />
                                                </a>
                                                }   
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                            {
                                                blog['category'].map(cat => <span className='mb-1 mx-1 rec-blog-category p-1'>{cat}</span>)
                                            }
                                        </div>
                                        <div className='mt-3'>
                                            {
                                                blog['templateType'] === 'pdf' ?
                                                    <a style={{cursor: 'pointer'}} onClick={() => handlePdfRequest(blog['file_key'])} className="bold text-black">
                                                        {blog['title'].length > 50 ? blog['title'].slice(0, 57) + '...' : blog['title']}
                                                    </a>
                                                    :
                                                    <a className='d-inline-block' href={`/case-studies/${blog['url']}`}>
                                                        <h6 className='bold text-black'>
                                                            {blog['title'].length > 50 ? blog['title'].slice(0, 57) + '...' : blog['title']}
                                                        </h6>
                                                    </a>
                                            }
                                        </div>
                                        <div className='my-3 w-100 row d-flex align-items-center justify-content-between'>
                                            <div className='col-5'>
                                            <span className='small-2 text-muted'>{moment(blog['publishedDate']).format('Do MMMM, YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : null
                    }
                </div>
            </div>

            <Modal
                show={pdfRequestModal}
                onHide={() => setPdfRequestModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="remove-white-bg"
                backdrop="static"
                centered
            >
                <Modal.Body className="p-0">
                    <CloseIcon onClick={() => setPdfRequestModal(false)} className='modal-close-icon' />
                    <Form {...pdfRequestProps} />
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default RecommendedCaseStudies;