import { useState, useEffect } from 'react';
import { useHeadsObserver } from './hooks/hooks'
import Form from '../../components/Forms/Form';
import Share from './components/Share_2';
// import Share from './components/Share.tsx';

import '../../assets/css/blogs.css';
import { toast } from 'react-toastify';

const Contents = ({ url, headings }) => {
    const props = {
        template: 2,
        text_color: 'text-black',
        form_name: "blogs-page", // Page name
        fields: [{name: "email", required: true}],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form Submitted successfully')
              } else {
                toast.error(res.data)
              }
        }
    }

    const getClassName = (level) => {
        switch (level) {
            case 2:
                return 'head2'
            case 3:
                return 'head3'
            case 4:
                return 'head4'
            // case 5:
            //     return 'head3'
            // case 6:
            //     return 'head4'
            default:
                return null
        }
    }
    const [shouldExecute, setShouldExecute] = useState(false);
    const { activeId } = useHeadsObserver()

    useEffect(() => {
        if(headings.length){
            setShouldExecute(true)
        }
    }, [headings])

    useEffect(() => {
        if (shouldExecute) {
          console.log(activeId);
        }
    }, [shouldExecute, activeId]);

    return (
        <>
            <nav className='w-100 sticky-section toc-nav'>
                <ul>
                    {headings.map(heading => (
                        <li
                            id={`heading-${heading.id}`}
                            key={heading.id} 
                            className={getClassName(heading.level)}
                        >
                            <a
                                href={`#${heading.id}`}
                                onClick={(e) => {
                                    e.preventDefault()
                                    document.getElementById(heading.id).scrollIntoView({
                                        behavior: "smooth",
                                        block: "center"
                                    })
                                }}
                            >
                                {heading.text.length >= 80 ? heading.text.slice(0,77) + '...' : heading.text}
                            </a>
                        </li>
                    ))}
                </ul>

                <div className='d-flex flex-column align-items-start justify-content-center'>
                    <div className='w-60'>
                    <Share url={url} />
                    </div>
                    <div className='mt-3 w-100'>
                        <Form {...props} />
                    </div>
                </div>
            </nav>

            
        </>
    )
}

export default Contents;