import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirect = ({ toUrl }) => {
    const navigate = useNavigate();
    useEffect(() => window.location.href = toUrl);

    return (<></>)
}

export default Redirect;