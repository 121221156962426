
import tsrtc from '../../assets/img/travelbus-client/tsrtc.png'
import jabbar from '../../assets/img/travelbus-client/jabbartravels.png'
import demoimg from '../../assets/img/travelbus-client/demo.png'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSliderTravel = () => {
  const data = [
    {
      "test_name": "Venka Reddy",
      "test_image": demoimg,
      "test_desg": "Sr Manager Transportation/Trucking/Railroad ",
      "test_det": "Their pricing algorithm is giving the best prices for our buses",
      "test_company_img": tsrtc,
    },
    {
      "test_name": "Jameel J",
      "test_image": demoimg,
      "test_desg": "Managing Director & CEO Leisure, Travel & Tourism  ",
      "test_det": "In the highly competitive market,pricing plays a vital role in getting customers at the right time.Sciative had played this role exceptionally well",
      "test_company_img": jabbar,
    }

  ];
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", borderRadius: "30px" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", borderRadius: "30px" }}
        onClick={onClick}
      />
    );
  }
  const testimonialsettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />

  };
  return (
    <>
      <section className="">
        <div className="testimonials">
          <div className="container"   data-aos="new-animation">
            <h2 className="bigHead  text-center mb-5">
              See What Our Users Say About Us
            </h2>
            <Slider {...testimonialsettings}>
              {data.map((item) => (
                <div className="test_sli">
                  <div className="card">
                    <div className='test_content'>
                      <div className="tes_img">
                        <img src={item.test_company_img} alt={item.test_name + " photo"} />
                      </div>
                      <p className='tes_para'>
                        {item.test_det}

                      </p>
                    </div>
                    <div className="tes_aut">
                      <img src={demoimg} alt="img" />
                      <div className="tes_det">
                        <p className="tes_name"> {item.test_name},</p>
                        <p className="tes_detail"> {item.test_desg}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}


export default TestimonialSliderTravel;