import React, { useState, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/Themes/SciativeTheme';
import ReCAPTCHA from '../../components/common/ReCAPTCHA';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify';
import toast_config from '../../toast_config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import sha256 from 'crypto-js/sha256';

import 'react-toastify/dist/ReactToastify.css';

import sciativeLogo from '../../assets/img/sciative.png'
import loginImg from '../../assets/img/login-img.svg';
import padlock from '../../assets/img/padlock.jpg';
import isValidEmail from '../../services/validators/emailValidator';

import DOMPurify from 'dompurify';
// import ForgotPassword from './ForgotPassword';
import { adminURL } from '../../global';

const AdminLogin = () => {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [msg, setMsg] = React.useState('');
	const [captcha, setCaptcha] = useState('');
	const [modalOpen, setModalOpen] = React.useState(false);

	//Forgot password variables
	const [step, setStep] = useState(1);
	const [email2, setEmail2] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [helperText, setHelperText] = useState('');
	const [otp, setOtp] = useState('');
	const [password1, setPassword1] = useState("");
	const [showPassword1, setShowPassword1] = React.useState(false);
	const [password2, setPassword2] = useState("");
	const [showPassword2, setShowPassword2] = React.useState(false);
	const [invalidOTP, setInvalidOTP] = useState(false);
	const [OTPErrorText, setOTPErrorText] = useState(false);
	const [pswdError, setPswdError] = useState(false);
	const [pswdValidations, setPswdValidations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOTPSent, setIsOTPSent] = useState(false);
	const [sendOTPLoading, setSendOTPLoading] = useState(false);
	const [reSendOTPLoading, setReSendOTPLoading] = useState(false);
	const [confirmOTPLoading, setConfirmOTPLoading] = useState(false);
	const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

	const closeModal = (event, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setModalOpen(false);
		setStep(1);
		setEmail2('');
		setEmailError(false);
		setHelperText('');
		setOtp('');
		setPassword1('')
		setPassword2('')
		setShowPassword1(false)
		setShowPassword2(false);
		setInvalidOTP(false);
		setPswdError(false);
		setIsOTPSent(false);
	}

	const navigate = useNavigate();

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const login = async () => {
		if (!username) {
			toast.error('Please enter your username.');
			return;
		}
		if (!password) {
			toast.error('Please enter your password.');
			return;
		}
		if (!captcha) {
			toast.error('Please verify the ReCAPTCHA to login!');
			return;
		}
		try {
			const credentials = `${username}:${password}`;
  			const encodedCredentials = btoa(credentials); // Base64 encoding
			
			const response = await axios.post(`/login`, { captcha }, {
				headers: {
					'Authorization': `Basic ${encodedCredentials}`,
					'Content-Type': 'application/json',
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				navigate(`/niyantran-panel/${adminURL}/blogs-admin`)
			}
		} catch (err) {
			window.grecaptcha.reset();
			setCaptcha('');
			const response_status = err.response.status;
			if (response_status === 401 || response_status === 422 || response_status === 403) {
				toast.error(err.response.data.detail);
			}
			else if (response_status === 500) {
				toast.error("Something went wrong");
			}
		}
	}

	const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

	const handleMouseDownPassword1 = (event) => {
		event.preventDefault();
	};

	const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

	const handleMouseDownPassword2 = (event) => {
		event.preventDefault();
	};

	const handleResendOTP = async () => {
		const sanitizedEmail = DOMPurify.sanitize(email2);
		setReSendOTPLoading(true);
		try {
			const response = await axios.post(`/forgot-password/check-email`, { email: sanitizedEmail }, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response) {
				setReSendOTPLoading(false);
				if (response.status === 200) {
					toast.success('OTP sent successfully!');
				}
			}
		}
		catch (err) {
			setReSendOTPLoading(false);
			setInvalidOTP(true);
			if (err.response.status === 422) {
				setOTPErrorText("Unprocessable Entity");
			}
			else if (err.response.status === 403) {
				setOTPErrorText("Account/IP is blocked");
			}
			else if (err.response.status === 500) {
				setOTPErrorText("Internal Server Error");
			}
			else {
				setOTPErrorText("Something went wrong");
			}
		}
	}

	const handleSendOTP = async () => {
		setSendOTPLoading(true);
		const sanitizedEmail = DOMPurify.sanitize(email2);
		if(!sanitizedEmail) {
			setEmailError(true);
			setHelperText('Please enter email ID');
			setSendOTPLoading(false);
			return;
		}
		if (!isValidEmail(sanitizedEmail)) {
			setEmailError(true);
			setHelperText('Invalid Email ID');
			setSendOTPLoading(false);
			return;
		}
		setEmailError(false);
		setHelperText('');
		try {
			const response = await axios.post(`/forgot-password/check-email`, { email: sanitizedEmail }, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response) {
				setSendOTPLoading(false);
				if (response.status === 200) {
					setIsOTPSent(true);
				}
				else {
					setEmailError(true);
					setHelperText("Something went wrong");
				}
			}

		} catch (err) {
			setSendOTPLoading(false);
			setEmailError(true);
			if (err.response.status === 422) {
				setHelperText("Unprocessable Entity");
			}
			else if (err.response.status === 403) {
				setHelperText("Account/IP is blocked");
			}
			else if (err.response.status === 500) {
				setHelperText("Internal Server Error");
			}
			else {
				setHelperText("Something went wrong");
			}
		}
	}

	const handleConfirmOTP = async () => {
		setConfirmOTPLoading(true);
		const sanitizedEmail = DOMPurify.sanitize(email2);
		if (otp.length !== 6) {
			setInvalidOTP(true);
			setOTPErrorText('Enter 6 digit OTP');
			setConfirmOTPLoading(false);
			return;
		}
		try {
			setConfirmOTPLoading(true);
			const response = await axios.post(`/forgot-password/verify-otp`, { email: sanitizedEmail, otp: otp }, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response) {
				setConfirmOTPLoading(false);
				if (response.status === 200) {
					if(atob(response.data) === otp){
						setStep(step + 1);
					}else{
						setInvalidOTP(true);
						setOTPErrorText("Something went wrong");
					}
				}
				else {
					setInvalidOTP(true);
					setOTPErrorText("Something went wrong");
				}
			}
		}
		catch (err) {
			setConfirmOTPLoading(false);
			setInvalidOTP(true);
			if (err.response.status === 422) {
				setOTPErrorText("Unprocessable Entity");
			}
			else if (err.response.status === 500) {
				setOTPErrorText("Internal Server Error");
			}
			else if (err.response.status === 403) {
				setOTPErrorText("Account/IP is blocked");
			}
			else if (err.response.status === 401) {
				setOTPErrorText("Invalid OTP");
			}
			else {
				setOTPErrorText("Something went wrong");
			}
		}

	}

	const handleResetPassword = async () => {
		console.log(password1, typeof password1);
		console.log(password2);
		setResetPasswordLoading(true);
		if(!password1 || !password2) {
			setPswdValidations(["Please enter password"]);
			setResetPasswordLoading(false);
			return;
		}
		const sanitizedEmail = DOMPurify.sanitize(email2);
		const sanitizedPassword1 = DOMPurify.sanitize(password1);
		const sanitizedPassword2 = DOMPurify.sanitize(password2);
		setPswdValidations([]);
		if (sanitizedPassword1 !== password1 || sanitizedPassword2 !== password2) {
			setPswdValidations(['Character not allowed']);
			setResetPasswordLoading(false);
			return;
		}
		if(password1.length < 8) {
			setPswdValidations(["Password must be atleast 8 characters long"]);
			setResetPasswordLoading(false);
			return;
		}
		if (password1 !== password2) {
			setPswdError(true);
			setPswdValidations([]);
			setResetPasswordLoading(false);
		}
		else {
			setPswdError(false);
			let errors_arr = [];
			!(/[a-z]/.test(password1)) && errors_arr.push("Password must contain atleast one lowercase letter");
			!(/[A-Z]/.test(password1)) && errors_arr.push("Password must contain atleast one uppercase letter");
			!(/[0-9]/.test(password1)) && errors_arr.push("Password must contain atleast one number");
			!(/[!@#$%^&*]/.test(password1)) && errors_arr.push("Password must contain atleast one special character");
			!(password1.length >= 8) && errors_arr.push("Password must be atleast 8 characters long");
			setPswdValidations(errors_arr);

			if (errors_arr.length === 0) {
				try {
					const response = await axios.post(`/forgot-password/reset-password`, { email: sanitizedEmail, password: password1 }, {
						headers: {
							'Content-Type': 'application/json',
						},
					});
					if (response) {
						setResetPasswordLoading(false);
						if (response.status === 200) {
							closeModal();
							toast.success('Password reset successfully!');
						}
						else {
							setPswdValidations(["Something went wrong"]);
						}
					}
				}
				catch (err) {
					setResetPasswordLoading(false);
					if (err.response.status === 422) {
						setPswdValidations(["Unprocessable Entity"]);
					}
					else if (err.response.status === 500) {
						setPswdValidations(["Internal Server Error"]);
					}
					else {
						setPswdValidations(["Something went wrong"]);
					}
				}
			}
			else {
				setResetPasswordLoading(false);
			}
		}

	}

	return (
		<ThemeProvider theme={theme}>
			<div className='w-100 d-flex justify-content-center'>
				<section className='w-100 row d-flex justify-content-center align-items-center'>
					<div className='col-md-6 d-flex flex-column align-items-center justify-content-center'>
						<img className='' src={sciativeLogo} />
						<img className='admin-login-img' src={loginImg} />
					</div>
					<div className='col-md-6 d-flex justify-content-center p-md-5'>
						<div className='w-100 admin-card shadow-2 px-4 py-5 d-flex flex-column justify-content-center align-items-center'>
							<h3 className='bold'>Niyantran Panel</h3>
							<p className='medium-3 mb-0 text-muted'>Welcome Back!</p>

							<div className='w-100 px-3 mt-5'>
								<TextField value={username}
									onChange={(evt) => setUsername(evt.target.value)} className='w-100' label="Username" variant="outlined" />
							</div>
							<div className='w-100 px-3 mt-3'>
								<FormControl className='w-100' variant="outlined">
									<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
									<OutlinedInput
										value={password}
										onChange={(evt) => setPassword(evt.target.value)}
										type={showPassword ? 'text' : 'password'}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
										label="Password"
									/>
								</FormControl>
							</div>

							<div>
								<ReCAPTCHA setCaptcha={setCaptcha} />
							</div>

							<div className='login-btns w-100 px-3 mt-3 d-flex flex-column justify-content-center align-items-center'>
								<Button onClick={login} size='large' className='w-100 my-1' variant='contained'>Sign IN</Button>
								<Button onClick={() => setModalOpen(true)} size='large' className='w-100 my-1'>Forgot Password?</Button>
							</div>


						</div>
					</div>
				</section>



				{/* <ForgotPassword 
					open={modalOpen}
					onClose={closeModal}
				/> */}
				<Dialog
					open={modalOpen}
					onClose={closeModal}
					maxWidth='lg'
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					{
						step === 1 ?
							<>
								<DialogContent>
									<h4 className='bold'>Forgot Password?</h4>
									<div className='mt-3 w-100 row'>
										<div className='col-6 d-flex justify-content-center align-items-center'>
											<img className='padlock' src={padlock} />
										</div>
										<div className='col-6 d-flex flex-column justify-content-center'>
											<div className='border rounded p-3'>
												<p className='text-muted small'>Enter your email address for verification. A 6 digit code will be sent to your email address.</p>
												<div className='d-flex align-items-center gap-3'>
													<TextField error={emailError} helperText={emailError ? helperText : ""} id="email-1" label="Email ID" variant="standard" value={email2} onChange={(evt) => setEmail2(evt.target.value)} disabled={isOTPSent} />
													{
														sendOTPLoading ? <CircularProgress /> : <Button onClick={handleSendOTP} variant='contained' disabled={isOTPSent}>Send OTP</Button>
													}
												</div>
												{
													isOTPSent &&
													<Alert icon={<CheckIcon fontSize="inherit" />} severity="success" className='mt-2 py-0'>
														OTP has been sent
													</Alert>
												}
											</div>

											<div className='otp-input d-flex flex-column justify-content-center align-items-center border rounded p-3 mt-5'>
												<p className='text-muted small m-0'>Enter OTP</p>
												<OtpInput
												disabled={true}
													value={otp}
													onChange={setOtp}
													numInputs={6}
													inputType='number'
													renderInput={(props) => <input {...props} 
												/>}
												/>
												{
													invalidOTP ? <p className='text-danger small'>{OTPErrorText}</p> : null
												}
												{
													reSendOTPLoading ? <CircularProgress /> : <Button onClick={handleResendOTP} disabled={!isOTPSent}>Resend OTP</Button>
												}
												{
													confirmOTPLoading ? <CircularProgress /> : <Button variant='contained' disabled={!isOTPSent} onClick={handleConfirmOTP} >Confirm OTP</Button>
												}
											</div>
										</div>
									</div>
								</DialogContent>
								<DialogActions>
									<div className='w-100 p-2 d-flex justify-content-end'>
										<Button onClick={closeModal}>Cancel</Button>
									</div>
								</DialogActions>
							</>
							:
							null
					}
					{/* {
						step === 2 ?
							<>
								<DialogContent>
									<h4 className='bold'>Enter 6 Digit OTP</h4>
									<div className='mt-3 w-100 row'>
										<div className='col-6 d-flex justify-content-center align-items-center'>
											<img className='padlock' src={padlock} />
										</div>
										<div className='otp-input col-6 d-flex flex-column justify-content-center align-items-center'>
											<p className='text-muted small'>Enter your 6 digit OTP recieved on your email address.</p>
											<OtpInput
												value={otp}
												onChange={setOtp}
												numInputs={6}
												renderInput={(props) => <input {...props} />}
											/>
											{
												invalidOTP ? <p className='text-danger small'>{OTPErrorText}</p> : null
											}
											<Button onClick={handleResendOTP}>Resend OTP</Button>
										</div>
									</div>
								</DialogContent>
								<DialogActions>
									<div className='w-100 p-2 d-flex justify-content-end'>
										<Button onClick={closeModal}>Cancel</Button>
										{
											!loading ? <Button onClick={next}>Continue</Button> : <CircularProgress />
										}
									</div>
								</DialogActions>
							</>
							:
							null
					} */}
					{
						step === 2 ?
							<DialogContent>
								<h4 className='bold'>Reset Password</h4>
								<div className='mt-3 w-100 row'>
									<div className='col-5 d-flex justify-content-center align-items-center'>
										<img className='padlock' src={padlock} />
									</div>
									<div className='col-7 d-flex flex-column justify-content-center'>
										<p className='text-muted small'>Set a new password for your account.</p>
										<FormControl sx={{ m: 0.3, width: '100%' }} variant="standard">
											<InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
											<Input
												id="standard-adornment-password"
												type={showPassword1 ? 'text' : 'password'}
												onChange={(evt) => setPassword1(evt.target.value)}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword1}
															onMouseDown={handleMouseDownPassword1}
														>
															{showPassword1 ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
										<FormControl className='mt-2' sx={{ width: '100%' }} variant="standard">
											<InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
											<Input
												id="standard-adornment-password"
												type={showPassword2 ? 'text' : 'password'}
												onChange={(evt) => setPassword2(evt.target.value)}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword2}
															onMouseDown={handleMouseDownPassword2}
														>
															{showPassword2 ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
										{
											pswdError &&
											<p className='text-danger small'>
												Passwords do not match!<br />
											</p>
										}
										{
											pswdValidations.length > 0 &&
											<p className='text-danger small'>
												<ul>
													{
														pswdValidations.map((error, index) => {
															return <li key={index}>{error}</li>
														})
													}
												</ul>
											</p>
										}
										<Button onClick={handleResetPassword} variant='contained' className='mt-4'>Reset Password</Button>
									</div>
								</div>
							</DialogContent>
							:
							null
					}
				</Dialog>




				<ToastContainer 
					position={toast_config.position}
					autoClose={toast_config.autoClose}
					hideProgressBar={toast_config.hideProgressBar}
					closeOnClick={toast_config.closeOnClick}
					pauseOnHover={toast_config.pauseOnHover}
					draggable={toast_config.draggable}
					theme={toast_config.theme} 
				/>
			</div>
		</ThemeProvider>
	)
};

export default AdminLogin;