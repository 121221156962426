import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes"; 
import InnerCard from "../retail/innercard";
// import img1 from "../../assets/img/retail/automated-pricing/1.png";
// import img2 from "../../assets/img/retail/automated-pricing/2.png";
// import img3 from "../../assets/img/retail/automated-pricing/3.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";

import img1 from "../../assets/img/retail/automated-pricing/optimize_revenue_and_margin.png";
import img2 from "../../assets/img/retail/automated-pricing/gain_strategic_competitive_advantage.png";
import img3 from "../../assets/img/retail/automated-pricing/eliminate_manual_errors_and_guesswork.png";
import img4_ from "../../assets/img/retail/automated-pricing/become_highly_scalable.png";


import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";

import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavigationSection from "../retail/NavigationSection";
import { GlobalUrl, GlobalImg } from "../../global";
import BenefitSection from "../retail/BenefitSection";
import AOS from "aos";
import { useEffect, useState } from "react";
import b1 from "../../assets/img/icon/integration.png";
import b2 from "../../assets/img/icon/Swift.png";
import b3 from "../../assets/img/icon/Customer.png";
import b4 from "../../assets/img/icon/Real-time.png";
import b5 from "../../assets/img/icon/Technology.png";
import b6 from "../../assets/img/icon/Customizations.png";
import "aos/dist/aos.css";
const AutomatedPricing = ({ }) => {

  const end_point = "automated-pricing";
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const qa_list = [
    "Does your business react instantly to competitor changes, dynamically adjusting prices to stay ahead of the curve?",
    "Does your pricing tool easily manage and track millions of data points across diverse product ranges?",
    "Do you have access to real-time market insights which would help you price right?",
    "Do you have a solution that provides pricing consistency across all sales channels and locations?"
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }

  const navigation_section_props = {
    id: "automated_pricing",
    heading: "Why Automated Price Management Guarantees Revenue Lifts?",
    tabs: [
        {
          title: "Optimize Revenue and Margin",
          desc: "Our AI-driven pricing software is fast, accurate, and provides pricing recommendations based on simultaneous assessment of multiple decision criteria within seconds ensuring margin and revenue maximization by at least 21%.",
          img: img1 ,
          alt: "Optimize Revenue and Margin" 
        },
        {
          title: "Gain Strategic Competitive Advantage",
          desc: "With 99% product matching accuracy and near real-time refresh data rates, our solution stays on top of your competitors’ price movements to help you increase product margins leading to higher revenue sales.",
          img: img2,
          alt: "Gain Strategic Competitive Advantage"
        },
        {
          title: "Eliminate Manual Errors and Guesswork",
          desc: "Our automated pricing tool ensures minimal uncertainty in success; it also offers brownie time by freeing up employees to focus on other important tasks such as customer success and strategic planning.",
          img: img3,
          alt: "Eliminate Manual Errors and Guesswork"
        },
        {
          title: "Become Highly Scalable",
          desc: "Whether you have a few stores or operate on a global scale, our automated systems easily adapt to the ever-growing needs of your retail businesses.",
          img: img4_,
          alt: "Become Highly Scalable"
        }
    ]
  }
  const benefit_props = {
    id: "automated_pricing",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Data Integration and Analysis",
        desc: "Plug and play APIs for seamless setups and rapid Return on Investment(ROI).",
        img: b1,
        alt: "Data Integration and Analysis Image" 
      },
      {
        title: "Swift Adaptability to Market Changes",
        desc: "Quick pricing decisions are crucial since delays can be fatal. Real-time data refresh rates ensure accuracy and speed in data-driven decision-making to seize opportunities effectively.",
        img: b2 ,
        alt: "Swift Adaptability to Market Changes" 
      },
      {
        title: "Improved Customer Experience",
        desc: "Fair and competitive prices to the customers generate increased loyalty and repeat business by 25%.",
        img: b3 ,
        alt: "Improved Customer Experience" 
      },
      {
        title: "Real-time Actionable Insights",
        desc: "Prevent revenue leaks caused by missed opportunities due to incorrect inventory pricing.",
        img: b4 ,
        alt: "Real-time Actionable Insights" 
      },
      {
        title: "Technology",
        desc: "Sciative is powered by state-of-the-art AI/ML technology and powerful retail datasets which can automate your pricing strategies in real-time.",
        img: b5 ,
        alt: "Technology" 
      },
      {
        title: "Customizations",
        desc: "Our highly flexible solution and geographically agnostic tech stack can be customised to incorporate any specific needs you have.",
        img: b6 ,
        alt: "Customizations" 
      },

    ]
  }
  return (
    <>

      <HelmetProvider>
        <Helmet> 
          <title>Automated Pricing for Retail Industry | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.automatedpricingpage}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />

          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta property="og:type" content="website" />
          <meta name='description' content="Manual pricing holding you back? Sciative's AI optimizes pricing decisions in real-time, maximizing profits & boosting sales. Want to know more, connect us now" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Automated Pricing for Retail Industry | Sciative" />
          <meta property="og:description" content="Manual pricing holding you back? Sciative's AI optimizes pricing decisions in real-time, maximizing profits & boosting sales. Want to know more, connect us now" />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content='https://sciative.com' />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
        
          <meta property="og:image"  content={GlobalImg} />
        
         
          <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org/", 
                  "@type": "BreadcrumbList", 
                  "itemListElement": [
                    {
                      "@type": "ListItem", 
                      "position": 1, 
                      "name": "Home",
                      "item": "https://sciative.com"  
                    },
                    {
                      "@type": "ListItem", 
                      "position": 2, 
                      "name": "Retail",
                      "item": "https://sciative.com/retail"  
                    },
                    {
                      "@type": "ListItem", 
                      "position": 3, 
                      "name": "Unified Pricing Engine",
                      "item": "https://sciative.com/retail/automated-retail-price-management"  
                    }
                  ]
                }
              `}
            </script>
         

        </Helmet> 

        <InnerHeroRetail end_point={end_point} page_name="automatedpricing" page_heading="Automated Retail Price Management" page_desc1="Advanced science-driven price management automation" page_desc2=" for over 1800+ global retail categories."  button_name="Explore Price Management Tool"/>
        <RetailClient />

        <NavigationSection {...navigation_section_props} />

        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
        <BenefitSection {...benefit_props} />
      
        <MarketPlaceIntegration  end_point={end_point} />
        <RetailTestimonial dataHeading="We Deliver, Our Customers ‘ Speak" />
        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />

        <QAScore end_point={end_point} qa_list={qa_list} />

        <section className="qa_store">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgcomp} pageheading="Competition Intelligence" pagelink={AllRoutes.competitionintpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container"  data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>


    </>
  )
}
export default AutomatedPricing;