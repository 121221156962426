import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const BlogCard = ({ blog }) => {

    if (typeof blog === 'undefined') return null

    return (
        <div className='blog-card p-1'>
            <div className='d-flex flex-column justify-content-between'>
                <Link to={`/research-articles/${blog['url']}`}>
                    <div className='w-100 corner blog-card-img' style={{backgroundImage: `url(${blog['images']['img-main-img']['url'] ? blog['images']['img-main-img']['url'] : 'https://blog.kanalysis.com/wp-content/uploads/2023/01/placeholder-116.png'})`}} ></div>
                </Link>
                <div className='p-1'>
                    <div className='mt-1'>
                        <span className='small-2 text-muted'>{moment(blog['publishedDate']).format('Do MMMM, YYYY')}</span>
                    </div>
                    <div className='mt-2'>
                        <Link className='d-inline-block' to={`/research-articles/${blog['url']}`}>
                            <h6 title={blog['title']} className='bold text-black'>
                                {blog['title'].length > 53 ? blog['title'].slice(0, 53) + '...' : blog['title']}
                            </h6>
                        </Link>
                    </div>
                    <div className='w-100 d-flex flex-wrap'>
                        
                        <div className='small-2'>
                        <span className='mr-2 '><i className="fa-solid fa-book mr-1"></i> {blog['blogReadTime'] || '10'} min read <span className=''>|</span></span>
                            {blog['categories'].map((cat, index) => (
                                <React.Fragment key={index}>
                                    <a className='text-black' href={`/research-articles/category/${cat}`}>{cat}</a>
                                    {index !== blog['categories'].length - 1 && ', '}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogCard;