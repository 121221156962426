import { Button } from 'react-bootstrap';  
import rightarrow from '../../assets/img/right-arrow.png';
import { Link } from 'react-router-dom';
const ProductNumber = () => {

      return (
          <div className='p_number_div'>
            <div className="container"  data-aos="new-animation">
              <h2 className="bigHead whiteColor text-center mb-5">
                Sciative Delivers Results and Drives Business Growth
              </h2>
              <div className="row">
                  <div className="col-md-6 col-xl-4 p_div">
                    <div className="card">
                      <p className="p_number">
                        25%
                      </p>
                      <p className="p_desc">
                        Upturn in Rate of Sale
                      </p>
                    </div>
                  </div>
                  {/* col-md-4-end */}
                  <div className="col-md-6 col-xl-4 p_div">
                    <div className="card">
                      <p className="p_number">
                        38%
                      </p>
                      <p className="p_desc">
                      Hike in Sales with Scientific and Timely Decisions
                      </p>
                    </div>
                  </div>
                  {/* col-md-4-end */}
                  <div className="col-md-6 col-xl-2 p_div">
                    <div className="card padding_small">
                      <p className="p_number">
                        21%
                      </p>
                      <p className="p_desc p_div">
                      Rise in Profitability
                      </p>
                    </div>
                  </div>
                  {/* col-md-2-end */}
                  <div className="col-md-6 col-xl-2  p_div">
                    <div className="card padding_small">
                      <p className="p_number">
                        37%
                      </p>
                      <p className="p_desc text-lg-center">
                      Boost in Markdown Realisation
                      </p>
                    </div>
                  </div>
                  {/* col-md-5-end */}
                  <div className="col-md-6 col-xl-5 p_div">
                    <div className="card">
                      <p className="p_number">
                        65%
                      </p>
                      <p className="p_desc">
                        Enhancement in Response to Market Changes
                      </p>
                    </div>
                  </div>
                  {/* col-md-5-end */}
                  <div className="col-md-6 col-xl-4 p_div">
                    <div className="card">
                      <p className="p_number">
                        80%
                      </p>
                      <p className="p_desc">
                        Reduction in NPA Inventory
                      </p>
                    </div>
                  </div>
                  {/* col-md-4-end */}
                  <div className=" col-md-12 col-xl-3 p_content_div">
                    <p className='p_text'>Make Pricing your Superpower</p>
                    <Link to="/retail/book-a-demo?navigation=main">
                      <button className="transparent-button d-flex align-items-center">
                        <span style={{textAlign: 'left'}}>Explore Pricing Models </span>
                        <img src={rightarrow} alt="right-arrow icon" /></button>
                    </Link>
                  </div>
                
              </div>
            </div>

          </div>
        );
    }
    
    
    export default ProductNumber;