import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { deleteToken, fetchToken } from '../../Auth';
import axios from 'axios';
import { adminURL } from '../../global';
import '../../assets/css/adminSidebar.css';
import logo from '../../assets/img/logo-2.png';

const Sidebar = () => {

    const [superAdmin, setSuperAdmin] = useState(false);
    const [dashboards, setDashboards] = useState([
        ['blogs', 'Blogs', `/niyantran-panel/${adminURL}/blogs-admin`],
        ['caseStudies', 'Case Studies', `/niyantran-panel/${adminURL}/case-studies-admin`],
        ['roadAhead', 'Road Ahead Talks', `/niyantran-panel/${adminURL}/road-ahead-talks-management`],
        ['expert', 'Road Ahead Expert', `/niyantran-panel/${adminURL}/expert-management`],
        ['forms', 'Forms Management', `/niyantran-panel/${adminURL}/forms-management`],
        ['websiteContent', 'Website Content', `/niyantran-panel/${adminURL}/website-content`]
    ]);
    const [user, setUser] = useState({});
    const [active, setActive] = useState('blogs');
    const [open, setOpen] = useState(false);

    const icons = {
        'users': 'fa-solid fa-user',
        'forms': 'fa-solid fa-pencil',
        'blogs': "fa-regular fa-file-lines",
        'roadAhead': "fa-solid fa-microphone-lines",
        'expert': "fa-solid fa-headset",
        'caseStudies': "fa-solid fa-newspaper",
        'logs': "fa-solid fa-clipboard",
        'websiteContent':"fa-solid fa-link"
    }

    const navigate = useNavigate();
    const location = useLocation();

    const signOut = () => {
        // localStorage.removeItem("temitope");
        deleteToken()
        navigate(`/niyantran-panel/${adminURL}/login`);
    };

    const isSuperAdmin = async () => {
        try {
            const response = await axios.get(`/is_super_admin`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
              },
            });
            if (response.status == 200 && response.data !== undefined) {
              setSuperAdmin(response.data)
              if(response.data){
                setDashboards([['users', 'User Management', `/niyantran-panel/${adminURL}/user-management`], ...dashboards, ['logs', 'Action Logs', `/niyantran-panel/${adminURL}/admin-logs`]])
              }
            }
          } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
              navigate(`/niyantran-panel/${adminURL}/login`)
            }
          }
    }

    useEffect(() => {
        isSuperAdmin()
        if(window.location.href.includes('case')){
            setActive('caseStudies')
        }
        else if(window.location.href.includes('website')){
            setActive('websiteContent')
        }
        else if(window.location.href.includes('road')){
            setActive('roadAhead')
        }
        else if(window.location.href.includes('expert')){
            setActive('expert')
        }
        else if(window.location.href.includes('forms')){
            setActive('forms')
        }
        else if(window.location.href.includes('user')){
            setActive('users')
        }
        else if(window.location.href.includes('admin-logs')){
            setActive('logs')
        }

    }, [])

    return(
        <>
            <div className={open ? 'sidebar open' : 'sidebar'}>
                <div className="logo-details d-flex justify-content-center align-items-center">
                    <img style={{height: '2em'}} src={logo}/>
                </div>
                <ul className="nav-list">
                    {
                        dashboards.map(d => <MenuItem active={d[0] === active} link={d[2]} menuTitle={d[1]} icon={d[0] in icons ? icons[d[0]] : icons['default']} />)
                    }
                    
                    <li className="profile">
                        <div className="profile-details">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png" alt="profile"/>
                            <div className="name_job">
                                <div className="name">{user.first_name} {user.last_name}</div>
                                <div className="email text-white">{user.username}</div>
                            </div>
                        </div>
                        {/* <i className='bx bx-log-out' id="log_out" ></i> */}
                        <IconButton onClick={() => signOut()} id="log_out" aria-label="delete" size="large">
                          <LogoutIcon className="text-white" />
                        </IconButton>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Sidebar