import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { fetchToken } from '../Auth';
import { set } from "react-hook-form";

const AdminLogs = () => {

    const navigate = useNavigate();

    const [selectedEntity, setSelectedEntity] = useState("all");

    const [rows, setRows] = useState([]);
    const [logs, setLogs] = useState([]);
    const cols = useRef([
        { field: 'id', headerName: 'Sr. No.', width: 80, headerClassName: 'bg-grey-3 bold' },
        {
            field: 'userName',
            headerName: 'Username',
            sortable: true,
            filterable: false,
            headerClassName: 'bg-grey-3 bold',
            flex: 1
        },
        {
            field: 'entity',
            headerName: 'Entity',
            sortable: true,
            filterable: true,
            headerClassName: 'bg-grey-3 bold',
            flex: 1
        },
        {
            field: 'url',
            headerName: 'URL',
            sortable: true,
            filterable: false,
            headerClassName: 'bg-grey-3 bold',
            flex: 1
        },
        {
            field: 'action',
            headerName: 'Action taken',
            sortable: true,
            filterable: false,
            headerClassName: 'bg-grey-3 bold',
            flex: 1
        },
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            sortable: true,
            filterable: false,
            headerClassName: 'bg-grey-3 bold',
            flex: 1
        }
    ])

    const getLogs = async () => {
        try {
            const response = await axios.get(`/logs/get_admin_logs`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                let temp = [];
                response.data.forEach((item, idx) => {
                    temp.push({ id: idx + 1, userName: item['userName'], entity: item['entity'], url: item['entity_url'], timestamp: item['timestamp'], action: item['action'] });
                });
                setRows(temp);
                setLogs(temp);
            }
        } catch (err) {
            if (err.response.status === 401) {
                navigate('/niyantran-panel/4128/login')
            }
        }
    }

    const handleEntityChange = (e) => {
        const selectedEntity = e.target.value;
        setSelectedEntity(selectedEntity);
        if(selectedEntity === "all") 
            return setRows(logs);

        const filteredLogs = logs.filter(log => log.entity === selectedEntity);
        setRows(filteredLogs)
    }

    useEffect(() => {
        getLogs();
    }, [])

    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'></div>
                    <div className='col-8'>
                        <h1 className='bold '>Action Logs</h1>
                        <p className='medium-3 text-muted'>View actions performed by users</p>
                    </div>
                    <div className="col-3">
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Filter by Entity</Form.Label>
                                <Form.Select aria-label="filter by entity" value={selectedEntity} onChange={handleEntityChange}>
                                    <option value="all">All</option>
                                    <option value="blog">Blogs</option>
                                    <option value="">Case Studies</option>
                                    <option value="ra_talks - webinar">Road Ahead Talks - Webinars</option>
                                    <option value="ra_talks - expert">Road Ahead Talks - Experts</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </div>
                </div>

                <div className='mt-3 admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-12'>
                        {
                            rows.length ?
                                <DataGrid
                                    sx={{ borderCollapse: 'collapse', border: 'none' }}
                                    rows={rows}
                                    columns={cols.current}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 10 },
                                        },
                                    }}
                                    pageSizeOptions={[10, 20, 30, 40]}
                                    checkboxSelection={false}
                                    disableRowSelectionOnClick={true}
                                    headerClassName="table-header"
                                />
                                :
                                <Alert className="w-100" severity="info">No logs available.</Alert>
                        }
                    </div>
                </div>
            </section>
        </>
    )
};

export default AdminLogs;