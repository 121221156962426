import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";

const RetailInsideNumsix = ({ end_point, num_prop, page }) => {
  return (
    <>
      <section className="investor-result  why_result">
        <div className="container" data-aos="new-animation">
          <h2 className="bigHead text-center mb-5">
            {num_prop.heading ? num_prop.heading : "We Let Numbers Do the Talking"}
            
          </h2>
          <div className="row">
            {/* col-4 end */}
            {num_prop.tabs.map((tab, index) => {
              return (
                <div
                  className={`${
                    num_prop.tabs.length === 4 ? "col-md-3" : "col-md-4"
                  } num_border`}
                  key={index}
                >
                  <div className="num_div">
                    <p className="number">{tab.num}</p>
                    {/* <p className="num_desc">
                        Repeat Purchase Growth

                        </p> */}
                  </div>
                  <p className="num_desc_2">{tab.desc}</p>
                </div>
              );
            })}
            {/* col-4 end */}
          </div>
        </div>
      </section>
      {page !== "hotels" ? (
        <section className="retail_bg_cta">
          <div className="container">
            <div className="card ap_card">
              <p className="card_text">
                Deliver Customer Success at the Right Price!
              </p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button className="yellow-button">Book a Demo Now</button>
              </Link>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};
RetailInsideNumsix.defaultProps = {
  num_prop: {
    id: "num_prop",
    heading: "We Let Numbers Do the Talking",
    tabs: [
      {
        num: "30%",
        desc: "Improvement in operational efficiency.",
      },
      {
        num: "38%",
        desc: "Improvement in Sales with Scientific and Timely Decision",
      },
      {
        num: "21%",
        desc: "improvement in profitability",
      },
      {
        num: "37%",
        desc: "Improvement in Markdown Realization",
      },
      {
        num: "94%",
        desc: "Accurate Demand Forecasting",
      },
      {
        num: "65%",
        desc: "Improvement in Response to Market Changes",
      },
    ],
  },
};

export default RetailInsideNumsix;
