import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Form from "../Forms/Form";
import { toast } from 'react-toastify';
import axios from 'axios';

const ProfitCalculator = () => {
    const [step, setStep] = useState(0);

    //Step 1
    const [pricingType, setPricingType] = useState('');

    //Step 2
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [numBuses, setNumBuses] = useState(null);

    //Step 3
    const [source, setSource] = useState('');
    const [distance, setDistance] = useState(null);
    const [busType, setBusType] = useState('');
    const [dieselCharges, setDieselCharges] = useState('');
    const [commission, setCommission] = useState('');
    const [salary, setSalary] = useState('');
    const [permitCharges, setPermitCharges] = useState('');
    const [avgSeatPrice, setAvgSeatPrice] = useState('');
    const [destination, setDestination] = useState('');
    const [toll, setToll] = useState('');
    const [mileage, setMileage] = useState('');
    const [numSeats, setNumSeats] = useState('');
    const [marketingExpense, setMarketingExpense] = useState('');
    const [emi, setEMI] = useState('');
    const [misc, setMisc] = useState('');

    //Step 4
    const [rows, setRows] = useState([]);

    const navigate = useNavigate();
    

    const calculatePrices = async () => {
        try{    
            const response = await axios.post(`/calculate_profit`,
            {pricingType, name, phone, email, numBuses, source, distance, busType, dieselCharges, commission, salary, permitCharges, 
            avgSeatPrice, destination, toll, mileage, numSeats, marketingExpense, emi, misc},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined){
                console.log(response.data)
                let temp = []
                response.data.forEach(item => temp.push([item['name'], item['55'], item['65'], item['75'], item['85'], item['95']]))
                setRows(temp)
            }

        }catch(err){
            console.log(err)
        }
    }

    const back = () => {
        if (step === 1) setStep(1)
        if (step === 2) setStep(1)
        if (step === 3) setStep(2)
        if (step === 4) setStep(3)
    }

    const next = () => {
        if (step === 1) {
            if (pricingType !== 'dynamic-pricing') {
                setStep(2)
            } else {
                setStep(0)
            }
        }
        if (step === 2) setStep(3)
        if (step === 3) {
            setStep(4)
            calculatePrices()
            // cleanup()
        }

    }

    const cleanup = () => {
        setPricingType('')
        setName('')
        setNameValid(true)
        setPhone('')
        setPhoneValid(true)
        setEmail('')
        setEmailValid(true)
        setNumBuses(null)
        setSource('')
        setDistance('')
        setBusType('')
        setDieselCharges('')
        setCommission('')
        setSalary('')
        setPermitCharges('')
        setAvgSeatPrice('')
        setDestination('')
        setToll('')
        setMileage('')
        setNumSeats('')
        setMarketingExpense('');
        setEMI('')
        setMisc('')
    }

    const validateEmail = (email) => {
        return email.match(
            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,4}$/
        );
    }
    const validatePhone = (phone) => {
        return phone.match(/^[0-9+]+$/) && !phone.match(/^(.)\1+$/);
    }

    useEffect(() => {
        if (email && !validateEmail(email)) {
            setEmailValid(false)
        } else {
            setEmailValid(true)
        }
    }, [email])
    useEffect(() => {
        if (phone && !validatePhone(phone)) {
            setPhoneValid(false)
        } else {
            setPhoneValid(true)
        }
    }, [phone])

    return (
        <section id='profit-calculator' className="route-report bg-black px-4 py-2">
            <div className="container-xxl p-4 d-flex flex-column justify-content-center align-items-center rounded-4"   data-aos="new-animation">
                <h3 className="bold w-100 text-center mb-5 mt-md-2 mt-4 text-white">Calculate Profitabilty on Current Routes</h3>
                <button onClick={() => navigate('/travel/profit-calculator')} className='btn-yellow p-3'><i className="mr-2 fa-solid fa-calculator"></i> Calculate Profit</button>
            </div>


            <Dialog
                open={step === 1}
                onClose={() => setStep(0)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='sm'
            >
                <DialogContent>

                    <h3 className='bold'>Select Pricing Type</h3>
                    <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center p-2'>
                            <Checkbox checked={pricingType === 'standard'} onChange={(evt) => setPricingType('standard')} />
                            <label className='medium-2 thin'>Standard Pricing</label>
                        </div>
                        <div className='d-flex align-items-center p-2'>
                            <Checkbox checked={pricingType === 'dynamic'} disabled />
                            <label className='medium-2 thin'>Dynamic Pricing (Coming soon)</label>
                        </div>
                        <div className='d-flex align-items-center p-2'>
                            <Checkbox checked={pricingType === 'ai-dynamic'} onChange={(evt) => setPricingType('ai-dynamic')} />
                            <label className='medium-2 thin'>AI Dynamic Pricing</label>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <button className='mx-2 btn-outline' onClick={() => setStep(0)}>Close</button>
                        <button disabled={pricingType === ''} className='mx-2 btn-yellow' onClick={() => next()}>Next</button>

                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={step === 2}
                onClose={() => setStep(0)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogContent>
                    <h3 className='bold'>Fill In Your Details</h3>
                    <div className='mt-3 d-flex flex-column'>
                        <TextField
                            error={!nameValid}
                            className='my-2'
                            label="Operator Name*"
                            value={name}
                            onChange={(evt) => setName(evt.target.value)}
                            helperText={!nameValid ? "Invalid Operator Name!" : ''}
                        />
                        <TextField
                            error={!emailValid}
                            className='my-2'
                            label="Email Adress*"
                            value={email}
                            onChange={(evt) => setEmail(evt.target.value)}
                            helperText={!emailValid ? "Invalid email address!" : ''}
                        />
                        <TextField
                            error={!phoneValid}
                            className='my-2'
                            label="Mobile number*"
                            value={phone}
                            onChange={(evt) => setPhone(evt.target.value)}
                            helperText={!phoneValid ? "Invalid Mobile number!" : ''}
                        />
                        <h6 className='bold mt-3'>Number of Buses*</h6>
                        <div className='mt-2 d-flex flex-wrap'>

                            <div className='mx-2 d-flex p-1 align-items-center'>
                                <Checkbox checked={numBuses === '1-10'} onChange={() => setNumBuses('1-10')} />
                                <label className='medium-2 thin'>1-10</label>
                            </div>
                            <div className='mx-2 d-flex p-1 align-items-center'>
                                <Checkbox checked={numBuses === '10-25'} onChange={() => setNumBuses('10-25')} />
                                <label className='medium-2 thin'>10-25</label>
                            </div>
                            <div className='mx-2 d-flex p-1 align-items-center'>
                                <Checkbox checked={numBuses === '25-40'} onChange={() => setNumBuses('25-40')} />
                                <label className='medium-2 thin'>25-40</label>
                            </div>
                            <div className='mx-2 d-flex p-1 align-items-center'>
                                <Checkbox checked={numBuses === '40-60'} onChange={() => setNumBuses('40-60')} />
                                <label className='medium-2 thin'>40-60</label>
                            </div>
                            <div className='mx-2 d-flex p-1 align-items-center'>
                                <Checkbox checked={numBuses === '60-80'} onChange={() => setNumBuses('60-80')} />
                                <label className='medium-2 thin'>60-80</label>
                            </div>
                            <div className='mx-2 d-flex p-1 align-items-center'>
                                <Checkbox checked={numBuses === '80-100'} onChange={() => setNumBuses('80-100')} />
                                <label className='medium-2 thin'>80-100</label>
                            </div>
                            <div className='mx-2 d-flex p-1 align-items-center'>
                                <Checkbox checked={numBuses === '100+'} onChange={() => setNumBuses('100+')} />
                                <label className='medium-2 thin'>100+</label>
                            </div>

                        </div>
                    </div>
                    <div className='mt-3 d-flex justify-content-center align-items-center'>
                        <button className='mx-2 btn-outline' onClick={() => back()}>Back</button>
                        <button disabled={!name || !email || !phone || !numBuses || !nameValid || !emailValid || !phoneValid} className='mx-2 btn-yellow' onClick={() => next()}>Next</button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={step === 3}
                onClose={() => setStep(0)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogContent>
                    <h3 className='bold'>Tell us about your Business</h3>
                    <div className='mt-3 w-100 row'>
                        <div className='col-6 p-3'>
                            <TextField
                                className='w-100 my-2'
                                label="Source*"
                                value={source}
                                onChange={(evt) => setSource(evt.target.value)}
                                size="small"
                            />
                            <TextField
                                className='w-100 my-2'
                                label="Distance*"
                                type='number'
                                value={distance}
                                onChange={(evt) => setDistance(evt.target.value)}
                                size="small"
                            />
                            <FormControl size="small" className='my-2 w-100'>
                                <InputLabel>Bus Type*</InputLabel>
                                <Select
                                    className='w-100'
                                    value={busType}
                                    label="Bus Type*"
                                    onChange={(evt) => setBusType(evt.target.value)}
                                >
                                    <MenuItem value='ac-sleeper'>AC Sleeper</MenuItem>
                                    <MenuItem value='non-ac-sleeper'>Non-AC Sleeper</MenuItem>
                                    <MenuItem value='ac-seater'>AC Seater</MenuItem>
                                    <MenuItem value='non-ac-seater'>Non-AC Seater</MenuItem>
                                    <MenuItem value='ac-seater-sleeper'>AC Seater/Sleeper</MenuItem>
                                    <MenuItem value='non-ac-seater-sleeper'>Non-AC Seater/Sleeper</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                value={dieselCharges}
                                onChange={(evt) => setDieselCharges(evt.target.value)}
                                label="Diesel Charges (per ltr)*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={commission}
                                onChange={(evt) => setCommission(evt.target.value)}
                                label="% Sales Commission (OTA like Redbus)*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={salary}
                                onChange={(evt) => setSalary(evt.target.value)}
                                label="Driver + Conductor Salary (per month)*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={permitCharges}
                                onChange={(evt) => setPermitCharges(evt.target.value)}
                                label="Quarterly Permit Charges*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={avgSeatPrice}
                                onChange={(evt) => setAvgSeatPrice(evt.target.value)}
                                label="Average Seat Price*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                        </div>
                        <div className='col-6 p-3'>
                            <TextField
                                value={destination}
                                onChange={(evt) => setDestination(evt.target.value)}
                                label="Destination*"
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={toll}
                                onChange={(evt) => setToll(evt.target.value)}
                                label="Toll*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={mileage}
                                onChange={(evt) => setMileage(evt.target.value)}
                                label="Mileage (km/ltr)*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={numSeats}
                                onChange={(evt) => setNumSeats(evt.target.value)}
                                label="Number of Seats*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={marketingExpense}
                                onChange={(evt) => setMarketingExpense(evt.target.value)}
                                label="Marketing Expense (per seat)*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={emi}
                                onChange={(evt) => setEMI(evt.target.value)}
                                label="Bus Monthly EMI*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                            <TextField
                                value={misc}
                                onChange={(evt) => setMisc(evt.target.value)}
                                label="Miscallaneous Charges*"
                                type='number'
                                className='w-100 my-2'
                                size='small'
                            />
                        </div>
                        <div className='mt-3 d-flex justify-content-center align-items-center'>
                            <button className='mx-2 btn-outline' onClick={() => back()}>Back</button>
                            <button disabled={!misc || !emi || !marketingExpense || !numSeats || !mileage || !toll || !destination || !avgSeatPrice || !permitCharges || !salary || !commission || !dieselCharges || !busType || !distance || !source} className='mx-2 btn-yellow' onClick={() => next()}>Calculate</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={step === 4}
                onClose={() => setStep(0)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogContent>
                    <h3 className='bold'>Profit Report</h3>
                    <div className='mt-3'>
                        <table className="w-100 profit-table">
                            <thead>
                                <tr>
                                    <th scope="col">Occupancy</th>
                                    <th scope="col">55%</th>
                                    <th scope="col">65%</th>
                                    <th scope="col">75%</th>
                                    <th scope="col">85%</th>
                                    <th scope="col">95%</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rows.map((item, key) => (
                                        <tr className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bg-grey-4' : ''} key={key}>
                                            <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>{item[0]}</td>
                                            <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>{item[1]}</td>
                                            <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>{item[2]}</td>
                                            <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>{item[3]}</td>
                                            <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>{item[4]}</td>
                                            <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>{item[5]}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-3 d-flex justify-content-center align-items-center'>
                        <button className='mx-2 btn-outline' onClick={() => back()}>Back</button>
                        <button onClick={() => setStep(0)}>Close</button>
                    </div>
                </DialogContent>
            </Dialog>
        </section>
    )
}

export default ProfitCalculator;