import {useState, useEffect} from 'react';
import Form from '../Forms/Form';
import NewsCard from '../common/NewsCard';
import axios from 'axios';
import moment from 'moment';
import img1 from '../../assets/img/route-reports/img-1.png'
import img2 from '../../assets/img/route-reports/img-2.png'
import img3 from '../../assets/img/route-reports/img-3.png'
import img4 from '../../assets/img/route-reports/img-4.png'
import img5 from '../../assets/img/route-reports/img-5.png'
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import { toast } from 'react-toastify';

const RouteReports = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, [])
    const [reports, setReports] = useState([]);

    const getReports = async () => {
        try {
            const response = await axios.get(`/route_reports/get_route_reports`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
            if (response.status === 200 && response.data !== undefined) {
                setReports(response.data)
            }

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getReports()
    }, [])

    const props = {
        template: 2,
        form_name: "route-reports-page", // Page name
        fields: [{
            name: "email",
            required: true
        }],
        recipients_group_id: 1,
        country: "IN",
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form Submitted successfully')
              } else {
                toast.error(res.data)
              }        
        }
    }

    return (
        <HelmetProvider>
        <Helmet> 
       <title>Route Reports for India's biggest bus routes! | Sciative </title>
       <link rel="canonical" href={`${GlobalUrl}${AllRoutes.routereports}`} />
       <meta charset="utf-8" />
       <meta http-equiv="X-UA-Compatible" content="IE=edge" />

       <meta property="type" content="website" />
       <meta name="theme-color" content="#ffffff" />
       <meta name="_token" content="" />
       <meta name="robots" content="noodp" />

       <meta name='description' content="Gain Valuable Insights into the Indian Intercity Bus Industry with Our Detailed Route Reports, Analyzing Supply and Demand Trends for Key Routes. " />
       <meta property="og:locale" content="en_US" />
       <meta property="og:title" content="Route Reports for India's biggest bus routes! | Sciative " />
       <meta property="og:description" content="Gain Valuable Insights into the Indian Intercity Bus Industry with Our Detailed Route Reports, Analyzing Supply and Demand Trends for Key Routes. " />
       
       <meta property="og:hashtag" content="#ai" />
       <meta content="image/*" property="og:image:type" />
       <meta property="og:url" content={`${GlobalUrl}${AllRoutes.routereports}`} />
       <meta property="og:site_name" content="Sciative Solutions - We Price Right" />

       <meta property="og:image"  content={GlobalImg} />
       <meta property="og:type" content="website" />
       </Helmet>
            <section className="smallBanner news_bg">
                <div className="container"  data-aos="new-animation">
                    <h1 className="about_para w-80 bold">
                    Intercity Bus Route Reports: Unlock Key Insights for Smart Travel Planning and Optimized Route Management
                    </h1>
                    <div className='mt-5 col-sm-12 col-md-6'>
                        <p className="bold about_para_2 mb-4">
                        Subscribe to our route reports for more data-driven insights
                        </p>
                        <Form {...props} />
                    </div>
                </div>
            </section>
            <section className="career_img">
                <div className="container d-flex flex-column align-items-center"  data-aos="new-animation">
                    <div className='w-100 '>
                        <h2 className='bold text-center'>Navigate the Challenges of Pricing with Confidence Through <br className='d-none d-md-block ' /> Our Insightful Route Reports</h2>
                        <p className='text-center'>Gain valuable insights for smarter travel planning with our intercity bus route reports. Whether you're booking tickets or optimizing pricing strategies, our comprehensive reports provide key data on intercity bus routes.</p>
                    </div>

                    <div className='mt-4 row'>
                        {
                            reports.map((item, idx) => 
                                <div key={idx} className='col-lg-3 col-md-4 col-sm-12 my-3 p-1 d-flex justify-content-center align-items-center'>
                                    <NewsCard fileName={item['fileName']} type='route-report' img={img2} date={moment(item['publishedDate']).format('Do MMMM, YYYY')} title={item['reportName']} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </HelmetProvider>
    )
}

export default RouteReports;