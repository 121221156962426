import clientimg from '../../assets/img/clients/nidhi-nirmal.jpg'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
const RetailTestimonial = ({dataHeading}) => {
   
    return(
        <section className="client_det">
        <div className="container"  data-aos="new-animation">
            <h2 className="bigHead mb-5">Discover What Our Customers Love About Us </h2>
            <div className="card">
                <div className="flex_card">
                    <div className="card_1">
                        <img src={clientimg} alt="Nidhi Nirmal" />
                        <div className="client_desc">
                            <p className="namec">Nidhi Nirmal,</p>
                            <p className="named">Co Founder and Business Head</p>
                        </div>
                    </div>
                   
                    <img src={kkimg} alt="KisanKonnect logo" className="kk_img" />
                </div>
                <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
            </div>
        </div>
    </section>
    )

}
RetailTestimonial.defaultProps = {
    dataHeading: "See what our clients ‘ speak about our product",
  };
export default RetailTestimonial