import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NavigationSection({ id, heading, tabs }) {
    return (
        <>
            <section className="investor_edge content_edge">
                <div className="container"  data-aos="new-animation">
                    <h2 className="bigHead text-center mb-5">{heading}</h2>
                    <Tab.Container defaultActiveKey={`${id}_tab_0`}>
                        <Row>
                            <Col xl={5}>
                                <Nav variant="pills" className="flex-column">
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <Nav.Item key={index}>
                                                    <Nav.Link eventKey={`${id}_tab_${index}`}>
                                                        {tab.title}
                                                        <p>
                                                            {tab.desc}
                                                        </p>
                                                        <div className="investor_edge_div res_img">
                                                            <img src={tab.img} alt={tab.alt} />
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            );
                                        })
                                    }
                                </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                                <Tab.Content>
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <Tab.Pane key={index} eventKey={`${id}_tab_${index}`}>
                                                    <div className="investor_edge_div">
                                                        <img src={tab.img} alt={tab.alt} />
                                                    </div>
                                                </Tab.Pane>
                                            );
                                        })
                                    }
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>

                </div>
            </section>
        </>
    );
}

export default NavigationSection;