
import '../../assets/css/main.scss';

import { Button } from 'react-bootstrap';



import ContactTemplate from "../common/contact";
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
import { Helmet, HelmetProvider } from "react-helmet-async";

const TravelDemo = ({ }) => {


    return (
        <HelmetProvider>
        <Helmet> 
       <title>Dynamic Pricing for Intercity Coaches | Sciative</title>
       <link rel="canonical" href={`${GlobalUrl}${AllRoutes.travelcontact}`} />
       <meta charset="utf-8" />
       <meta http-equiv="X-UA-Compatible" content="IE=edge" />

       <meta property="type" content="website" />
       <meta name="theme-color" content="#ffffff" />
       <meta name="_token" content="" />
       <meta name="robots" content="noodp" />

       <meta name='description' content="No more guesswork, no more empty seats. Our dynamic pricing solution optimizes ticket prices in real-time, ensuring fair prices, maximized revenue. " />
       <meta property="og:locale" content="en_US" />
       <meta property="og:title" content="Dynamic Pricing for Intercity Coaches | Sciative" />
       <meta property="og:description" content="No more guesswork, no more empty seats. Our dynamic pricing solution optimizes ticket prices in real-time, ensuring fair prices, maximized revenue. " />
       
       <meta property="og:hashtag" content="#ai" />
       <meta content="image/*" property="og:image:type" />
       <meta property="og:url" content='https://sciative.com' />
       <meta property="og:site_name" content="Sciative Solutions - We Price Right" />

       <meta property="og:image"  content={GlobalImg} />
       <meta property="og:type" content="website" />
       </Helmet>
            <ContactTemplate 
                country="IN"
                title_upper={"Get Onboard: Revolutionize Your Bus Business Now with Sciative!"} 
                text={"The future of bus pricing is here: Empowering operators with dynamic pricing solutions."} 
                client={"travel_client"} 
            />
        </HelmetProvider>
    );
}


export default TravelDemo;