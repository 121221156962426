import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import "../../../assets/css/road_ahead_talks/create_webinar.css"
import React, { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { adminURL } from "../../../global";

function ExpertManagement() {

    const navigate = useNavigate();

    const [ShowCreateNewModal, setShowCreateNewModal] = useState(false);

    const handleCreateNewModalShow = () => {
        setShowCreateNewModal(!ShowCreateNewModal);
    }

    const [rows, setRows] = React.useState([]);

    const handleEdit = (id) => {
        navigate(`/niyantran-panel/${adminURL}/edit-expert/${id}`);
    }

    const columns = React.useMemo(
        () => [
            // { field: 'id', headerName: 'Sr. No.', type: 'number', flex: 1, headerClassName: 'text-left bg-grey-3 bold' },
            { field: 'name', headerName: 'Name', type: 'string', flex: 1, headerClassName: 'text-left bg-grey-3 bold'},
            { field: 'designation', headerName: 'Designation', type: 'string', flex: 1, headerClassName: 'text-left bg-grey-3 bold'},
            { field: 'affiliation', headerName: 'Affiliation', type: 'string', flex: 1, headerClassName: 'text-left bg-grey-3 bold'},
            { field: 'bio', headerName: 'Bio', type: 'string', flex: 1, headerClassName: 'text-left bg-grey-3 bold'},
            {
                field: 'edit',
                type: 'actions',
                headerName: 'Edit', flex: 1, headerClassName: 'bg-grey-3 bold',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={() => handleEdit(params.id)}
                    />
                ],
            },
        ],
        [handleEdit]
    );

    const getExperts = async () => {
        try {
            const response = await axios.get(`/ra_talks/get_all_experts`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setRows(response.data);
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    function getRowId(row) {
        return row.id_;
    }

    useEffect(() => {
        getExperts();
    }, [])


    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'></div>
                    <div className='col-8'>
                        <h1 className='bold '>Road Ahead - Expert Management</h1>
                        <p className='medium-3 text-muted'>Create and manage experts & speakers created for webinars.</p>
                    </div>
                    <div className='col-3 d-flex flex-column justify-content-center align-items-center'>
                        <Button variant="contained" size='large' onClick={() => navigate(`/niyantran-panel/${adminURL}/add-expert`)}>Create New Expert</Button>
                    </div>
                </div>

                <div className='mt-3 admin-card corner row p-3 shadow-2 w-100'>
                    {
                        typeof columns !== 'undefined' && columns.length && rows.length ?
                            <div>
                                <DataGrid 
                                    columns={columns} 
                                    rows={rows.map((item, index) => ({ id: index + 1, ...item }))} 
                                    getRowId={getRowId} 
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 10 },
                                        }
                                    }}
                                    pageSizeOptions={[10, 20, 30, 40]}
                                    checkboxSelection={false}
                                    disableRowSelectionOnClick={true}
                                />
                            </div>
                            :
                            <Alert severity="info">No Blogs created.</Alert>
                    }
                </div>
            </section>
        </>
    );
}

export default ExpertManagement;