import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function NavigationBody({heading, desc, img, alt, btn_link, btn_text}) {
    return (
        <>
            <h2 className="text-center bigHead  mb-5">{heading}</h2>
            <Row>
                <Col xl={5}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">
                                <p>
                                    {desc}
                                </p>
                                <div className="investor_edge_div res_img">
                                    <img src={img} alt={alt} className=" " />
                                </div>
                                <Link to={btn_link} className="tab_link_btn">
                                    <button className="transparent-button">{btn_text} <span class="material-symbols-outlined">east</span></button>
                                </Link>

                            </Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Col>
                <Col xl={7} className="second_div">
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div className="investor_edge_div">
                                <img src={img} alt={alt} />
                            </div>
                        </Tab.Pane>

                    </Tab.Content>
                </Col>
            </Row>
        </>
    );
}

export default NavigationBody;