import { useState, useEffect } from 'react';
import {
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share';

const Share = ({ url, title, imageUrl }) => {
    const mode = 'PROD';
    const [shareUrl, setShareUrl] = useState('');

    useEffect(() => {
      if (mode === 'DEV') {
          setShareUrl('https://192.168.1.74:3000/research-articles/' + String(url))
      } else {
          setShareUrl(window.location.href);
          // setShareUrl('https://www.sciative.com/research-articles/' + String(url));
      }
    }, [])

    return (
        <div className='d-flex justify-content-between align-items-center sm-buttons'>
            <FacebookShareButton 
              url={shareUrl} className='mx-1'
            >
              <FacebookIcon size={30} />
            </FacebookShareButton>

            <LinkedinShareButton 
              url={shareUrl} className='mx-1'
            >
              <LinkedinIcon size={30} />
            </LinkedinShareButton>

            <WhatsappShareButton url={shareUrl} className='mx-1'>
              <WhatsappIcon size={30} />
            </WhatsappShareButton>

            <TwitterShareButton url={shareUrl} className='mx-1'>
              <div className='p-1 bg-black twitter-btn' style={{width: '30px', height: '30px'}}><i className="text-white fa-brands fa-x-twitter"></i></div>
            </TwitterShareButton>

            <EmailShareButton url={shareUrl} className='mx-1'>
              <EmailIcon size={30} />
            </EmailShareButton>
        </div>
    )
}

export default Share;
