import { Link } from 'react-router-dom';
import brioLogo from '../../assets/img/brio.png'
import AllRoutes from '../../AllRoutes';

const RetailHeader = ({ end_point }) => {
return( 
    <>
<div className="banner_logo_sticky">



<div className="row w-100 banner_logo_div">
<div className="col-md-3">
    <a href={AllRoutes.retail}>
        <img src={brioLogo} alt="Brio Logo" id="banner-logo" />
    </a>
</div>
<div className="col-md-9 text-md-end d-none d-md-block">
    <ul>
        {/* <li><a href="#key-features">KEY FEATURES</a></li> */}
        <li className="has_menu "><a  className="menu_link">Products</a>
        <div className="mega-box">
            <div className="content row">
            
                <div className="col-md-4">
                    <a href={AllRoutes.automatedpricingpage}>
                        <h5 className="nav_head active">Automated Retail Price Management</h5>
                        <p className='first_p'>Science-driven price automation for +1800 categories</p>
                    </a>
                    
                </div>
                {/* col-4 end */}
                <div className="col-md-4">
                    <a href={AllRoutes.unifiedpricingpage}>
                        <h5 className="nav_head">Unified Retail Pricing Software</h5>
                        <p className='first_p'>Manage prices across all selling channels with just one click</p>
                    </a>
                   
                </div>
                {/* col-4 end */}
                <div className="col-md-4">
                    <a href={AllRoutes.competitionintpage}>
                        <h5 className="nav_head">Competition Intelligence in Retail</h5>
                        <p  className='first_p'>Track your competitor's pricing with ease</p>
                    </a>
                  
                </div>
                {/* col-4 end */}
                
                <div className="col-md-4">
                    <a href={AllRoutes.goalbasedpage}>
                        <h5 className="nav_head">Goal Driven Retail Pricing</h5>
                        <p className='first_p'>Predict demand for each SKU and respective prices</p>
                    </a>
                </div>
                {/* col-4 end */}
                <div className="col-md-4">
                    <a href={AllRoutes.pricingconsultpage}>
                        <h5 className="nav_head">Retail Pricing Consulting</h5>
                        <p className='first_p'>Get inspired with our industry analysis and opinion</p>
                    </a>
                </div>
                {/* col-4 end */}
               
                <div className="col-md-4">
                    <a href={AllRoutes.otherdriver}> 
                        <h5 className="nav_head">Other Drivers of Pricing</h5>
                        <p className='first_p'>Value added products for boosting retail growth</p>
                    </a>
                </div>
                {/* col-4 end */}
             
                
            </div>
            </div>
        </li>
        <li className="has_menu"><a  className="menu_link">Solutions</a>
        <div className="mega-box">
            <div className="content content_2 row">
            
                <div className="col-md-4">
                <h5 className="nav_head nav_head_2 active">By Need</h5>
                    <a href={AllRoutes.automated_dp}>
                      <p>Time Saving with an Automated Pricing Tool</p>
                    </a>
                    <a href={AllRoutes.pricingautomation}>
                      <p>Automated Pricing Across Channels</p>
                    </a>
                    <a href={AllRoutes.comppricetrack}>
                      <p>Competitive Price Tracking</p>
                    </a>
                    <a href={AllRoutes.marketpricing}>
                      <p>Market Driven Pricing</p>
                    </a>
                    
                </div>
                {/* col-4 end */}
                <div className="col-md-8">
                    <div className="row has_menu_2">
                        <div className="col-md-4">
                            <h5 className="nav_head nav_head_2 active">By Type</h5>
                            <a href={AllRoutes.retailers}>
                            <p>For Retailers</p>
                            </a>
                            <a href={AllRoutes.marketintel}>
                            <p>For Marketplaces</p>
                            </a>
                            <a href={AllRoutes.brandseller}>
                            <p>For Brands and Sellers</p>
                            </a>
                          
                            
                        </div>
                        {/* col-4 -end */}
                        <div className="col-md-4">
                            <h5 className="nav_head nav_head_2 active">By Industry</h5>
                            <a href={AllRoutes.electronics}>
                            <p>Electronics</p>
                            </a>
                            <a href={AllRoutes.hwb}>
                            <p>Health, Wellness & Beauty</p>
                            </a>
                            <a href={AllRoutes.food}>
                            <p>Food & Consumables</p>
                            </a>
                            <a href={AllRoutes.fashion}>
                            <p>Fashion & Apparel</p>
                            </a>
                            
                        </div>
                        {/* col-4 -end */}
                        <div className="col-md-4">
                            <h5 className="nav_head nav_head_2 active">By Roles</h5>
                            <a href={AllRoutes.cxodecision}>
                            <p>CXOs & Decision Makers</p>
                            </a>
                            <a href={AllRoutes.categorymanagers}>
                            <p>Category Managers</p>
                            </a>
                           
                            
                        </div>
                        {/* col-4 -end */}
                    </div>
                </div>

             
                
            </div>
        </div>
        </li>
        <li><a href="/retail/why-us">Why Us</a></li>
        <li><Link to={`/retail/book-a-demo?navigation=${end_point}`} className="yellow-button p-2">Book a Demo</Link></li>
    </ul>
</div>
</div>
</div></>)
}

export default RetailHeader;