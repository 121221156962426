import { Link } from 'react-router-dom';
import zettaLogo from '../../assets/img/hotels/zettaprice.png'
import AllRoutes from '../../AllRoutes';

const HotelHeader = ({ end_point }) => {
return( 
    <>
<div className="banner_logo_sticky">



<div className="row w-100 banner_logo_div zetta-banner-div">
<div className="col-md-3">
    <a href={AllRoutes.hotel}>
        <img src={zettaLogo} alt="Zettaprice Logo" id="banner-logo" />
    </a>
</div>
<div className="col-md-9 text-md-end d-none d-md-block">
    <ul>
        {/* <li><a href="#key-features">KEY FEATURES</a></li> */}
        {/* <li className="has_menu "><a  className="menu_link">Customers</a> */}
        <li><a href={AllRoutes.hotelcustomer}  style={{textTransform: "initial"}}  >CUSTOMERS</a></li>
        <li><a href={AllRoutes.hotelfaq} style={{textTransform: "initial"}}>FAQs</a></li>
      
        <li><Link to={AllRoutes.hotelcontact} className="yellow-button p-2">Book a Demo</Link></li>
    </ul>
</div>
</div>
</div></>)
}

export default HotelHeader;