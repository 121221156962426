import { Tab, Col, Nav, Navbar, Container, NavDropdown, Button, Row } from "react-bootstrap";
import AllRoutes from "../../AllRoutes";
import viajeLogo from '../../assets/img/viaje-logo.png'

const TravelHeaderMobile = ({scrollToKeyFeatures, scrollToProfitCalculator, scrollToRouteReports}) => {

    return(
        <Navbar style={{ zIndex: 200, position: 'fixed', top: '60px'}} collapseOnSelect expand="xl" className={`p-0`}>
                <Container>
                  <Navbar.Brand href={AllRoutes.travel}> <img src={viajeLogo} alt="Viaje Logo" id="brand-logo-2" /></Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav travel-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                      <Nav.Link onClick={scrollToKeyFeatures} >Key Features</Nav.Link>
                      <Nav.Link onClick={scrollToRouteReports} >Route Reports</Nav.Link>
                      <Nav.Link onClick={scrollToProfitCalculator} >Profit Calculator</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
    )
}

export default TravelHeaderMobile;