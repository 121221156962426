import captera from '../../assets/img/travel-award/captera.png'
import getapp from '../../assets/img/travel-award/get-app.png'
import softwareadvice from '../../assets/img/travel-award/software-advice.png'
import QRC from '../../assets/img/travel-award/QRC_logo.png'


const CapteraAchievements = ({props}) =>{
return( <section className={"black-bg "+props} id="achievements" >
<div className='recog_div travel_recog' data-aos="new-animation">
 <div className="container">
 <h2 className="bigHead text-center mb-5 ">Recognised by the Best in the Industry</h2>
   <div className="row">
       <div className=" col-md-6 col-xl-3">
         <div className="recog_img_div">
            <img src={getapp} alt="GetApp Logo" />
            <p className=" award_desc">Best Functionality & Features</p>
           
             <div className="">
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
             </div>
             <div className="">
                 <p className="award_name">GetApp 2023</p>
             </div>
            
         </div>
       </div>
       {/* col-12 col-md-6 col-xl-3 end */}
      
    
       <div className=" col-md-6 col-xl-3">
         <div className="recog_img_div">
            <img src={softwareadvice} alt="Software Advice Logo" />
            <p className="award_desc">Best Customer Support <br /> | Most Recommended</p>
           
             <div className=" ">
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
             </div>
             <div className="">
                 <p className="award_name">Software Advice 2023</p>
             </div>
            
         </div>
       </div>
      
       {/* col-12 col-md-6 col-xl-3 end */}
       <div className=" col-md-6 col-xl-3">
         <div className="recog_img_div">
            <img src={captera} alt="Captera Logo" />
            <p className="award_desc">Best Ease of Use | Best Value </p>
            <div className=" ">
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
             </div>
             <div className="">
                 <p className="award_name">Capterra 2023</p>
             </div>
           
         </div>
       </div>
       {/* col-12 col-md-6 col-xl-3 end */}
        {/* col-12 col-md-6 col-xl-3 end */}
        <div className=" col-md-6 col-xl-3">
         <div className="recog_img_div">
            <img src={QRC} alt="QRC Logo" />
            <p className="award_desc">Certified for Web Application Security Testing</p>
           
             <div className=" ">
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
                 <i class="fa-solid fa-star"></i>
             </div>
             <div className="">
                 <p className="award_name">QRC 2024</p>
             </div>
            
         </div>
       </div>
   </div>
 </div>
 
</div>
</section>)

}
export default CapteraAchievements;