const categories = [
    'Competition Intelligence',
    'Dynamic Pricing',
    'E-Commerce',
    'Hospitality',
    'Intercity Coaches',
    'Price Elasticity',
    'Retail',
    'Road Ahead',
    'Road Ahead Air',
    'Road Ahead Bus',
    'Road Ahead Retail',
    'Travel'
]

export default categories;