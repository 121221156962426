import ContactTemplate from "../common/contact";
import '../../assets/css/main.scss';
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
import { Helmet, HelmetProvider } from "react-helmet-async";
const InvestorsContactPage = ({ }) => {

    return (
        <>
          <HelmetProvider>
            <Helmet>
                <title>Become a Part of Our Thriving Ecosystem | Sciative</title>
                <link rel="canonical" href={`${GlobalUrl}${AllRoutes.partnercontact}`} />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="csrf_token" content="" />
                <meta property="type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta name='description' content="Partner with us and leverage our combined strengths to reach new heights. Learn more about our partner program and become a partner. Connect  now " />
                {/* <meta name='keywords' content="Travel, Viaje.ai, Bus, Dynamic Pricing, automate pricing,automated pricing tool,brand protection software,competitor price tracking software,demand forecasting software,discounting software" /> */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content="Become a Part of Our Thriving Ecosystem | Sciative" />
                <meta property="og:description" content="Partner with us and leverage our combined strengths to reach new heights. Learn more about our partner program and become a partner. Connect  now" />
                <meta property="og:hashtag" content="#ai" />
                <meta content="image/*" property="og:image:type" />
              
                <meta property="og:url" content={`${GlobalUrl}${AllRoutes.partnercontact}`}  />
                <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                <meta property="og:image"  content={GlobalImg} />
                <meta property="og:type" content="website" />
            </Helmet>
            <ContactTemplate 
            title_upper = "Partner with Us for Success!"
            text = "Partner with us and gain access to new revenue generation streams. Together, we'll unlock new opportunities!"
            client="all"
            />
            </HelmetProvider>
        </>
    );
}


export default InvestorsContactPage;