import ContactTemplate from "../common/contact";
import '../../assets/css/main.scss';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
const HotelsContactPage = ({ }) => {

    return (
        <HelmetProvider>
        <Helmet> 
      <title>See Zettaprice in Action: Book Your Revenue Management Demo</title>
      <link rel="canonical" href={`${GlobalUrl}${AllRoutes.hotelcontact}`} />
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />

      <meta property="type" content="website" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
    
      <meta name='description' content="Experience Zettaprice's Revenue Management System in action. Schedule a demo to see how our dynamic pricing solutions can drive profitability and efficiency" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content="See Zettaprice in Action: Book Your Revenue Management Demo" />
      <meta property="og:description" content="Experience Zettaprice's Revenue Management System in action. Schedule a demo to see how our dynamic pricing solutions can drive profitability and efficiency" />
     
      <meta property="og:hashtag" content="#ai" />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={`${GlobalUrl}${AllRoutes.hotelcontact}`} />
      <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
    
      <meta property="og:image"  content={GlobalImg} />
      <meta property="og:type" content="website" />
    </Helmet>
            <ContactTemplate 
            title_upper = "Harness AI Pricing with Sciative's Expertise. "
            text = "From room rates to competition rates, empower your hospitality business to price right with our cutting-edge pricing tools."
            client="hotel"
            />
       </HelmetProvider>
    );
}


export default HotelsContactPage;