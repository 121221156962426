import {useState, useEffect} from 'react';
import axios from 'axios';

import '../../assets/css/ticker-style/ticker.scss';

const Ticker = ({ props }) => {
    const [content, setContent] = useState("");
    const [link, setLink] = useState("");

    const getContent = async () => {
        try{    
            const response = await axios.get(`/get_website_content/ticker`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined){
                setContent(response.data.content)
                setLink(response.data.link)
            }

        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        getContent()
    }, [])
    return (
        <div className={"tickerMain "+props.tPosition + `${!props.fixed ? 'sticky_ticker' : ''}`}>
            <p className="tickr_">
                {content}
               <a href={link}>{props.linkText}</a> 
            </p>
        </div>
    );
}


export default Ticker;