const learningMaterials = [
    {
        key: 'software-developer',
        jobRole: "Software Development Engineer (SDE)",
        skills: [
            {
                skillName: 'Web Fundamentals',
                skillLevel: 'Basic',
                domain: 'Tech',
                desc: 'This course will equip you with the essential knowledge for web development. It covers key topics including HTML, CSS, CSS preprocessors, Bootstrap, JavaScript, and its plugins.',
                time: '2 Weeks',
                subTopics: [
                    {
                        topicName: 'HTML',
                        link: 'https://www.tutorialspoint.com/html'
                    },
                    {
                        topicName: 'CSS',
                        link: 'https://www.tutorialspoint.com/css'
                    },
                    {
                        topicName: 'SCSS',
                        link: 'https://youtu.be/tEQOdFgUXI4'
                    },
                    {
                        topicName: 'Bootstrap',
                        link: 'https://getbootstrap.com/docs/4.6/getting-started/introduction/'
                    },
                    {
                        topicName: 'JavaScript',
                        link: 'https://www.tutorialspoint.com/javascript/javascript_syntax.html'
                    },
                    {
                        topicName: 'jQuery',
                        link: 'https://www.w3schools.com/jquery/jquery_syntax.asp'
                    }
                ]
            },
            {
                skillName: 'Exploring Data Visualization with Highcharts & Datatables',
                skillLevel: 'Intermediate',
                domain: 'Tech',
                desc: "We'll look closely at using Highcharts to visualize data and see how we can populate and display data in a datatable.",
                time: '5 Days',
                subTopics: [
                    {
                        topicName: 'Highcharts',
                        link: ''
                    },
                    {
                        topicName: 'Basic Column',
                        link: 'https://www.highcharts.com/demo/highcharts/column-basic'
                    },
                    {
                        topicName: 'Stacked & Group Column',
                        link: 'https://www.highcharts.com/demo/highcharts/column-stacked-and-grouped'
                    },
                    {
                        topicName: 'Pie Chart',
                        link: 'https://www.highcharts.com/demo/highcharts/pie-drilldown'
                    },
                    {
                        topicName: 'Semi Circle Pie',
                        link: 'https://www.highcharts.com/demo/highcharts/pie-semi-circle'
                    },
                    {
                        topicName: 'Scatter plot',
                        link: 'https://www.highcharts.com/demo/highcharts/scatter'
                    },
                    {
                        topicName: 'Guage charts',
                        link: 'https://www.highcharts.com/demo/highcharts/gauge-speedometer'
                    },
                    {
                        topicName: 'Heatmap',
                        link: 'https://www.highcharts.com/demo/highcharts/heatmap'
                    },
                    {
                        topicName: 'Datatable Basic',
                        link: 'https://www.educba.com/jquery-data-table/'
                    },
                    {
                        topicName: 'Multiple Button Group',
                        link: 'https://datatables.net/extensions/buttons/examples/initialisation/multiple.html'
                    },
                    {
                        topicName: 'Fixed Columns',
                        link: 'https://datatables.net/extensions/fixedcolumns/'
                    },
                    {
                        topicName: 'Fixed Headers',
                        link: 'https://datatables.net/extensions/fixedheader/'
                    },
                    {
                        topicName: 'Custom Filtering',
                        link: 'https://datatables.net/examples/plug-ins/range_filtering.html'
                    },
                    {
                        topicName: 'Default Ordering',
                        link: 'https://datatables.net/examples/basic_init/table_sorting.html'
                    },
                    {
                        topicName: 'Column Visibility',
                        link: 'https://datatables.net/extensions/buttons/examples/column_visibility/simple.html'
                    },
                    {
                        topicName: 'Column Sorting',
                        link: 'https://datatables.net/forums/discussion/66446/custom-sorting-on-a-column-within-render-multiple-numbers'
                    }

                ]
            },
            {
                skillName: 'Mastering React: Building Dynamic and Efficient User Interfaces',
                skillLevel: 'Intermediate',
                domain: 'Tech',
                desc: "Learning React is valuable because it enables developers to build dynamic and efficient user interfaces for web applications. It's needed because React's component-based architecture promotes code reusability, simplifies UI development, and enhances maintainability.",
                time: '1 Month',
                subTopics: [
                    {
                        topicName: 'Functional Components',
                        link: 'https://www.youtube.com/watch?v=GouADXXx-KM'
                    },
                    {
                        topicName: 'React Hooks',
                        link: 'https://www.youtube.com/watch?v=LlvBzyy-558'
                    },
                    {
                        topicName: 'useState',
                        link: 'https://www.youtube.com/watch?v=O6P86uwfdR0'
                    },
                    {
                        topicName: 'useEffect',
                        link: 'https://www.youtube.com/watch?v=0ZJgIjIuY7U'
                    },
                    {
                        topicName: 'useContext',
                        link: 'https://www.youtube.com/watch?v=5LrDIWkK_Bc'
                    },
                    {
                        topicName: 'useRef',
                        link: 'https://www.youtube.com/watch?v=t2ypzz6gJm0'
                    },
                    {
                        topicName: 'useReducer',
                        link: 'https://www.youtube.com/watch?v=kK_Wqx3RnHk'
                    },
                    {
                        topicName: 'useMemo',
                        link: 'https://www.youtube.com/watch?v=THL1OPn72vo'
                    },
                    {
                        topicName: 'useCallback',
                        link: 'https://www.youtube.com/watch?v=_AyFP5s69N4'
                    },
                    {
                        topicName: 'Redux',
                        link: 'https://www.youtube.com/watch?v=1i04-A7kfFI'
                    }
                ]
            },
            {
                skillName: 'Python Mastery: Unlock the Power of Programming with Python',
                skillLevel: 'Intermediate',
                domain: 'Tech',
                desc: "Embark on a journey to master Python, the versatile and powerful programming language that powers applications across various domains. In this comprehensive course, you'll delve into the fundamentals of Python programming, learning everything from basic syntax to advanced concepts.",
                time: '1 Month',
                subTopics: [
                    {
                        topicName: "Python Installation",
                        link: 'https://www.youtube.com/watch?v=dX2-V2BocqQ',
                    },
                    {
                        topicName: "Python File Handling",
                        link: 'https://www.guru99.com/reading-and-writing-files-in-python.html',
                    },
                    {
                        topicName: "Python Dictionary",
                        link: 'https://www.guru99.com/python-dictionary-beginners-tutorial.html',
                    },
                    {
                        topicName: "Python Strings",
                        link: 'https://www.guru99.com/learning-python-strings-replace-join-split-reverse.html',
                    },
                    {
                        topicName: "Python Connector to MySQL",
                        link: 'https://dev.mysql.com/doc/connector-python/en/connector-python-example-connecting.html',
                    },
                    {
                        topicName: "Pandas",
                        link: 'https://www.analyticsvidhya.com/blog/2015/04/comprehensive-guide-data-exploration-sas-using-python-numpy-scipy-matplotlib-pandas/',
                    },
                    {
                        topicName: "NumPy",
                        link: 'https://www.youtube.com/watch?v=8JfDAm9y_7s',
                    },
                    {
                        topicName: "SciPy",
                        link: 'https://www.datacamp.com/community/tutorials/python-scipy-tutorial',
                    },
                    {
                        topicName: "Beginner's Guide",
                        link: 'https://wiki.python.org/moin/BeginnersGuide',
                    },
                ]
            },
            {
                skillName: 'Mastering Databases: Dive Deep into Database Management and Optimization',
                skillLevel: "Intermediate",
                domain: "Tech",
                desc: "Unlock the power of data with our comprehensive course on database management. Whether you're a novice or an experienced developer, this course will provide you with the essential skills and knowledge needed to effectively work with databases.",
                time: '20 Days',
                subTopics: [
                    {
                        topicName: "SQL",
                        link: "https://www.mysqltutorial.org/getting-started-with-mysql/"
                    },
                    {
                        topicName: "MongoDB",
                        link: "https://www.mongodb.com/docs/manual/introduction/"
                    },
                    {
                        topicName: "MongoDB eBook",
                        link: "https://www.practical-mongodb-aggregations.com/"
                    },
                    {
                        topicName: "MongoDB Crash Course",
                        link: "https://www.youtube.com/watch?v=ofme2o29ngU"
                    }
                ]
            },
            {
                skillName: "Mastering Django: Building Dynamic Web Applications with Python",
                skillLevel: "Intermediate",
                domain: "Tech",
                desc: "Django is a powerful web framework for building dynamic and scalable web applications with Python. In this comprehensive course, you'll dive deep into Django's features and learn how to leverage its capabilities to develop robust web applications efficiently.",
                time: "2 Weeks",
                subTopics: [
                    {
                        topicName: "Documentation",
                        link: "https://docs.djangoproject.com/en/1.9/"
                    },
                    {
                        topicName: "Installing Django",
                        link: "https://www.youtube.com/watch?v=qgGIqRFvFFk&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK"
                    },
                    {
                        topicName: "Creating Project",
                        link: "https://www.youtube.com/watch?v=CHjXtRrhqxc&index=2&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK"
                    },
                    {
                        topicName: "Creating App",
                        link: "https://www.youtube.com/watch?v=4saN7GZnawU&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK&index=3"
                    },
                    {
                        topicName: "Overview of Basic App",
                        link: "https://www.youtube.com/watch?v=lcD0CDurxas&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK&index=4"
                    },
                    {
                        topicName: "Views",
                        link: "https://www.youtube.com/watch?v=nAn1KpPlN2w&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK&index=5"
                    },
                    {
                        topicName: "Database Setup",
                        link: "https://www.youtube.com/watch?v=IU3LbtbmXXI&index=6&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK"
                    }
                ]
            },
            {
                skillName: "Advanced Databases: Dive Deep into Database Management and Optimization",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "Unlock the power of data with our comprehensive course on database management. Whether you're a novice or an experienced developer, this course will provide you with the essential skills and knowledge needed to effectively work with databases.",
                time: "15 Days",
                subTopics: [
                    {
                        topicName: "Redis",
                        link: "https://redis.io/docs/latest/"
                    },
                    {
                        topicName: "SQL - Working with Stored Procedures",
                        link: "https://www.mysqltutorial.org/mysql-stored-procedure/introduction-to-sql-stored-procedures/"
                    },
                    {
                        topicName: "Creating and Managing MySQL indexes",
                        link: "https://www.mysqltutorial.org/mysql-index/mysql-create-index/"
                    },
                    {
                        topicName: "Transaction Isolation Levels and Table Locking",
                        link: "https://www.mysqltutorial.org/mysql-table-locking/"
                    },
                    {
                        topicName: "MySQL Python Connector",
                        link: "https://www.mysqltutorial.org/getting-started-mysql-python-connector/"
                    },
                    {
                        topicName: "Date Functions",
                        link: "https://www.mysqltutorial.org/mysql-date-functions/"
                    },
                    {
                        topicName: "MySQL String Functions",
                        link: "https://www.mysqltutorial.org/mysql-string-functions/"
                    },
                    {
                        topicName: "MySQL Regular Expressions",
                        link: "https://www.mysqltutorial.org/mysql-regular-expressions/"
                    },
                    {
                        topicName: "MySQL Window Functions",
                        link: "https://www.mysqltutorial.org/mysql-window-functions/"
                    },
                    {
                        topicName: "Aggregation Pipeline",
                        link: "https://www.digitalocean.com/community/tutorials/how-to-use-aggregations-in-mongodb"
                    },
                    {
                        topicName: "Field Update Operators",
                        link: "https://www.mongodb.com/docs/v7.0/reference/operator/update-field/"
                    },
                    {
                        topicName: "Indexes",
                        link: "https://www.mongodb.com/docs/manual/indexes/"
                    },
                ]
            },
            {
                skillName: "Python",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "Lambda functions",
                        link: "https://www.youtube.com/watch?v=ww2uPkwSjjY"
                    },
                    {
                        topicName: "Decorators",
                        link: "https://www.youtube.com/watch?v=FsAPt_9Bf3U"
                    },
                    {
                        topicName: "GIL",
                        link: "https://www.youtube.com/watch?v=KVKufdTphKs&list=PLP05cUdxR3KsS3yWl5LRiko2lRAp1XPUd"
                    },
                    {
                        topicName: "Regex in Python",
                        link: "https://medium.com/analytics-vidhya/regex-in-python-a-z-88ebf1c8fed4"
                    },
                    {
                        topicName: "Threading in Python",
                        link: "https://www.youtube.com/watch?v=FsAPt_9Bf3U"
                    },
                ]
            },
            {
                skillName: "Django",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "Cache in Django",
                        link: "https://dev.to/pragativerma18/django-caching-101-understanding-the-basics-and-beyond-49p"
                    },
                    {
                        topicName: "Signals in Django",
                        link: "https://www.guvi.in/blog/guide-for-django-signals-and-their-uses/"
                    },
                    {
                        topicName: "ASGI in Django",
                        link: "https://arunrocks.com/a-guide-to-asgi-in-django-30-and-its-performance/"
                    },
                    {
                        topicName: "WSGI in Django",
                        link: "https://medium.com/@nurettinabaci/wsgi-protocol-and-django-implementation-2b6300398659"
                    },
                    {
                        topicName: "Middlewares in Django",
                        link: "https://www.youtube.com/watch?v=EpZOVmEw9Qg"
                    },
                ]
            },
            {
                skillName: "Ngnix",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "How to host a Django App",
                        link: "https://www.digitalocean.com/community/tutorials/how-to-set-up-an-asgi-django-app-with-postgres-nginx-and-uvicorn-on-ubuntu-20-04"
                    },
                ]
            },
            {
                skillName: "FastAPI",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "Websockets",
                        link: "https://medium.com/@nmjoshi/getting-started-websocket-with-fastapi-b41d244a2799"
                    },
                    {
                        topicName: "Concurrency & Parallelism",
                        link: "https://www.youtube.com/watch?v=KL6CjNxkZDQ"
                    },
                ]
            },
            {
                skillName: "MongoDB",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "Aggregation",
                        link: "https://www.youtube.com/watch?v=A3jvoE0jGdE&list=PLWkguCWKqN9OwcbdYm4nUIXnA2IoXX0LI"
                    },
                    {
                        topicName: "Indexing",
                        link: "https://www.youtube.com/watch?v=yo6ZXsgsyBA"
                    },
                    {
                        topicName: "Sharding",
                        link: "https://www.youtube.com/watch?v=Jr0PiGIVJ8k&list=PLIRAZAlr4cfbxN_BaUlPSan9BM7rA6Pki"
                    },
                ]
            },
            
        ]
    },
    {
        key: 'full-stack-developer',
        jobRole: "Fullstack Developer (FSD)",
        skills: [
            {
                skillName: 'Web Fundamentals',
                skillLevel: 'Basic',
                domain: 'Tech',
                desc: 'This course will equip you with the essential knowledge for web development. It covers key topics including HTML, CSS, CSS preprocessors, Bootstrap, JavaScript, and its plugins.',
                time: '2 Weeks',
                subTopics: [
                    {
                        topicName: 'HTML',
                        link: 'https://www.tutorialspoint.com/html'
                    },
                    {
                        topicName: 'CSS',
                        link: 'https://www.tutorialspoint.com/css'
                    },
                    {
                        topicName: 'SCSS',
                        link: 'https://youtu.be/tEQOdFgUXI4'
                    },
                    {
                        topicName: 'Bootstrap',
                        link: 'https://getbootstrap.com/docs/4.6/getting-started/introduction/'
                    },
                    {
                        topicName: 'JavaScript',
                        link: 'https://www.tutorialspoint.com/javascript/javascript_syntax.html'
                    },
                    {
                        topicName: 'jQuery',
                        link: 'https://www.w3schools.com/jquery/jquery_syntax.asp'
                    }
                ]
            },
            {
                skillName: 'Exploring Data Visualization with Highcharts & Datatables',
                skillLevel: 'Intermediate',
                domain: 'Tech',
                desc: "We'll look closely at using Highcharts to visualize data and see how we can populate and display data in a datatable.",
                time: '5 Days',
                subTopics: [
                    {
                        topicName: 'Highcharts',
                        link: ''
                    },
                    {
                        topicName: 'Basic Column',
                        link: 'https://www.highcharts.com/demo/highcharts/column-basic'
                    },
                    {
                        topicName: 'Stacked & Group Column',
                        link: 'https://www.highcharts.com/demo/highcharts/column-stacked-and-grouped'
                    },
                    {
                        topicName: 'Pie Chart',
                        link: 'https://www.highcharts.com/demo/highcharts/pie-drilldown'
                    },
                    {
                        topicName: 'Semi Circle Pie',
                        link: 'https://www.highcharts.com/demo/highcharts/pie-semi-circle'
                    },
                    {
                        topicName: 'Scatter plot',
                        link: 'https://www.highcharts.com/demo/highcharts/scatter'
                    },
                    {
                        topicName: 'Guage charts',
                        link: 'https://www.highcharts.com/demo/highcharts/gauge-speedometer'
                    },
                    {
                        topicName: 'Heatmap',
                        link: 'https://www.highcharts.com/demo/highcharts/heatmap'
                    },
                    {
                        topicName: 'Datatable Basic',
                        link: 'https://www.educba.com/jquery-data-table/'
                    },
                    {
                        topicName: 'Multiple Button Group',
                        link: 'https://datatables.net/extensions/buttons/examples/initialisation/multiple.html'
                    },
                    {
                        topicName: 'Fixed Columns',
                        link: 'https://datatables.net/extensions/fixedcolumns/'
                    },
                    {
                        topicName: 'Fixed Headers',
                        link: 'https://datatables.net/extensions/fixedheader/'
                    },
                    {
                        topicName: 'Custom Filtering',
                        link: 'https://datatables.net/examples/plug-ins/range_filtering.html'
                    },
                    {
                        topicName: 'Default Ordering',
                        link: 'https://datatables.net/examples/basic_init/table_sorting.html'
                    },
                    {
                        topicName: 'Column Visibility',
                        link: 'https://datatables.net/extensions/buttons/examples/column_visibility/simple.html'
                    },
                    {
                        topicName: 'Column Sorting',
                        link: 'https://datatables.net/forums/discussion/66446/custom-sorting-on-a-column-within-render-multiple-numbers'
                    }

                ]
            },
            {
                skillName: 'Mastering React: Building Dynamic and Efficient User Interfaces',
                skillLevel: 'Intermediate',
                domain: 'Tech',
                desc: "Learning React is valuable because it enables developers to build dynamic and efficient user interfaces for web applications. It's needed because React's component-based architecture promotes code reusability, simplifies UI development, and enhances maintainability.",
                time: '1 Month',
                subTopics: [
                    {
                        topicName: 'Functional Components',
                        link: 'https://www.youtube.com/watch?v=GouADXXx-KM'
                    },
                    {
                        topicName: 'React Hooks',
                        link: 'https://www.youtube.com/watch?v=LlvBzyy-558'
                    },
                    {
                        topicName: 'useState',
                        link: 'https://www.youtube.com/watch?v=O6P86uwfdR0'
                    },
                    {
                        topicName: 'useEffect',
                        link: 'https://www.youtube.com/watch?v=0ZJgIjIuY7U'
                    },
                    {
                        topicName: 'useContext',
                        link: 'https://www.youtube.com/watch?v=5LrDIWkK_Bc'
                    },
                    {
                        topicName: 'useRef',
                        link: 'https://www.youtube.com/watch?v=t2ypzz6gJm0'
                    },
                    {
                        topicName: 'useReducer',
                        link: 'https://www.youtube.com/watch?v=kK_Wqx3RnHk'
                    },
                    {
                        topicName: 'useMemo',
                        link: 'https://www.youtube.com/watch?v=THL1OPn72vo'
                    },
                    {
                        topicName: 'useCallback',
                        link: 'https://www.youtube.com/watch?v=_AyFP5s69N4'
                    },
                    {
                        topicName: 'Redux',
                        link: 'https://www.youtube.com/watch?v=1i04-A7kfFI'
                    }
                ]
            },
            {
                skillName: 'Python Mastery: Unlock the Power of Programming with Python',
                skillLevel: 'Intermediate',
                domain: 'Tech',
                desc: "Embark on a journey to master Python, the versatile and powerful programming language that powers applications across various domains. In this comprehensive course, you'll delve into the fundamentals of Python programming, learning everything from basic syntax to advanced concepts.",
                time: '1 Month',
                subTopics: [
                    {
                        topicName: "Python Installation",
                        link: 'https://www.youtube.com/watch?v=dX2-V2BocqQ',
                    },
                    {
                        topicName: "Python File Handling",
                        link: 'https://www.guru99.com/reading-and-writing-files-in-python.html',
                    },
                    {
                        topicName: "Python Dictionary",
                        link: 'https://www.guru99.com/python-dictionary-beginners-tutorial.html',
                    },
                    {
                        topicName: "Python Strings",
                        link: 'https://www.guru99.com/learning-python-strings-replace-join-split-reverse.html',
                    },
                    {
                        topicName: "Python Connector to MySQL",
                        link: 'https://dev.mysql.com/doc/connector-python/en/connector-python-example-connecting.html',
                    },
                    {
                        topicName: "Pandas",
                        link: 'https://www.analyticsvidhya.com/blog/2015/04/comprehensive-guide-data-exploration-sas-using-python-numpy-scipy-matplotlib-pandas/',
                    },
                    {
                        topicName: "NumPy",
                        link: 'https://www.youtube.com/watch?v=8JfDAm9y_7s',
                    },
                    {
                        topicName: "SciPy",
                        link: 'https://www.datacamp.com/community/tutorials/python-scipy-tutorial',
                    },
                    {
                        topicName: "Beginner's Guide",
                        link: 'https://wiki.python.org/moin/BeginnersGuide',
                    },
                ]
            },
            {
                skillName: 'Mastering Databases: Dive Deep into Database Management and Optimization',
                skillLevel: "Intermediate",
                domain: "Tech",
                desc: "Unlock the power of data with our comprehensive course on database management. Whether you're a novice or an experienced developer, this course will provide you with the essential skills and knowledge needed to effectively work with databases.",
                time: '20 Days',
                subTopics: [
                    {
                        topicName: "SQL",
                        link: "https://www.mysqltutorial.org/getting-started-with-mysql/"
                    },
                    {
                        topicName: "MongoDB",
                        link: "https://www.mongodb.com/docs/manual/introduction/"
                    },
                    {
                        topicName: "MongoDB eBook",
                        link: "https://www.practical-mongodb-aggregations.com/"
                    },
                    {
                        topicName: "MongoDB Crash Course",
                        link: "https://www.youtube.com/watch?v=ofme2o29ngU"
                    }
                ]
            },
            {
                skillName: "Mastering Django: Building Dynamic Web Applications with Python",
                skillLevel: "Intermediate",
                domain: "Tech",
                desc: "Django is a powerful web framework for building dynamic and scalable web applications with Python. In this comprehensive course, you'll dive deep into Django's features and learn how to leverage its capabilities to develop robust web applications efficiently.",
                time: "2 Weeks",
                subTopics: [
                    {
                        topicName: "Documentation",
                        link: "https://docs.djangoproject.com/en/1.9/"
                    },
                    {
                        topicName: "Installing Django",
                        link: "https://www.youtube.com/watch?v=qgGIqRFvFFk&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK"
                    },
                    {
                        topicName: "Creating Project",
                        link: "https://www.youtube.com/watch?v=CHjXtRrhqxc&index=2&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK"
                    },
                    {
                        topicName: "Creating App",
                        link: "https://www.youtube.com/watch?v=4saN7GZnawU&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK&index=3"
                    },
                    {
                        topicName: "Overview of Basic App",
                        link: "https://www.youtube.com/watch?v=lcD0CDurxas&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK&index=4"
                    },
                    {
                        topicName: "Views",
                        link: "https://www.youtube.com/watch?v=nAn1KpPlN2w&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK&index=5"
                    },
                    {
                        topicName: "Database Setup",
                        link: "https://www.youtube.com/watch?v=IU3LbtbmXXI&index=6&list=PL6gx4Cwl9DGBlmzzFcLgDhKTTfNLfX1IK"
                    }
                ]
            },
            {
                skillName: "Advanced Databases: Dive Deep into Database Management and Optimization",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "Unlock the power of data with our comprehensive course on database management. Whether you're a novice or an experienced developer, this course will provide you with the essential skills and knowledge needed to effectively work with databases.",
                time: "15 Days",
                subTopics: [
                    {
                        topicName: "Redis",
                        link: "https://redis.io/docs/latest/"
                    },
                    {
                        topicName: "SQL - Working with Stored Procedures",
                        link: "https://www.mysqltutorial.org/mysql-stored-procedure/introduction-to-sql-stored-procedures/"
                    },
                    {
                        topicName: "Creating and Managing MySQL indexes",
                        link: "https://www.mysqltutorial.org/mysql-index/mysql-create-index/"
                    },
                    {
                        topicName: "Transaction Isolation Levels and Table Locking",
                        link: "https://www.mysqltutorial.org/mysql-table-locking/"
                    },
                    {
                        topicName: "MySQL Python Connector",
                        link: "https://www.mysqltutorial.org/getting-started-mysql-python-connector/"
                    },
                    {
                        topicName: "Date Functions",
                        link: "https://www.mysqltutorial.org/mysql-date-functions/"
                    },
                    {
                        topicName: "MySQL String Functions",
                        link: "https://www.mysqltutorial.org/mysql-string-functions/"
                    },
                    {
                        topicName: "MySQL Regular Expressions",
                        link: "https://www.mysqltutorial.org/mysql-regular-expressions/"
                    },
                    {
                        topicName: "MySQL Window Functions",
                        link: "https://www.mysqltutorial.org/mysql-window-functions/"
                    },
                    {
                        topicName: "Aggregation Pipeline",
                        link: "https://www.digitalocean.com/community/tutorials/how-to-use-aggregations-in-mongodb"
                    },
                    {
                        topicName: "Field Update Operators",
                        link: "https://www.mongodb.com/docs/v7.0/reference/operator/update-field/"
                    },
                    {
                        topicName: "Indexes",
                        link: "https://www.mongodb.com/docs/manual/indexes/"
                    },
                ]
            },
            {
                skillName: "Python",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "2 Hrs",
                time: "15 Days",
                subTopics: [
                    {
                        topicName: "Lambda functions",
                        link: "https://www.youtube.com/watch?v=ww2uPkwSjjY"
                    },
                    {
                        topicName: "Decorators",
                        link: "https://www.youtube.com/watch?v=FsAPt_9Bf3U"
                    },
                    {
                        topicName: "GIL",
                        link: "https://www.youtube.com/watch?v=KVKufdTphKs&list=PLP05cUdxR3KsS3yWl5LRiko2lRAp1XPUd"
                    },
                    {
                        topicName: "Regex in Python",
                        link: "https://medium.com/analytics-vidhya/regex-in-python-a-z-88ebf1c8fed4"
                    },
                    {
                        topicName: "Threading in Python",
                        link: "https://www.youtube.com/watch?v=FsAPt_9Bf3U"
                    },
                ]
            },
            {
                skillName: "Django",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "Cache in Django",
                        link: "https://dev.to/pragativerma18/django-caching-101-understanding-the-basics-and-beyond-49p"
                    },
                    {
                        topicName: "Signals in Django",
                        link: "https://www.guvi.in/blog/guide-for-django-signals-and-their-uses/"
                    },
                    {
                        topicName: "ASGI in Django",
                        link: "https://arunrocks.com/a-guide-to-asgi-in-django-30-and-its-performance/"
                    },
                    {
                        topicName: "WSGI in Django",
                        link: "https://medium.com/@nurettinabaci/wsgi-protocol-and-django-implementation-2b6300398659"
                    },
                    {
                        topicName: "Middlewares in Django",
                        link: "https://www.youtube.com/watch?v=EpZOVmEw9Qg"
                    },
                ]
            },
            {
                skillName: "Ngnix",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "How to host a Django App",
                        link: "https://www.digitalocean.com/community/tutorials/how-to-set-up-an-asgi-django-app-with-postgres-nginx-and-uvicorn-on-ubuntu-20-04"
                    },
                ]
            },
            {
                skillName: "FastAPI",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "Websockets",
                        link: "https://medium.com/@nmjoshi/getting-started-websocket-with-fastapi-b41d244a2799"
                    },
                    {
                        topicName: "Concurrency & Parallelism",
                        link: "https://www.youtube.com/watch?v=KL6CjNxkZDQ"
                    },
                ]
            },
            {
                skillName: "MongoDB",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "Aggregation",
                        link: "https://www.youtube.com/watch?v=A3jvoE0jGdE&list=PLWkguCWKqN9OwcbdYm4nUIXnA2IoXX0LI"
                    },
                    {
                        topicName: "Indexing",
                        link: "https://www.youtube.com/watch?v=yo6ZXsgsyBA"
                    },
                    {
                        topicName: "Sharding",
                        link: "https://www.youtube.com/watch?v=Jr0PiGIVJ8k&list=PLIRAZAlr4cfbxN_BaUlPSan9BM7rA6Pki"
                    },
                ]
            },
        ]
    },
    {
        key: 'dei',
        jobRole: "DEI",
        skills: [
            {
                skillName: 'Web Fundamentals',
                skillLevel: 'Basic',
                domain: 'Tech',
                desc: 'This course will equip you with the essential knowledge for web development. It covers key topics including HTML, CSS, CSS preprocessors, Bootstrap, JavaScript, and its plugins.',
                time: '2 Weeks',
                subTopics: [
                    {
                        topicName: 'HTML',
                        link: 'https://www.tutorialspoint.com/html'
                    },
                    {
                        topicName: 'CSS',
                        link: 'https://www.tutorialspoint.com/css'
                    },
                    {
                        topicName: 'SCSS',
                        link: 'https://youtu.be/tEQOdFgUXI4'
                    },
                    {
                        topicName: 'Bootstrap',
                        link: 'https://getbootstrap.com/docs/4.6/getting-started/introduction/'
                    },
                    {
                        topicName: 'JavaScript',
                        link: 'https://www.tutorialspoint.com/javascript/javascript_syntax.html'
                    },
                    {
                        topicName: 'jQuery',
                        link: 'https://www.w3schools.com/jquery/jquery_syntax.asp'
                    }
                ]
            },
            {
                skillName: 'Python Mastery: Unlock the Power of Programming with Python',
                skillLevel: 'Intermediate',
                domain: 'Tech',
                desc: "Embark on a journey to master Python, the versatile and powerful programming language that powers applications across various domains. In this comprehensive course, you'll delve into the fundamentals of Python programming, learning everything from basic syntax to advanced concepts.",
                time: '1 Month',
                subTopics: [
                    {
                        topicName: "Python Installation",
                        link: 'https://www.youtube.com/watch?v=dX2-V2BocqQ',
                    },
                    {
                        topicName: "Python File Handling",
                        link: 'https://www.guru99.com/reading-and-writing-files-in-python.html',
                    },
                    {
                        topicName: "Python Dictionary",
                        link: 'https://www.guru99.com/python-dictionary-beginners-tutorial.html',
                    },
                    {
                        topicName: "Python Strings",
                        link: 'https://www.guru99.com/learning-python-strings-replace-join-split-reverse.html',
                    },
                    {
                        topicName: "Python Connector to MySQL",
                        link: 'https://dev.mysql.com/doc/connector-python/en/connector-python-example-connecting.html',
                    },
                    {
                        topicName: "Pandas",
                        link: 'https://www.analyticsvidhya.com/blog/2015/04/comprehensive-guide-data-exploration-sas-using-python-numpy-scipy-matplotlib-pandas/',
                    },
                    {
                        topicName: "NumPy",
                        link: 'https://www.youtube.com/watch?v=8JfDAm9y_7s',
                    },
                    {
                        topicName: "SciPy",
                        link: 'https://www.datacamp.com/community/tutorials/python-scipy-tutorial',
                    },
                    {
                        topicName: "Beginner's Guide",
                        link: 'https://wiki.python.org/moin/BeginnersGuide',
                    },
                ]
            },
            {
                skillName: 'Mastering Databases: Dive Deep into Database Management and Optimization',
                skillLevel: "Intermediate",
                domain: "Tech",
                desc: "Unlock the power of data with our comprehensive course on database management. Whether you're a novice or an experienced developer, this course will provide you with the essential skills and knowledge needed to effectively work with databases.",
                time: '20 Days',
                subTopics: [
                    {
                        topicName: "SQL",
                        link: "https://www.mysqltutorial.org/getting-started-with-mysql/"
                    },
                    {
                        topicName: "MongoDB",
                        link: "https://www.mongodb.com/docs/manual/introduction/"
                    },
                    {
                        topicName: "MongoDB eBook",
                        link: "https://www.practical-mongodb-aggregations.com/"
                    },
                    {
                        topicName: "MongoDB Crash Course",
                        link: "https://www.youtube.com/watch?v=ofme2o29ngU"
                    }
                ]
            },
            {
                skillName: "Advanced Databases: Dive Deep into Database Management and Optimization",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "Unlock the power of data with our comprehensive course on database management. Whether you're a novice or an experienced developer, this course will provide you with the essential skills and knowledge needed to effectively work with databases.",
                time: "15 Days",
                subTopics: [
                    {
                        topicName: "Redis",
                        link: "https://redis.io/docs/latest/"
                    },
                    {
                        topicName: "SQL - Working with Stored Procedures",
                        link: "https://www.mysqltutorial.org/mysql-stored-procedure/introduction-to-sql-stored-procedures/"
                    },
                    {
                        topicName: "Creating and Managing MySQL indexes",
                        link: "https://www.mysqltutorial.org/mysql-index/mysql-create-index/"
                    },
                    {
                        topicName: "Transaction Isolation Levels and Table Locking",
                        link: "https://www.mysqltutorial.org/mysql-table-locking/"
                    },
                    {
                        topicName: "MySQL Python Connector",
                        link: "https://www.mysqltutorial.org/getting-started-mysql-python-connector/"
                    },
                    {
                        topicName: "Date Functions",
                        link: "https://www.mysqltutorial.org/mysql-date-functions/"
                    },
                    {
                        topicName: "MySQL String Functions",
                        link: "https://www.mysqltutorial.org/mysql-string-functions/"
                    },
                    {
                        topicName: "MySQL Regular Expressions",
                        link: "https://www.mysqltutorial.org/mysql-regular-expressions/"
                    },
                    {
                        topicName: "MySQL Window Functions",
                        link: "https://www.mysqltutorial.org/mysql-window-functions/"
                    },
                    {
                        topicName: "Aggregation Pipeline",
                        link: "https://www.digitalocean.com/community/tutorials/how-to-use-aggregations-in-mongodb"
                    },
                    {
                        topicName: "Field Update Operators",
                        link: "https://www.mongodb.com/docs/v7.0/reference/operator/update-field/"
                    },
                    {
                        topicName: "Indexes",
                        link: "https://www.mongodb.com/docs/manual/indexes/"
                    },
                ]
            },
            {
                skillName: "Mastering Web Scraping with Scrapy: Extracting Data from the Web Efficiently",
                skillLevel: "Intermediate",
                domain: "Tech",
                desc: "Scrapy is a powerful and flexible framework for extracting data from websites and web applications. In this hands-on course, you'll learn how to harness the full potential of Scrapy to scrape and crawl web pages, extract structured data, and automate data extraction tasks.",
                time: "1 Week",
                subTopics: [
                    {
                        topicName: "Element Tab",
                        link: "https://developer.chrome.com/docs/devtools/css"
                    },
                    {
                        topicName: "Network Tab",
                        link: "https://developer.chrome.com/docs/devtools/network"
                    },
                    {
                        topicName: "CURL",
                        link: "https://pieriantraining.com/converting-curl-commands-to-python-code-with-python-requests/"
                    },
                    {
                        topicName: "Postman API Testing",
                        link: "https://www.postman.com/api-platform/api-lifecycle/"
                    },
                    {
                        topicName: "Dynamic XPath",
                        link: "https://www.guru99.com/xpath-selenium.html#1-basic-xpath"
                    },
                    {
                        topicName: "Command Line Tools",
                        link: "https://docs.scrapy.org/en/latest/topics/commands.html"
                    },
                    {
                        topicName: "Spiders",
                        link: "https://docs.scrapy.org/en/latest/topics/spiders.html"
                    },
                    {
                        topicName: "Selectors",
                        link: "https://docs.scrapy.org/en/latest/topics/selectors.html"
                    },
                    {
                        topicName: "Scrapy Shell",
                        link: "https://docs.scrapy.org/en/latest/topics/shell.html"
                    },
                    {
                        topicName: "Item Pipeline",
                        link: "https://docs.scrapy.org/en/latest/topics/item-pipeline.html"
                    },
                    {
                        topicName: "Feed Exports",
                        link: "https://docs.scrapy.org/en/latest/topics/feed-exports.html"
                    },
                    {
                        topicName: "Requests & Responses",
                        link: "https://docs.scrapy.org/en/latest/topics/request-response.html"
                    },
                    {
                        topicName: "Link Extractors",
                        link: "https://docs.scrapy.org/en/latest/topics/link-extractors.html"
                    },
                    {
                        topicName: "Settings",
                        link: "https://docs.scrapy.org/en/latest/topics/settings.html"
                    },
                    {
                        topicName: "Exceptions",
                        link: "https://docs.scrapy.org/en/latest/topics/exceptions.html"
                    }


                ]
            },
            {
                skillName: "Python",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "2 Hrs",
                time: "15 Days",
                subTopics: [
                    {
                        topicName: "Lambda functions",
                        link: "https://www.youtube.com/watch?v=ww2uPkwSjjY"
                    },
                    {
                        topicName: "Decorators",
                        link: "https://www.youtube.com/watch?v=FsAPt_9Bf3U"
                    },
                    {
                        topicName: "GIL",
                        link: "https://www.youtube.com/watch?v=KVKufdTphKs&list=PLP05cUdxR3KsS3yWl5LRiko2lRAp1XPUd"
                    },
                    {
                        topicName: "Regex in Python",
                        link: "https://medium.com/analytics-vidhya/regex-in-python-a-z-88ebf1c8fed4"
                    },
                    {
                        topicName: "Threading in Python",
                        link: "https://www.youtube.com/watch?v=FsAPt_9Bf3U"
                    },
                ]
            },
            {
                skillName: "MongoDB",
                skillLevel: "Advanced",
                domain: "Tech",
                desc: "",
                time: "3 Hrs",
                subTopics: [
                    {
                        topicName: "Aggregation",
                        link: "https://www.youtube.com/watch?v=A3jvoE0jGdE&list=PLWkguCWKqN9OwcbdYm4nUIXnA2IoXX0LI"
                    },
                    {
                        topicName: "Indexing",
                        link: "https://www.youtube.com/watch?v=yo6ZXsgsyBA"
                    },
                    {
                        topicName: "Sharding",
                        link: "https://www.youtube.com/watch?v=Jr0PiGIVJ8k&list=PLIRAZAlr4cfbxN_BaUlPSan9BM7rA6Pki"
                    },
                ]
            },
        ]
    },
    {
        key: 'product-analyst',
        jobRole: "Jr. Product Analyst",
        skills: [
            {
                skillName: 'Introduction to Product Management',
                skillLevel: 'Basic',
                domain: 'Product',
                desc: 'Introduction of Product management | Benefits of product management | Product lifecycle | Marketing Mix | Product Strategy | Product Leadership',
                time: '1 hr 48 min',
                subTopics: [
                    {
                        topicName: "What is Product Management",
                        link: "https://www.youtube.com/watch?v=ravLfnYuqmA&list=PLZkpiiuDw2b9kda_9r12PWxunQHcRBhW6&index=1"
                    },
                ]
            },
            {
                skillName: 'Basic tech skills for a Product Manager',
                skillLevel: 'Basic',
                domain: 'Product',
                desc: 'Product pitch: precisely | Mockup: make a basic mockup of your app using Sketch | Database | User stories',
                time: '1 hr 16 min',
                subTopics: [
                    {
                        topicName: "Basic Tech Skills",
                        link: "https://www.youtube.com/watch?v=4nH7luM9LM0&list=PLZkpiiuDw2b9kda_9r12PWxunQHcRBhW6&index=19"
                    },
                ]
            },
            {
                skillName: 'Essential Skills For Data Analysis In Excel',
                skillLevel: 'Basic',
                domain: 'Product',
                desc: 'Basic of excel data analysis | Lookup & functions in excel data analysis | Conditional formatting in excel data analysis | Data validation in excel data analysis | Sorting and filtering in excel data analysis | Data analysis using toolpak in  excel data analysis',
                time: '4 hr 11 min',
                subTopics: [
                    {
                        topicName: "Data Analysis in Excel",
                        link: "https://www.youtube.com/watch?v=OOWAk2aLEfk&list=PLZkpiiuDw2b9kda_9r12PWxunQHcRBhW6&index=59"
                    },
                ]
            },
            {
                skillName: 'Design Thinking and PM by Globant Product Strategy Director',
                skillLevel: 'Basic',
                domain: 'Product',
                desc: 'What is design thinking? | Process Overloads | Design Thinking toolkit | Data Analysis | Design Thinking Analysis | Research & Deadlines | Outcomes & Conclusion',
                time: '35 min',
                subTopics: [
                    {
                        topicName: "Design Thinking",
                        link: "https://www.youtube.com/watch?v=9ynx-Byk1pU&list=PLZkpiiuDw2b9kda_9r12PWxunQHcRBhW6&index=33"
                    },
                ]
            },
            {
                skillName: 'UX Design for Product Managers by Dan Olsen',
                skillLevel: 'Basic',
                domain: 'Product',
                desc: '',
                time: '1 hr 3 min',
                subTopics: [
                    {
                        topicName: "UX Design",
                        link: "https://www.youtube.com/watch?v=8LOGEecY1iE&list=PLZkpiiuDw2b9kda_9r12PWxunQHcRBhW6&index=23"
                    },
                ]
            },
            {
                skillName: 'Webinar: Communicating with Executives Effectively by Google Group PM, Vincent Wu',
                skillLevel: 'Basic',
                domain: 'Product',
                desc: '',
                time: '23 min',
                subTopics: [
                    {
                        topicName: "Communicating with Executives",
                        link: "https://www.youtube.com/watch?v=CC4pQ39U_f0"
                    },
                ]
            },
            {
                skillName: 'Product Management Essential',
                skillLevel: 'Intermediate',
                domain: 'Product',
                desc: '',
                time: '6 hr',
                subTopics: [
                    {
                        topicName: "Product Management Essential",
                        link: "https://www.coursera.org/learn/product-management-essentials"
                    },
                ]
            }
        ]
    },
    {
        key: 'marketing',
        jobRole: "Digital Marketing Specialist",
        skills: [
            {
                skillName: 'Introduction to Digital Marketing',
                skillLevel: 'Basic',
                domain: 'Marketing',
                desc: 'The fundamentals and significance of digital marketing and learn how to deploy its strategies effectively. What is Digital Marketing? | Why Digital Marketing? | How to do Digital Marketing? | Types of Digital Marketing | Content Marketing | SEO SEM | Social Media Marketing | Affiliate and Automated Marketing | Pay Per Click and PR | Demo - SEO/SEM and Keyword Research',
                time: '2 hr',
                subTopics: [
                    {
                        topicName: "Digital Marketing",
                        link: "https://www.mygreatlearning.com/academy/learn-for-free/courses/introduction-to-digital-marketing"
                    },
                ]
            },
            {
                skillName: 'Intro to SaaS Sales',
                skillLevel: 'Basic',
                domain: 'Marketing',
                desc: "SaaS (software-as-a-service) sales | Overview of the roles available in today's market | How to make a switch from another industry or job type | Time management and organization best practices | Content Marketing | SaaS sales process | Sales methodology",
                time: '1.45 hr',
                subTopics: [
                    {
                        topicName: "Learn about the software sales industry and how to pursue a career as an SaaS SDR or AE",
                        link: "https://www.udemy.com/course/intro-to-saas-sales/"
                    },
                ]
            },
            {
                skillName: 'Marketing Terms: A to Z Glossary',
                skillLevel: 'Basic',
                domain: 'Marketing',
                desc: 'Marketing Terms: A to Z Glossary',
                time: '2 hr',
                subTopics: [
                    {
                        topicName: "Marketing Terms: A to Z Glossary",
                        link: "https://www.coursera.org/articles/marketing-terms"
                    },
                ]
            },
            {
                skillName: 'Google Ads',
                skillLevel: 'Basic',
                domain: 'Marketing',
                desc: 'Create a Google Ads account and set up first campaign structure | Learn how to use the tools and settings available to optimize campaigns and make them profitable| Create ad groups, do keyword research, set up audience targeting, and write ads',
                time: '4 hr',
                subTopics: [
                    {
                        topicName: "Google Ads Account Creation Learning - 1",
                        link: "https://www.coursera.org/projects/google-ads-beginner#details"
                    },
                    {
                        topicName: "Google Ads Account Creation Learning - 2",
                        link: "https://www.coursera.org/projects/create-a-google-ads-search-campaign"
                    },
                ]
            },
            {
                skillName: 'MS Clarity',
                skillLevel: 'Basic',
                domain: '',
                desc: 'Introduction to Clarity | Install | Google Analytics Integration | Dashboard Walkthrough | Connection Bug | Recordings and Heatmaps',
                time: '25 min',
                subTopics: [
                    {
                        topicName: "MS Clarity - 1",
                        link: "https://www.youtube.com/watch?v=U6QkNeku1DM"
                    },
                    {
                        topicName: "MS Clarity - 2",
                        link: "https://clarity.microsoft.com/session-recordings"
                    },
                ]
            },
            {
                skillName: 'Google Keywords Planner',
                skillLevel: 'Basic',
                domain: 'Marketing',
                desc: 'How to do keyword research with the Google Keyword Planner | Columns in the Google Keyword Planner | Refine keywords with the Google Keyword Planner | How to use filters with the Google Keyword Planner | How to find long-tail keywords with the Google Keyword Planner | Website & competitive keyword research with the Google Keyword Planner | View Grouped Ideas and Ad Group ideas with the Google Keyword Planner | How to see search volume with the Google Keyword Planner | How to forecast Google Ads campaigns with the Google Keyword Planner | How to organize keywords into Ad Groups with the Google Keyword Planner | How to target keywords and create ad groups with the Google Keyword Planner',
                time: '44 min',
                subTopics: [
                    {
                        topicName: "Google Keywords Planner",
                        link: "https://www.youtube.com/watch?v=lUVsZy4Ny8w"
                    },
                    {
                        topicName: "How to use the Google Keywords Planner",
                        link: "https://surfsideppc.com/google-keyword-planner/"
                    },
                ]
            }
        ]
    },
    {
        key: 'qa',
        jobRole: "QA Engineer",
        skills: [
            {
                skillName: 'Basics of Software testing',
                skillLevel: 'Basic',
                domain: 'QA',
                desc: 'What is Software testing | Types of Testing | Types of Acceptanct testing | SDLC | STLC| Defect life cycle',
                time: '1 hr 30 min',
                subTopics: [
                    {
                        topicName: "Basics of Software testing",
                        link: "https://youtube.com/playlist?list=PLXXvO4OXeJrfbPrI0CV-re2VkdiV1dC7X&si=brLCJMHfpod70lZ0"
                    }
                ]
            },
            {
                skillName: 'Manual Testing',
                skillLevel: 'Basic',
                domain: 'QA',
                desc: 'Manual Testing | Test Cases | Test Scenario | Test Plan | RTM',
                time: '15 hr',
                subTopics: [
                    {
                        topicName: "Manual Testing",
                        link: "https://youtube.com/playlist?list=PLUDwpEzHYYLseflPNg0bUKfLmAbO2JnE9&si=6oUSj_ffoHPjjXfe"
                    }
                ]
            },
            {
                skillName: 'Python',
                skillLevel: 'Basic',
                domain: 'QA',
                desc: 'Python Basics | Python Variables| Python functions| Python Key Words',
                time: '3-4 hr',
                subTopics: [
                    {
                        topicName: "Basic Python",
                        link: "https://youtube.com/playlist?list=PLL34mf651faPg74A-vy1TIPWUjYBIJ52n&si=LcKwn0oCQh65fXwp"
                    },
                    {
                        topicName: "Python with Selenium",
                        link: "https://youtube.com/playlist?list=PLL34mf651faPg74A-vy1TIPWUjYBIJ52n&si=LcKwn0oCQh65fXwp"
                    }
                ]
            },
            {
                skillName: 'Selenium Locators',
                skillLevel: 'Basic',
                domain: 'QA',
                desc: 'Basics of Selenium ',
                time: '1 hr 30 min',
                subTopics: [
                    {
                        topicName: "Basics of Selenium",
                        link: "https://youtu.be/HBOdscUwUnc?si=pDGMbU3LNTHwLX7B"
                    }
                ]
            },
            
        ]
    }
];

export default learningMaterials;