import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import AllRoutes from '../../AllRoutes'
import { Link } from "react-router-dom";

const MarketPlaceIntegration = ({props, end_point}) => {

    return (
        <section className={"market_int_product "+props}>
        <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">
            Market Place Integrations with the Best
            </h2>
            <div className="row_flex">
                
                    <div className="img_div">
                        <img src={shopify} alt="Shopify Integration" />
                    </div>
                    <div className="img_div">
                        <img src={amazon} alt="Amazon Integration" />
                    </div>
                    <div className="img_div">
                        <img src={flipkart} alt="Flipkart Integration" />
                    </div>
                
            </div>
            <p className="text-center">Estimate the Right Price at the Right Time for each product.
            <br className="d-none d-md-block" />Book a demo to see how our price optimisation solution can drive profits for your brand.
</p>
            <div className="text-center">
                <Link to={AllRoutes.retailcontact + `?navigation=${end_point}`}>
                    <button className='yellow-button'>Contact Experts</button>
                </Link>
            </div>


        </div>
    </section>
    )

}
export default MarketPlaceIntegration;