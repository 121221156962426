import p_1 from "../../assets/img/about/value_prop_1.png";
import p_2 from "../../assets/img/about/value_prop_2.png";
import p_3 from "../../assets/img/about/value_prop_3.png";
import p_4 from "../../assets/img/about/value_prop_4.png";

const ValueProposition = () => {

    const content = [
        {
            title: 'Automation',
            text: 'We think big. We are working towards automating machine learning models to trigger price, marketing and inventory decisions.',
            img: p_2
        },
        {
            title: 'Proven Scientific Models',
            text: 'With proven machine learning (like forecasting, elasticity and cannibalization) we want to augment historical data and build a better product.',
            img: p_3
        },
        {
            title: 'Market Intelligence',
            text: 'We track the competition in order to make informed pricing and product decisions. The idea? To stay competitive.',
            img: p_1
        },
        {
            title: 'Goal Driven Framework',
            text: 'For us to achieve our desired growth, we use prescriptive ML models which also help in maintaining a good margin and reaching customer targets.',
            img: p_4
        },
    ]

    return (
        <>
            <section className="sciative_media why_partner">
                <div className="container" data-aos="new-animation">
                    <h2 className="bigHead text-center mb-5">Our Value Proposition</h2>
                    <div className="row">
                        {
                            content.map(item => 
                                <div className="col-md-6 why_row">
                                    <div className="card_2 row">
                                        <div className="why_headings col-12  col-xl-7">

                                            <p className="why_head">{item['title']}</p>
                                            <p className="why_para">{item['text']}</p>
                                        </div>
                                        <div className="why_img col-6 offset-6  offset-xl-0 col-xl-5">
                                            <div className="img_inside">
                                                <img src={item['img']} alt={item['title']} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default ValueProposition;