import { createTheme } from '@mui/material/styles';
import { lime, purple } from '@mui/material/colors';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                  fontWeight: 'bold'
                }
              }
        }
    },
    typography: {
      fontFamily: 'Inter',
    },
    palette: {
      primary: {
        light: '#FFD654',
        main: '#FFC50B',
        dark: '#E6B10A',
        contrastText: '#000',
      }
    },
});

export default theme