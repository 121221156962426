import React from 'react';
import Stack from '@mui/material/Stack';
import { ReactComponent as PatternThree } from '../../../assets/img/PatternThree.svg';
import { ReactComponent as PatternTwo } from '../../../assets/img/PatternTwo.svg';
import { ReactComponent as PatternOne } from '../../../assets/img/PatternOne.svg';
import { ReactComponent as CaseWhite } from '../../../assets/img/case-white.svg';
import { ReactComponent as CaseBlack } from '../../../assets/img/case-black.svg';
import DefaultCaseIcon from '../../../assets/img/DefaultCaseIcon.png';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    //   padding: theme.spacing(1),
    padding: '5px',
    textAlign: 'center',
    fontSize: '0.8em',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));



const BlogCard = ({ blog, handlePdfRequest }) => {
    return (
        <>
            {blog && (
                // col-sm-12 
                // col-md-3 
                <div className='
                col-xl-3 
                col-lg-4
                col-md-6
                p-2 d-flex justify-content-center  case-card-modify'  >
                    <div className='case-card-container cursor-pointer'
                        {...(blog['templateType'] === 'page'
                            ? { onClick: () => window.location.href = `/case-studies/${blog['url']}` }
                            : { onClick: () => handlePdfRequest(blog) }
                        )}
                        style={{ backgroundColor: blog['cardColor']?.hex || 'black' }}>
                        <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: '20%' }}>
                            {blog['categories'].length !== 0 && (
                                <Stack direction="row" spacing={1} className='col-10 ms-1 card-stack'>
                                    <Item
                                        title={blog['categories'].map(item => item).join(', ')}
                                        style={{ maxWidth: '80%', backgroundColor: blog['cardTextBackgroundColor'] || '#262626' }}
                                    >
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                color: blog['cardTextColor'] || 'white',
                                            }}
                                        >
                                            {blog['categories'].slice(0, 2).map(item => item).join(' & ')}
                                        </div>
                                    </Item>

                                    {blog['categories'].length > 2 && (
                                        <Item
                                            style={{
                                                color: blog['cardTextColor'] || 'white',
                                                backgroundColor: blog['cardTextBackgroundColor'] || '#262626'
                                            }}
                                        >
                                            + {blog['categories'].length - 2}
                                        </Item>
                                    )}
                                </Stack>
                            )}
                        </div>

                        <div className='col-12 d-flex justify-content-center' style={{ height: '60%' }} >
                            <div className='col-10' style={{ height: '80%', maxHeight: '80%', overflow: 'hidden', position: 'relative', zIndex: 10 }} title={blog.title}>
                                <div className='case-study-description-page'

                                    style={{
                                        color: blog['cardTextColor'] || 'white',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '6',
                                        WebkitBoxOrient: 'vertical',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 'bold'
                                    }}>
                                    {blog.title}
                                </div>
                            </div>
                        </div>

                        <div className='col-12 d-flex justify-content-evenly' style={{ height: '3em' }}>
                            <div className='col-5 align-self-end'>
                                {!blog['cardTextColor'] || blog['cardTextColor'] === '#FFFFFF' ? (
                                    <CaseWhite />
                                ) : (
                                    <CaseBlack />
                                )}

                            </div>
                            <div className='col-3 d-flex justify-content-end'>
                                {blog?.['image_key'] ? (
                                    <img
                                        src={blog['image_key']}
                                        alt="blog image"
                                        className="card-icon"
                                    />
                                ) : (
                                    null
                                )}



                            </div>
                        </div>

                        {blog['cardPattern'] === 'PatternOne' && <PatternOne className='previewPattern' />}
                        {blog['cardPattern'] === 'PatternTwo' && <PatternTwo className='previewPattern' />}
                        {blog['cardPattern'] === 'PatternThree' && <PatternThree className='previewPattern' />}
                        {!['PatternOne', 'PatternTwo', 'PatternThree'].includes(blog['cardPattern']) && <PatternOne className='previewPattern' />}

                    </div>
                </div>
            )}
        </>
    );

};

export default BlogCard;
