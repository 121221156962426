import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { Button, Tooltip } from "@mui/material";
import { useEffect } from 'react';

function AddFieldsModal({ showFieldsModal, handleCloseFieldsModal, handleAddField }) {

    useEffect(() => {
        reset();
    }, [showFieldsModal]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const validateDifference = (field) => (_, allValues) => {
        const min = parseInt(allValues.min);
        const max = parseInt(allValues.max);
        if (field === "min") {
            const return_val = max ? min <= max : true;
            return return_val;
        }
        const return_val = min ? max >= min : true;
        return return_val;
    };

    return (
        <>
            <Modal
                show={showFieldsModal}
                onHide={handleCloseFieldsModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Modal.Header className="text-center" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="w-100 fw-bold">
                        Add Field
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(handleAddField)}>
                        <Form.Group className="mb-3" controlId="label">
                            <Form.Label className="required">
                                Label
                            </Form.Label>
                            <Tooltip title="label desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Control
                                type="text"
                                {...register("label", { required: true, minLength: 2, maxLength: 50 })}
                                isInvalid={errors.label}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="placeholder">
                            <Form.Label className="required">
                                Placeholder
                            </Form.Label>
                            <Tooltip title="placeholder desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Control
                                type="text"
                                {...register("placeholder", { required: true, minLength: 2, maxLength: 50 })}
                                isInvalid={errors.placeholder}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="is_required">
                            <Form.Label>
                                Is required
                            </Form.Label>
                            <Tooltip title="is required desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Check
                                type="checkbox"
                                {...register("is_required")}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="type">
                            <Form.Label className="required">
                                Type
                            </Form.Label>
                            <Tooltip title="is required desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Select
                                aria-label="select type of field"
                                defaultValue=""
                                {...register("type", { required: true, minLength: 1 })}
                                isInvalid={errors.type}
                            >
                                <option disabled selected value="">Select type of field</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="min">
                            <Form.Label className="required">
                                Minimum length
                            </Form.Label>
                            <Tooltip title="min length desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Control
                                type="number"
                                {...register("min", { required: true, min: 0, validate: validateDifference("min") })}
                                isInvalid={errors.min}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="max">
                            <Form.Label className="required">
                                Maximum length
                            </Form.Label>
                            <Tooltip title="max length desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Control
                                type="number"
                                {...register("max", { required: true, min: 0, validate: validateDifference("max") })}
                                isInvalid={errors.max}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="regex">
                            <Form.Label>
                                Regex
                            </Form.Label>
                            <Tooltip title="min length desc" arrow placement="right">
                                <i className="fa-solid fa-circle-info info-icon ml-2 color-grey"></i>
                            </Tooltip>
                            <Form.Control
                                type="text"
                                {...register("regex")}
                            />
                        </Form.Group>
                        <div className="text-center">
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddFieldsModal;