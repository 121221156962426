import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import missionimg from '../../assets/img/investors/mission_img.png';
import sce1 from '../../assets/img/hotels/1-sce.jpg';
import sce2 from '../../assets/img/hotels/2-sce.jpg';
import sce3 from '../../assets/img/hotels/3-sce.jpg';
import sce4 from '../../assets/img/hotels/4-sce.jpg';
import sce5 from '../../assets/img/hotels/5-sce.jpg';
import sce6 from '../../assets/img/hotels/6-sce.jpg';
import sce7 from '../../assets/img/hotels/7-sce.jpg';
import profileuser from '../../assets/img/hotels/profile-user.png';

const Testimonial = ({ end_point }) => {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: "30px" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: "30px" }}
                onClick={onClick}
            />
        );
    }
    const slider_data = [
        {
            "test_data": "I have used multiple RMS tools before, and this is by far the best. I appreciate the way your team has always catered to our specific needs.",
            "img": sce1,
            "alt": "Revenue Manager - A Renowned 5-Star Hotel Chain",
            "test_desg": "Revenue Manager",
            "test_desg2": "A Renowned 5-Star Hotel Chain",
        },
        {
            "test_data": "We approached Sciative Solutions with our pricing problem, and they integrated their solution within a week. Since then, I feel a lot more relieved.",
            "img": sce2,
            "alt": "Director of Revenue Strategy - International Hotel Brand",
            "test_desg": "Director of Revenue Strategy",
            "test_desg2": "International Hotel Brand",

        },
        {
            "test_data": "Thanks to Zettaprice, I can track my prices effortlessly, this has saved me a lot of time.",
            "img": sce3,
            "alt": "Senior Data Analyst - Upscale Hotel Brand",
            "test_desg": "Senior Data Analyst",
            "test_desg2": "Upscale Hotel Brand",

        },
        {
            "test_data": "After two months of using Zettaprice, I know our pricing strategy is in the right hands",
            "img": sce4,
            "alt": "Revenue Optimization Director - 4 Star International Hotel Chain",
            "test_desg": "Revenue Optimization Director",
            "test_desg2": "4 Star International Hotel Chain",

        },
        {
            "test_data": "Zettaprice provides top-notch service. They’re always ready to help with any issue and are willing to make adjustments to meet our needs. It’s been a great experience working with them.",
            "img": sce5,
            "alt": "IT Manager - Renowned Resort Chain",
            "test_desg": "IT Manager",
            "test_desg2": "Renowned Resort Chain",

        },
        {
            "test_data": "Zettaprice has been a reliable partner in our revenue management. Their tools and insights are spot-on, and we’ve seen real growth since we started using their services",
            "img": sce6,
            "alt": "Head of Sales - Leading Luxury Hotel Chain in India",
            "test_desg": "Head of Sales",
            "test_desg2": "Leading Luxury Hotel Chain in India",
        },
        {
            "test_data": "Working with Zettaprice has been a game-changer for us. Their system is not only fast and easy to integrate, but their team is always quick to help out whenever we need it.",
            "img": sce7,
            "alt": "General Manager -  Luxury 3-Star Hotel",
            "test_desg": "General Manager",
            "test_desg2": "Luxury 3-Star Hotel",

        },

    ]
    const slidersettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,

        // speed: 2000,
        // autoplaySpeed: 2000,
        // cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

    };


    return (
        <div className="hotel_testimonial_slider" data-aos="new-animation">
            <div className="inner_ret_slider" >
                <div className="container">
                    <h2 className="bigHead text-center mb-3">Hear it from our clients</h2>

                    <div className="layer_content_3">
                        <Slider {...slidersettings}  >
                            {slider_data.map((item, index) => (
                                <>
                                    <div className="">
                                        <div className="row slider_div">
                                            <div className="col-md-4 order-md-2">
                                                <div key={index.alt} className="slider_card">
                                                    <img src={item.img} alt={item.alttext} />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="slider_card2">
                                                    <p className="slider_para">{item.test_data}</p>
                                                    <div className="slider-card2-1">
                                                        <div>
                                                            <img src={profileuser} alt={item.alt} />
                                                        </div>
                                                        <div className="ml-2">
                                                            <p className="slider_para">{item.test_desg}</p>
                                                            <p className="slider_para">{item.test_desg2}</p>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Testimonial;