import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";

const HotelIntegration = ({end_point}) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if(end_point){
      setUrl(end_point)
    }
  }, [end_point])
return(<>
 <section className="investor-result  why_result"  data-aos="new-animation" >
          <div className="container" >
            <h2 className="bigHead text-center mb-5" >Our top-tier partnerships will elevate your success</h2>
            <div className="row">
              <div className="col-6 col-md-3 offset-md-2 num_border">
                <div className="num_div">
                  <p className="number">
                  43+
                  </p>
                  {/* <p className="num_desc">
                  Repeat Purchase Growth

                  </p> */}
                </div>
                <p className="num_desc_2">
                PMS Integration
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-6 col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  50+
                  </p>
                  {/* <p className="num_desc">
                  Forecast Accuracy

                  </p> */}
                </div>
                <p className="num_desc_2">
                CRS Integrations
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-6 col-md-3 num_border">
                <div className="num_div">
                  <p className="number">
                  27+
                  </p>
                  {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                </div>
                <p className="num_desc_2">
                Booking Engine 
                </p>
              </div>
              {/* col-4 end */}

              
              </div>
             
          </div>
        </section>
        {/* <section className="retail_bg_cta">
            <div className="container">
                <div className="card ap_card">
                    <p className="card_text">Explore the difference with Zetta</p>
                    <Link to={`/retail/book-a-demo?navigation=${url}`}>
                        <button className="yellow-button">Book a Demo </button>
                    </Link>
                </div>
            </div>
        </section> */}
</>)

}


export default HotelIntegration;