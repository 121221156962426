import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function QAScore({ qa_list, end_point }) {

    const [score, setScore] = useState(0);

    const [negativeIndexes, setNegativeIndexes] = useState([]);
    const [positiveIndexes, setPositiveIndexes] = useState([]);

    const handleNegativeClick = (index) => {
        if (positiveIndexes.includes(index)) {
            setPositiveIndexes(positiveIndexes.filter((i) => i !== index));
            setNegativeIndexes([...negativeIndexes, index]);
            setScore(score - 1);
        }
    }

    const handlePositiveClick = (index) => {
        if (negativeIndexes.includes(index)) {
            setNegativeIndexes(negativeIndexes.filter((i) => i !== index));
            setPositiveIndexes([...positiveIndexes, index]);
            setScore(score + 1);
        }
    }

    useEffect(() => {
        const negativeIndexes = Array.from({ length: qa_list.length }, (_, index) => index);
        setNegativeIndexes(negativeIndexes);
    }, [])


    return (
        <>
            <section className="qa_retail">
                <div className="container" data-aos="new-animation">
                    <h2 className="bigHead text-center mb-5">
                        Up for a Q&A Session
                    </h2>
                    <div className="qa_quest">
                        {
                            qa_list.map((qa, index) => {
                                return (
                                    <div key={index} className="card">
                                        <div className="card-upper">
                                            <p>{qa}</p>
                                            <div className="icons">
                                                <button className={`positive-bg ${positiveIndexes.includes(index) ? 'positive-active-btn' : ''}`} onClick={() => handlePositiveClick(index)}>Yes</button>
                                                <button className={`negative-bg ${negativeIndexes.includes(index) ? 'negative-active-btn' : ''}`} onClick={() => handleNegativeClick(index)}>No</button>
                                            </div>
                                        </div>
                                        <div className="card-lower">
                                            <span>Your Score</span>
                                            <span className="active"> {score}/{qa_list.length} </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section class="retail_bg_cta comp_cta">
                <div class="container">
                    <div class="card ap_card game_card">
                        <div className="w-80 comp_card text-center">
                            <span className="stext">Your score</span>
                            <span className="snum"> {score}/{qa_list.length} </span>
                            <span>|</span>
                            <span className="snum">{qa_list.length}/{qa_list.length}</span>
                            <span className="stext">Your Competitor Brand Score</span>
                        </div>
                        <p class="card_text">
                            {
                                score === qa_list.length ?
                                    "Well done! Let's now proceed with increasing your profitability by 21%."
                                    :
                                    `See the ${qa_list.length - score} key ${qa_list.length - score > 1 ? "areas" : "area"} where your competition is better than you`
                            }

                        </p>
                        <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                            <button type="button" class="yellow-button btn btn-primary">Get Started Now</button>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default QAScore;