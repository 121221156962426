const AllRoutes = {
    home: '/',
    retail: '/retail',
    travel: '/travel',
    hotel: '/hotels',
    about: '/about-us',
    aboutcontact: '/about-us/contact',
    investors: '/investors',
    investorscontact: '/investors/contact',
    partner: '/partners',
    partnercontact: '/partners/contact',
    careers: '/careers',
    contact: '/contact',
    retailcontact: '/retail/book-a-demo',
    travelcontact: '/travel/book-a-demo',
    news: '/media',
    routereports: '/route-reports',
    retailwhyus: '/retail/why-us',
    automatedpricingpage: '/retail/automated-retail-price-management',
    unifiedpricingpage: '/retail/unified-retail-pricing-software',
    goalbasedpage: '/retail/goal-driven-retail-pricing',
    competitionintpage: '/retail/retail-competition-intelligence',
    pricingconsultpage: '/retail/retail-pricing-consulting',
    otherdriver: '/retail/other-pricing-drivers',
    automated_dp: '/retail/automated-dynamic-pricing',
    pricingautomation: '/retail/pricing-automation',
    comppricetrack: '/retail/competitive-price-tracking',
    marketpricing: '/retail/market-based-pricing',
    electronics: '/retail/electronics',
    hwb: '/retail/health-wellness-beauty',
    food: '/retail/food-consumable',
    fashion: '/retail/fashion-apparel',
    researcharticles: '/research-articles',
    casestudies: '/case-studies',
    roadaheadtalks: '/road-ahead-talks',
    roadaheadnewsletter: '/road-ahead-newsletter',
    privacy: '/privacy-policy',
    cookie: '/cookie-policy',
    terms: '/terms-and-conditions',
    marketintel: '/retail/marketplaces',
    brandseller: '/retail/brands-and-sellers',
    retailers: '/retail/retailers',
    cxodecision: '/retail/cxo-decision-makers',
    categorymanagers: '/retail/category-managers',
    learningmaterials1: '/learning-materials/software-developer',
    learningmaterials2: '/learning-materials/full-stack-developer',
    learningmaterials3: '/learning-materials/dei',
    learningmaterials4: '/learning-materials/product-analyst',
    learningmaterials5: '/learning-materials/marketing',
    learningmaterials6: '/learning-materials/qa',
    hotelfaq: '/hotels/faq',
    hotelcontact: '/hotels/book-a-demo',
    hotelcustomer: '/hotels/customer-stories',
    bannedRoute1: '/docs',
    bannedRoute2: '/index.html',
    bannedRoute3: '/openapi.json',
    bannedRoute4: '/redoc',
    
    // Add more route paths as needed
    ProfitCalculatorPage: '/travel/profit-calculator',
    retailStaticPage: '/sciative-snapshot',
    glossary: '/glossary',
    ebooks: '/e-books',
    aus: '/australia-landing-page'
  };
  
  export default AllRoutes;