import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';

import BlogsSection from './components/BlogsSection';
import ShortStories from "../../components/common/ShortStories";
import SocialMedia from './components/SocialMedia';
import ProductNumbers from '../../components/retail/productnumber';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Form from '../../components/Forms/Form';
import { toast } from 'react-toastify';
import '../../assets/css/blogs.css'

import blogCategories from '../../data/blogCategories';

import chats2 from '../../assets/img/chats2.png'
import notes2 from '../../assets/img/notes2.png'
import Contributor from '../../components/common/contributor';

const BlogsByCategory = () => {

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [topBlogs, setTopBlogs] = useState([]);
    const [category, setCategory] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [RATitle, setRATitle] = useState('');
    const [RAUrl, setRAUrl] = useState('');
    const [RAImg, setRAImg] = useState('');
    const [guestModal, setGuestModal] = useState(false);
    const [writerModal, setWriterModal] = useState(false);

    const { cat } = useParams();
    const navigate = useNavigate();

    const props = {
        template: 2,
        form_name: "blogs-catalogue", // Page name
        fields: [{
            name: "email",
            required: true
        }],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form submitted successfully!');
              } else {
                toast.error(res.data)
              }
        }
    }

    const guestSpeakerProps = {
        template: 1,
        form_name: "become-a-guest-speaker",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become Our Guest Speaker",
        content_upper: "Got an amazing story to share? Become a featured guest on our podcast and share your insights with our audience. Submit your topic idea and let's make magic together on air.",
        content_lower: "Your voice could reach thousands of eager listeners!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                setGuestModal(false);
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }

    const guestWriterProps = {
        template: 1,
        form_name: "blogs-catalogue-become-a-guest-writer",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become a Guest Writer",
        content_upper: "Got a great idea? We want to hear it! Submit your blog topic here and become a valued contributor to our site.",
        content_lower: "Your insights could reach thousands!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            setGuestModal(false);
            if (res.status == "success"){
                toast.success('Form submitted successfully!');
              } else {
                toast.error(res.data)
              }
        }
    }

    const getBlogsByCategory = async () => {
        try {
            const response =
                category !== "all" ?
                    await axios.post(`/fetch_blogs_by_category`, { category }, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    })
                    :
                    await axios.get(`/fetch_all_blogs`, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });
            if (response.status == 200 && response.data !== undefined) {
                setBlogs(response.data.filter(item => item['active'] == true))
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    const getRecentRATalk = async () => {
        try {
            const response = await axios.get(`/ra_talks/fetch_all_webinars`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                // console.log(response.data)
                setRATitle(response.data[0]['title'])
                setRAUrl('/road-ahead-talks/' + response.data[0]['seo']['pageURL'])
                setRAImg(response.data[0]['pre-img']['pre-img-key'])
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    useEffect(() => {
        if (category) {
            getBlogsByCategory()
            getRecentRATalk()
        }
    }, [category])

    useEffect(() => {
        if (cat) {
            setCategory(cat)
        }
    }, [cat]);

    useEffect(() => {
        if (category) { setSelectedCategory(category) }
    }, [category]);

    useEffect(() => {
        setCategories(blogCategories)
    }, [])
    return (
        <>
            <main style={{ paddingTop: '10em' }} className='bg-beige'>
                <div className='container'>

                    <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
                        <h1 className='bold'>{category.charAt(0).toUpperCase() + category.slice(1)}</h1>
                        <h4 className='thin'>Research Articles</h4>
                    </div>
                </div>

                <div className='my-4 w-100 container'>
                    <div className='mb-3 filter-section w-100 d-flex align-items-center justify-content-center flex-wrap'>
                        {/* <span className='d-none d-md-inline bold medium-3 mr-2'>Select category</span> */}
                        <button onClick={() => navigate('/research-articles/category/All')} className={`mx-1 ${selectedCategory.toLowerCase() === 'all' ? 'selected' : ""}`}>All topics</button>
                        {categories.map(cat => <button onClick={() => navigate(`/research-articles/category/${cat}`)} className={`mx-1 ${selectedCategory === cat ? 'selected' : ""}`}>{cat}</button>)}
                    </div>
                    {
                        blogs.length ?
                            <BlogsSection setTopBlogs={setTopBlogs} category={category} blogs={blogs} />
                            :
                            <Alert className='d-flex justify-content-center' severity="warning">No Blogs found with this category.</Alert>
                    }
                </div>

                <div className='mt-4 w-100 d-flex justify-content-center align-items-center'>
                    <button onClick={() => setGuestModal(true)} className='py-2 px-3 btn-black'>Become a Guest Writer <i class="mx-2 fa-solid fa-arrow-right"></i></button>
                </div>

                <div className='mt-4 p-3 py-5 container d-flex flex-column align-items-center'>
                    <div className='w-100 d-flex justify-content-center'>
                        <h4 className='bold'>Road Ahead Talks</h4>
                    </div>
                    <div className='mt-3 w-100 row'>
                        <div className='mb-3 col-sm-12 col-md-6 p-sm-1 p-md-3 p-lg-5 d-flex flex-column justify-content-center align-items-center'>
                            <h2 className='text-center bold text-black'>{RATitle}</h2>
                            <a href={RAUrl} className='text-center p-2 mt-3 btn-black'>Know more</a>
                        </div>
                        <div className='col-sm-12 col-md-6 p-2'>
                            <img className='w-100 corner' src={RAImg} />
                        </div>
                    </div>
                </div>

                <div className='p-3 py-1 d-flex flex-sm-column flex-md-row align-items-center'>
                    <ShortStories />
                </div>

                <div className='mt-4 p-3 py-5 d-flex flex-sm-column flex-md-row align-items-center'>
                    <SocialMedia />
                </div>

                <Contributor type='dark'/>


                
            </main>
        </>
    )
};

export default BlogsByCategory;