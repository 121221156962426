import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import ClipLoader from "react-spinners/ClipLoader";

function CareersForm(props) {


    let [loading, setLoading] = useState(false);

    const name_pattern = /^[a-zA-Z\s]+$/;
    const email_pattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,})$/;
    const phone_pattern = /^[789]\d{9}$/;

    const { role, recipients_group_id, handleApiResponse } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();

    const [resumeError, setResumeError] = useState("");
    useEffect(() => {
        setValue("role", role);
    }, [setValue, role]);

    // this characters are used in number type of input fields
    const disallow_chars_list_1 = ['e', 'E', '+', '-'];
    const disallow_chars_list_2 = ['e', 'E', '+', '-', '.'];

    const validateDifference = (field1, field2, diffVal) => (_, allValues) => {
        if (allValues[field1] === "" || allValues[field2] === "") return true;
        return !(allValues[field1] - allValues[field2] < diffVal);
    };

    const validateResumeUpload = (file) => {
        const resume = file[0];
        if(resume.type !== 'application/pdf') {
            setResumeError("Only PDF files are allowed");
            return false;
        }
        else if(resume.size > 500000) {
            setResumeError("File size must be less than 500KB");
            return false;
        }
        setResumeError("");
        return true;
    };

    const onSubmit = async (data) => {
        setLoading(true)
        let { resume, ...text_data } = data;

        resume = resume[0];

        const formData = new FormData();
        formData.append('data', JSON.stringify(text_data));
        formData.append('resume', resume);
        formData.append('recipients_group_id', recipients_group_id);
        formData.append('form_name', 'careers-form');
        try {
            const response =
                await axios.post(`/careers_form_submission`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                });
            if (response.status === 200 && response.data !== undefined) {
                if (response.data.status && response.data.status === 'success') {
                    handleApiResponse({
                        status: "success",
                        
                    });
                }
            }
            setLoading(false)
        } catch (error) {
            handleApiResponse({
                status: "error",
                data: error.response.data.message
            });
            setLoading(false)
        }
    }

    return (
        <>
            <h2 style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} className="text-center p-2 rounded">Job Role Application Form</h2>
            <form style={{ height: '100%' }} className="d-flex flex-column justify-content-evenly mt-4" onSubmit={handleSubmit(onSubmit)}>

                <div className="row">
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="name" className="form-label mb-0 text-center">Name</label>
                        <input {...register("name", { required: true, minLength: 2, maxLength: 25, pattern: name_pattern })} type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} id="name" name="name" />
                    </div>
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="email" className="form-label mb-0 text-center">Email Address</label>
                        <input {...register("email", { required: true, pattern: email_pattern })} type="text" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" name="email" />
                    </div>
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="phone" className="form-label mb-0 text-center">Phone Number</label>
                        <input {...register("phone", { required: true, pattern: phone_pattern })} type="number" className={`form-control ${errors.phone ? 'is-invalid' : ''}`} id="phone" name="phone" />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group required col-md-6">
                        <label style={{ lineHeight: '1rem' }} htmlFor="location" className="form-label mb-0 text-center">Current Location</label>
                        <input {...register("location", { required: true, pattern: name_pattern })} type="text" className={`form-control ${errors.location ? 'is-invalid' : ''}`} id="location" name="location" />
                    </div>
                    <div className="form-group required col-md-6">
                        <label style={{ lineHeight: '1rem' }} htmlFor="role" className="form-label mb-0 text-center">Job Role</label>
                        <input {...register("role")} disabled type="text" className={`form-control ${errors.role ? 'is-invalid' : ''}`} id="role" name="role" />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="tenth_percentage" className="form-label mb-0 text-center">10th %/CGPA</label>
                        <input
                            {...register("tenth_percentage", { required: true, min: 1, max: 100 })}
                            step=".001"
                            onKeyDown={(e) => disallow_chars_list_1.includes(e.key) && e.preventDefault()}
                            type="number"
                            className={`form-control ${errors.tenth_percentage ? 'is-invalid' : ''}`} id="tenth_percentage" name="tenth_percentage"
                        />
                    </div>
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="tenth_passing_year" className="form-label mb-0 text-center">10th Passing Year</label>
                        <input
                            {...register("tenth_passing_year", { required: true, min: 1900, max: new Date().getFullYear() })}
                            onKeyDown={(e) => disallow_chars_list_2.includes(e.key) && e.preventDefault()}
                            type="number"
                            className={`form-control ${errors.tenth_passing_year ? 'is-invalid' : ''}`} id="tenth_passing_year" name="tenth_passing_year"
                        />
                    </div>
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="tenth_institute" className="form-label mb-0 text-center">10th Institute Name</label>
                        <input {...register("tenth_institute", { required: true })} type="text" className={`form-control ${errors.tenth_institute ? 'is-invalid' : ''}`} id="tenth_institute" name="tenth_institute" />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="twelth_percentage" className="form-label mb-0 text-center">12th %/CGPA</label>
                        <input
                            {...register("twelth_percentage", { required: true, min: 1, max: 100 })}
                            step=".001"
                            onKeyDown={(e) => disallow_chars_list_1.includes(e.key) && e.preventDefault()}
                            type="number" className={`form-control ${errors.twelth_percentage ? 'is-invalid' : ''}`}
                            id="twelth_percentage"
                            name="twelth_percentage"
                        />
                    </div>
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="twelth_passing_year" className="form-label mb-0 text-center">12th Passing Year</label>
                        <input
                            {...register("twelth_passing_year", { required: true, min: 1900, max: new Date().getFullYear(), validate: validateDifference("twelth_passing_year", "tenth_passing_year", 2) })}
                            onKeyDown={(e) => disallow_chars_list_2.includes(e.key) && e.preventDefault()}
                            type="number"
                            className={`form-control ${errors.twelth_passing_year ? 'is-invalid' : ''}`} id="twelth_passing_year" name="twelth_passing_year"
                        />
                    </div>
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="twelth_institute" className="form-label mb-0 text-center">12th Institute Name</label>
                        <input {...register("twelth_institute", { required: true })} type="text" className={`form-control ${errors.twelth_institute ? 'is-invalid' : ''}`} id="twelth_institute" name="twelth_institute" />
                    </div>
                </div>
            
                <div className="row mt-3">
        
                    <div className="form-group col-md-6">
                        <label style={{ lineHeight: '1rem' }} htmlFor="jee_percentile" className="form-label mb-0 text-center">JEE MAIN Percentile</label>
                        <input
                            {...register("jee_percentile", {min: 0, max: 100 })}
                            onKeyDown={(e) => disallow_chars_list_1.includes(e.key) && e.preventDefault()}
                            step=".001"
                            type="number"
                            className={`form-control ${errors.jee_percentile ? 'is-invalid' : ''}`} 
                            id="jee_percentile" 
                            name="jee_percentile" />
                    </div>
                    <div className="form-group  col-md-6">
                        <label style={{ lineHeight: '1rem' }} htmlFor="cet_percentile" className="form-label mb-0 text-center">CET Percentile</label>
                        <input
                            {...register("cet_percentile", { min: 0, max: 100 })}
                            onKeyDown={(e) => disallow_chars_list_1.includes(e.key) && e.preventDefault()}
                            step=".001"
                            type="number"
                            className={`form-control ${errors.cet_percentile ? 'is-invalid' : ''}`} 
                            id="cet_percentile" 
                            name="cet_percentile"
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="grad_percentage" className="form-label mb-0 text-center">Graduation %/CGPA</label>
                        <input
                            {...register("grad_percentage", { required: true, min: 1, max: 100 })}
                            step=".001"
                            onKeyDown={(e) => disallow_chars_list_1.includes(e.key) && e.preventDefault()}
                            type="number"
                            className={`form-control ${errors.grad_percentage ? 'is-invalid' : ''}`} id="grad_percentage" name="grad_percentage" />
                    </div>
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="grad_passing_year" className="form-label mb-0 text-center">Graduation Passing Year</label>
                        <input
                            {...register("grad_passing_year", { required: true, min: 1900, max: new Date().getFullYear() + 3, validate: validateDifference("grad_passing_year", "twelth_passing_year", 3) })}
                            onKeyDown={(e) => disallow_chars_list_2.includes(e.key) && e.preventDefault()}
                            type="number"
                            className={`form-control ${errors.grad_passing_year ? 'is-invalid' : ''}`} id="grad_passing_year" name="grad_passing_year" />
                    </div>
                    <div className="form-group required col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="grad_institute" className="form-label mb-0 text-center">Graduation Institute Name</label>
                        <input
                            {...register("grad_institute", { required: true })}
                            type="text"
                            className={`form-control ${errors.grad_institute ? 'is-invalid' : ''}`} id="grad_institute" name="grad_institute"
                        />
                    </div>
                </div>

    
                <div className="row mt-3">
                    <div className="form-group col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="post_grad_percentage" className="form-label mb-0 text-center">Post Graduation %/CGPA</label>
                        <input
                            {...register("post_grad_percentage", { min: 1, max: 100 })}
                            step=".001"
                            type="number"
                            onKeyDown={(e) => disallow_chars_list_1.includes(e.key) && e.preventDefault()}
                            className={`form-control ${errors.post_grad_percentage ? 'is-invalid' : ''}`} id="post_grad_percentage" name="post_grad_percentage" />
                    </div>
                    <div className="form-group col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="post_grad_passing_year" className="form-label mb-0 text-center">Post Graduation Passing Year</label>
                        <input
                            {...register("post_grad_passing_year", { min: 1900, max: new Date().getFullYear() + 3, validate: validateDifference("post_grad_passing_year", "grad_passing_year", 2) })}
                            onKeyDown={(e) => disallow_chars_list_2.includes(e.key) && e.preventDefault()}
                            type="number"
                            className={`form-control ${errors.post_grad_passing_year ? 'is-invalid' : ''}`} id="post_grad_passing_year" name="post_grad_passing_year" />
                    </div>
                    <div className="form-group col-md-4">
                        <label style={{ lineHeight: '1rem' }} htmlFor="post_grad_institute" className="form-label mb-0 text-center">Post Graduation Institute Name</label>
                        <input {...register("post_grad_institute", {})} type="text" className={`form-control ${errors.post_grad_institute ? 'is-invalid' : ''}`} id="post_grad_institute" name="post_grad_institute" />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group required col-md-6">
                        <label style={{ lineHeight: '1rem' }} htmlFor="experience_years" className="form-label mb-0 text-center">Total Years of Experience</label>
                        <input
                            {...register("experience_years", { required: true, min: 0 })}
                            step=".001"
                            onKeyDown={(e) => disallow_chars_list_1.includes(e.key) && e.preventDefault()}
                            type="number"
                            className={`form-control ${errors.experience_years ? 'is-invalid' : ''}`} id="experience_years" name="experience_years" />
                    </div>
                    <div className="form-group required col-md-6">
                        <label style={{ lineHeight: '1rem' }} htmlFor="prev_ctc" className="form-label mb-0 text-center">Previous CTC (in LPA)</label>
                        <input
                            {...register("prev_ctc", { required: true, min: 0 })}
                            min="0"
                            step=".001"
                            onKeyDown={(e) => disallow_chars_list_1.includes(e.key) && e.preventDefault()}
                            type="number"
                            className={`form-control ${errors.prev_ctc ? 'is-invalid' : ''}`} id="prev_ctc" name="prev_ctc" />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group col-md-6">
                        <label style={{ lineHeight: '1rem' }} htmlFor="motivation" className="form-label mb-0 text-center">Motivation to join Sciative</label>
                        <textarea {...register("motivation")} type="text" className={`form-control ${errors.motivation ? 'is-invalid' : ''}`} id="motivation" name="motivation" />
                    </div>
                    <div className="form-group required col-md-6">
                        <label style={{ lineHeight: '1rem' }} htmlFor="resume" className="form-label mb-0 text-center">Resume</label>
                        <input {...register("resume", { required: true, validate: validateResumeUpload })} type="file" accept="application/pdf" className={`form-control ${errors.resume ? 'is-invalid' : ''}`} id="resume" name="resume" />
                        {
                            resumeError && <div className="invalid-feedback">{resumeError}</div>
                        }
                    </div>
                </div>
                <div className="text-center py-3">
                    <button type="submit" className="yellow-button">
                    {loading ? (
                                    <ClipLoader color={"#ffffff"} size={15} />
                                ) : (
                                    'Submit'
                                )}  
                    </button>
                </div>
            </form>
        </>
    );
}

export default CareersForm;