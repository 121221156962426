import img1_ from "../../assets/img/retail/market-intelligence/strategic_price_competitive_positioning.png";
import img2_ from "../../assets/img/retail/market-intelligence/competitive_assortment.png";
import img3_ from "../../assets/img/retail/market-intelligence/market_intelligence.png";
import { Tab, Col, Nav, Row } from "react-bootstrap";

const MarketIntelligenceTab = () => {
   
    return(
        <Tab.Container defaultActiveKey="mfirst">
        <Row>
        <Col xl={5}>
            <Nav variant="pills" className="flex-column">
            <Nav.Item>
                <Nav.Link eventKey="mfirst">Competitive Price Monitoring
                 <p>Get easy access to comprehensive market intelligence through real-time monitoring and analysis of price fluctuations on both your website and competitor platforms. We also closely track product availability, price trends, ratings, and more.</p>
                 <div className="investor_edge_div res_img">
                    <img src={img1_} alt="Competitive Price Monitoring" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="msecond">Competitive Assortment
                <p>Analyze and showcase the uniqueness of your product assortment compared to competitors to understand your market distinction.</p>
                <div className="investor_edge_div res_img">
                    <img src={img2_} alt="Competitive Assortment" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="mthird">Strategic Competitive Positioning
                <p>Categorize your product's price competitiveness as L (Lower), C (Competitive), or H (Higher) relative to competitors, gaining strategic insights for data-driven decision-making and enhancing your market advantage.</p>
                <div className="investor_edge_div res_img">
                    <img src={img3_} alt="Strategic Competitive Positioning" className=" "/>
                </div>
                </Nav.Link>
            </Nav.Item>
            </Nav>
        </Col>
        <Col xl={7} className="second_div">
            <Tab.Content>
            <Tab.Pane eventKey="mfirst">
                <div className="investor_edge_div">
                <img src={img1_} alt="Competitive Price Monitoring" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="msecond">
            <div className="investor_edge_div">
                <img src={img2_} alt="Competitive Assortment" />
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="mthird">
            <div className="investor_edge_div">
                <img src={img3_} alt="Strategic Competitive Positioning" />
                </div>
            </Tab.Pane>
            </Tab.Content>
        </Col>
        </Row>
</Tab.Container>
    )

}

export default MarketIntelligenceTab