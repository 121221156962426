import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import '../../assets/css/main.scss';
import rightarrow from '../../assets/img/right-arrow.png';
import retailvideo from '../../assets/videos/retail_video.mp4';

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Button } from 'react-bootstrap';  
import ClientSlider from "../retail/clientSlider";
import Leadership from "../retail/leadership";
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
import ProductNumber from "../retail/productnumber";
import RaTalkSingle from "../retail/ratalksingle";
import TestimonialSlider from "../retail/testimonialslider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";
import RetailNumbers from "../retail/retailNumbers";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailTestimonial from "../retail/retailTestimonial";
import RetailDatapoint from "../retail/retaiDatapoint";
import img10 from "../../assets/img/retail/automated-pricing/10.jpg";
import img11 from "../../assets/img/retail/automated-pricing/11.jpg";
import img12 from "../../assets/img/retail/automated-pricing/12.jpg";
import cancelred from "../../assets/img/cancel-red.png";
import roles_bg from "../../assets/img/retail/roles/role_bg.png";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import AllRoutes from "../../AllRoutes";
import AutomatedPricingTab from "../retail/automatedPricingTab";
import MarketIntelligenceTab from "../retail/marketIntelligenceTab";
import SalesInventoryTab from "../retail/salesInventoryTab";
import DiscountManagementTab from "../retail/discountManagement";
import { Link } from "react-router-dom";
import CustomerDataTab from "../retail/customerDataTab";
import DigitalShelfTab from "../retail/digitalShelfTab";
import InnerHeroRetail from "../retail/innerHeroRetail";
import InnerCard from "../retail/innercard";
import bggoal from "../../assets/img/retail/goalbased-pricing/goal-based-pricing.jpg";
import bgunified from "../../assets/img/retail/unified-pricing/unified-pricing.jpg";
import bgautomated from "../../assets/img/retail/automated-pricing/automated-pricing.png";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailInsideNumsix from "../retail/retailInsideNumSix";
import correct from "../../assets/img/correct.png";
import AOS from "aos";
import { useEffect, useState } from "react";
import { GlobalUrl, GlobalImg } from "../../global";
import "aos/dist/aos.css";
const CXODecisionMakers = ({  }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "cxo-decision-makers";
  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }
    return (
        <>
        <HelmetProvider>
            <Helmet>
            <title>Best AI Pricing Software for CXO & Decision Makers | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.cxodecision}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Sciative empowers CXOs unleash real-time price optimization, maximize profits, and outmaneuver competitors across all channels with AI Automated Pricing Tool" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Best AI Pricing Software for CXO & Decision Makers | Sciative" />
          <meta property="og:description" content="Sciative empowers CXOs unleash real-time price optimization, maximize profits, and outmaneuver competitors across all channels with AI Automated Pricing Tool" />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.cxodecision}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/cxo-decision-makers"  
                }]
              }
               
              `}
            </script>
          </Helmet>
       <InnerHeroRetail end_point={end_point} page_name="cxodecison" page_heading="CXO's and Decision Makers" page_desc1="Elevate Your C-Level Retail Performance By Conquering Challenges And Unleash Full Operational Potential."  />
       
        <section className="new_cards">
          <div className="container" data-aos="new-animation">

            <h2 className="text-center bigHead mb-5">
            Challenges That Keep You Up At Night
            </h2>
              <div className="row">
                  <div className="col-md-4 new_card_2 order-md-2">
                      <div className="textcenter">
                        <img src={roles_bg} alt="challenges image" />
                      </div>
                </div>
                <div className="col-md-4 new_card_1 order-md-1">
                      <div className="new_container con_1">
                        <div className="row">
                        <div className="col-md-1">
                         <img src={cancelred} alt="cancel icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p>Lost Margins Due to Pricing Errors</p> 
                        
                        </div>
                        <div className="col-md-1">
                         <img src={correct} alt="cancel icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p> BRIO corrects underpricing or overpricing instantly with automated market-driven adjustments.</p> 
                        
                        </div>
                      
                       
                       </div>
                      </div>
                      <div className="new_container con_2">
                        <div className="row">
                        <div className="col-md-1">
                         <img src={cancelred} alt="cancel icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p>Customer Dissatisfaction from Inconsistent Pricing</p> 
                        
                        </div>
                        <div className="col-md-1">
                         <img src={correct} alt="correct icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p>BRIO ensures uniform pricing across all channels for consistent customer experiences.</p> 
                        
                        </div>
                      
                       
                       </div>
                      </div>
                </div>
              
                <div className="col-md-4 new_card_3 order-md-3">
                      <div className="new_container con_1">
                        <div className="row">
                        <div className="col-md-1">
                         <img src={cancelred} alt="cancel icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p>Inaccurate Demand Forecasting</p> 
                        
                        </div>
                        <div className="col-md-1">
                         <img src={correct} alt="correct icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p> BRIO enhances inventory accuracy with precise, data-driven demand forecasting.</p> 
                        
                        </div>
                      
                       
                       </div>
                      </div>
                      <div className="new_container con_2">
                        <div className="row">
                        <div className="col-md-1">
                         <img src={cancelred} alt="cancel icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p>Missed Revenue Opportunities</p> 
                        
                        </div>
                        <div className="col-md-1">
                         <img src={correct} alt="cancel icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p>BRIO taps into competitive insights for proactive, opportunity-focused pricing.</p> 
                        
                        </div>
                      
                       
                       </div>
                      </div>
                </div>
                <div className="col-md-4 offset-md-4 new_card_4 order-md-4">
                      
                      <div className="new_container con_2">
                        <div className="row">
                        <div className="col-md-1">
                         <img src={cancelred} alt="cancel icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p>Difficulty in Adapting to Market Changes </p> 
                        
                        </div>
                        <div className="col-md-1">
                         <img src={correct} alt="correct icon" />

                        </div>
                        <div className="col-md-11 new_div_2">
                           <p>BRIO rapidly adjusts pricing strategies to align with evolving market conditions.</p> 
                        
                        </div>
                      
                       
                       </div>
                      </div>
                </div>
              </div>
          </div>
       </section>
       <section className="qa_store role_store">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">Start Increasing Profits Immediately with Brio: <br className="display-none display-md-block"/>Experience Rapid Time-to-Value and Seamless Integration.</h2>
          <p className="text-center mb-5">Discover our extensive product offerings such as</p>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Pricing Engine" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal-Based Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
            </div>
          </div>
        </section>
        <section className="client_det inside_det">
        <div className="container" data-aos="new-animation">
            <h2 className="bigHead mb-5">We Deliver, Our Customer's Speak </h2>
            <div className="card">
              
                <p className="client_test"> Thanks to BRIO's feature extraction and digital shelf analytics in the fast fashion sector, our fashion and apparel brand has seen remarkable improvements. We've experienced a 30% increase in online visibility, allowing us to showcase our products more effectively. Additionally, our inventory turnover has improved by 25%, thanks to the insights on product performance and shelf placement. BRIO has truly revolutionized our approach to fast fashion, enabling us to stay ahead of trends and make data-driven decisions that drive growth. </p>
                <div className="flex_card">
                    <div className="card_1">
                        {/* <img src={clientimg} alt="Rupesh Patil" /> */}
                        <div className="client_desc">
                            <p className="namec">Private Label Fashion Brand</p>
                            <p className="named">North America</p>
                        </div>
                    </div>
                   
                    {/* <img src={kkimg} alt="" className="kk_img" /> */}
                </div>
            </div>
            <div className="text-center mt-5">
              <p className="bold">Our experts are ready to help you with your profit margins now!</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
              <button class="yellow-button">Book your slot!</button>
              </Link>
            </div>
        </div>
    </section>
    <RetailClient page_name="retail_page_client" page_heading="Brands who Leverage Sciative to Stay Ahead"  />
    
    <RetailInsideNumsix end_point={end_point} />
    
        <CapteraAchievements props={"whyus_captera"}/>
       
     
       
            {/* <TestimonialSlider /> */}
        {/* <section className="click_button ">
            <div className="container text-center">
                <h2 className="bigHead text-center mb-3 text-white">See How We Can Make a Difference to Your Business</h2>
                <Button className="yellow-button">Explore Sciative</Button>
            </div>
        </section> */}
        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
        </HelmetProvider>
       </>
    );
}


export default CXODecisionMakers;